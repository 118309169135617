import {
    Box3,
    ColorRepresentation,
    DoubleSide,
    Intersection,
    LineBasicMaterial,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    OrthographicCamera,
    PerspectiveCamera,
    Vector3
} from 'three';
import {ThreeEditor} from '../../editors/ThreeEditor/ThreeEditor';
import {AnyAction, Dispatch} from 'redux';
import {CommonObject} from '../../objects/CommonObject/CommonObject';
import {Font, FontLoader} from 'three/examples/jsm/loaders/FontLoader';
import * as threeFont from '../../../assets/fonts/helvetiker_bold.typeface.json';
import {TextGeometry, TextGeometryParameters} from 'three/examples/jsm/geometries/TextGeometry';
import {
    ADD_MESSAGE,
    CHANGE_CONFIG, CHANGE_KITCHEN_NEED_SAVE,
    CHANGE_PROJECT_DATA,
    DEFAULT_ROOM_HEIGHT,
    DEFAULT_ROOM_LENGTH,
    DEFAULT_ROOM_WIDTH,
    DEFAULT_SHOW_MESSAGE_INTERVAL,
    DEFAULT_WALL_DEPTH,
    HIDE_CONTEXT_MENU,
    HIDE_REPLACE_DATA,
    HIDE_SETTINGS_MENU,
    HIDE_UNIT_SPEC, MESSAGE_ID_CANT_EDIT_PROJECT,
    MESSAGE_TYPE_WARNING,
    REMOVE_MESSAGE,
    SHOW_CONTEXT_MENU,
    SHOW_SETTINGS_MENU,
    SIZE_DEFAULT_TEXT_SIZE,
} from '../../../constants';
import {Wall} from '../../objects/Wall/Wall';
import {TPlanesIntersections} from '../../types/TPlanesIntersections';
import {UserControls} from '../../userControls/UserControls/UserControls';
import {i18n} from '../../../i18n';
import {IThreeService} from '../../interfaces/IThreeService';
import {TWizardUrlOptions} from '../../../types/TWizardUrlOptions';
import {IAppConfig} from '../../../../common-code/interfaces/config/IAppConfig';
import {IProjectData} from '../../../../common-code/interfaces/project/IProjectData';
import {TWizardUIOptions} from '../../../types/TWizardUIOptions';
import {TDataForSizeByParent} from '../../../../common-code/types/TDataForSizeByParent';
import {TAspectData} from '../../../../common-code/types/TAspectData';
import {TMessage} from '../../../types/TMessage';
import {IContextMenu} from '../../../interfaces/IContextMenu';
import {IContextIcon} from '../../../interfaces/IContextIcon';
import {ISaveRoomData} from '../../../../common-code/interfaces/saveData/ISaveRoomData';
import axios, {AxiosResponse} from 'axios';
import {IUserData} from '../../../../common-code/interfaces/IUserData';
import {
    CONNECTION_TYPE_DEFAULT,
    DEFAULT_PROJECT_ID,
    DEFAULT_ROOM_ID,
    POINT_TYPE_ROOM
} from '../../../../common-code/constants';
import {ISavePoint2DData} from '../../../../common-code/interfaces/saveData/ISavePoint2DData';
import {ISaveWallData} from '../../../../common-code/interfaces/saveData/ISaveWallData';
import {CommonHelper} from 'common-code';
import {IProjectSpecData} from '../../../../common-code/interfaces/project/IProjectSpecData';
import {IHistoryState} from '../../interfaces/history/IHistoryState';
import {TLocationData} from '../../../../common-code/types/TLocationData';
import {TKitchenSizesType} from '../../../types/TKitchenSizesType';
import {TKitchenImages} from '../../../../common-code/types/TKitchenImages';
import {TKitchenCuttingImages} from '../../types/TKitchenCuttingImages';
import {IProjectImages} from '../../../../common-code/interfaces/project/IProjectImages';
import {ISpecDataApron} from '../../../../common-code/interfaces/spec/ISpecDataApron';
import {ISpecDataFacade} from '../../../../common-code/interfaces/spec/ISpecDataFacade';
import {ISpecDataTabletop} from '../../../../common-code/interfaces/spec/ISpecDataTabletop';
import {ISpecDataEuroZapil} from '../../../../common-code/interfaces/spec/ISpecDataEuroZapil';
import {IClientSessionData} from '../../../../common-code/interfaces/session/IClientSessionData';
import {TSizes} from '../../../../common-code/types/geometry/TSizes';

export class EditorService implements IThreeService {
    public urlOptions: TWizardUrlOptions;
    public appConfig: IAppConfig;

    public projectData: IProjectData;
    protected editor: ThreeEditor;
    protected userControls: UserControls;
    protected reduxDispatch: Dispatch;
    protected threeFont?: Font;
    protected lastId: number;
    protected currentIds: { [n: number]: number };
    protected kitchenOptions: TWizardUIOptions;

    public ready: boolean;

    protected sessionData: IClientSessionData;

    threeEditorOnResize: () => void;
    threeEditorPointerMove: (event: PointerEvent) => void;
    objectList: { [s: string]: CommonObject };

    sizeLineMaterial?: LineBasicMaterial;
    sizeTextMaterial?: MeshBasicMaterial;

    constructor(editor: ThreeEditor,
                userControls: UserControls,
                kitchenOptions: TWizardUIOptions,
                urlOptions: TWizardUrlOptions,
                reduxDispatch: Dispatch,
                appConfig: IAppConfig,
                projectData: IProjectData,
                sessionData: IClientSessionData) {
        this.editor = editor;
        this.kitchenOptions = kitchenOptions;
        this.urlOptions = urlOptions;
        this.appConfig = appConfig;
        this.projectData = projectData;
        this.editor.setService(this);
        this.userControls = userControls;
        this.sessionData = sessionData;
        this.threeEditorOnResize = () => this.editor.onResize();
        this.threeEditorPointerMove = (event: PointerEvent) => this.editor.onPointerMove(event);
        this.reduxDispatch = reduxDispatch;
        this.objectList = {};
        this.lastId = 1;
        this.currentIds = {};
        this.ready = false;
    }

    public remove() {
        this.hideContextMenu();
        this.hideSettingsMenu();
        this.hideReplaceMenu();
        this.hideUnitSpecMenu();
    }

    public isReady() {
        return this.editor.isReady() && this.ready;
    }

    public getEditor(): ThreeEditor {
        return this.editor;
    }

    public getUserControls(): UserControls {
        return this.userControls;
    }

    public getCamera(): PerspectiveCamera | OrthographicCamera {
        return this.editor.getCamera();
    }

    public rebuildScene(): boolean {
        throw new Error('Method not implemented.');
    }

    public setHistoryState(state: IHistoryState) {
        throw new Error('Method not implemented.');
    }

    public setSessionData(session: IClientSessionData) {
        this.sessionData = session;
    }

    public updateProjectData() {
        let projectData: IProjectData;

        projectData = this.calculateProjectData();
        if (CommonHelper.md5(this.projectData) !== CommonHelper.md5(projectData)) {
            this.projectData = projectData;
            this.setProjectDataRedux();
        }
    }

    public getProjectData(): IProjectData {
        return CommonHelper.deepCopy(this.projectData);
    }

    public canChangeProject(): boolean {
        return !this.projectData.orderId && !this.projectData.freeze;
    }

    public canChangeSessionProject(): boolean {
        return !this.projectData.orderId &&
            (!this.projectData.sessionId || this.projectData.sessionId === this.getProjectSessionId());
    }

    public getProjectSessionId(): string {
        return '' + this.sessionData.user.id;
    }

    public setAppConfig(appConfig: IAppConfig) {
        this.appConfig = appConfig;
        this.reduxDispatch({
            type: CHANGE_CONFIG,
            payload: CommonHelper.deepCopy(this.appConfig),
        })
    }

    public setLocation(location: TLocationData, sendRedux?: boolean) {
        this.appConfig.location = location;
        if (sendRedux) {
            this.setAppConfig(this.appConfig);
        }
        this.afterSetLocations();
    }

    public getLocation(): TLocationData | undefined {
        return this.appConfig.location;
    }

    public getDataForSizeByParent(): TDataForSizeByParent {
        return {};
    }

    public getFixAspect(): TAspectData | undefined {
        return undefined;
    }

    public getWalls(): Wall[] {
        throw new Error('replace-EditorService-getWalls');
    }

    public getOptions(): TWizardUIOptions {
        return this.kitchenOptions;
    }

    public getDefaultSketchViewMaterial(): MeshBasicMaterial {
        throw new Error('replace-EditorService-getDefaultSketchViewMaterial');
    }

    public getSizeLineMaterial(): LineBasicMaterial {
        if (!this.sizeLineMaterial) {
            this.sizeLineMaterial = new LineBasicMaterial({
                color: '#000000',
                linewidth: 2
            });
        }

        return this.sizeLineMaterial;
    }

    public getDefaultTextGeometryParameters(): TextGeometryParameters {
        return {
            font: this.getFont(),
            size: SIZE_DEFAULT_TEXT_SIZE,
            height: 0,
            curveSegments: 2,
            bevelEnabled: true,
            bevelThickness: 0,
            bevelSize: 1
        }
    }

    public createTextSizeMesh(text: string, parameters?: TextGeometryParameters): Mesh {
        let textGeometry, textMesh, textSettings, textHeight;

        parameters = parameters || this.getDefaultTextGeometryParameters();
        textSettings = {
            ...this.getDefaultTextGeometryParameters(),
            ...parameters
        };
        textGeometry = new TextGeometry(
            text,
            textSettings
        );
        textGeometry.center();
        textGeometry.computeBoundingBox();
        textHeight = 0;
        if (textGeometry.boundingBox) {
            textHeight = textGeometry.boundingBox.max.y - textGeometry.boundingBox.min.y;
        }
        textMesh = new Mesh(textGeometry, this.getSizeTextMaterial());
        textMesh.position.y = textHeight / 2;
        textMesh.applyMatrix4(textMesh.matrix);

        return textMesh;
    }

    public getSizeTextMaterial(): MeshBasicMaterial {
        if (!this.sizeTextMaterial) {
            this.sizeTextMaterial = new MeshBasicMaterial({
                color: '#000000',
                side: DoubleSide
            });
        }

        return this.sizeTextMaterial;
    }

    public getFont(): Font {
        if (!this.threeFont) {
            this.threeFont = new FontLoader().parse(threeFont)
        }

        return this.threeFont;
    }

    public addToScene(object: CommonObject): void {
        this.editor.addToScene(object);
    }
    // Method for WizardEdit
    public addToSceneEDIT(object: CommonObject): void {
        this.editor.addToSceneEDIT(object);
    }

    public removeFromScene(object: CommonObject): void {
        this.removeUId(object.getId());
        this.editor.removeFromScene(object);
    }

    public addToSceneObject3D(object: Object3D): void {
        this.editor.addToSceneObject3D(object);
    }

    public removeFromSceneObject3D(object: Object3D): void {
        this.editor.removeFromSceneObject3D(object);
    }

    public addToCuttingSceneObject3D(object: Object3D): void {
        this.editor.addToCuttingSceneObject3D(object);
    }

    public generateUId(id?: number): number {
        let newId;

        if (id && !isNaN(+id) && !this.currentIds[id]) {
            newId = +id;
        } else {
            while (this.currentIds[this.lastId]) {
                this.lastId++;
            }
            newId = +this.lastId;
        }
        this.currentIds[newId] = newId;

        return newId;
    }

    public removeUId(id: number): void {
        delete this.currentIds[id];
    }

    public hasUid(id: string): boolean {
        return this.objectList[id] !== undefined;
    }

    public getSelectColor(): ColorRepresentation {
        return '#5DB661'
    }

    public getDetailSelectColor(): ColorRepresentation {
        return '#ffe340'
    }

    public getMoveColor(): ColorRepresentation {
        return '#385870'
    }

    public getErrorColor(): ColorRepresentation {
        return '#A02700'
    }

    public getNotMoveColor(): ColorRepresentation {
        return '#f07410'
    }

    public showMessage(messageData: TMessage) {
        if (!messageData.params) {
            messageData.params = {
                id: '' + (+new Date())
            }
        }
        this.reduxDispatch({
            type: ADD_MESSAGE,
            payload: messageData
        });
        if (messageData.autoClose) {
            setTimeout(() => {
                this.reduxDispatch({
                    type: REMOVE_MESSAGE,
                    payload: messageData.params ? messageData.params.id : undefined
                });

            }, messageData.interval || DEFAULT_SHOW_MESSAGE_INTERVAL)
        }
    }

    public getIntersectionPlanes(): TPlanesIntersections {
        let intersects: Intersection[], intersect: Intersection;
        let intersectionList: TPlanesIntersections;

        intersects = this.editor.getRayCasterIntersection(this.editor.getPlanes());
        intersectionList = {}
        if (intersects.length > 0) {
            for (intersect of intersects) {
                if (intersect.object.userData.type) {
                    intersectionList[intersect.object.userData.type] = intersect;
                }
            }
        }

        return intersectionList;
    }

    public getCameraPosition(): Vector3 {
        return this.editor.getCameraPosition();
    }

    public getRoomPolygon() {
        throw new Error('replace-EditorService-getRoomPolygon');
    }

    public getRoomBox(): Box3 {
        throw new Error('replace-EditorService-getRoomBox');
    }

    public getEditorCovers(): { [n: string]: Object3D } {
        return this.editor.getCovers();
    }

    public showContextMenu(contextMenu: IContextMenu) {
        this.reduxDispatch({type: SHOW_CONTEXT_MENU, payload: contextMenu});
    }

    public hideContextMenu() {
        this.reduxDispatch({type: HIDE_CONTEXT_MENU});
    }

    public hideSettingsMenu() {
        this.reduxDispatch({type: HIDE_SETTINGS_MENU});
    }

    public hideReplaceMenu() {
        this.reduxDispatch({type: HIDE_REPLACE_DATA});
    }

    public hideUnitSpecMenu() {
        this.reduxDispatch({type: HIDE_UNIT_SPEC});
    }

    public updateSettingsMenu(object: CommonObject) {
        this.reduxDispatch({type: SHOW_SETTINGS_MENU, payload: object.getId()});
    }

    public applyContextAction(data: IContextIcon): boolean {
        throw new Error('replace-EditorService-applyContextAction');
    }

    public sendToRedux(reduxAction: AnyAction) {
        this.reduxDispatch(reduxAction);
    }

    public getRoomData(isTemplate?: boolean): ISaveRoomData {
        throw new Error('replace-RoomService-getRoomData');
    }

    public setRoomData(roomData: ISaveRoomData) {
        throw new Error('replace-RoomService-setRoomData');
    }

    public saveRoom(isTemplate?: boolean, isCopy?: boolean): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            axios.post<Promise<IProjectData>>(
                '/api/room/save/',
                this.getRoomData(isTemplate),
                {params: {template: isTemplate, copy: isCopy}}
            )
                .then((response: AxiosResponse) => {
                    if (response.data && response.data.id) {
                        this.setRoomData(response.data);
                        resolve(response.data.id);
                    } else {
                        reject();
                    }
                })
                .catch(() => {
                    console.error('saveRoom error')
                    reject();
                });
        });
    }

    public clearProjectSaveData() {

    }

    public saveProject(
        roomId: string,
        isTemplate?: boolean,
        isCopy?: boolean,
        saveImages?: boolean
    ): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let specList: IProjectSpecData;
            let sendProjectData: IProjectData;

            this.updateProjectData();
            sendProjectData = CommonHelper.deepCopy(this.projectData);
            sendProjectData.room = roomId;
            if (isTemplate) {
                sendProjectData.template = true;
            }
            if (isCopy) {
                delete sendProjectData.orderId;
                delete sendProjectData.orderTime;
            }
            if (sendProjectData.orderId) {
                reject();
                return;
            }
            this.clearProjectSaveData();
            specList = this.getProjectSpecData();
            if (saveImages) {
                this.createProjectImages().then((imagesData) => {
                    this.processSaveProject(
                        sendProjectData,
                        specList,
                        imagesData,
                        isTemplate,
                        isCopy
                    ).then((projectId: string) => {
                        resolve(projectId);
                    }).catch(() => {
                        reject();
                    });
                }).catch(() => {
                    reject();
                });
            } else {
                this.processSaveProject(sendProjectData, specList, undefined, isTemplate, isCopy).then((projectId: string) => {
                    resolve(projectId);
                }).catch(() => {
                    reject();
                });
            }
        })

    }

    protected getProjectSpecData(): IProjectSpecData {
        let specList: IProjectSpecData;

        specList = {
            aprons: this.getSpecDataAprons(),
            corners: [],
            edges: [],
            facades: this.getSpecDataFacades(),
            planks: [],
            plinths: [],
            tabletops: this.getSpecDataTabletops(),
            euroSaws: this.getSpecDataEuroZapil(),
            modules: [],
        };

        return specList;
    }

    protected getSpecDataAprons(): ISpecDataApron[] {
        return [];
    }

    protected getSpecDataFacades(): ISpecDataFacade[] {
        return [];
    }

    protected getSpecDataTabletops(): ISpecDataTabletop[] {
        return [];
    }

    protected getSpecDataEuroZapil(): ISpecDataEuroZapil[] {
        return [];
    }

    public createProjectImages(): Promise<IProjectImages> {
        return new Promise<IProjectImages>((resolve, reject) => {
            this.createImages().then((images) => {
                this.createCuttingImages().then((cuttingImages) => {
                    if (images && cuttingImages) {
                        resolve({
                            visual: images.visual,
                            id: this.getProjectData().id,
                            aprons: cuttingImages.apron.image,
                            sketch: images.sketch,
                            extraImages: [],
                            cuttingInfo: {
                                tabletops: cuttingImages.tabletop.cuttingData.map(cuttingItem => {
                                    return {
                                        sizes: cuttingItem.sizes,
                                        position: cuttingItem.position,
                                        rotation: cuttingItem.rotation,
                                        index: cuttingItem.index,
                                        sticker: cuttingItem.sticker,
                                    }
                                }),
                                aprons: cuttingImages.apron.cuttingData.map(cuttingItem => {
                                    return {
                                        sizes: cuttingItem.sizes,
                                        position: cuttingItem.position,
                                        rotation: cuttingItem.rotation,
                                        index: cuttingItem.index,
                                        sticker: cuttingItem.sticker,
                                    }
                                })
                            },
                            tabletops: cuttingImages.tabletop.image
                        });
                    } else {
                        reject();
                    }
                })
            }).catch(() => {
                reject();
            })
        })
    }

    public createImages(sizeType?: TKitchenSizesType): Promise<TKitchenImages | undefined> {
        throw new Error('replace createImages');
    }

    public createCuttingImages(): Promise<TKitchenCuttingImages | undefined> {
        throw new Error('replace createCuttingImages');
    }

    public setRoomPlanes(planes: Object3D[]) {

    }

    protected setProjectDataRedux(disableMessage?: boolean) {
        this.sendToRedux({
            type: CHANGE_PROJECT_DATA,
            payload: CommonHelper.deepCopy(this.projectData)
        });
        if (!this.canChangeProject() && !disableMessage && this.showEditWarning()) {
            this.showMessage({
                type: MESSAGE_TYPE_WARNING,
                message: i18n.t('Проект не доступен для редактирования, при сохранении будет создана копия старого проекта'),
                params: {
                    id: MESSAGE_ID_CANT_EDIT_PROJECT
                },
            })
        }
    }

    protected showEditWarning(): boolean {
        return false;
    }

    protected processSaveProject(
        projectData: IProjectData,
        specList: IProjectSpecData,
        imagesData?: IProjectImages,
        isTemplate?: boolean,
        isCopy?: boolean
    ): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            axios.post<Promise<IProjectData>>(
                '/api/project/save/',
                {project: projectData, specList: specList, template: isTemplate, copy: isCopy, images: imagesData},
            ).then((response: AxiosResponse) => {
                if (response.data) {
                    this.afterSaveProject(response.data).then((projectId: string) => {
                        resolve(projectId);
                    });
                } else {
                    reject();
                }
            })
                .catch((error) => {
                    console.log('saveProject error', error)
                    reject();
                });
        });
    }

    public savePng(pngData: string, fileName: string) {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link)
        link.href = pngData.replace(/^data:image\/[^;]/, 'data:application/octet-stream');
        link.download = fileName;
        link.click();
    }

    public saveArrayBuffer(buffer: ArrayBuffer, fileName: string) {
        this.saveBlob(new Blob([buffer], {type: 'application/octet-stream'}), fileName);
    }

    public saveString(text: string, fileName: string) {
        this.saveBlob(new Blob([text], {type: 'text/plain'}), fileName);
    }

    public saveBlob(blob: Blob, fileName: string) {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link)
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    public isSelectErrorObjects(): boolean {
        return false;
    }
    public isSelectPriceErrorObjects(): boolean {
        return false;
    }

    public static getVersion() {
        return '1.0.0';
    }

    protected afterSetLocations() {
    }

    protected calculateProjectData(): IProjectData {
        return {...this.projectData};
    }

    public static getDefaultProjectData(appConfig: IAppConfig, user: IUserData, collection: string): IProjectData {
        return {
            id: DEFAULT_PROJECT_ID,
            createTime: Math.floor(Date.now() / 1000),
            updateTime: Math.floor(Date.now() / 1000),
            name: i18n.t('Новый проект'),
            room: DEFAULT_ROOM_ID,
            owner: user.id,
            collection: collection,
            showTabletops: true,
            showAprons: true,
            showCorners: true,
            showPlinths: true,
            enableServices: true,
            enableAutoServices: true,
            customer: {...user},
            priceData: {
                facades: 0,
                handles: 0,
                edges: 0,
                planks: 0,
                corners: 0,
                aprons: 0,
                tabletops: 0,
                plinths: 0,
                units: 0,
                services: 0,
                extraOffers: 0,
                full: 0
            },
        };
    }

    public calculateRoomData(roomData: ISaveRoomData, sizes: TSizes): ISaveRoomData {
        let newRoomData = CommonHelper.deepCopy(roomData);
        newRoomData.sizes = {...sizes};
        newRoomData.points = EditorService.getPointsBySizes(newRoomData.sizes);
        newRoomData.walls = EditorService.generateWalls(newRoomData.points);
        newRoomData.version = EditorService.getVersion();

        return newRoomData;
    }

    public static getDefaultRoomData(sizes?: TSizes): ISaveRoomData {
        const roomSizes = this.getDefaultSizes(sizes);
        const points = this.getPointsBySizes(roomSizes);
        return {
            id: DEFAULT_ROOM_ID,
            name: i18n.t('Помещение по-умолчанию'),
            sizes: roomSizes,
            points: points,
            version: this.getVersion(),
            walls: this.generateWalls(points),
            constructive: [],
            roof: {id: 0},
            floor: {id: 0}
        };
    }

    public setNeedSave(value: boolean): boolean {
        this.kitchenOptions.needSave = value;
        this.reduxDispatch({
            type: CHANGE_KITCHEN_NEED_SAVE,
            payload: value
        });

        return this.kitchenOptions.needSave;
    }

    protected afterSaveProject(projectData: IProjectData): Promise<string> {
        return new Promise<string>((resolve) => {
            this.projectData = projectData;
            this.updateProjectData();
            this.setProjectDataRedux();
            this.setNeedSave(false);
            resolve(this.projectData.id);
        });
    }

    protected static generateWalls(points: ISavePoint2DData[]): ISaveWallData[] {
        let walls: ISaveWallData[] = [];
        let index: number;
        let sort: number;
        let lastId: number;
        let pointA: ISavePoint2DData;
        let pointB: ISavePoint2DData;

        sort = 1;
        lastId = 100;
        for (index = 0; index < points.length; index++) {
            pointA = points[index];
            pointB = (points[index + 1] !== undefined) ? points[index + 1] : points[0];
            walls.push({
                id: +(lastId++),
                pointA: pointA.id,
                pointB: pointB.id,
                depth: DEFAULT_WALL_DEPTH,
                sort: +(sort++),
                direction: true,
            });
        }

        return walls;
    }

    protected static getPointsBySizes(sizes: TSizes): ISavePoint2DData[] {
        return [
            {
                id: 1,
                sort: 1,
                type: POINT_TYPE_ROOM,
                value: {x: -sizes.length / 2, y: -sizes.width / 2},
                connectionType: CONNECTION_TYPE_DEFAULT,
            },
            {
                id: 2,
                sort: 2,
                type: POINT_TYPE_ROOM,
                value: {x: sizes.length / 2, y: -sizes.width / 2},
                connectionType: CONNECTION_TYPE_DEFAULT,
            },
            {
                id: 3,
                sort: 3,
                type: POINT_TYPE_ROOM,
                value: {x: sizes.length / 2, y: sizes.width / 2},
                connectionType: CONNECTION_TYPE_DEFAULT,
            },
            {
                id: 4,
                sort: 4,
                type: POINT_TYPE_ROOM,
                value: {x: -sizes.length / 2, y: sizes.width / 2},
                connectionType: CONNECTION_TYPE_DEFAULT,
            },
        ];
    }

    protected static getDefaultSizes(sizes?: TSizes): TSizes {
        return sizes ? sizes : {
            length: DEFAULT_ROOM_LENGTH,
            width: DEFAULT_ROOM_WIDTH,
            height: DEFAULT_ROOM_HEIGHT
        };
    }

}
