import {ThreeKUnit} from '../ThreeKUnit/ThreeKUnit';
import {KitchenService} from '../../../../services/KitchenService/KitchenService';
import {ThreeApron} from '../../details/ThreeApron/ThreeApron';
import {Box3, Vector3} from 'three';
import {
    CLASSNAME_EQUIPMENT_BUILTIN_EXTRACT,
    CLASSNAME_EQUIPMENT_MOUNTED_EXTRACT,
    LEVEL_TOP,
    SIDE_TYPE_BOTTOM,
    SIDE_TYPE_TOP,
    SIZE_TYPE_DEPTH,
    SIZE_TYPE_HEIGHT,
    SIZE_TYPE_WIDTH
} from '../../../../../../common-code/constants';
import {TLevelBoxes} from '../../../../types/TLevelBoxes';
import {ThreeSize} from '../../ThreeSize/ThreeSize';
import {TDetail} from '../../../../types/TDetail';
import {TLevel} from '../../../../../../common-code/types/TLevel';
import {TClassName} from '../../../../../../common-code/types/TClassName';
import {ISaveTopUnitData} from '../../../../../../common-code/interfaces/saveData/ISaveTopUnitData';
import {DEFAULT_VERTICAL_LINE_STICK, UNIT_SIZE_TEXT_SIZE} from '../../../../constants';
import {ISaveSizeData} from '../../../../../../common-code/interfaces/saveData/ISaveSizeData';
import {TPlaneType} from '../../../../types/TPlaneType';
import {PLANE_TYPE_HORIZONTAL, PLANE_TYPE_VERTICAL, PLANE_TYPE_WALL} from '../../../../../constants';
import {ITechnologMap} from '../../../../../../common-code/interfaces/ITechnologMap';

export class ThreeTopUnit extends ThreeKUnit {
    saveData: ISaveTopUnitData;
    aprons: ThreeApron[];

    constructor(options: ISaveTopUnitData, service: KitchenService) {
        super(options, service);
        this.canVerticalMove = true;
        this.saveData = options;
        this.aprons = [];
        this.transparentForBack = true;
    }

    public initState(isRebuild?: boolean) {
        this.createAprons();
        super.initState(isRebuild);
    }

    protected initPosition(): Vector3 {
        if (!this.saveData.position) {
            this.saveData.position = {};
        }
        if (this.saveData.position.y === undefined) {
            this.saveData.position.y = this.defaultYPosition();
        }
        return super.initPosition();
    }

    public setPosition(position?: Vector3): void {
        if (position) {
            this.view3d.position.copy(position);
        } else {
            if (this.view3d.position.y !== this.defaultYPosition()) {
                this.view3d.position.y = this.defaultYPosition();
            }
        }
        this.afterSetPosition();
    }

    public getUnionDetailYPosition(detail: TDetail): number {
        let yPosition: number;

        if (detail instanceof ThreeSize) {
            yPosition = this.view3d.position.y - this.getSideDistance(SIDE_TYPE_BOTTOM) + detail.getUnionYPosition()
        } else {
            yPosition = super.getUnionDetailYPosition(detail);
        }

        return yPosition;
    }

    public getLevel(): TLevel {
        return LEVEL_TOP;
    }

    public defaultYPosition(): number {
        let topFirstYPosition: number;
        let yPosition: number;

        topFirstYPosition = this.service.getTopFirstYPosition();
        switch (this.saveData.catalogCode) {
            case "shkaf-gorizontal-bottom":
            case "shkaf-gorizontal-visokiy-bottom":
            case "shkaf-gorizontal-steklo-visokiy-bottom":
            case "shkaf-gorizontal-steklo-bottom":
                yPosition = this.getSideDistance(SIDE_TYPE_BOTTOM) + topFirstYPosition;
                break;
            default:
                if (this.getHeight() >= this.service.getTopUnitHeight()) {
                    yPosition = this.getSideDistance(SIDE_TYPE_BOTTOM) + topFirstYPosition;
                } else {
                    yPosition = this.service.getInstallTopUnitHeight() - this.getSideDistance(SIDE_TYPE_TOP);
                }
                break;
        }

        return yPosition;
    }

    public afterTryMove(isMove: boolean, isFinal?: boolean): boolean {
        super.afterTryMove(isMove, isFinal);
        if (isMove && isFinal) {
            console.log('TODO rebuild aprons')
        }
        return isMove;
    }

    protected correctMovePosition(movePosition: Vector3) {
        let lines: number[];
        let line: number;
        let delta: number;

        if (!this.getIsStick()) {
            return;
        }
        lines = this.getMoveLines();
        for (line of lines) {
            delta = Math.abs(movePosition.y - this.getSideDistance(SIDE_TYPE_BOTTOM) - line);
            if (delta <= DEFAULT_VERTICAL_LINE_STICK &&
                (line + this.getHeight()) <= this.service.getRoom().getHeight()) {
                movePosition.y = (line + this.getSideDistance(SIDE_TYPE_BOTTOM));
            }
            delta = Math.abs(movePosition.y + this.getSideDistance(SIDE_TYPE_TOP) - line);
            if (delta <= DEFAULT_VERTICAL_LINE_STICK &&
                (line) <= this.service.getRoom().getHeight()) {
                movePosition.y = (line - this.getSideDistance(SIDE_TYPE_TOP));
            }
        }
    }

    protected getIntersectsTypes(): TPlaneType[] {
        return [
            PLANE_TYPE_WALL,
            PLANE_TYPE_VERTICAL,
            PLANE_TYPE_HORIZONTAL,
        ];
    }

    protected availableEquipments(): TClassName[] {
        let availableEquipments: TClassName[];
        let technologMap: ITechnologMap | undefined;

        availableEquipments = super.availableEquipments();
        if (availableEquipments.indexOf(CLASSNAME_EQUIPMENT_BUILTIN_EXTRACT) === -1) {
            availableEquipments.push(CLASSNAME_EQUIPMENT_BUILTIN_EXTRACT);
        }
        technologMap = this.service.getTechnologMap(this.getTechnologMapFacadeId());
        if (technologMap && this.getHeight() <= technologMap.topUnits.defaultHeight) {
            if (availableEquipments.indexOf(CLASSNAME_EQUIPMENT_MOUNTED_EXTRACT) === -1) {
                availableEquipments.push(CLASSNAME_EQUIPMENT_MOUNTED_EXTRACT);
            }
        }

        return this.filterDisableEquipments(availableEquipments);
    }

    protected createAprons() {
        // TODO
    }

    protected getBackWallGap(): number {
        return 0;
    }

    protected setSizesPosition(levelBoxes?: TLevelBoxes) {
        let size: ThreeSize;

        if (!levelBoxes) {
            return;
        }
        for (size of this.sizes) {
            size.setPositionFromLevelBox(levelBoxes.top.min.y - this.view3d.position.y);
        }
    }

    protected initSizesData(): ISaveSizeData[] {
        let sizesData: ISaveSizeData[] = [];
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);

        sizesData.push({
            id: 0,
            pointA: {x: coverBox.min.x, y: coverBox.min.y, z: coverBox.min.z + this.SIZE_GAP},
            pointB: {x: coverBox.max.x, y: coverBox.min.y, z: coverBox.min.z + this.SIZE_GAP},
            type: SIZE_TYPE_WIDTH,
            decimal: 0,
            textSize: UNIT_SIZE_TEXT_SIZE,
            textInvert: true,
            rotation: {z: Math.PI},
            textIndent: 0,
        });
        sizesData.push({
            id: 0,
            pointA: {x: coverBox.max.x, y: coverBox.min.y, z: coverBox.min.z + this.SIZE_GAP},
            pointB: {x: coverBox.max.x, y: coverBox.max.y, z: coverBox.min.z + this.SIZE_GAP},
            type: SIZE_TYPE_HEIGHT,
            decimal: 0,
            rotation: {z: -Math.PI/2},
            textSize: UNIT_SIZE_TEXT_SIZE,
            textIndent: 0,
        });
        sizesData.push({
            id: 0,
            pointA: {x: coverBox.min.x + this.SIZE_GAP, y: coverBox.min.y, z: coverBox.min.z},
            pointB: {x: coverBox.min.x + this.SIZE_GAP, y: coverBox.min.y, z: coverBox.max.z},
            type: SIZE_TYPE_DEPTH,
            decimal: 0,
            textSize: UNIT_SIZE_TEXT_SIZE,
            textInvert: true,
            rotation: {y: Math.PI/2, x: Math.PI},
            textIndent: 0,
        });

        return sizesData;
    }
}