import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import React, {ChangeEvent, MutableRefObject, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './CreateCommonObject.css'
import {ICreateObjectData} from '../../../../common-code/interfaces/createData/ICreateObjectData';
import {ICreateObjectDomElements} from '../../../interfaces/ICreateObjectDomElements';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {DataManager} from '../../../3d/services/KitchenService/managers/DataManager';
import {ICreateObjectDataWidths} from '../../../../common-code/interfaces/createData/ICreateObjectDataWidths';
import {TSelectItem} from '../../../../common-code/types/TSelectItem';
import {Option, OptionSimple} from '@yandex/ui/Select';
import {Svg} from '../../components/Svg';
import {Button, cnButton} from '../../components/Button';
import {Select} from '../../components/Select/Select';
import {searchInCreateObjectData} from '../../../helpers/searchInCreateObjectData';
import {CommonObjectHelper} from '../../../3d/helpers/CommonObjectHelper/CommonObjectHelper';

export const cnCreateCommonObject = cn('CreateCommonObject');

export interface ICreateCommonObjectProps extends IClassNameProps {
    objectData: ICreateObjectData;
    createAction: (objectData: ICreateObjectData, width: string | null, corpusWidth: string | null, createOptions?: any) => void;
    createObjects: MutableRefObject<ICreateObjectDomElements>;
    service: KitchenService;
    searchText: string;
    createOptions?: any;
    type?: string; 
}

export const CreateCommonObject: React.FC<ICreateCommonObjectProps> =
    ({
         className,
         objectData,
         createAction,
         createObjects,
         createOptions,
         service,
         searchText,
         type
     }) => {
        const {t} = useTranslation();
        const widthsData: ICreateObjectDataWidths = DataManager.calculateCreateObjectWidths(objectData);
        const [width, setWidth] = useState<string>(createOptions && createOptions.width ? createOptions.width : (widthsData.defaultWidth || ''));
        const [corpusWidth, setCorpusWidth] = useState<string>(createOptions && createOptions.corpus && createOptions.corpus.width ? createOptions.corpus.width : (widthsData.defaultCorpusWidth || ''));
        const [hoverItem, setHoverItem] = useState<boolean>(false);
        const [hoverImages, setHoverImages] = useState<boolean>(false);

        const onChangeObjectWidth = (event: ChangeEvent<HTMLSelectElement>) => {
            setWidth(event.target.value);
        }

        const onChangeObjectCorpusWidth = (event: ChangeEvent<HTMLSelectElement>) => {
            setCorpusWidth(event.target.value);
        }

        const onCreateObject = () => {
            service.setCreateUnitDynamicOptions(objectData);
            createAction(objectData, width, corpusWidth, createOptions);
        }


        const getSelectOptions = (selectItems: TSelectItem[], value: string, setValue: (value: string) => void): Option[] => {
            let isChangeValue: boolean = false;
            let selectItem: TSelectItem;
            let options: Option[] = [];
            let optionItem: Option;

            for (selectItem of selectItems) {
                if (selectItem.disabled && selectItem.id === value) {
                    isChangeValue = true;
                }
                options.push({value: selectItem.id, content: selectItem.id, disabled: selectItem.disabled})
            }
            if (isChangeValue) {
                for (optionItem of options) {
                    if (!(optionItem as OptionSimple).disabled) {
                        isChangeValue = false;
                        setValue((optionItem as OptionSimple).value);
                        break;
                    }
                }
                if (isChangeValue) {
                    setValue('');
                }
            }

            return options;
        };
        if (!searchInCreateObjectData(objectData, searchText)) {
            return null;
        }

        const handleDeleteModule = () => {
            createAction(objectData, "delete", corpusWidth, createOptions);
        }

        const handleEditModule = () => {
            createAction(objectData, "edit", corpusWidth, createOptions);
        }

        return (
            <div
                className={cnCreateCommonObject({animated: true, hover: hoverItem}, [className])}
                onMouseEnter={() => setHoverItem(true)}
                onMouseLeave={() => setHoverItem(false)}
            >
                <div
                    className={cnCreateCommonObject('Images', {grab: hoverImages && !createOptions})}
                    data-content={t('Перенесите на сцену')}
                    onMouseEnter={() => {setHoverImages(true)}}
                    onMouseLeave={() => {setHoverImages(false)}}
                    onClick={onCreateObject}
                    ref={(element) => {
                        if (element instanceof  HTMLDivElement) {
                            createObjects.current[objectData.uid] = {
                                dom: element,
                                data: objectData,
                                width: width,
                                corpusWidth: corpusWidth
                            }
                        }
                    }}>
                    {
                        objectData.svgImage ?
                            <Svg icon={objectData.svgImage} className={cnCreateCommonObject('Icon')} label={t(objectData.title) || undefined}/> :
                            (
                                objectData.image.length > 0 ?
                                    <img src={objectData.image} alt={t(objectData.title) || undefined} className={cnCreateCommonObject('Image')}/> :
                                    <Svg icon={'no-photo'} className={cnCreateCommonObject('Icon')} label={t(objectData.title) || undefined}/>
                            )
                    }
                </div>
                <div className={cnCreateCommonObject('Name')}>
                    {t(objectData.title)}
                </div>
                {
                    objectData.widthText ?
                        <div className={cnCreateCommonObject('Descr')}>
                            {CommonObjectHelper.getCreateObjectWidthsText(objectData)}
                        </div> : null
                }
                {
                    !createOptions && widthsData.widths.length > 0 ?
                        <div className={cnCreateCommonObject('Widths')}>
                            <div className={cnCreateCommonObject('WidthsTitle')}>{t('Ширина')}</div>
                            <Select
                                className={cnCreateCommonObject('Select')}
                                placeholder={t('Ширина')}
                                showPlaceholder={false}
                                options={getSelectOptions(widthsData.widths, width, setWidth)}
                                value={width}
                                opened={hoverItem}
                                callBack={onChangeObjectWidth}
                            />
                        </div> : null
                }
                {
                    !createOptions && widthsData.corpusWidths.length > 0 ?
                        <div className={cnCreateCommonObject('Widths')}>
                            <div className={cnCreateCommonObject('WidthsTitle')}>{t('Ширина корпуса')}</div>
                            <Select
                                className={cnCreateCommonObject('Select')}
                                placeholder={t('Ширина корпуса')}
                                showPlaceholder={false}
                                options={getSelectOptions(widthsData.corpusWidths, corpusWidth, setCorpusWidth)}
                                value={corpusWidth}
                                opened={hoverItem}
                                callBack={onChangeObjectCorpusWidth}
                            />
                        </div> : null
                }
                {type === 'editModule' ?
                <div className={cnCreateCommonObject('Buttons')}>
                    {!objectData.builtIn ?
                        <>
                        
                        <Button
                            className={cnCreateCommonObject(cnButton())}
                            type={'button'}
                            text={t('Удалить')|| undefined}
                            leftIcon={ undefined}
                            mods={{
                                mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                                hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                            }}
                            callBack={handleDeleteModule}
                        /> 

                        <Button
                            className={cnCreateCommonObject(cnButton())}
                            type={'button'}
                            text={t('Редактировать')|| undefined}
                            leftIcon={ undefined}
                            mods={{
                                mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                                hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                            }}
                            callBack={handleEditModule}
                        /> 
                        </>: null
                    }
                </div>
                :
                <div className={cnCreateCommonObject('Buttons')}>
                    {!objectData.builtIn ?
                        <Button
                            className={cnCreateCommonObject(cnButton())}
                            type={'button'}
                            text={(createOptions ? t('Заменить') : t('Добавить')) || undefined}
                            leftIcon={!createOptions ? {path: 'plus2', width: 16, height: 16, marginRight: 8} : undefined}
                            mods={{
                                mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                                hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                            }}
                            callBack={onCreateObject}
                        /> : null
                    }
                </div>}
            </div>
        );
    }