import { IIntegratedHandle } from "./IntegratedHandles";

export const initionalIntegratedHandle = (): IIntegratedHandle => {
    return {
      id: 0,
      height: 100,
      width: 100,
      functionalType: "default",
      interval: {
        pointA: {
          x: "",
          y: "",
          z: "",
        },
        pointB: {
          x: "",
          y: "",
          z: "",
        },
      },
    };
  };