import {KitchenService} from "../KitchenService";
import {TDirectionSideType} from '../../../../../common-code/types/TDirectionSideType';
import {TClassName} from '../../../../../common-code/types/TClassName';
import {NONE_EQUIPMENT} from '../../../../../common-code/constants';
import {ISaveUnitData} from '../../../../../common-code/interfaces/saveData/ISaveUnitData';
import {IOption} from '../../../../../common-code/interfaces/option/IOption';
import {ISettingFacade} from '../../../../interfaces/settingData/ISettingFacade';
import {ISettingApron} from '../../../../interfaces/settingData/ISettingApron';
import {ISaveKUnitDetailData} from '../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {ISaveKUnitData} from '../../../../../common-code/interfaces/saveData/ISaveKUnitData';
import {ISettingEquipment} from '../../../../interfaces/settingData/ISettingEquipment';
import {ICreateObjectData} from '../../../../../common-code/interfaces/createData/ICreateObjectData';
import {ISaveBuiltInEquipmentData} from '../../../../../common-code/interfaces/saveData/ISaveBuiltInEquipmentData';
import {IMaterialData} from '../../../../../common-code/interfaces/materials/IMaterialData';
import {IHandleData} from '../../../../../common-code/interfaces/materials/IHandleData';

export class RebuildUnitManager {
    service: KitchenService;

    constructor(service: KitchenService) {
        this.service = service;
    }

    public getAllMethods(): any {
        return Object.getPrototypeOf(this);
    }

    public rebuildIsDimensions(saveData: ISaveUnitData, value: boolean): ISaveUnitData {
        return {
            ...saveData,
            isDimensions: value
        }
    }

    public rebuildIsStick(saveData: ISaveUnitData, value: boolean): ISaveUnitData {
        return {
            ...saveData,
            isStick: value
        }
    }

    public rebuildIsLevelStick(saveData: ISaveUnitData, value: boolean): ISaveUnitData {
        return {
            ...saveData,
            isLevelStick: value
        }
    }

    public rebuildIsWallStick(saveData: ISaveUnitData, value: boolean): ISaveUnitData {
        return {
            ...saveData,
            isWallStick: value
        }
    }

    public rebuildOtherOption(saveData: ISaveUnitData, value: {option: IOption, fieldId: string, value: any}) {
        if (value.option.groupId && value.option.groupId in saveData) {
            // @ts-ignore
            let group = saveData[value.option.groupId];

            return {
                ...saveData,
                [value.option.groupId]: {
                    ...group,
                    [value.fieldId]: value.value
                }
            }
        }
        return {
            ...saveData,
            [value.fieldId]: value.value
        }
    }

    public rebuildCorpusMaterial(saveData: ISaveUnitData, value: {value: string}): ISaveUnitData {
        let corpusMaterial: IMaterialData;

        corpusMaterial = this.service.getCorpusMaterial(value.value);
        if (corpusMaterial.id !== value.value) {
            return saveData;
        }
        if (saveData.corpus) {
            return {
                ...saveData,
                corpus: {
                    ...saveData.corpus,
                    material: value.value
                },
                plinths: saveData.plinths ? saveData.plinths.map(plinthData => {
                    if (corpusMaterial.plinthMaterial) {
                        return {
                            ...plinthData,
                            material: corpusMaterial.plinthMaterial
                        }
                    }
                    return plinthData;
                }) : undefined,
            }
        }

        return saveData;
    }

    public rebuildCorpusSizes(saveData: ISaveUnitData, value: {name: string, value: number}): ISaveUnitData {
        if (saveData.corpus) {
            return {
                ...saveData,
                corpus: {
                    ...saveData.corpus,
                    sizes: {
                        ...saveData.corpus.sizes,
                        [value.name]: value.value
                    }
                },
                tabletops: saveData.tabletops ? saveData.tabletops.map(tabletopData => {
                    if (tabletopData.sizes && tabletopData.sizes.length !== undefined && tabletopData.sizes.length > 0) {
                        return {
                            ...tabletopData,
                            sizes: {
                                ...tabletopData.sizes,
                                length: undefined
                            }
                        }
                    }
                    return tabletopData;
                }) : undefined,
                plinths: saveData.plinths ? saveData.plinths.map(plinthData => {
                    if (plinthData.sizes && plinthData.sizes.length !== undefined && plinthData.sizes.length > 0) {
                        return {
                            ...plinthData,
                            sizes: {
                                ...plinthData.sizes,
                                length: undefined
                            }
                        }
                    }
                    return plinthData;
                }) : undefined,
                aprons: saveData.aprons ? saveData.aprons.map(apronData => {
                    if (apronData.sizes && apronData.sizes.length !== undefined && apronData.sizes.length > 0) {
                        return {
                            ...apronData,
                            sizes: {
                                ...apronData.sizes,
                                length: undefined
                            }
                        }
                    }
                    return apronData;
                }) : undefined,
                corners: saveData.corners ? saveData.corners.map(cornerData => {
                    if (cornerData.sizes && cornerData.sizes.length !== undefined && cornerData.sizes.length > 0) {
                        return {
                            ...cornerData,
                            sizes: {
                                ...cornerData.sizes,
                                length: undefined
                            }
                        }
                    }
                    return cornerData;
                }) : undefined,
                sizes: {
                    ...saveData.sizes,
                    [value.name]: value.value
                }
            }
        }

        return saveData;

    }

    public tryUnitSetFacadeMaterial(
        saveData: ISaveUnitData,
        value: {facadeMaterial: string, settingFacade: ISettingFacade}): ISaveUnitData {
        if (saveData.facades) {
            return {
                ...saveData,
                facades: saveData.facades.map(facadeData => {
                    if (value.settingFacade.id === facadeData.id ||
                        (facadeData.groupId && value.settingFacade.groupId === facadeData.groupId)) {
                        return {
                            ...facadeData,
                            facadeMaterial: value.facadeMaterial
                        }
                    }
                    return facadeData;
                })
            }
        }

        return saveData;
    }

    public trySetFacadeSideType(
        saveData: ISaveUnitData,
        value: {sideType: TDirectionSideType, settingFacade: ISettingFacade}): ISaveUnitData {
        if (saveData.facades) {
            let sideType: TDirectionSideType | undefined;
            if (saveData.sideType) {
                sideType = saveData.sideType;
            }
            if (saveData.syncSideTypes !== false && sideType) {
                sideType = value.sideType;
            }
            return {
                ...saveData,
                sideType: sideType,
                facades: saveData.facades.map(facadeItem => {
                    let handleData: IHandleData | undefined;
                    if (value.settingFacade.id === facadeItem.id ||
                        (facadeItem.groupId && value.settingFacade.groupId === facadeItem.groupId)) {
                        handleData = undefined;
                        if (facadeItem.handle && facadeItem.handle.modelId) {
                            handleData = this.service.getHandleData(facadeItem.handle.modelId);
                        }
                        delete facadeItem.handle?.position;
                        delete facadeItem.handle?.rotation;

                        return {
                            ...facadeItem,
                            sideType: value.sideType,
                            handle: this.service.calculateHandleData(
                                facadeItem.handle,
                                handleData,
                                facadeItem.openType,
                                facadeItem.level,
                                facadeItem.sizes,
                                value.sideType
                            )
                        }
                    }
                    return facadeItem;
                })
            }
        }

        return {
            ...saveData,
            sideType: saveData.sideType ? value.sideType : undefined
        };
    }

    public setApronItemMaterial(saveData: ISaveUnitData, value: {apronMaterial: string, apronSetting: ISettingApron}): ISaveUnitData {
        if (saveData.aprons) {
            return {
                ...saveData,
                aprons: saveData.aprons.map((apronData: ISaveKUnitDetailData) => {
                    if (apronData.id === value.apronSetting.id) {
                        return {
                            ...apronData,
                            material: value.apronMaterial
                        }
                    }

                    return apronData;
                })
            };
        }
        return saveData;
    }

    public setApronItemSelfVisible(saveData: ISaveUnitData, value: {apronSetting: ISettingApron, visible: boolean}): ISaveUnitData {
        if (saveData.aprons) {
            return {
                ...saveData,
                aprons: saveData.aprons.map((apronData: ISaveKUnitDetailData) => {
                    if (apronData.id === value.apronSetting.id) {
                        return {
                            ...apronData,
                            isSelfVisible: value.visible
                        }
                    }

                    return apronData;
                })
            };
        }

        return saveData;
    }

    public tryChangeEquipment(saveData: ISaveKUnitData, value: {uid: TClassName | typeof NONE_EQUIPMENT, setting: ISettingEquipment}): ISaveKUnitData {
        let createEquipment: ICreateObjectData | undefined;
        let equipmentSaveData: ISaveBuiltInEquipmentData | undefined;
        let index: string;
        let isSet: boolean;
        let newEquipments: ISaveBuiltInEquipmentData[] | undefined;

        createEquipment = this.service.getCreateUnitByUid(value.uid);
        if (createEquipment) {
            equipmentSaveData = this.service.getDefaultOptions(createEquipment);
        }
        isSet = false;
        if (saveData.equipments) {
            newEquipments = [];
            for (index in saveData.equipments) {
                if (value.setting.cellName === saveData.equipments[index].cellName) {
                    if (equipmentSaveData) {
                        newEquipments.push(equipmentSaveData);
                    }
                    isSet = true;
                } else {
                    newEquipments.push({...saveData.equipments[index]});
                }
            }
        }
        if (!isSet && equipmentSaveData) {
            if (!newEquipments) {
                newEquipments = [equipmentSaveData];
            } else {
                newEquipments.push(equipmentSaveData);
            }
        }

        return {
            ...saveData,
            equipments: newEquipments
        };
    }
}