import React from 'react';
import {IClassNameProps} from "@bem-react/core";
import './WizardSpec.css'
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {cn} from '@bem-react/classname';
import {useTranslation} from 'react-i18next';
import {WizardSpecImages} from './Images/WizardSpec-Images';
import {ISpecItem} from '../../../../common-code/interfaces/catalog/ISpecItem';
import {WizardSpecGroup} from './Group/WizardSpec-Group';

export const cnWizardSpec = cn('WizardSpec')

export interface IWizardSpecProps extends IClassNameProps {
    service: KitchenService;
}

export const WizardSpec: React.FC<IWizardSpecProps> =
    ({
         service,
         className
     }) => {
        const {t} = useTranslation();
        const units: ISpecItem[] = service.getUnitsSpecItems(service.getUnits());
        const facades: ISpecItem[] = service.getDetailsSpecItems(service.getSpecFacades());
        const tabletops: ISpecItem[] = service.getDetailsSpecItems(service.getSpecTabletops());
        const aprons: ISpecItem[] = service.getDetailsSpecItems(service.getSpecAprons());
        const corners: ISpecItem[] = service.getDetailsSpecItems(service.getSpecCorners());
        const plinths: ISpecItem[] = service.getDetailsSpecItems(service.getSpecPlinths());
        return (
            <div className={cnWizardSpec({}, [className])}>
                <div className={cnWizardSpec('Title')}>{t('Спецификация проекта')}</div>
                <div className={cnWizardSpec('Content')}>
                    <WizardSpecImages service={service}/>
                    <div className={cnWizardSpec('Groups')}>
                        <WizardSpecGroup title={t('Модули')} specItems={units}/>
                        <WizardSpecGroup title={t('Фасады')} specItems={facades}/>
                        <WizardSpecGroup title={t('Столешница')} specItems={tabletops}/>
                        <WizardSpecGroup title={t('Стеновая панель')} specItems={aprons}/>
                        <WizardSpecGroup title={t('Уплотнительный уголок')} specItems={corners}/>
                        <WizardSpecGroup title={t('Цоколь')} specItems={plinths}/>
                    </div>
                </div>
            </div>
        );
    };