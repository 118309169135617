import { ThreeDetail } from "../ThreeDetail/ThreeDetail";
import {
  BoxGeometry,
  BufferGeometry,
  DoubleSide,
  Euler,
  ExtrudeGeometry,
  Mesh,
  MeshStandardMaterial,
  Shape,
  Vector2,
  Vector3,
} from "three";
import { ThreeKUnit } from "../../units/ThreeKUnit/ThreeKUnit";
import { ThreeShelf } from "../ThreeShelf/ThreeShelf";
import {
  BOX_TYPE_NORMAL,
  CLASSNAME_CORPUS,
} from "../../../../../../common-code/constants";
import { KitchenService } from "../../../../services/KitchenService/KitchenService";
import { ExtrudeGeometryOptions } from "three/src/geometries/ExtrudeGeometry";
import { IMaterialTextures } from "../../../../interfaces/IMaterialTextures";
import { ThreeBox } from "../ThreeBox/ThreeBox";
import { ThreeBoxNormal } from "../ThreeBox/types/ThreeBoxNormal";
import { ISaveCorpusData } from "../../../../../../common-code/interfaces/saveData/ISaveCorpusData";
import { IMaterialData } from "../../../../../../common-code/interfaces/materials/IMaterialData";
import { CommonHelper } from "common-code";
import { ISaveShelfData } from "../../../../../../common-code/interfaces/saveData/ISaveShelfData";
import { ISaveBoxData } from "../../../../../../common-code/interfaces/saveData/ISaveBoxData";
import { TSizes } from "../../../../../../common-code/types/geometry/TSizes";
import {
  BOTTOM_UNIT_TECHNOLOGICAL_HOLE_DEPTH,
  BOTTOM_UNIT_TECHNOLOGICAL_HOLE_HEIGHT,
  BOTTON_UNIT_TECHNOLOGICAL_HOLE_C_HEIGHT
} from "../../../../constants";

export class ThreeCorpus extends ThreeDetail {
  saveData: ISaveCorpusData;
  unit: ThreeKUnit;
  bodyMaterial?: MeshStandardMaterial;
  shapeBodyMaterial?: MeshStandardMaterial;
  shelves: ThreeShelf[];
  boxes?: ThreeBox[];
  panels: Mesh[];
  service: KitchenService;
  shape: Shape;
  shelfShape: Shape;
  materialData: IMaterialData;
  materialTextures: IMaterialTextures;
  shapeMaterialTextures: IMaterialTextures;

  constructor(options: ISaveCorpusData, unit: ThreeKUnit) {
    super(options, unit);
    this.saveData = options;
    this.service = unit.service;
    this.unit = unit;
    this.view3d.name = CLASSNAME_CORPUS;
    this.shelves = [];
    this.panels = [];
    this.shape = new Shape();
    this.shelfShape = new Shape();
    this.materialData = this.initMaterialData(this.saveData.material);
    // this.materialTextures = this.loadTexturesEDIT();
    this.materialTextures = this.loadTextures();
    this.shapeMaterialTextures = this.loadShapeTextures();
  }

  public initState(isRebuild?: boolean) {
    if (isRebuild) {
      this.materialData = this.initMaterialData(this.saveData.material);
      this.materialTextures = this.loadTextures();
      this.shapeMaterialTextures = this.loadShapeTextures();
      this.bodyMaterial = undefined;
      this.shapeBodyMaterial = undefined;
    }
    this.createShape();
    this.createShelfShape();
    this.addCoverPointsToUnit();
    super.initState(isRebuild);
    this.setPosition(this.initPosition());
  }

  public createView(isRebuild?: boolean) {
    this.createShapePanels();
    this.createShapeShelves();
    this.createFrames();
    this.createPanels();
    this.createShelves();
    this.createBoxes();
    this.createBackPanels();
    this.createOtherDetails();
    this.unit.view3d.add(this.view3d);
    super.createView(isRebuild);
  }

  public removeChildren() {
    this.removePanels();
    this.removeShelves();
    this.removeBoxes();
    super.removeChildren();
  }

  public rebuildView() {
    this.removePanels();
    this.removeShelves();
    this.removeBoxes();
    this.removeOtherDetails();

    this.createShapePanels();
    this.createShapeShelves();
    this.createPanels();
    this.createShelves();
    this.createBoxes();
    this.createBackPanels();
    this.createOtherDetails();
    this.updateAllMatrices();
  }

  public getData(): ISaveCorpusData {
    let data: ISaveCorpusData;

    data = CommonHelper.deepCopy(this.saveData);
    data.position = {
      x: this.view3d.position.x,
      y: this.view3d.position.y,
      z: this.view3d.position.z,
    };
    data.material = this.materialData.id;
    return data;
  }

  public getCatalogCode(): string {
    return this.saveData.catalogCode || "";
  }

  public getPanelMaterialId(): string | undefined {
    return undefined;
  }

  public getFrameMaterialId(): string | undefined {
    return undefined;
  }

  public hasBoxes(): boolean {
    return this.boxes !== undefined && this.boxes.length > 0;
  }

  public getBoxes(): ISaveBoxData[] {
    let boxesData: ISaveBoxData[] = [];
    let box: ThreeBox;

    if (!this.boxes || !this.boxes.length) {
      return boxesData;
    }
    for (box of this.boxes) {
      boxesData.push(box.getData());
    }

    return boxesData;
  }

  protected initPosition(): Vector3 {
    return this.unit.getCorpusInitPosition();
  }

  protected removePanels() {
    let panel: Mesh;

    for (panel of this.panels) {
      this.view3d.remove(panel);
    }
    this.panels = [];
  }

  protected removeShelves() {
    let shelf: ThreeShelf;

    for (shelf of this.shelves) {
      shelf.remove();
    }
    this.shelves = [];
  }

  protected removeBoxes() {
    let box: ThreeBox;

    if (!this.boxes) {
      return;
    }
    for (box of this.boxes) {
      box.remove();
    }
    this.boxes = undefined;
  }

  protected removeOtherDetails() {}

  public setMaterial(material: IMaterialData) {
    this.materialData = material;
    this.materialTextures = this.loadTextures();
    this.shapeMaterialTextures = this.loadShapeTextures();
    this.bodyMaterial = undefined;
    this.shapeBodyMaterial = undefined;
    this.rebuildView();
  }

  public getThickness() {
    return +this.saveData.thickness;
  }

  public getBackThickness() {
    return +this.saveData.backThickness;
  }

  public getInnerWidth() {
    return this.saveData.sizes.length - this.getThickness() * 2;
  }

  public getInnerHeight() {
    return this.saveData.sizes.height - this.getThickness() * 2;
  }

  public getInnerDepth() {
    return this.saveData.sizes.width - this.getBackThickness();
  }

  public getSizes(): TSizes {
    return this.saveData.sizes;
  }

  public getOrderPart(): number | undefined {
    return undefined;
  }

  protected loadTextures(): IMaterialTextures {
    return this.service.loadMaterialTextures(
      this.materialData.id,
      this.materialData.textures
    );
  }

  protected loadTexturesEDIT(): IMaterialTextures {
    return this.service.loadMaterialTexturesEDIT();
  }

  protected loadShapeTextures(): IMaterialTextures {
    if (!this.materialData.textures) {
      return this.service.loadMaterialTextures(
        this.materialData.id,
        this.materialData.textures
      );
    }
    let materialData: IMaterialData;
    let index: string;

    materialData = CommonHelper.deepCopy(this.materialData);
    if (materialData.textures) {
      for (index in materialData.textures) {
        if (!materialData.textures.hasOwnProperty(index)) {
          continue;
        }
        materialData.textures[index].repeat = {
          x: materialData.textures[index].repeat.x * 0.001,
          y: materialData.textures[index].repeat.x * 0.001,
        };
      }
    }

    return this.service.loadMaterialTextures(
      materialData.id,
      materialData.textures
    );
  }

  protected initMaterialData(materialId?: string): IMaterialData {
    return this.service.getCorpusMaterial(materialId);
  }

  protected createShape() {}

  protected createShelfShape() {}

  protected createShapePanels() {}

  protected createShapeShelves() {}

  protected createFrames() {}

  public calculateGlobalFrontVector() {
    this.globalFrontVector = this.unit.globalFrontVector;
    this.calculateChildrenGlobalFrontVector();
    this.setCenterPosition();
  }

  public getBodyMaterial() {
    if (!this.bodyMaterial) {
      this.bodyMaterial = new MeshStandardMaterial({
        roughness: 0.7,
        envMapIntensity: 5,
        map: this.materialTextures.texture || null,
        normalMap: this.materialTextures.normal || null,
        displacementMap: this.materialTextures.diffuse || null,
        displacementScale: 0,
        roughnessMap: this.materialTextures.roughness || null,
        side: DoubleSide,
      });
    }
    return this.bodyMaterial;
  }

  public getShapeBodyMaterial() {
    if (!this.shapeBodyMaterial) {
      this.shapeBodyMaterial = new MeshStandardMaterial({
        color: this.materialData.color || "#cccccc",
        emissive: this.materialData.emissiveColor || undefined,
        map: this.shapeMaterialTextures.texture || null,
        normalMap: this.shapeMaterialTextures.normal || null,
        roughnessMap: this.shapeMaterialTextures.roughness || null,
        side: DoubleSide,
      });
    }

    return this.shapeBodyMaterial;
  }

  public setCenterPosition() {
    if (
      !this.view3d.userData.centerPosition ||
      !(this.view3d.userData.centerPosition instanceof Vector3)
    ) {
      this.view3d.userData.centerPosition = new Vector3();
    }
    this.view3d.userData.centerPosition = this.view3d.userData.centerPosition
      .copy(this.view3d.position)
      .applyMatrix4(this.unit.view3d.matrixWorld);
  }

  protected calculateChildrenGlobalFrontVector() {
    let shelf: ThreeShelf;

    for (shelf of this.shelves) {
      shelf.calculateGlobalFrontVector();
    }
  }

  protected createShapePanel(extrudeSettings?: ExtrudeGeometryOptions): Mesh {
    let panel;
    let geometry;

    extrudeSettings = extrudeSettings || {
      steps: 1,
      depth: this.getThickness(),
      bevelEnabled: false,
    };
    geometry = new ExtrudeGeometry(this.shape, extrudeSettings);
    panel = new Mesh(geometry, this.getShapeBodyMaterial());
    panel.matrixAutoUpdate = false;
    panel.position.y = this.getThickness() / 2;
    panel.rotation.x = 0.5 * Math.PI;
    panel.updateMatrix();
    panel.geometry.applyMatrix4(panel.matrix);
    panel.rotation.x = 0;
    panel.position.y = 0;
    this.panels.push(panel);

    return panel;
  }

  protected createShapeShelf(extrudeSettings?: ExtrudeGeometryOptions): Mesh {
    let panel;
    let geometry;

    extrudeSettings = extrudeSettings || {
      steps: 1,
      depth: this.getThickness(),
      bevelEnabled: false,
    };
    geometry = new ExtrudeGeometry(this.shelfShape, extrudeSettings);
    panel = new Mesh(geometry, this.getShapeBodyMaterial());
    panel.matrixAutoUpdate = false;
    panel.position.y = this.getThickness() / 2;
    panel.rotation.x = 0.5 * Math.PI;
    panel.updateMatrix();
    panel.geometry.applyMatrix4(panel.matrix);
    panel.rotation.x = 0;
    panel.position.y = 0;
    this.panels.push(panel);

    return panel;
  }

  protected addCoverPointsToUnit() {
    let coverPoints: Vector3[];

    coverPoints = [
      new Vector3(
        -this.getWidth() / 2,
        -this.getHeight() / 2,
        -this.getDepth() / 2
      ),
      new Vector3(
        this.getWidth() / 2,
        this.getHeight() / 2,
        this.getDepth() / 2
      ),
    ];
    this.unit.addCoverPoints(coverPoints);
    this.addCoverPoints(coverPoints);
  }

  protected createBackPanels() {
    this.createPanel(
      new BoxGeometry(
        this.getWidth(),
        this.getHeight(),
        this.getBackThickness()
      ),
      "back",
      new Vector3(0, 0, -this.getDepth() / 2 + this.getBackThickness() / 2)
    );
  }

  protected addUniquePoint(point: Vector2, points: { [key: string]: Vector2 }) {
    if (!points[point.x + "_" + point.y]) {
      points[point.x + "_" + point.y] = point;
    }
  }

  protected createOtherDetails() {}

  protected createShelves() {
    let shelfData: ISaveShelfData;
    let shelf: ThreeShelf;

    for (shelfData of this.saveData.shelves) {
      shelf = new ThreeShelf(CommonHelper.deepCopy(shelfData), this);
      shelf.initState();
      shelf.createView();
      this.shelves.push(shelf);
    }
  }

  protected createBoxes() {
    let boxData: ISaveBoxData;
    let box: ThreeBox;
    if (!this.saveData.boxes) {
      return;
    }
    this.boxes = [];
    for (boxData of this.saveData.boxes) {
      switch (boxData.type) {
        case BOX_TYPE_NORMAL:
        default:
          box = new ThreeBoxNormal(CommonHelper.deepCopy(boxData), this);
      }
      box.initState();
      box.createView();
      this.boxes.push(box);
    }
  }

  protected createPanels() {
    this.createPanel(
      new BoxGeometry(
        this.getWidth(),
        this.getThickness(),
        this.getDepth() - this.getBackThickness()
      ),
      "bottom",
      new Vector3(
        0,
        -this.getHeight() / 2 + this.getThickness() / 2,
        this.getBackThickness() / 2
      )
    );
    this.createPanel(
      new BoxGeometry(
        this.getThickness(),
        this.getHeight() - this.getThickness(),
        this.getDepth() - this.getBackThickness()
      ),
      "left",
      new Vector3(
        -this.getWidth() / 2 + this.getThickness() / 2,
        this.getThickness() / 2,
        this.getBackThickness() / 2
      )
    );
    this.createPanel(
      new BoxGeometry(
        this.getThickness(),
        this.getHeight() - this.getThickness(),
        this.getDepth() - this.getBackThickness()
      ),
      "right",
      new Vector3(
        this.getWidth() / 2 - this.getThickness() / 2,
        this.getThickness() / 2,
        this.getBackThickness() / 2
      )
    );
    this.createPanel(
      new BoxGeometry(
        this.getWidth() - this.getThickness() * 2,
        this.getThickness(),
        this.getDepth() - this.getBackThickness()
      ),
      "top",
      new Vector3(
        0,
        this.getHeight() / 2 - this.getThickness() / 2,
        this.getBackThickness() / 2
      )
    );
  }

  protected createPanel(
    geometry: BufferGeometry,
    name: string,
    position?: Vector3,
    rotation?: Euler
  ) {
    let panel: Mesh;
    panel = new Mesh(geometry, this.getBodyMaterial());
    panel.matrixAutoUpdate = false;
    panel.name = name;
    if (position) {
      panel.position.copy(position);
    }
    if (rotation) {
      panel.rotation.copy(rotation);
    }
    this.view3d.add(panel);
    this.panels.push(panel);
    return panel;
  }

  protected createSingleIntegratedHandleSidePanel(
    geometry: BufferGeometry,
    name: string,
    position?: Vector3,
    rotation?: Euler
  ) {
    const shape = new Shape();
    shape.moveTo(
      (-this.getDepth() + this.getBackThickness()) / 2,
      (-this.getHeight() + this.getThickness()) / 2
    );
    shape.lineTo(
      (-this.getDepth() + this.getBackThickness()) / 2,
      (this.getHeight() - this.getThickness()) / 2
    );
    shape.lineTo(
      (this.getDepth() - this.getBackThickness()) / 2 -
        BOTTOM_UNIT_TECHNOLOGICAL_HOLE_DEPTH,
      (this.getHeight() - this.getThickness()) / 2
    );
    shape.lineTo(
      (this.getDepth() - this.getBackThickness()) / 2 -
        BOTTOM_UNIT_TECHNOLOGICAL_HOLE_DEPTH,
      (this.getHeight() - this.getThickness()) / 2 -
        BOTTOM_UNIT_TECHNOLOGICAL_HOLE_HEIGHT
    );
    shape.lineTo(
      (this.getDepth() - this.getBackThickness()) / 2,
      (this.getHeight() - this.getThickness()) / 2 -
        BOTTOM_UNIT_TECHNOLOGICAL_HOLE_HEIGHT
    );
    shape.lineTo(
      (this.getDepth() - this.getBackThickness()) / 2,
      (-this.getHeight() + this.getThickness()) / 2
    );
    shape.lineTo(
      (-this.getDepth() + this.getBackThickness()) / 2,
      (-this.getHeight() + this.getThickness()) / 2
    );

    const extrudeSettings = {
      steps: 1,
      depth: this.getThickness(),
      bevelEnabled: false,
    };

    geometry = new ExtrudeGeometry([shape], extrudeSettings);
    const panel = new Mesh(geometry, this.getBodyMaterial());

    if (position) {
      panel.position.copy(position);
    }
    if (rotation) {
      panel.rotation.copy(rotation);
    }
    panel.name = name;
    this.view3d.add(panel);
    this.panels.push(panel);
    return panel;
  }

  protected createDoubleIntegratedHandleSidePanel(
    geometry: BufferGeometry,
    name: string,
    position?: Vector3,
    rotation?: Euler
  ) {
    const shape = new Shape();
    shape.moveTo(
      (-this.getDepth() + this.getBackThickness()) / 2,
      (-this.getHeight() + this.getThickness()) / 2
    );
    shape.lineTo(
      (-this.getDepth() + this.getBackThickness()) / 2,
      (this.getHeight() - this.getThickness()) / 2
    );
    shape.lineTo(
      (this.getDepth() - this.getBackThickness()) / 2 -
        BOTTOM_UNIT_TECHNOLOGICAL_HOLE_DEPTH,
      (this.getHeight() - this.getThickness()) / 2
    );
    shape.lineTo(
      (this.getDepth() - this.getBackThickness()) / 2 -
        BOTTOM_UNIT_TECHNOLOGICAL_HOLE_DEPTH,
      (this.getHeight() - this.getThickness()) / 2 -
      BOTTOM_UNIT_TECHNOLOGICAL_HOLE_HEIGHT
    );
    shape.lineTo(
      (this.getDepth() - this.getBackThickness()) / 2,
      (this.getHeight() - this.getThickness()) / 2 -
      BOTTOM_UNIT_TECHNOLOGICAL_HOLE_HEIGHT
    );
    shape.lineTo(
      (this.getDepth() - this.getBackThickness()) / 2,
      (this.saveData.techHole ? this.saveData.techHole?.positionC - this.getThickness()/2 : 0) + BOTTON_UNIT_TECHNOLOGICAL_HOLE_C_HEIGHT * 0.5 
    );
    shape.lineTo(
      (this.getDepth() - this.getBackThickness()) / 2 -
        BOTTOM_UNIT_TECHNOLOGICAL_HOLE_DEPTH,
      (this.saveData.techHole ? this.saveData.techHole?.positionC - this.getThickness()/2 : 0) + BOTTON_UNIT_TECHNOLOGICAL_HOLE_C_HEIGHT * 0.5
    );
    shape.lineTo(
      (this.getDepth() - this.getBackThickness()) / 2 -
        BOTTOM_UNIT_TECHNOLOGICAL_HOLE_DEPTH,
      (this.saveData.techHole ? this.saveData.techHole?.positionC - this.getThickness()/2 : 0) - BOTTON_UNIT_TECHNOLOGICAL_HOLE_C_HEIGHT * 0.5
    );
    shape.lineTo(
      (this.getDepth() - this.getBackThickness()) / 2,
      (this.saveData.techHole ? this.saveData.techHole?.positionC - this.getThickness()/2: 0) - BOTTON_UNIT_TECHNOLOGICAL_HOLE_C_HEIGHT * 0.5
    );
    shape.lineTo(
      (this.getDepth() - this.getBackThickness()) / 2,
      (-this.getHeight() + this.getThickness()) / 2
    );
    shape.lineTo(
      (-this.getDepth() + this.getBackThickness()) / 2,
      (-this.getHeight() + this.getThickness()) / 2
    );

    const extrudeSettings = {
      steps: 1,
      depth: this.getThickness(),
      bevelEnabled: false,
    };

    geometry = new ExtrudeGeometry([shape], extrudeSettings);
    const panel = new Mesh(geometry, this.getBodyMaterial());

    if (position) {
      panel.position.copy(position);
    }
    if (rotation) {
      panel.rotation.copy(rotation);
    }
    panel.name = name;
    this.view3d.add(panel);
    this.panels.push(panel);
    return panel;
  }
}
