import {TSideType} from '../../../../../../common-code/types/TSideType';
import {Box3, Euler, ExtrudeGeometry, Mesh, MeshStandardMaterial, Vector2, Vector3} from 'three';
import {SIDE_TYPE_BACK, SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from '../../../../../../common-code/constants';
import {TPoint2D} from '../../../../../../common-code/types/TPoint2D';
import {ThreeKUnitDetail} from '../ThreeKUnitDetail/ThreeKUnitDetail';
import {IThreeKUnitDetail} from '../../../../interfaces/IThreeKUnitDetail';
import {ICoverMainPoints} from '../../../../interfaces/ICoverMainPoints';
import {ThreeUnit} from '../../ThreeUnit/ThreeUnit';
import {ICornerData} from '../../../../../../common-code/interfaces/materials/ICornerData';
import {ISaveKUnitDetailData} from '../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {TPositionSideType} from '../../../../../../common-code/types/TPositionSideType';
import {CommonHelper} from 'common-code';
import {IGlobalSidePoints} from '../../../../interfaces/IGlobalSidePoints';

export class ThreeCorner extends ThreeKUnitDetail implements IThreeKUnitDetail {
    materialData: ICornerData;

    constructor(options: ISaveKUnitDetailData, unit: ThreeUnit) {
        super(options, unit);
        this.materialData = this.initMaterialData();
    }

    public getDefaultPositionByType(): Vector3 {
        let position: Vector3;
        let coverBox: Box3;

        coverBox = this.unit.getCoverBox(0);
        switch (this.saveData.positionType) {
            case SIDE_TYPE_LEFT:
                position = new Vector3(
                    coverBox.min.x + this.getHeight() / 2 + this.unit.getApronHeight(SIDE_TYPE_LEFT),
                    coverBox.max.y + this.getWidth()/2,
                    coverBox.min.z + this.getLength() / 2
                );
                break;
            case SIDE_TYPE_RIGHT:
                position = new Vector3(
                    coverBox.max.x - this.getHeight() / 2 - this.unit.getApronHeight(SIDE_TYPE_RIGHT),
                    coverBox.max.y + this.getWidth()/2,
                    coverBox.min.z + this.getLength() / 2
                );
                break;
            case SIDE_TYPE_BACK:
            default:
                position = new Vector3(
                    (coverBox.min.x + coverBox.max.x) / 2,
                    coverBox.max.y + this.getWidth()/2,
                    coverBox.min.z + this.getHeight() / 2 + this.unit.getApronHeight(SIDE_TYPE_BACK)
                );
                break;
        }

        return position;
    }

    public createBody(): void {
        let geometry;
        let extrudeSettings;

        extrudeSettings = {
            steps: 1,
            depth: this.getLength(),
            bevelEnabled: false
        };
        geometry = new ExtrudeGeometry(this.shape, extrudeSettings);
        geometry.center();
        this.body = new Mesh(geometry, this.getBodyMaterial());
        this.body.rotation.y = -0.5 * Math.PI;
        this.body.updateMatrix();
        this.body.geometry.applyMatrix4(this.body.matrix);
        this.body.rotation.y = 0;
        this.body.name = "body";
        this.body.matrixAutoUpdate = false;
        this.body.updateMatrix();
        this.view3d.add(this.body);
        this.addCoverPoints(this.calculateMeshCoverPoints(this.body));
    }

    public getBodyMaterial() {
        return new MeshStandardMaterial({
            color: this.materialData.color || '#ffffff',
            map: this.materialTextures.texture || null,
            normalMap: this.materialTextures.normal || null,
            roughnessMap: this.materialTextures.roughness || null,
            envMapIntensity: 5
        })
    }

    public getGlobalSidePoints(cover: Mesh = this.cover): IGlobalSidePoints {
        return super.getGlobalSidePoints();
    }

    public initHeight(positionType?: TPositionSideType): number {
        return this.service.getCornerHeight();
    }

    public initWidth(positionType?: TPositionSideType): number {
        return this.service.getCornerWidth();
    }

    public initLength(positionType?: TPositionSideType) {
        let length: number;
        let coverBox: Box3;

        coverBox = this.unit.getCoverBox(0);
        switch (positionType) {
            case SIDE_TYPE_LEFT:
            case SIDE_TYPE_RIGHT:
                length = coverBox.max.z - coverBox.min.z;
                break;
            case SIDE_TYPE_BACK:
            default:
                length = coverBox.max.x - coverBox.min.x;
                break;
        }

        return length;
    }

    public getDefaultRotationByType(): Euler {
        let rotation: Euler;

        switch (this.saveData.positionType) {
            case SIDE_TYPE_LEFT:
                rotation = new Euler(0, 0.5* Math.PI, 0);
                break;
            case SIDE_TYPE_RIGHT:
                rotation = new Euler(0, -0.5* Math.PI, 0);
                break;
            case SIDE_TYPE_BACK:
            default:
                rotation = new Euler();
                break;
        }

        return rotation;
    }
    public getDefaultPoints(type: TSideType): Vector2[] {
        let points;

        points = [
            new Vector2(0, 0),
        ];

        return points;
    }

    public getGlobalMainPoints(cover: Mesh = this.cover): ICoverMainPoints {
        if (!this.correctLeftPoints || !this.correctRightPoints) {
            throw new Error('error-ThreeApron-getGlobalMainPoints');
        }
        let mainPoints: ICoverMainPoints;
        let coverId: string;

        coverId = CommonHelper.md5({
            id: cover.uuid,
            position: cover.name === 'selectCover'? this.getGlobalPosition().toArray() : cover.position.toArray(),
            rotation: cover.name === 'selectCover'? this.getGlobalRotation().toArray() : cover.rotation.toArray(),
            parentPosition: this.unit.getGlobalPosition().toArray(),
            parentRotation: this.unit.getGlobalRotation().toArray(),
        });
        if (this.globalMainPoints[coverId]) {
            return this.globalMainPoints[coverId];
        }
        this.view3d.updateMatrixWorld();
        mainPoints = {
            bottom: {
                pointA: new Vector3(this.correctLeftPoints[0].x, this.correctLeftPoints[0].y, 0).applyMatrix4(this.view3d.matrixWorld),
                pointB: new Vector3(this.correctRightPoints[0].x, this.correctRightPoints[0].y, 0).applyMatrix4(this.view3d.matrixWorld),
            },
            top: {
                pointA: this.defaultMainPoint,
                pointB: this.defaultMainPoint,
            },
            back: {
                pointA: this.defaultMainPoint,
                pointB: this.defaultMainPoint,
            },
            front: {
                pointA: this.defaultMainPoint,
                pointB: this.defaultMainPoint,
            },
            left: {
                pointA: this.defaultMainPoint,
                pointB: this.defaultMainPoint,
            },
            right: {
                pointA: this.defaultMainPoint,
                pointB: this.defaultMainPoint,
            },
        };
        if (Object.keys(this.globalMainPoints).length > 30) {
            delete this.globalMainPoints[Object.keys(this.globalMainPoints)[0]];
        }
        this.globalMainPoints[coverId] = mainPoints;

        return mainPoints;
    }

    public getUnionYPosition(): number {
        return this.getHeight()/2;
    }

    protected initPriceType() {
        if (this.saveData.priceType === undefined && this.service.appConfig.catalog.corners !== undefined) {
            this.saveData.priceType = this.service.appConfig.catalog.corners.priceType;
        }
    }

    protected getOtherDetails(): ThreeKUnitDetail[] {
        return this.unit.corners || [];
    }

    protected calculateShapePoints(): Vector2[] {
        let contourPath: TPoint2D[] | undefined;
        let uniquePoints: { [key: string]: boolean } = {};
        let shapePoints: Vector2[] = [];
        let index: number;

        contourPath = this.getContourPath();

        for (index = 0; index < contourPath.length; index++) {
            if (contourPath[index] === undefined) {
                continue;
            }
            if (!uniquePoints[contourPath[index].x + '_' + contourPath[index].y]) {
                shapePoints.push(new Vector2(contourPath[index].x, contourPath[index].y));
                uniquePoints[contourPath[index].x + '_' + contourPath[index].y] = true;
            }
        }
        shapePoints.push(new Vector2(contourPath[0].x, contourPath[0].y));

        return shapePoints;
    }

    protected getContourPath(): TPoint2D[] {
        let contourPath: TPoint2D[] | undefined;

        contourPath = this.saveData.contourPath;
        if (contourPath) {
            return contourPath;
        } else {
            return this.defaultContourPath();
        }
    }

    protected defaultContourPath(): TPoint2D[] {
        return [
            {x: 0, y: 0},
            {x: 0, y: this.getHeight()},
            {x: this.getWidth(), y: 0},
            {x: 0, y: 0},
        ];
    }

    protected initMaterialData(): ICornerData {
        return  CommonHelper.deepCopy(this.service.getCornerMaterial(this.saveData.material));
    }
}