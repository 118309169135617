import {ThreeTopUnit} from '../ThreeTopUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {
    ALIGN_CENTER,
    ALIGN_LEFT,
    ALIGN_RIGHT,
    SIDE_TYPE_RIGHT
} from '../../../../../../../common-code/constants';
import {TDirectionSideType} from "../../../../../../../common-code/types/TDirectionSideType";
import {ThreeTopAngleTwoFacades90Corpus} from "../../../details/ThreeCorpus/types/ThreeTopAngleTwoFacades90Corpus";
import {
    ISaveTopUnitAngleTwoFacades90Data
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitAngleTwoFacades90Data';
import {
    ISaveTopAngleTwoFacades90CorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopAngleTwoFacades90CorpusData';
import {CommonHelper} from 'common-code';
import {ISaveFacadeData} from '../../../../../../../common-code/interfaces/saveData/ISaveFacadeData';

export class ThreeTopUnitAngleTwoFacades90 extends ThreeTopUnit {
    corpus: ThreeTopAngleTwoFacades90Corpus;
    saveData: ISaveTopUnitAngleTwoFacades90Data;

    constructor(options: ISaveTopUnitAngleTwoFacades90Data, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    public isAngleUnit(): boolean {
        return true;
    }

    protected initCorpus(corpusData: ISaveTopAngleTwoFacades90CorpusData): ThreeTopAngleTwoFacades90Corpus {
        return new ThreeTopAngleTwoFacades90Corpus(CommonHelper.deepCopy(corpusData), this);
    }

    protected calculateFacadesData(facades?: ISaveFacadeData[]): ISaveFacadeData[] | undefined {
        if (facades) {
            let facade: ISaveFacadeData;
            for (facade of facades) {
                facade.align = this.getSideType() === SIDE_TYPE_RIGHT ?
                    {x: ALIGN_LEFT, y: ALIGN_CENTER} : {x: ALIGN_RIGHT, y: ALIGN_CENTER};
                if (facade.margin) {
                    facade.margin.x = this.getSideType() === SIDE_TYPE_RIGHT ?
                        facade.margin.x * -1 : facade.margin.x;
                }
                if (facade.rotation) {
                    facade.rotation.y = this.getSideType() === SIDE_TYPE_RIGHT ?
                        (facade.rotation.y !== undefined && !isNaN(facade.rotation.y) ? +facade.rotation.y : 0) + Math.PI :
                        facade.rotation.y;
                }
            }
        }

        return facades;
    }

}