import {RoomPoint} from '../RoomPoint/RoomPoint';
import {Vector3} from 'three';
import {KitchenService} from '../../services/KitchenService/KitchenService';
import {ISavePoint2DData} from '../../../../common-code/interfaces/saveData/ISavePoint2DData';
import {CommonHelper} from 'common-code';

export class ThreeRoomPoint extends RoomPoint {
    saveData: ISavePoint2DData;
    value: Vector3;
    service: KitchenService;

    constructor(options: ISavePoint2DData, kitchenService: KitchenService) {
        super(options, kitchenService);
        this.saveData = options;
        this.service = kitchenService;
        this.value = new Vector3(options.value.x, 0, options.value.y);
    }

    public setPosition() {
        this.view3d.position.copy(this.calculatePosition());
    }

    public initState() {
        this.calculatePosition();
        super.initState();
    }


    public createView() {
        // const geometry = new CircleBufferGeometry(50, 32);
        // const material = new MeshStandardMaterial({color: '#ffff00', side: DoubleSide});
        // const circle = new Mesh(geometry, material);
        // circle.rotation.x = -Math.PI/2;
        // circle.position.copy(this.value);
        // this.view3d.add(circle)
        // this.service.addToScene(this);
    }

    public getData(): ISavePoint2DData {
        let data: ISavePoint2DData = CommonHelper.deepCopy(super.getData());
        data.value = {x: this.value.x, y: this.value.z};

        return data;
    }

    public calculatePosition(): Vector3 {
        if (!this.ready) {
            this.ready = true;
        }

        return this.value;
    }
}