import {ThreeTopUnit} from '../ThreeTopUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeTopEndNormalCorpus} from '../../../details/ThreeCorpus/types/ThreeTopEndNormalCorpus';
import {ThreeFacade} from '../../../details/ThreeFacade/ThreeFacade';
import {
    FACADE_POSITION_TYPE_ANGLE,
    GEOMETRY_TYPE_SQUARE,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_RIGHT
} from '../../../../../../../common-code/constants';
import {Euler, Vector3} from 'three';
import {ThreeSquareFacade} from '../../../details/ThreeFacade/geometry/ThreeSquareFacade';
import {TPoint2D} from '../../../../../../../common-code/types/TPoint2D';
import {ICorpusPoints} from '../../../../../interfaces/ICorpusPoints';
import {TDirectionSideType} from '../../../../../../../common-code/types/TDirectionSideType';
import {
    ISaveTopUnitEndNormalData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitEndNormalData';
import {
    ISaveTopEndNormalCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopEndNormalCorpusData';
import {CommonHelper} from 'common-code';
import {ISaveFacadeData} from '../../../../../../../common-code/interfaces/saveData/ISaveFacadeData';
import {MathHelper} from 'common-code';

export class ThreeTopUnitEndNormal extends ThreeTopUnit {
    saveData: ISaveTopUnitEndNormalData;
    corpus: ThreeTopEndNormalCorpus;

    constructor(options: ISaveTopUnitEndNormalData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public isEndUnit(): boolean {
        return true;
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    protected initCorpus(corpusData: ISaveTopEndNormalCorpusData): ThreeTopEndNormalCorpus {
        return new ThreeTopEndNormalCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    protected createFacades() {
        let facadeData: ISaveFacadeData;
        let facade: ThreeFacade | undefined;
        let facadePoint: TPoint2D;
        let angleY: number;
        let angleZ: number;

        this.saveData.facades = this.calculateFacadesData(this.saveData.facades);
        if (!this.saveData.facades) {
            return;
        }

        const corpusPoints: ICorpusPoints = this.getCorpusPoints();
        this.facades = [];
        if (!this.isSingleFacadeCorpus() && this.saveData.facades.length > 1) {
            for (facadeData of this.saveData.facades) {
                switch (facadeData.geometryType) {
                    case GEOMETRY_TYPE_SQUARE:
                        facade = new ThreeSquareFacade(CommonHelper.deepCopy(facadeData), this);
                }
                if (!facade) {
                    continue;
                }
                facade.initState();
                facade.createView();
                angleZ = facade.isFlipY() ? Math.PI : 0;
                switch (facadeData.positionType) {
                    case FACADE_POSITION_TYPE_ANGLE:
                        facadePoint = MathHelper.getPointByRatio2D(corpusPoints.pointA, corpusPoints.pointC, 0.5, true);
                        facade.setPosition(new Vector3(facadePoint.x, 0, facadePoint.y));
                        angleY = this.getSideType() === SIDE_TYPE_RIGHT ?
                            2 * Math.PI - MathHelper.getNormalAngle({x: corpusPoints.pointA.x - corpusPoints.pointC.x, y: corpusPoints.pointA.y - corpusPoints.pointC.y}) :
                            2 * Math.PI - MathHelper.getNormalAngle({x: corpusPoints.pointC.x - corpusPoints.pointA.x, y: corpusPoints.pointC.y - corpusPoints.pointA.y});
                        facade.setRotation(new Euler(0, angleY, angleZ));
                        break;
                    default:
                        facadePoint = MathHelper.getPointByRatio2D(corpusPoints.pointE, corpusPoints.pointC, 0.5, true);
                        facade.setPosition(new Vector3(facadePoint.x, 0, facadePoint.y));
                        facade.setRotation(new Euler(0, 0, angleZ));
                }
                this.view3d.add(facade.view3d);
                this.facades.push(facade);
            }

            return;
        }

        const isIntersectFacadeCorpus: TPoint2D | undefined = this.isIntersectFacadeCorpus();
        const pointB: TPoint2D = isIntersectFacadeCorpus ? isIntersectFacadeCorpus : corpusPoints.pointB;
        for (facadeData of this.saveData.facades) {
            switch (facadeData.geometryType) {
                case GEOMETRY_TYPE_SQUARE:
                    facade = new ThreeSquareFacade(CommonHelper.deepCopy(facadeData), this);
            }
            if (!facade) {
                continue;
            }
            facade.initState();
            facade.createView();
            angleZ = facade.isFlipY() ? Math.PI : 0;
            facadePoint = MathHelper.getPointByRatio2D(corpusPoints.pointA, pointB, 0.5, true);
            facade.setPosition(new Vector3(facadePoint.x, 0, facadePoint.y));
            angleY = this.getSideType() === SIDE_TYPE_RIGHT ?
                2 * Math.PI - MathHelper.getNormalAngle({x: corpusPoints.pointA.x - corpusPoints.pointC.x, y: corpusPoints.pointA.y - corpusPoints.pointC.y}) :
                2 * Math.PI - MathHelper.getNormalAngle({x: corpusPoints.pointC.x - corpusPoints.pointA.x, y: corpusPoints.pointC.y - corpusPoints.pointA.y});
            facade.setRotation(new Euler(0, angleY, angleZ));
            this.view3d.add(facade.view3d);
            this.facades.push(facade);
        }
    }

    protected calculateFacadesData(facades?: ISaveFacadeData[]): ISaveFacadeData[] | undefined {
        if (!facades) {
            return undefined;
        }

        const corpusPoints: ICorpusPoints = this.getCorpusPoints();
        let mainFacadeWidth: string;
        let anotherFacadeWidth: string;
        let facadeData: ISaveFacadeData;

        if (!this.isSingleFacadeCorpus() && facades.length > 1) {
            mainFacadeWidth = '' + Math.floor(MathHelper.getLength2D(corpusPoints.pointA, corpusPoints.pointC));
            anotherFacadeWidth = '' + Math.floor(MathHelper.getLength2D(corpusPoints.pointC, corpusPoints.pointE));
            for (facadeData of facades) {
                switch (facadeData.positionType) {
                    case FACADE_POSITION_TYPE_ANGLE:
                        facadeData.initSizes.width = mainFacadeWidth;
                        break;
                    default:
                        facadeData.initSizes.width = anotherFacadeWidth;
                }
            }

            return facades;
        }

        const isIntersectFacadeCorpus: TPoint2D | undefined = this.isIntersectFacadeCorpus();
        if (isIntersectFacadeCorpus) {
            mainFacadeWidth = '' + Math.floor(MathHelper.getLength2D(corpusPoints.pointA, isIntersectFacadeCorpus));
        } else {
            mainFacadeWidth = '' + Math.floor(MathHelper.getLength2D(corpusPoints.pointA, corpusPoints.pointB));
        }
        for (facadeData of facades) {
            facadeData.initSizes.width = mainFacadeWidth;
        }

        return facades;
    }

    private isIntersectFacadeCorpus(): TPoint2D | undefined {
        const corpusPoints: ICorpusPoints = this.getCorpusPoints();

        return MathHelper.getIntersectionPoint({pointA: corpusPoints.pointA, pointB: corpusPoints.pointB}, {pointA: corpusPoints.pointD, pointB: corpusPoints.pointE});
    }

    private isSingleFacadeCorpus(): boolean {
        return MathHelper.isEqualPoints2D(this.getCorpusPoints().pointB, this.getCorpusPoints().pointC);
    }

    private getCorpusPoints(): ICorpusPoints {
        let pointA: TPoint2D;
        let pointB: TPoint2D;
        let pointC: TPoint2D;
        let pointD: TPoint2D;
        let pointE: TPoint2D;
        let points: ICorpusPoints;
        let smallWidth: number;
        let smallDepth: number;

        smallWidth = this.corpus.getSmallWidth() ? +this.corpus.getSmallWidth() : +this.corpus.getThickness();
        smallDepth = this.corpus.getSmallDepth() ? +this.corpus.getSmallDepth() : +this.corpus.getBackThickness();
        switch (this.getSideType()) {
            case SIDE_TYPE_RIGHT:
                pointA = {x: this.getWidth()/2 - this.corpus.getThickness(), y: -this.getDepth()/2 + smallDepth};
                pointB = {x: -this.getWidth()/2 + this.corpus.getThickness(), y: this.getDepth()/2};
                pointC = {x: -this.getWidth()/2 + smallWidth, y: this.getDepth()/2};
                pointD = {x: -this.getWidth()/2, y: -this.getDepth()/2};
                pointE = {x: -this.getWidth()/2, y: this.getDepth()/2};
                points = {pointA, pointB, pointC, pointE, pointD};
                break;
            case SIDE_TYPE_LEFT:
            default:
                pointA = {x: -this.getWidth()/2 + this.corpus.getThickness(), y: -this.getDepth()/2 + smallDepth};
                pointB = {x: this.getWidth()/2 - this.corpus.getThickness(), y: this.getDepth()/2};
                pointC = {x: this.getWidth()/2 - smallWidth, y: this.getDepth()/2};
                pointD = {x: this.getWidth()/2, y: -this.getDepth()/2};
                pointE = {x: this.getWidth()/2, y: this.getDepth()/2};
                points = {pointA, pointB, pointC, pointE, pointD};
        }

        return points;
    }

}