import {ThreeRoom} from '../../rooms/ThreeRoom/ThreeRoom';
import {ThreeConstructive} from '../ThreeConstructive/ThreeConstructive';
import {Box3, BoxGeometry, BufferGeometry, LineSegments, Mesh, MeshStandardMaterial, Vector3} from 'three';
import {
    SETTING_GROUP_GENERAL,
    WINDOW_FRAME_DEPTH,
    WINDOW_FRAME_WIDTH
} from '../../../../../constants';
import {onAfterRenderHideForBack, onBeforeRenderHideForBack} from '../../../../helpers/ThreeHelper/ThreeHelper';
import {SIDE_TYPE_BOTTOM, SIZE_TYPE_HEIGHT, SIZE_TYPE_WIDTH} from '../../../../../../common-code/constants';
import {DEFAULT_WINDOW_BOTTOM_GAP, UNIT_SIZE_TEXT_SIZE} from '../../../../constants';
import {ISaveConstructiveData} from '../../../../../../common-code/interfaces/saveData/ISaveConstructiveData';
import {ISettingGroup} from '../../../../../interfaces/settingData/ISettingGroup';
import {IOption} from '../../../../../../common-code/interfaces/option/IOption';
import {ISettingGroupGeneral} from '../../../../../interfaces/settingData/ISettingGroupGeneral';
import {IOptionRange} from '../../../../../../common-code/interfaces/option/IOptionRange';
import {ISaveSizeData} from '../../../../../../common-code/interfaces/saveData/ISaveSizeData';

export class ThreeWindow extends ThreeConstructive {
    frameMaterial: MeshStandardMaterial | null;

    constructor(options: ISaveConstructiveData, room: ThreeRoom) {
        super(options, room);
        this.frameMaterial = null;
        // this.canMove = false;
        this.canVerticalMove = true;
    }

    public createView(isRebuild?: boolean) {
        this.createGlass();
        this.createWindowHole();
        this.createFrames();
        this.calculateGlobalFrontVector();
        this.addBodyCoverPoints();
        super.createView(isRebuild);
    }

    public defaultYPosition(): number {
        return this.getSideDistance(SIDE_TYPE_BOTTOM) + DEFAULT_WINDOW_BOTTOM_GAP;
    }

    public getSettingsGroups(): {[key: string]: ISettingGroup} {
        let groups: {[key: string]: ISettingGroup};
        let option: IOption;

        groups = super.getSettingsGroups();
        if ((groups[SETTING_GROUP_GENERAL].data as ISettingGroupGeneral).other) {
            for (option of (groups[SETTING_GROUP_GENERAL].data as ISettingGroupGeneral).other) {
                if (option.id === 'height') {
                    if ('max' in option) {
                        if ((option as IOptionRange).max >= (this.service.getRoom().getHeight())) {
                            (option as IOptionRange).max = (this.service.getRoom().getHeight())
                        }
                    }
                }
            }
        }

        return groups;
    }

    protected addBodyCoverPoints() {
        this.addCoverPoints([
            new Vector3(-this.getWidth()/2, -this.getHeight()/2, -this.getDepth()/2),
            new Vector3(this.getWidth()/2, this.getHeight()/2, this.getDepth()/2)
        ])
    }

    protected initPosition(): Vector3 {
        let initPosition: Vector3;

        initPosition = new Vector3(0, this.defaultYPosition(), 0);
        if (this.saveData.initPosition) {
            if (this.saveData.initPosition.x !== undefined && !isNaN(+this.saveData.initPosition.x)) {
                initPosition.x = +this.saveData.initPosition.x;
            }
            if (this.saveData.initPosition.y !== undefined && !isNaN(+this.saveData.initPosition.y)) {
                initPosition.y = +this.saveData.initPosition.y;
            }
            if (this.saveData.initPosition.z !== undefined && !isNaN(+this.saveData.initPosition.z)) {
                initPosition.z = +this.saveData.initPosition.z;
            }
        }
        if (this.saveData.position) {
            if (this.saveData.position.x !== undefined && !isNaN(+this.saveData.position.x)) {
                initPosition.x = this.saveData.position.x;
            }
            if (this.saveData.position.y !== undefined && !isNaN(+this.saveData.position.y)) {
                initPosition.y = this.saveData.position.y;
            }
            if (this.saveData.position.z !== undefined && !isNaN(+this.saveData.position.z)) {
                initPosition.z = this.saveData.position.z;
            }
        }

        return initPosition;
    }

    protected initSizesData(): ISaveSizeData[] {
        let sizesData: ISaveSizeData[] = [];
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);
        sizesData.push({
            id: 0,
            pointA: {x: coverBox.min.x, y: coverBox.max.y, z: coverBox.max.z + this.SIZE_GAP},
            pointB: {x: coverBox.max.x, y: coverBox.max.y, z: coverBox.max.z + this.SIZE_GAP},
            type: SIZE_TYPE_WIDTH,
            decimal: 0,
            textSize: UNIT_SIZE_TEXT_SIZE,
            textIndent: 0,
        });
        sizesData.push({
            id: 0,
            pointA: {x: coverBox.max.x, y: coverBox.min.y, z: coverBox.max.z + this.SIZE_GAP},
            pointB: {x: coverBox.max.x, y: coverBox.max.y, z: coverBox.max.z + this.SIZE_GAP},
            type: SIZE_TYPE_HEIGHT,
            decimal: 0,
            rotation: {z: -Math.PI/2},
            textSize: UNIT_SIZE_TEXT_SIZE,
            textIndent: 0,
        });

        return sizesData;
    }

    private createGlass() {
        let geometry: BufferGeometry;
        let glass: Mesh;

        geometry = new BoxGeometry(this.getWidth() - WINDOW_FRAME_WIDTH, this.getHeight() - WINDOW_FRAME_WIDTH, 10);
        glass = new Mesh(geometry, new MeshStandardMaterial({
            color: '#c2dcdc',
            transparent: true,
            opacity: 0.3,
            envMapIntensity:4,
        }));
        glass.matrixAutoUpdate = false;
        glass.onBeforeRender = onBeforeRenderHideForBack;
        glass.onAfterRender = onAfterRenderHideForBack;
        glass.userData.notSwitchView = true;
        glass.name = 'glass';
        this.view3d.add(glass);
    }

    private createWindowHole() {
        let windowHole;

        windowHole = new Mesh(
            new BoxGeometry(this.getWidth(), this.getHeight(), 2),
            new MeshStandardMaterial()
        );
        windowHole.matrixAutoUpdate = false;
        windowHole.material.colorWrite = false;
        windowHole.renderOrder = 1;
        windowHole.onBeforeRender = onBeforeRenderHideForBack;
        windowHole.onAfterRender = onAfterRenderHideForBack;
        windowHole.name = 'windowHole';
        windowHole.userData.notSwitchView = true;
        this.view3d.add(windowHole);
    }

    private createFrames() {
        let geometry: BufferGeometry;
        let frames: Mesh[] = [];
        let frame: Mesh;
        let carcass: LineSegments;

        geometry = new BoxGeometry(WINDOW_FRAME_WIDTH, this.getHeight(), WINDOW_FRAME_DEPTH);
        frames[0] = new Mesh(geometry, this.getFrameMaterial());
        frames[0].position.x = this.getWidth() / 2 - WINDOW_FRAME_WIDTH / 2;
        frames[1] = frames[0].clone();
        frames[1].position.x = -this.getWidth() / 2 + WINDOW_FRAME_WIDTH / 2;
        geometry = new BoxGeometry(this.getWidth() - WINDOW_FRAME_WIDTH * 2, WINDOW_FRAME_WIDTH, WINDOW_FRAME_DEPTH);
        frames[2] = new Mesh(geometry, this.getFrameMaterial());
        frames[2].position.y = this.getHeight() / 2 - WINDOW_FRAME_WIDTH / 2;
        frames[3] = frames[2].clone();
        frames[3].position.y = -this.getHeight() / 2 + WINDOW_FRAME_WIDTH / 2;
        geometry = new BoxGeometry(WINDOW_FRAME_WIDTH, this.getHeight() - WINDOW_FRAME_WIDTH * 2, WINDOW_FRAME_DEPTH);
        frames[4] = new Mesh(geometry, this.getFrameMaterial());
        for (frame of frames) {
            frame.onBeforeRender = onBeforeRenderHideForBack;
            frame.onAfterRender = onAfterRenderHideForBack;
            frame.matrixAutoUpdate = false;
            carcass = this.createMeshCarcass(frame);
            carcass.userData.parent = this.view3d;
            carcass.onBeforeRender = onBeforeRenderHideForBack;
            carcass.onAfterRender = onAfterRenderHideForBack;
            this.view3d.add(frame);
        }
    }

    private getFrameMaterial() {
        if (!this.frameMaterial) {
            this.frameMaterial = new MeshStandardMaterial({color: '#919191', envMapIntensity:4})
        }

        return this.frameMaterial;
    }
}