import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeTabletop} from '../../../details/ThreeTabletop/ThreeTabletop';
import {Box3, Vector3} from 'three';
import {
    ALIGN_CENTER,
    ALIGN_LEFT,
    ALIGN_RIGHT,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_RIGHT
} from '../../../../../../../common-code/constants';
import {TDirectionSideType} from '../../../../../../../common-code/types/TDirectionSideType';
import {
    ThreeBottomAngleFullLengthSidewallNormalSinkCorpus
} from "../../../details/ThreeCorpus/types/ThreeBottomAngleFullLengthSidewallNormalSinkCorpus";
import {
    ISaveBottomUnitAngleFullLengthSidewallNormalSinkData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitAngleFullLengthSidewallNormalSinkData';
import {
    ISaveBottomAngleFullLengthSidewallNormalSinkCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomAngleFullLengthSidewallNormalSinkCorpusData';
import {CommonHelper} from 'common-code';
import {ISaveFacadeData} from '../../../../../../../common-code/interfaces/saveData/ISaveFacadeData';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';

export class ThreeBottomUnitAngleFullLengthSidewallNormalSink extends ThreeBottomUnit {
    saveData: ISaveBottomUnitAngleFullLengthSidewallNormalSinkData;
    corpus: ThreeBottomAngleFullLengthSidewallNormalSinkCorpus;

    constructor(options: ISaveBottomUnitAngleFullLengthSidewallNormalSinkData, service: KitchenService) {
        super(options, service);
        this.saveData =options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    public isAngleUnit(): boolean {
        return true;
    }

    public getInitTabletopPosition(tabletop: ThreeTabletop): Vector3 {
        let position: Vector3;
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);
        position = new Vector3(
            (coverBox.min.x + coverBox.max.x) / 2,
            coverBox.max.y + tabletop.getHeight()/2,
            this.getZInitTabletopPosition(tabletop)
        );
        switch (this.getSideType()) {
            case SIDE_TYPE_RIGHT:
                position.x = coverBox.min.x + tabletop.getLength()/2;
                break;
            case SIDE_TYPE_LEFT:
                position.x = coverBox.max.x - tabletop.getLength()/2;
                break;
        }

        return position;
    }

    protected initCorpus(corpusData: ISaveBottomAngleFullLengthSidewallNormalSinkCorpusData): ThreeBottomAngleFullLengthSidewallNormalSinkCorpus {
        return new ThreeBottomAngleFullLengthSidewallNormalSinkCorpus(CommonHelper.deepCopy(corpusData), this)
    }

    protected calculateFacadesData(facades?: ISaveFacadeData[]): ISaveFacadeData[] | undefined {
        if (facades) {
            let facade: ISaveFacadeData;
            for (facade of facades) {
                facade.align = this.getSideType() === SIDE_TYPE_RIGHT ?
                    {x: ALIGN_LEFT, y: ALIGN_CENTER} : {x: ALIGN_RIGHT, y: ALIGN_CENTER};
                if (facade.margin) {
                    facade.margin.x = this.getSideType() === SIDE_TYPE_RIGHT ?
                        Math.abs(facade.margin.x) : Math.abs(facade.margin.x) * -1;
                }
                if (facade.rotation) {
                    facade.rotation.y = this.getSideType() === SIDE_TYPE_RIGHT ?
                        (facade.rotation.y !== undefined && !isNaN(facade.rotation.y) ? +facade.rotation.y : 0) + Math.PI :
                        facade.rotation.y;
                }
            }
        }

        return facades;
    }

    protected calculatePlinthsData(plinths?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        if (plinths) {
            let plinth: ISaveKUnitDetailData;
            for (plinth of plinths) {
                if (plinth.initPosition && plinth.initPosition.x !== undefined) {

                    if (plinth.margin && plinth.margin.x !== undefined) {
                        plinth.initPosition.x = this.getSideType() === SIDE_TYPE_RIGHT ?
                            +plinth.margin.x * -1 : +plinth.margin.x;
                    }
                }
            }
        }

        return plinths;
    }
}