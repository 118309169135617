import {ThreeKUnit} from '../../units/ThreeKUnit/ThreeKUnit';
import {DoubleSide, MeshStandardMaterial, Vector3} from 'three';
import {ISaveLegData} from '../../../../../../common-code/interfaces/saveData/ISaveLegData';
import {KitchenHelper} from 'common-code';
import {ThreeUnitDetail} from '../ThreeUnitDetail/ThreeUnitDetail';
import {ISaveUnitDetailData} from '../../../../../../common-code/interfaces/saveData/ISaveUnitDetailData';
import {DEFAULT_LEGS_HEIGHT} from '../../../../../../common-code/constants';

export class ThreeLeg extends ThreeUnitDetail {
    saveData: ISaveLegData;
    unit: ThreeKUnit;
    bodyMaterial: MeshStandardMaterial;

    constructor(options: ISaveLegData, unit: ThreeKUnit) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
        this.bodyMaterial = this.createBodyMaterial();
    }

    public initState(isRebuild?: boolean) {
        this.createBody();
        super.initState(isRebuild);
    }

    public createView(isRebuild?: boolean) {
        super.createView(isRebuild);
    }

    public getData(): ISaveLegData {
        let data: ISaveUnitDetailData = super.getData();

        return data as ISaveLegData;
    }

    public getHeight() {
        return this.saveData.height !== undefined && !isNaN(+this.saveData.height) && +this.saveData.height > 0 ?
            +this.saveData.height : DEFAULT_LEGS_HEIGHT;
    }

    public getGeometryType(): string {
        return 'default';
    }

    public calculateGlobalFrontVector() {
        this.globalFrontVector = this.unit.globalFrontVector;
        this.setCenterPosition();
    }

    public setCenterPosition() {
        if (!this.view3d.userData.centerPosition ||
            !(this.view3d.userData.centerPosition instanceof Vector3)) {
            this.view3d.userData.centerPosition = new Vector3()
        }
        this.view3d.userData.centerPosition = this.view3d.userData.centerPosition.copy(this.view3d.position).applyMatrix4(this.unit.view3d.matrixWorld);
    }

    public removeChildren() {
        this.view3d.traverse(function (mesh) {
            mesh.userData = {};
        });
        while (this.view3d.children.length) {
            this.view3d.remove(this.view3d.children[0]);
        }
    }

    public getTechnologMapFacadeId(): string {
        return this.unit.getTechnologMapFacadeId();
    }

    protected initSaveData(options: ISaveLegData): ISaveLegData {
        super.initSaveData(options);
        options.height = this.initHeight(options.height);
        return options;
    }

    protected initHeight(height: number | undefined): number {
        if (height !== undefined && !isNaN(+height) && +height > 0) {
            return +height;
        }

        return this.service.getProjectLegsHeight() || DEFAULT_LEGS_HEIGHT;
    }

    protected initPosition(): Vector3 {
        let position: Vector3;

        position = new Vector3(0, -this.unit.corpus.getHeight()/2, 0);
        if (this.saveData.initPosition) {
            if (this.saveData.initPosition.x !== undefined) {
                position.x = -this.unit.corpus.getWidth()/2 + KitchenHelper.calculateSizeByParent(
                    this.saveData.initPosition.x,
                    this.unit.corpus.getWidth(),
                    this.service.getDataForSizeByParent()
                ) + (this.saveData.margin?.x as number  || 0); ;
            }
            if (this.saveData.initPosition.y !== undefined) {
                position.y = -this.unit.corpus.getHeight()/2 + KitchenHelper.calculateSizeByParent(
                    this.saveData.initPosition.y,
                    this.unit.corpus.getHeight(),
                    this.service.getDataForSizeByParent()
                ) + (this.saveData.margin?.y as number || 0);
            }
            if (this.saveData.initPosition.z !== undefined) {
                position.z = -this.unit.corpus.getDepth()/2 +
                    KitchenHelper.calculateSizeByParent(
                        this.saveData.initPosition.z,
                        this.unit.corpus.getDepth(),
                        this.service.getDataForSizeByParent()
                    ) + (this.saveData.margin?.z as number || 0) ;
                }
        }

        if (this.saveData.position) {
            if (this.saveData.position.x !== undefined && !isNaN(+this.saveData.position.x)) {
                position.x = this.saveData.position.x;
            }
            if (this.saveData.position.y !== undefined && !isNaN(+this.saveData.position.y)) {
                position.y = this.saveData.position.y;
            }
            if (this.saveData.position.z !== undefined && !isNaN(+this.saveData.position.z)) {
                position.z = this.saveData.position.z;
            }
        }

        return position;
    }

    protected createBody() {

    }

    protected createBodyMaterial(): MeshStandardMaterial {
        return new MeshStandardMaterial({color: '#232323', side: DoubleSide, envMapIntensity: 5});
    }
}