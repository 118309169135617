import React from "react";
import { IClassNameProps } from "@bem-react/core";
import { useTranslation } from "react-i18next";
import { Button, cnButton } from "../../../components/Button";
import FormMaterial, { INewMaterialData } from "../FormMaterial/FormMaterial";
import { cn } from "@bem-react/classname";
import { IMaterialData } from "common-code/lib/interfaces/materials/IMaterialData";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import { IEditMode } from "../CurrentStep/WizardEditCurrent";
import * as materialServices from "../api/MaterialServices";
import { ISaveTexture } from "../../../../../common-code/interfaces/materials/ISaveTexture";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import { TTextureExtension } from "common-code/lib/types/TTextureExtension";
import { ITextureData } from "common-code/lib/interfaces/materials/ITextureData";
import { INewTextureData } from "../FormMaterial/Textures/Textures";
import { IMaterialMode } from "../WizardCollection/WizardCollection";
import { useDispatch } from "react-redux";
import { 
  CHANGE_EDIT_MODE,
  HIDE_LOADING, INIT_EDIT_MATERIAL, SHOW_LOADING } from "../../../../constants";
import { useLocation } from "react-router-dom";
import "./EditMaterial.css";

interface IEditMaterialProps extends IClassNameProps {
  service: KitchenService;
  saveMaterial: (
    materialListNew: IMaterialData[],
    materialMode: IMaterialMode
  ) => Promise<void>;
  saveImagePreview: (
    imageNew: File,
    materialId: string,
    materialMode: IMaterialMode
  ) => Promise<string | null>;
}

const cnEditMaterial = cn("EditMaterial");

function EditMaterial({
  service,
  saveMaterial,
  saveImagePreview,
}: IEditMaterialProps) {

  const { t } = useTranslation();
  const isEditMode = useSelector(
    (state: AppState) => state.wizard.editModule.isEditMode
  );
  const materials = useSelector(
    (state: AppState) => state.wizard[isEditMode]
  );
  const dispatch = useDispatch();
  const initialValue = useSelector((state: AppState) => state.wizard.editMaterial.itemMaterial);
  const [itemMaterial, setItemMaterial] = React.useState<INewMaterialData | null>(initialValue || null);
  const location = useLocation();
  const idCollection = location.search
    .split("&")
    .find((param) => param.includes("collection"))
    ?.split("=")[1];

  const handleToggleMode = (str: IEditMode) => {
    dispatch({ type: CHANGE_EDIT_MODE, payload: str});
    dispatch({ type: INIT_EDIT_MATERIAL });
  };

  const createMaterial = (material: INewMaterialData) => {
    service.addTextureObject(material);
    setItemMaterial(material);
  };
  // сохраняем текстуры
  const saveTextures = async (
    texturesNew: INewTextureData[],
    textures: ITextureData[] | undefined,
    idMaterial: string
  ) => {
    await Promise.all(
      texturesNew.map(async (textureNew) => {
        // сохраняем текстуру на сервер
        const saveTextureData: ISaveTexture = {
          id: `${textureNew.type}-${idMaterial}-${idCollection}`,
          file: textureNew.fileNew,
          type: textureNew.type,
        };

        const textureUrl = await materialServices.saveTexture(saveTextureData);
        if (!textureUrl) return (textures = undefined);

        // формируем новую текстуру
        const file = textureNew.fileNew.name;
        const extension = file.split(".")[1] as TTextureExtension;
        const { url, fileNew, ...rest } = textureNew;
        const texture = {
          ...rest,
          path: textureUrl.url as string,
          file,
          extension,
        };

        // проверяем есть ли такая текстура
        const existingTexture = textures?.find(
          (textureItem) => textureItem.type === texture.type
        );

        if (existingTexture) {
          // если есть - перезаписываем
          textures = textures?.map((textureItem) =>
            textureItem.type === texture.type ? texture : textureItem
          );
        } else {
          // если нет - добавляем
          textures = [...(textures || []), texture];
        }
      })
    );

    return textures;
  };

  const saveMaterialCollection = async (itemMaterial: INewMaterialData) => {
    // проверяем корректность заполнения формы
    if (!itemMaterial) {
      window.alert("Необходимо корректно заполнить форму.");
      return;
    }
    // если такое название уже есть
    if (
      materials.length > 0 &&
      materials.find(
        (material: IMaterialData) => material.id === itemMaterial.id
      )
    ) {
      window.alert(
        "Материал с таким наменованием уже существует в коллекции. Укажите другое название."
      );
      return;
    }
    dispatch({ type: SHOW_LOADING });
  
    // сохраняем текстуру материала
    if (itemMaterial.texturesNew) {
      const textures = await saveTextures(
        itemMaterial.texturesNew,
        itemMaterial.textures as ITextureData[],
        itemMaterial.id
      );
      if (!textures) {
        window.alert("Ошибка при сохранении текстур. Попробуйте еще раз.");
        dispatch({ type: HIDE_LOADING });
        return;
      }
      itemMaterial.textures = textures;
      // удаляем ключ texturesNew
      delete itemMaterial.texturesNew;
    }

    // сохраняем preview материала
    if (itemMaterial.imageNew) {
      const imageUrl = await saveImagePreview(
        itemMaterial.imageNew,
        itemMaterial.id,
        isEditMode
      );
      if (!imageUrl) {
        window.alert("Ошибка при сохранении превью. Попробуйте еще раз.");
        dispatch({ type: HIDE_LOADING });
        return;
      }
      itemMaterial.image = imageUrl;
      // удаляем клуч imageNew
      delete itemMaterial.imageNew;
    }

    // сохраняем материал
    const materialListNew = [...materials, itemMaterial];
    saveMaterial(materialListNew, isEditMode).then(() => {
      handleToggleMode("");
      dispatch({ type: HIDE_LOADING });
    });
  };

  const editMaterialCollection = async (itemMaterial: INewMaterialData) => {
    // проверяем корректность заполнения формы
    if (!itemMaterial) {
      window.alert("Необходимо корректно заполнить форму.");
      return;
    }
    // если такого названия нет
    if (
      materials.length > 0 &&
      !materials.find(
        (material: IMaterialData) => material.id === itemMaterial.id
      )
    ) {
      window.alert(
        "Материала с таким наменованием нет. Укажите другое название."
      );
      return;
    }
    dispatch({ type: SHOW_LOADING });
 
    // сохраняем текстуру материала
    if (itemMaterial.texturesNew) {
      const textures = await saveTextures(
        itemMaterial.texturesNew,
        itemMaterial.textures as ITextureData[],
        itemMaterial.id
      );
      if (!textures) {
        window.alert("Ошибка при сохранении текстур. Попробуйте еще раз.");
        dispatch({ type: HIDE_LOADING });
        return;
      }
      itemMaterial.textures = textures;
      // удаляем ключ texturesNew
      delete itemMaterial.texturesNew;
    }

    // сохраняем preview материала
    if (itemMaterial.imageNew) {
      const imageUrl = await saveImagePreview(
        itemMaterial.imageNew,
        itemMaterial.id,
        isEditMode
      );
      if (!imageUrl) {
        window.alert("Ошибка при сохранении превью. Попробуйте еще раз.");
        dispatch({ type: HIDE_LOADING });
        return;
      }
      itemMaterial.image = imageUrl;
      // удаляем клуч imageNew
      delete itemMaterial.imageNew;
    }
    // сохраняем материал
    const materialListNew = materials.map((material: IMaterialData) =>
      material.id === itemMaterial.id ? itemMaterial : material
    );
    saveMaterial(materialListNew, isEditMode).then(() => {
      handleToggleMode("");
      dispatch({ type: HIDE_LOADING });
    });
  };

  return (
    <>
      <FormMaterial
        itemMaterial={itemMaterial}
        createMaterial={createMaterial}
      />

      <div className={cnEditMaterial("Buttons-Container")}>
        <Button
          className={cnEditMaterial(cnButton(), { type: "prev" })}
          leftIcon={{path: "arrow-left",width: 20,height: 20,marginRight: 8,}}
          text={t("Предыдущий шаг") || undefined}
          callBack={() => handleToggleMode("")}
          mods={{mods: {  color: "black",  bkg: "white",  border: "black",  fill: "black",},}}
          type={"button"}
        />
      </div>

      {itemMaterial && (
        <>
          <div className={cnEditMaterial("Button-Add")}>
            <Button  className={cnEditMaterial(cnButton())}  
              type={"button"}  text={"Добавить новый материал в коллекцию"}  
              leftIcon={{    path: "plus2",    width: 16,    height: 16,    marginRight: 8,}}
              mods={{mods: {bkg: "white",color: "lightGreen",border: "lightGreen",fill: "lightGreen",},
              hover: {mods: { bkg: "lightGreen", color: "white", fill: "white" },},}}
              callBack={() => saveMaterialCollection(itemMaterial)}
            />
            <Button
              className={cnEditMaterial(cnButton())}
              type={"button"}
              text={"Заменить редактируемый материал в коллекции"}
              leftIcon={undefined}
              mods={{mods: {bkg: "white",color: "lightGreen",border: "lightGreen",fill: "lightGreen",},
              hover: {mods: { bkg: "lightGreen", color: "white", fill: "white" },},}}
              callBack={() => editMaterialCollection(itemMaterial)}
            />
          </div>
        </>
      )}
    </>
  );
}

export default EditMaterial;
