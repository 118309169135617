import React, { MutableRefObject } from "react";
import { IClassNameProps } from "@bem-react/core";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import { ICreateObjectDomElements } from "../../../../interfaces/ICreateObjectDomElements";
import { ICreateGroup } from "../../../../../common-code/interfaces/createData/ICreateGroup";
import { WizardEditModules } from "../EditModules/Wizard-Edit-Modules";
import {
  IMaterialMode,
  WizardCollection,
} from "../WizardCollection/WizardCollection";
import { cn } from "@bem-react/classname";
import {
  GROUP_BOTTOM_ANGLE_UNITS,
  GROUP_BOTTOM_END_UNITS,
  GROUP_BOTTOM_NORMAL_UNITS,
  GROUP_PENAL_UNITS,
  GROUP_TOP_ANGLE_UNITS,
  GROUP_TOP_END_UNITS,
  GROUP_TOP_NORMAL_UNITS,
} from "common-code/lib/constants";
import * as unitListServices from "../api/UnitListServices";
import * as materialServices from "../api/MaterialServices";
import { useLocation } from "react-router-dom";
import { useEffectDidMount } from "../../../../hooks/useEffectDidMount";
import { useDispatch } from "react-redux";
import {
  CHANGE_CORPUS_MATERIALS,
  CHANGE_EDIT_MODE,
  CHANGE_FACADE_MATERIALS,
  CHANGE_FACADES,
  CHANGE_MATERIALS,
  CHANGE_PLINTHS,
  HIDE_LOADING,
  INIT_EDIT_MATERIAL,
  INIT_EDIT_MODULE,
} from "../../../../constants";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import EditModule from "../EditModule/EditModule";
import EditMaterial from "../EditMaterial/EditMaterial";
import EditFacades from "../EditFacades/EditFacades";
import EditPlinths from "../EditPlinth/EditPlinths";
import EditFacadeMaterials from "../EditFacadeMaterial/EditFacadeMaterials";
import { ISaveImage } from "../../../../../common-code/interfaces/materials/ISaveImage";
import { IMaterialData } from "common-code/lib/interfaces/materials/IMaterialData";
import "./WizardEditCurrent.css";

export const cnWizardEditCurrent = cn("WizardEditCurrent");

export interface IWizardEditCurrentProps extends IClassNameProps {
  service: KitchenService;
  createObjects: MutableRefObject<ICreateObjectDomElements>;
  isInitState?: boolean;
}

export type IEditMode =
  | "module"
  | "materials"
  | "corpusMaterials"
  | "facadeMaterials"
  | "facades"
  | "plinths"
  | "";

export const WizardEditCurrent: React.FC<IWizardEditCurrentProps> = ({
  service,
  createObjects,
  isInitState,
}) => {
  const dispatch = useDispatch();

  const isEditMode = useSelector(
    (state: AppState) => state.wizard.editModule.isEditMode
  );

  // коллекции модулей
  const [unitListState, setUnitListState] = React.useState<
    ICreateGroup[] | undefined
  >(undefined);

  // получаем все модули для коллекции из сервера
  const unitList = service?.getCreateGroupUnits([
    GROUP_BOTTOM_ANGLE_UNITS,
    GROUP_BOTTOM_END_UNITS,
    GROUP_BOTTOM_NORMAL_UNITS,
    GROUP_PENAL_UNITS,
    GROUP_TOP_ANGLE_UNITS,
    GROUP_TOP_END_UNITS,
    GROUP_TOP_NORMAL_UNITS,
  ]);

  const location = useLocation();
  const idCollection = location.search
    .split("&")
    .find((param) => param.includes("collection"))
    ?.split("=")[1];

  // проверка наличия модулей в коллекции в файле
  useEffectDidMount(() => {
    unitListServices
      .initUnitList(idCollection as string)
      .then((data: ICreateGroup[] | undefined) => {
        if (data && data.length > 0) {
          setUnitListState(data);
        }
      });
    dispatch({ type: INIT_EDIT_MODULE });
    dispatch({ type: INIT_EDIT_MATERIAL });
  });

  React.useEffect(() => {
    if (isInitState && isEditMode && service) {
      service.startRender();
    } else if (isInitState && !isEditMode && service) {
      service.clearEditorScene();
    }
    // eslint-disable-next-line
  }, [isEditMode]);

  // запись коллекции модулей в файл
  const saveUnitList = async (unitListNew: ICreateGroup[]) =>
    await unitListServices
      .saveUnitList(unitListNew as ICreateGroup[], idCollection as string)
      .then((data: ICreateGroup[] | undefined) => {
        setUnitListState(data as ICreateGroup[]);
        dispatch({ type: CHANGE_EDIT_MODE, payload: "" });
        dispatch({ type: HIDE_LOADING });
      })
      .catch(() => {
        alert(
          "Произошла ошибка при сохранении коллекции модулей. Попробуйте еще раз."
        );
        dispatch({ type: HIDE_LOADING });
      });

  // сохраням превью материала
  const saveImagePreview = async (
    imageNew: File,
    facadeMaterialId: string,
    materialMode: IMaterialMode
  ): Promise<string | null> => {
    const saveImageData: ISaveImage = {
      id: `${facadeMaterialId}-${idCollection}`,
      folder: "imagePreview",
      title: materialMode,
      file: imageNew,
    };
    const imageUrl = await materialServices.saveImage(saveImageData);
    if (!imageUrl) return null;
    return imageUrl.url as string;
  };

  // сохраням материалы
  const saveMaterial = async (
    materialListNew: IMaterialData[],
    materialMode: IMaterialMode
  ) => {
    const typeAction = (materialMode: IMaterialMode) => {
      switch (materialMode) {
        case "materials":
          return CHANGE_MATERIALS;
        case "corpusMaterials":
          return CHANGE_CORPUS_MATERIALS;
        case "facadeMaterials":
          return CHANGE_FACADE_MATERIALS;
        case "facades":
          return CHANGE_FACADES;
        case "plinths":
          return CHANGE_PLINTHS;
      }
    };
    // сохраням материалы на сервер
    await materialServices
      .saveMaterial(materialListNew, materialMode, idCollection as string)
      .then(() => {
        dispatch({
          type: typeAction(materialMode),
          payload: materialListNew,
        });
      });
  };

  // рендерим режим редактирования
  const renderEditMode = () => {
    switch (isEditMode) {
      case "materials":
      case "corpusMaterials":
        return (
          <EditMaterial
            service={service}
            saveMaterial={saveMaterial}
            saveImagePreview={saveImagePreview}
          />
        );
      case "facades":
        return (
          <EditFacades
            saveFacade={saveMaterial}
            saveImagePreview={saveImagePreview}
          />
        );
      case "plinths":
        return (
          <EditPlinths
            service={service}
            savePlinth={saveMaterial}
            saveImagePreview={saveImagePreview}
          />
        );
      case "facadeMaterials":
        return (
          <EditFacadeMaterials
            service={service}
            saveFacadeMaterial={saveMaterial}
            saveImagePreview={saveImagePreview}
          />
        );
      case "module":
        return (
          <EditModule
            service={service}
            modules={unitListState ? unitListState : unitList}
            saveUnitList={saveUnitList}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <WizardEditModules
        service={service}
        createObjects={createObjects}
        modules={unitListState ? unitListState : unitList}
        saveUnitList={saveUnitList}
      />
      <WizardCollection saveMaterial={saveMaterial} />
      {renderEditMode()}
    </>
  );
};
