import {KitchenService} from '../KitchenService';
import {TAspectData} from '../../../../../common-code/types/TAspectData';
import {
    KITCHEN_SIZES_TYPE_ALL,
    KITCHEN_SIZES_TYPE_NONE,
    KITCHEN_VIEW_SKETCH,
    KITCHEN_VIEW_VISUAL
} from '../../../../constants';
import {TKitchenImages} from '../../../../../common-code/types/TKitchenImages';
import {TKitchenImagesEDIT} from '../../../../../common-code/types/TKitchenImagesEDIT';
import {TKitchenSizesType} from '../../../../types/TKitchenSizesType';
import {TWizardUIOptions} from '../../../../types/TWizardUIOptions';
import {CommonHelper} from 'common-code';
import {TKitchenCuttingImages} from '../../../types/TKitchenCuttingImages';
import {ThreeKUnitDetail} from '../../../objects/threeD/details/ThreeKUnitDetail/ThreeKUnitDetail';
import {IDetailCuttingData} from '../../../interfaces/cutting/IDetailCuttingData';
import {RENDERER_TYPE_CUTTING} from '../../../constants';
import {BufferGeometry, Line, LineBasicMaterial, Mesh} from 'three';

export class DrawManager {
    service: KitchenService;

    creatingImages: boolean;
    creatingCuttingImages: boolean;
    constructor(service: KitchenService) {
        this.service = service;
        this.creatingImages = false;
        this.creatingCuttingImages = false;
    }

    public createImages(sizeType?: TKitchenSizesType, aspect?: TAspectData): Promise<TKitchenImages | undefined> {
        return new Promise<TKitchenImages | undefined>((resolve, reject) => {
            if (!this.service.isReady()) {
                this.creatingImages = false;
                resolve(undefined);
            }
            let oldOptions: TWizardUIOptions | undefined;

            if (!this.creatingImages) {
                oldOptions = CommonHelper.deepCopy(this.service.getOptions());
            }
            this.creatingImages = true;
            this.service.clearSelectCovers();
            this.service.setAspect(this.service.getDefaultAspect());
            this.service.renderStep();
            this.service.renderStep();
            this.createVisualImage(sizeType).then((visualImage) => {
                this.createSketchImage(sizeType,aspect).then((sketchImage) => {
                    this.creatingImages = false;
                    if (oldOptions) {
                        this.service.setViewType(oldOptions.viewType);
                        this.service.setSizeType(oldOptions.sizesType);
                    }
                    resolve({visual: visualImage, sketch: sketchImage});
                })
            }).catch(() => {
                this.creatingImages = false;
                reject();
            });
        })

    }

    public createDivImages(sizeType?: TKitchenSizesType, aspect?: TAspectData): Promise<TKitchenImages | undefined> {
        return new Promise<TKitchenImages | undefined>((resolve, reject) => {
            if (!this.service.isReady()) {
                this.creatingImages = false;
                resolve(undefined);
            }
            let oldOptions: TWizardUIOptions | undefined;

            if (!this.creatingImages) {
                oldOptions = CommonHelper.deepCopy(this.service.getOptions());
            }
            this.creatingImages = true;
            this.service.clearSelectCovers();
            this.service.setAspect(this.service.getDefaultAspect());
            this.service.renderStep();
            this.service.renderStep();
            this.createVisualImage(sizeType).then((visualImage) => {
                this.createDivSketchImage(sizeType,aspect).then((sketchImage) => {
                    this.creatingImages = false;
                    if (oldOptions) {
                        this.service.setViewType(oldOptions.viewType);
                        this.service.setSizeType(oldOptions.sizesType);
                    }
                    resolve({visual: visualImage, sketch: sketchImage});
                })
            }).catch(() => {
                this.creatingImages = false;
                reject();
            });
        })
    }

    public createImagesEDIT(leftAspect: TAspectData, frontAspect: TAspectData): Promise<TKitchenImages | undefined> {
        return new Promise<TKitchenImages | undefined>((resolve, reject) => {
            if (!this.service.isReady()) {
                this.creatingImages = false;
                resolve(undefined);
            }
            let oldOptions: TWizardUIOptions | undefined;

            if (!this.creatingImages) {
                oldOptions = CommonHelper.deepCopy(this.service.getOptions());
            }
            this.creatingImages = true;
            this.service.clearSelectCovers();
            // this.service.setAspect(aspect);
            this.service.renderStep();
            this.service.renderStep();
            this.createVisualImage('none', leftAspect).then((visualImage_left) => {
                this.createVisualImage('none',frontAspect).then((visualImage_right) => {
                    this.createSketchImage('all',leftAspect).then((sketchImage) => {
                        this.creatingImages = false;
                        
                        if (oldOptions) {
                            this.service.setViewType(oldOptions.viewType);
                            this.service.setSizeType(oldOptions.sizesType);
                        }
                        resolve({visual: visualImage_right, sketch: sketchImage, otherImages:[visualImage_left]});
                    })
                })  
            }).catch(() => {
                this.creatingImages = false;
                reject();
            });
        })
    }

    public createCuttingImages(): Promise<TKitchenCuttingImages | undefined> {
        return new Promise<TKitchenCuttingImages | undefined>((resolve, reject) => {
            if (!this.service.isReady()) {
                this.creatingCuttingImages = false;
                resolve(undefined);
            }
            let tabletopsCutting: IDetailCuttingData[];
            let apronsCutting: IDetailCuttingData[];

            this.creatingCuttingImages = true;
            this.service.initEditorCuttingViewer();
            tabletopsCutting = this.getDetailCuttingData(this.service.getTabletops());
            apronsCutting = this.getDetailCuttingData(this.service.getAprons());
            this.createCuttingImage(tabletopsCutting).then(tabletopImage => {
                this.createCuttingImage(apronsCutting).then(apronImage => {
                    this.creatingCuttingImages = false;
                    this.service.removeEditorCuttingViewer();
                    resolve({
                        tabletop: {
                            cuttingData: tabletopsCutting,
                            image: tabletopImage,
                        },
                        apron: {
                            cuttingData: apronsCutting,
                            image: apronImage,
                        }
                    });
                })
            }).catch(() => {
                this.creatingCuttingImages = false;
                this.service.removeEditorCuttingViewer();
                reject();
            });
        })
    }

    public createCuttingImage(cuttingData: IDetailCuttingData[]): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let data: IDetailCuttingData;
            let lineMaterial: LineBasicMaterial;
            let line: Line;
            let textMesh: Mesh;

            this.service.clearEditorCuttingScene();
            lineMaterial = new LineBasicMaterial({color: '#000000', linewidth: 3});
            for (data of cuttingData) {
                line = new Line(new BufferGeometry().setFromPoints(data.points), lineMaterial);
                line.position.set(data.position.x, data.position.y, data.position.z);
                line.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
                textMesh = this.service.createTextSizeMesh('' + data.index);
                textMesh.rotation.copy(line.rotation);
                textMesh.position.setY(-50);
                line.add(textMesh);
                this.service.addToCuttingSceneObject3D(line);
            }
            this.service.renderCuttingStep(cuttingData);
            this.createImage(RENDERER_TYPE_CUTTING, true).then((imageData) => {
                this.service.clearEditorCuttingScene();
                resolve(imageData);
            }).catch(() => {
                reject();
            });
        })
    }

    public createVisualImage(sizeType?: TKitchenSizesType, aspect?: TAspectData): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!sizeType) {
                sizeType = KITCHEN_SIZES_TYPE_NONE;
            }
            if (!aspect) {
                aspect = this.service.getDefaultAspect();
            }
            this.service.clearSelectCovers();
            this.service.setViewType(KITCHEN_VIEW_VISUAL);
            this.service.setSizeType(sizeType);
            this.service.renderStep();
            this.service.setAspect(aspect);
            this.service.renderStep();
            this.service.renderStep();
            this.createImage('visual').then((imageData) => {
                resolve(imageData);
            }).catch(() => {
                reject();
            });
        })
    }

    public createSketchImage(sizeType?: TKitchenSizesType, aspect?: TAspectData): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!sizeType) {
                sizeType = KITCHEN_SIZES_TYPE_ALL;
            }
            if (!aspect) {
                aspect = this.service.getDefaultAspect();
            }
            this.service.setViewType(KITCHEN_VIEW_SKETCH);
            this.service.setSizeType(sizeType);
            this.service.setAspect(aspect);
            this.service.renderStep();
            this.service.renderStep();
            this.createImage('sketch').then((imageData) => {
                resolve(imageData);
            }).catch(() => {
                reject();
            });
        })

    }

    public createDivSketchImage(sizeType?: TKitchenSizesType, aspect?: TAspectData): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!sizeType) {
                sizeType = KITCHEN_SIZES_TYPE_ALL;
            }
            if (!aspect) {
                aspect = this.service.getDefaultAspect();
            }
            this.service.setViewType(KITCHEN_VIEW_SKETCH);
            this.service.setSizeType(sizeType);
            this.service.setAspect(aspect);
            this.service.renderStep();
            this.service.renderStep();
            this.createDivImage('sketch').then((imageData) => {
                resolve(imageData);
            }).catch(() => {
                reject();
            });
        })

    }

    protected createImage(rendererType: string, screenAll?: boolean): Promise<string> {
        return this.service.getImageData(rendererType, screenAll);
    }

    protected createDivImage(rendererType: string, screenAll?: boolean): Promise<string> {
        return this.service.getDivImageData(rendererType, screenAll);
    }

    protected getDetailCuttingData(details: ThreeKUnitDetail[]): IDetailCuttingData[] {
        let detailsCuttingData: IDetailCuttingData[] = [];
        let detail: ThreeKUnitDetail;
        let yPosition: number;
        let index: number;
        const GAP = 720;

        yPosition = details.length / 4 * GAP - GAP / 4;
        for (index = 0; index < details.length; index++) {
            detail = details[index];
            if (index % 2 === 0 && index > 1) {
                yPosition = yPosition - GAP;
            }
            detailsCuttingData.push({
                index: index + 1,
                points: detail.getShapePoints(),
                sizes: {
                    length: detail.getLength(),
                    height: detail.getHeight(),
                    width: detail.getWidth()
                },
                position: {
                    x: (details.length > 1) ?
                        ((index % 2 !== 0) ?
                                (detail.getLength() / 2 + 130) : (-detail.getLength() / 2 - 130)
                        ) : 0,
                    y: yPosition,
                    z: 0
                },
                rotation: {
                    x: 0,
                    y: Math.PI,
                    z: Math.PI
                }
            });
        }

        return detailsCuttingData;
    }
}