import React from "react";
import { cnIntegratedHandles } from "./IntegratedHandles";
import Edit from "../../../icons/Edit";
import Trash from "../../../icons/Trash";
import ModalIntegratedHandles from "./ModalIntegratedHandles";
import { IIntegratedHandle } from "./IntegratedHandles";

function IntegratedHandle({
  arrIntegratedHandles,
  setArrIntegratedHandles,
  integratedHandle,
  index,
  validateIntegratedHandle,
}: {
  arrIntegratedHandles: IIntegratedHandle[];
  setArrIntegratedHandles: (arr: IIntegratedHandle[]) => void;
  integratedHandle: IIntegratedHandle;
  index: number;
  validateIntegratedHandle: (integratedHandle: IIntegratedHandle) => string;
}) {
  const [modal, setModal] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [newIntegratedHandle, setNewIntegratedHandle] =
    React.useState<IIntegratedHandle>(integratedHandle);

  const handleToggleModal = () => setModal((prev) => !prev);
  const onFocus = () => {
    setError("");
  };

  const handleDeleteIntegratedHandle = () =>
    setArrIntegratedHandles(
      arrIntegratedHandles.filter((el, i) => i !== index)
    );

  const handleEditIntegratedHandle = () => {
    const error = validateIntegratedHandle(newIntegratedHandle);
    if (error) {
      setError(error);
      return;
    }
    setArrIntegratedHandles(
      arrIntegratedHandles.map((el) =>
        el.functionalType === integratedHandle.functionalType
          ? newIntegratedHandle
          : el
      )
    );
    setModal(!modal);
  };

  return (
    <div className={cnIntegratedHandles("Children")}>
      <span>Интегрированные ручки</span>
      <div className={cnIntegratedHandles("Box-Btn")}>
        <Edit handleToggleModal={handleToggleModal} />
        <Trash handleDeleteValue={handleDeleteIntegratedHandle} />
      </div>

      <ModalIntegratedHandles
        modal={modal}
        onFocus={onFocus}
        handleToggleModal={handleToggleModal}
        newIntegratedHandle={newIntegratedHandle}
        setNewIntegratedHandle={setNewIntegratedHandle}
        error={error}
        handleSubmit={handleEditIntegratedHandle}
        keyValue={`#editIntegratedHandle-${index}`}
      />
    </div>
  );
}

export default IntegratedHandle;
