import React from "react";
import { cnIntegratedHandles } from "./IntegratedHandles";
import { cnModal, Modal } from "../../../../../components/Modal";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { Button } from "../../../../../components/Button";
import { Select } from "../../../../../components/Select/Select";
import {
  IIntegratedHandle,
  integratedHandlesFunctionalTypes,
} from "./IntegratedHandles";
import { TIntegratedHandleFunctionalType } from "common-code/lib/types/materials/TIntegratedHandleFunctionalType";

function ModalIntegratedHandles({
  modal,
  onFocus,
  handleToggleModal,
  error,
  newIntegratedHandle,
  setNewIntegratedHandle,
  handleSubmit,
  keyValue,
}: {
  modal: boolean;
  onFocus: () => void;
  handleToggleModal: () => void;
  error?: string;
  newIntegratedHandle: IIntegratedHandle;
  setNewIntegratedHandle: (newIntegratedHandle: IIntegratedHandle) => void;
  handleSubmit: () => void;
  keyValue: string;
}) {
  const isEdit = keyValue.includes("edit");
  return (
    <Modal
      className={cnIntegratedHandles(cnModal())}
      visible={modal}
      callBack={handleToggleModal}
      key={keyValue}
    >
      <h4 className={cnIntegratedHandles("Header")}>
        {isEdit ? "Редактирование " : "Добавление"} интегрированной ручки
      </h4>

      <div className={cnIntegratedHandles("Content")}>
        <h4 className={cnIntegratedHandles("Title")}>Позиция</h4>

        <div className={cnIntegratedHandles("Box-Input")}>
          <div>
            <label htmlFor="width">Позиция A по горизонтали</label>
            <Textinput
              className={cnIntegratedHandles("Textinput")}
              value={newIntegratedHandle.interval?.pointA.x}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewIntegratedHandle(
                  newIntegratedHandle.interval
                    ? {
                        ...newIntegratedHandle,
                        interval: {
                          ...newIntegratedHandle.interval,
                          pointA: {
                            x: e.target.value,
                            y: newIntegratedHandle.interval.pointA.y,
                            z: newIntegratedHandle.interval.pointA.z,
                          },
                          pointB: newIntegratedHandle.interval.pointB,
                        },
                      }
                    : {
                        ...newIntegratedHandle,
                        interval: {
                          pointA: { x: e.target.value, y: "", z: "" }, // or some default value for y
                          pointB: { x: "", y: "", z: "" }, // or some default value for pointB
                        },
                      }
                )
              }
              name="pointA.x"
              onFocus={onFocus}
              type="string"
            />
          </div>

          <div>
            <label htmlFor="width">Позиция A по вертикали</label>
            <Textinput
              className={cnIntegratedHandles("Textinput")}
              value={newIntegratedHandle.interval?.pointA.y}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewIntegratedHandle(
                  newIntegratedHandle.interval
                    ? {
                        ...newIntegratedHandle,
                        interval: {
                          ...newIntegratedHandle.interval,
                          pointA: {
                            x: newIntegratedHandle.interval.pointA.x,
                            y: e.target.value,
                            z: newIntegratedHandle.interval.pointA.z,
                          },
                          pointB: newIntegratedHandle.interval.pointB,
                        },
                      }
                    : {
                        ...newIntegratedHandle,
                        interval: {
                          pointA: { x: "", y: e.target.value, z: "" }, // or some default value for y
                          pointB: { x: "", y: "", z: "" }, // or some default value for pointB
                        },
                      }
                )
              }
              name="pointA.y"
              onFocus={onFocus}
              type="string"
            />
          </div>

          <div>
            <label htmlFor="width">Позиция A в глубину</label>
            <Textinput
              className={cnIntegratedHandles("Textinput")}
              value={newIntegratedHandle.interval?.pointA.z}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setNewIntegratedHandle(
                  newIntegratedHandle.interval
                    ? {
                        ...newIntegratedHandle,
                        interval: {
                          ...newIntegratedHandle.interval,
                          pointA: {
                            x: newIntegratedHandle.interval.pointA.x,
                            y: newIntegratedHandle.interval.pointA.y,
                            z: e.target.value,
                          },
                          pointB: newIntegratedHandle.interval.pointB,
                        },
                      }
                    : {
                        ...newIntegratedHandle,
                        interval: {
                          pointA: { x: "", y: "", z: e.target.value }, // or some default value for y
                          pointB: { x: "", y: "", z: "" }, // or some default value for pointB
                        },
                      }
                )
              }
              name="pointA.y"
              onFocus={onFocus}
              type="string"
            />
          </div>

          <div>
            <label htmlFor="functionalType">Тип ручки</label>
            <Select
              className={cnIntegratedHandles("Select")}
              placeholder={"Выберите значение"}
              showPlaceholder={false}
              options={integratedHandlesFunctionalTypes}
              value={
                newIntegratedHandle.functionalType
                  ? newIntegratedHandle.functionalType
                  : "default"
              }
              callBack={(e) =>
                setNewIntegratedHandle({
                  ...newIntegratedHandle,
                  functionalType: e.target
                    .value as TIntegratedHandleFunctionalType,
                })
              }
            />
          </div>
          {error && <p className="text-danger">{error}</p>}
        </div>

        <Button
          className={cnIntegratedHandles("Button")}
          type={"button"}
          text={`${isEdit ? "Изменить" : "Добавить"} интегрированную ручку`}
          leftIcon={{ path: "plus2", width: 16, height: 16, marginRight: 8 }}
          mods={{
            mods: {
              bkg: "white",
              color: "lightGreen",
              border: "lightGreen",
              fill: "lightGreen",
            },
            hover: {
              mods: { bkg: "lightGreen", color: "white", fill: "white" },
            },
          }}
          callBack={handleSubmit}
        />
      </div>
    </Modal>
  );
}

export default ModalIntegratedHandles;
