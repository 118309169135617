import {ThreeUnit} from '../../ThreeUnit/ThreeUnit';
import SpriteText from 'three-spritetext';
import {ACTION_COPY, ACTION_REPLACE, KITCHEN_VIEW_SKETCH, KITCHEN_VIEW_VISUAL} from '../../../../../constants';
import {KitchenService} from '../../../../services/KitchenService/KitchenService';
import {BoxGeometry, DoubleSide, Group, Mesh, MeshBasicMaterial, Object3D, Vector3} from 'three';
import {i18n} from '../../../../../i18n';
import {
    onAfterRenderTransparentForBack,
    onBeforeRenderTransparentForBack
} from '../../../../helpers/ThreeHelper/ThreeHelper';
import {ISaveEquipmentData} from '../../../../../../common-code/interfaces/saveData/ISaveEquipmentData';
import {IEquipmentModelData} from '../../../../../../common-code/interfaces/IEquipmentModelData';
import {CommonHelper} from 'common-code';
import {IContextIcon} from '../../../../../interfaces/IContextIcon';
import {ICreateObjectData} from '../../../../../../common-code/interfaces/createData/ICreateObjectData';

export class ThreeEquipment extends ThreeUnit {
    saveData: ISaveEquipmentData;
    sticker?: SpriteText;
    threeModelData: IEquipmentModelData;
    threeModel: Group;
    dummy: Mesh;

    constructor(options: ISaveEquipmentData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.dummy = new Mesh();
        this.threeModelData = this.initThreeModelData();
        this.threeModel = new Group();
    }
    public initState(isRebuild?: boolean) {
        this.createDummy();
        this.createThreeModel();
        super.initState(isRebuild);
    }

    public createView(isRebuild?: boolean) {
        this.createSticker();
        super.createView(isRebuild);
    }

    public getData(): ISaveEquipmentData {
        let data: ISaveEquipmentData = CommonHelper.deepCopy(super.getData());
        data.sticker = this.sticker ? this.sticker.text : '';
        data.notPrice = this.calculateNotPrice();
        return data;
    }

    public hideSticker() {
        if (this.sticker) {
            this.sticker.visible = false;
        }
    }

    public showSticker() {
        if (this.sticker) {
            this.sticker.visible = true;
        }
    }

    public getThreeModelId(): string {
        return this.saveData.model;
    }

    public setLoadModel(type: string, details: Object3D[]) {
        let detail: Object3D;
        let newDetail: Object3D;

        for (detail of details) {
            newDetail = detail.clone();
            newDetail.matrixAutoUpdate = false;
            this.threeModel.add(newDetail);
        }
        this.dummy.visible = false;
        // модель масштабируется в ThreeKUnit - createEquipment
        this.scaleThreeModel();
        this.updateViewType();
        if (this.transparentForBack) {
            this.setTransparentForBack();
        }
    }

    public setTransparentForBack() {
        this.threeModel.traverse((child) => {
            if (child.userData.notTransparentForBack) {
                return;
            }
            child.userData.parent = this.view3d;
            if (child instanceof Mesh) {
                child.onBeforeRender = onBeforeRenderTransparentForBack;
                child.onAfterRender = onAfterRenderTransparentForBack;
            }
        })
    }

    public scaleThreeModel() {
        // не масштабировать модель встраиваемой варочной панели 
        const hobIndex = this.threeModel.children.findIndex((child) => child.name === "ChamferBox001002");
        if(hobIndex !== -1) return;

        // масштабировать модель встаиваемой раковины 
        const builtInBoxSinkIndex  = this.threeModel.children.findIndex((child) => child.name === "Ulgran_U-507-310_&_Frap_F4352-20");
        if(builtInBoxSinkIndex !== -1) {
            this.threeModel.scale.set(
                this.getWidth()/this.threeModelData.width,
                (this.getHeight()/this.threeModelData.height)+0.49,
                this.getDepth()/this.threeModelData.depth
            );
            this.updateAllMatrices();
        } else {
            const index2 = this.threeModel.children.findIndex((child) => child.name === "Box018");   
            this.threeModel.scale.set(
            this.getWidth()/this.threeModelData.width,
            index2 !== -1  ? this.getHeight()/this.threeModelData.height-1 : this.getHeight()/this.threeModelData.height,
            this.getDepth()/this.threeModelData.depth
            );
            this.updateAllMatrices();
        }
    }

    public getContextIcons(): IContextIcon[] {
        let icons: IContextIcon[];
        let actionData = this.actionData();

        icons = [
            {
                channelName: 'ThreeEquipment',
                action: ACTION_COPY,
                actionData: actionData,
                popup: false,
                icon: 'copy-object',
                hide: true,
                title: i18n.t('Копировать'),
                sort: 110,
            },
            {
                channelName: 'ThreeEquipment',
                action: ACTION_REPLACE,
                actionData: actionData,
                popup: false,
                icon: 'replace',
                hide: true,
                title: i18n.t('Заменить'),
                sort: 120
            }
        ];
        icons = icons.concat(super.getContextIcons());

        return icons;
    }


    public updateViewType() {
        super.updateViewType();
        switch (this.viewType) {
            case KITCHEN_VIEW_SKETCH:
                this.showSticker();
                break;
            case KITCHEN_VIEW_VISUAL:
            default:
                this.hideSticker();
                break;
        }
    }

    protected initThreeModelData(): IEquipmentModelData {
        return this.service.getEquipmentModel(this);
    }

    protected calculateNotPrice(): boolean {
        const createData: ICreateObjectData | undefined = this.service.getCreateUnitByUid(this.saveData.uid);

        if (createData && createData.notPrice !== undefined) {
            return createData.notPrice;
        }

        return false;
    }

    protected createDummy() {
        let geometry;
        let coverPoints: Vector3[];

        geometry = new BoxGeometry(this.getWidth(), this.getHeight(), this.getDepth());
        this.dummy = new Mesh(
            geometry,
            new MeshBasicMaterial({
                side: DoubleSide,
                color: '#757575',
                wireframe: true,
            })
        );
        coverPoints = [
            new Vector3(-this.getWidth()/2, -this.getHeight()/2, -this.getDepth()/2),
            new Vector3(this.getWidth()/2, this.getHeight()/2, this.getDepth()/2),
        ];
        this.view3d.add(this.dummy);
        this.addCoverPoints(coverPoints);
    }

    protected createThreeModel() {
        this.threeModel.name = 'threeModel';
        this.threeModel.matrixAutoUpdate = false;
        this.view3d.add(this.threeModel);
        this.service.loadEquipmentThreeModel(this);
    }

    protected createSticker() {
        if (this.sticker) {
            this.removeSticker();
        }
        this.sticker = new SpriteText();
        this.sticker.textHeight = 120;
        this.sticker.color = 'black';
        this.sticker.backgroundColor = 'white';
        this.sticker.fontWeight = 'bold';
        this.sticker.padding = 5;
        this.sticker.borderRadius = 3;
        this.sticker.visible = this.service.getOptions().viewType === KITCHEN_VIEW_SKETCH;
        this.sticker.material.depthTest = false;
        this.sticker.renderOrder = 4;
        this.sticker.text = this.saveData.sticker;
        this.view3d.add(this.sticker);
    }

    protected removeSticker() {
        if (this.sticker) {
            this.view3d.remove(this.sticker);
            this.sticker = undefined;
        }
    }

    public isEquipment(): boolean {
        return true;
    }
}