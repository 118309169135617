import {ThreePenalUnit} from '../ThreePenalUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeTopCorpus} from '../../../details/ThreeCorpus/types/ThreeTopCorpus';
import {Vector3} from 'three';
import {ISavePenalUnitData} from '../../../../../../../common-code/interfaces/saveData/ISavePenalUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreePenalUnitTop extends ThreePenalUnit {
    corpus: ThreeTopCorpus;

    constructor(options: ISavePenalUnitData, service: KitchenService) {
        super(options, service);
        this.canVerticalMove = true;
        this.corpus = this.initCorpus(options.corpus);
    }

    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
        // this.view3d.userData.transparentAngle = -0.4;
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeTopCorpus {
        return new ThreeTopCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    public setPosition(position?: Vector3): void {
        if (position) {
            this.view3d.position.copy(position);
        } else {
            if (this.view3d.position.y !== this.defaultYPosition()) {
                this.view3d.position.y = this.defaultYPosition();
            }
        }
        this.cover.position.copy(this.view3d.position);
        this.cover.userData.oldPosition =
            this.cover.userData.correctPosition = this.cover.position.clone();
        this.updateAllMatrices();
    }

    public defaultYPosition(): number {
        return this.service.getInstallTopUnitHeight() - this.getHeight() / 2;
    }
}