import React, { useState } from 'react';
import { cnCreateForm } from '../FormModule';
import { Textinput } from '@yandex/ui/Textinput/desktop/bundle';
import { Button } from '../../../../components/Button';

interface AddOptionInSelectProps {
    options: Record<string, string>;
    setOptions: (object: Record<string, string>) => void;
    isReverse?: boolean;
}

function AddOptionInSelect({options, setOptions, isReverse} 
    : AddOptionInSelectProps) {
    
    const [modal, setModal] = useState(false);
    const [error, setError] = useState('');
    const [code, setCode] = useState('');
    const [name, setName] = useState('');

    const onFocus = () => {
        setError('');
    }

    const handleSubmit =  () => {
        if(code.length === 0 || name.length === 0) {
            setError("Необходимо заполнить все поля");
            return;
        } else if (code.length < 6) {
            setError("Код должен содержать более 5 символов");
            return;
        } else if (name.length < 6) {
            setError("Наименование должно содержать более 5 символов");
            return;
        } else if (!/^[a-zA-Z0-9_-]+$/.test(code)) {
            setError("Код должен содержать только латинские буквы, цифры, \"_\" и \"-\"");
            return;
        } else if (options.hasOwnProperty(code)){
            setError("Значение уже существует");
            return;
        } else {
           setOptions( isReverse ? { ...options, [code]: name} : {...options, [name]: code});
            openCloseModal();
        }
    }

    const openCloseModal = () => {
        setModal(!modal);
    }

  return (
    <>
    { modal ?
        <div className={cnCreateForm('Modal-Content')}>
                <div>
                    <label htmlFor="code">Значение (латинскими буквами, пробелы заменить на "-")</label>
                    <Textinput
                        className={cnCreateForm('Textinput')}
                        value={code}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
                        onFocus={onFocus}
                        name="code"
                    />
                </div> 
                <div>
                    <label htmlFor="name">Наименование (русскими буквами, чтобы было понятно)</label>
                    <Textinput
                        className={cnCreateForm('Textinput')}
                        value={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                        onFocus={onFocus}
                        name="name"
                    />
                </div>

                { error && <div className='text-danger'> {error}</div>}

       
            <div className={cnCreateForm('Box-Input')}>
                <Button
                    className={cnCreateForm('Button')}
                    type={'button'}
                    text={`Добавить`}
                    leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                    mods={{
                        mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                        hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                    }}
                    callBack={handleSubmit}
                />

                <Button
                    className={cnCreateForm('Button')}
                    type={'button'}
                    text={`Закрыть`}
                    mods={{
                        mods: {bkg: 'white', color: 'lightRed', border: 'red', fill: 'lightRed'},
                        hover: {mods: {bkg: 'lightRed', color: 'white', fill: ''}}
                    }}
                    callBack={openCloseModal}
                />
            </div>
        </div>
    :
    <Button
        className={cnCreateForm('Button')}
        type={'button'}
        text={`Добавить свои значения в список`}
        leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
        mods={{
            mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
            hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
        }}
        callBack={openCloseModal}
    />
    }
    </>
  )
}

export default AddOptionInSelect