import {CommonObject} from '../CommonObject/CommonObject';
import {BufferAttribute, BufferGeometry, Euler, LineSegments, Mesh, Vector3} from 'three';
import {TextGeometryParameters} from 'three/examples/jsm/geometries/TextGeometry';
import {ThreeMathHelper} from '../../helpers/ThreeMathHelper/ThreeMathHelper';
import {TSizeType} from '../../../../common-code/types/TSizeType';
import {SIZE_TYPE_DEFAULT} from '../../../../common-code/constants';
import {ISaveSizeData} from '../../../../common-code/interfaces/saveData/ISaveSizeData';
import {SIZE_DEFAULT_LINE_HEIGHT, SIZE_DEFAULT_LINE_INDENT, SIZE_DEFAULT_TEXT_INDENT} from '../../constants';
import {onAfterRenderHideForBack, onBeforeRenderHideForBack} from '../../helpers/ThreeHelper/ThreeHelper';

export class CommonSize extends CommonObject {
    saveData: ISaveSizeData;
    pointA: Vector3;
    pointB: Vector3;
    length: number;
    direction: boolean;
    decimal: number;
    unit: CommonObject;
    isUnion: boolean;
    lineIndent: number;
    lineHeight: number;
    textIndent: number;
    textInvert: boolean;
    textSize?: number;
    meshPointA: Mesh;
    meshPointB: Mesh;
    textMesh: Mesh;
    positionY: number;

    constructor(options: ISaveSizeData, parent: CommonObject) {
        super(options, parent.service);
        this.unit = parent;
        this.saveData = options;
        this.pointA = new Vector3(options.pointA.x, options.pointA.y, options.pointA.z);
        this.pointB = new Vector3(options.pointB.x, options.pointB.y, options.pointB.z);
        this.meshPointA = new Mesh();
        this.meshPointB = new Mesh();
        this.decimal = options.decimal !== undefined && !isNaN(+options.decimal) ?
            +options.decimal : 0;
        this.length = options.length !== undefined && !isNaN(+options.length) ?
            +options.length :
            +(ThreeMathHelper.getLength(this.pointA, this.pointB)).toFixed(this.decimal);
        this.direction = options.direction !== undefined ? options.direction : true;
        this.lineIndent = options.lineIndent !== undefined && !isNaN(+options.lineIndent) ?
            +options.lineIndent : SIZE_DEFAULT_LINE_INDENT;
        this.lineHeight = options.lineHeight !== undefined && !isNaN(+options.lineHeight) ?
            +options.lineHeight : SIZE_DEFAULT_LINE_HEIGHT;
        this.textIndent = options.textIndent !== undefined && !isNaN(+options.textIndent) ?
            +options.textIndent : SIZE_DEFAULT_TEXT_INDENT;
        this.textInvert = options.textInvert !== undefined ? options.textInvert : false;
        this.textSize = options.textSize;
        this.view3d.userData.notSwitchView = true;
        this.isUnion = false;
        this.textMesh = new Mesh();
        this.positionY = 0;
    }

    public getType(): TSizeType {
        return this.saveData.type || SIZE_TYPE_DEFAULT;
    }

    public initState(isRebuild?: boolean) {
        this.createPoints();
        this.createLines();
        this.createText();
        super.initState(isRebuild);
    }

    public createView(isRebuild?: boolean) {
        this.unit.view3d.add(this.view3d);
        this.view3d.name = 'Size';
        this.view3d.userData.vector = new Vector3();
        this.view3d.matrixAutoUpdate = false;
        this.setPosition(this.initPosition());
        this.positionY = +this.view3d.position.y;
        this.setRotation(this.initRotation());
        this.updateAllMatrices();
        this.setCenterPosition();
    }

    public setCenterPosition() {
        if (!this.view3d.userData.centerPosition ||
            !(this.view3d.userData.centerPosition instanceof Vector3)) {
            this.view3d.userData.centerPosition = new Vector3()
        }
        this.view3d.userData.centerPosition = this.view3d.userData.centerPosition.copy(this.view3d.position).applyMatrix4(this.unit.view3d.matrixWorld);
    }

    public calculateGlobalFrontVector() {
        super.calculateGlobalFrontVector();
        this.setCenterPosition();
    }

    public removeChildren() {
        this.unit.view3d.remove(this.view3d);
        this.globalMainPoints = {};
        super.removeChildren();
    }

    public isVisible(): boolean {
        return !!this.saveData.isVisible;
    }

    public isView3dVisible(): boolean {
        return this.view3d.visible;
    }

    public setVisible(isVisible: boolean) {
        this.saveData.isVisible = isVisible;
        this.view3d.visible = this.saveData.isVisible;
    }

    public setIsUnion(value: boolean) {
        this.isUnion = value;
    }

    public hide() {
        this.setVisible(false);
    }

    public show() {
        this.setVisible(true);
    }

    protected getLengthText() {
        return '' + this.length;
    }

    protected initPosition() {

        return new Vector3(
            (this.pointB.x + this.pointA.x)/2,
            (this.pointB.y + this.pointA.y)/2,
            10
        );
    }

    protected initRotation() {
        let rotation: Euler;
        rotation = new Euler();
        if (this.saveData.rotation) {
            if (this.saveData.rotation.x !== undefined && !Number.isNaN(+this.saveData.rotation.x)) {
                rotation.x = +this.saveData.rotation.x;
            }
            if (this.saveData.rotation.y !== undefined && !Number.isNaN(+this.saveData.rotation.y)) {
                rotation.y = +this.saveData.rotation.y;
            }
            if (this.saveData.rotation.z !== undefined && !Number.isNaN(+this.saveData.rotation.z)) {
                rotation.z = +this.saveData.rotation.z;
            }
        }
        return rotation;
    }

    protected createPoints() {
        this.meshPointA.position.set(-this.length/2, 0, 0);
        this.meshPointB.position.set(this.length/2, 0, 0);
        this.meshPointA.userData.centerPosition = this.meshPointA.position.clone();
        this.meshPointB.userData.centerPosition = this.meshPointB.position.clone();
        this.view3d.add(this.meshPointA);
        this.view3d.add(this.meshPointB);
    }

    protected createLines() {
        let geometry: BufferGeometry;
        let line: LineSegments;
        let positions;
        let indent: number;

        geometry = new BufferGeometry();

        indent = this.direction ? 1 : -1;
        positions = [
            -this.length / 2, 0, 0,
            -this.length / 2, indent * this.lineHeight, 0,
            this.length / 2, 0, 0,
            this.length / 2, indent * this.lineHeight, 0,
            -this.length / 2, indent * this.lineIndent, 0,
            this.length / 2, indent * this.lineIndent, 0
        ];
        geometry.setAttribute(
            'position',
            new BufferAttribute(new Float32Array(positions), 3));
        line = new LineSegments(geometry, this.service.getSizeLineMaterial());
        line.name = 'line';
        line.matrixAutoUpdate = false;
        line.userData.notTransparentForBack = true;
        line.onBeforeRender = onBeforeRenderHideForBack;
        line.onAfterRender = onAfterRenderHideForBack;
        this.view3d.add(line);
    }

    protected createText() {
        let indent: number;

        indent = this.direction ? 1 : -1;
        this.textMesh = this.service.createTextSizeMesh(this.getLengthText(), this.getTextParams());
        if (this.textInvert) {
            this.textMesh.rotation.z = -Math.PI * indent;
        }
        this.textMesh.position.y += this.lineIndent * indent + this.textIndent * indent;

        this.textMesh.userData.notTransparentForBack = true;
        this.textMesh.onBeforeRender = onBeforeRenderHideForBack;
        this.textMesh.onAfterRender = onAfterRenderHideForBack;
        this.view3d.add(this.textMesh);
    }

    protected getTextParams(): TextGeometryParameters | undefined {
        if (this.saveData.textSize) {
            return {size: this.saveData.textSize, font: this.service.getFont()};
        }
        return undefined;
    }

}
