import React, { useState } from "react";
import { IClassNameProps } from "@bem-react/core";
import "./WizardEdit-VerticalMenu.css";
import { cn } from "@bem-react/classname";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import { Svg } from "../../../components/Svg";
import { TWizardUIOptions } from "../../../../types/TWizardUIOptions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import {
  KITCHEN_SIZES_TYPE_ALL,
  KITCHEN_SIZES_TYPE_NONE,
} from "../../../../constants";
import { Popup } from "../../../components/Popup";
import { useTranslation } from "react-i18next";
import { ThreeUnit } from "../../../../3d/objects/threeD/ThreeUnit/ThreeUnit";
import { frontCameraAspect } from "../utils/CameraFrontAspect";
import { leftCameraAspect } from "../utils/CameraLeftAspect";

export const cnWizardVerticalMenu = cn("WizardVerticalMenu");
export interface IWizardVerticalMenuProps extends IClassNameProps {
  service: KitchenService;
}

export const WizardEditVerticalMenu: React.FC<IWizardVerticalMenuProps> = ({
  className,
  service,
}) => {
  const itemModule = useSelector((state: AppState) => state.wizard.editModule.itemModule);
  const kitchenOptions: TWizardUIOptions = useSelector(
    (state: AppState) => state.wizard.options
  );
  const [hoverIcon, setHoverIcon] = useState<string | null>(null);
  const { t } = useTranslation();

  const toggleSizes = () => {
    if (!service.sceneCheck().length) {
      window.alert("Добавьте модуль!");
      return;
    }
    if (kitchenOptions.sizesType === KITCHEN_SIZES_TYPE_NONE) {
      service.setSizeType(KITCHEN_SIZES_TYPE_ALL);
    } else {
      service.setSizeType(KITCHEN_SIZES_TYPE_NONE);
    }
  };

  // Features for displaying aprons and corners
  const toogleApronVisible = () => {
    if (!service.sceneCheck().length) {
      window.alert("Добавьте модуль!");
      return;
    }
    const sceneChildren: ThreeUnit[] = service.sceneCheck();
    if (sceneChildren[sceneChildren.length - 1].aprons?.length) {
      service.setShowPart(sceneChildren[sceneChildren.length - 1].aprons);
    } else {
      window.alert("Добавьте стеновые панели!");
    }
  };

  const toogleCornerVisible = () => {
    if (!service.sceneCheck().length) {
      window.alert("Добавьте модуль!");
      return;
    }
    const sceneChildren: ThreeUnit[] = service.sceneCheck();
    if (sceneChildren[sceneChildren.length - 1].corners?.length) {
      service.setShowPart(sceneChildren[sceneChildren.length - 1].corners);
    } else {
      window.alert("Добавьте уголки!");
    }
  };
  
  const toogleRenderImage = () => {
    if (!service.sceneCheck().length) {
      window.alert("Добавьте модуль!");
      return;
    }
    service.createImagesEDIT(leftCameraAspect(service, itemModule), frontCameraAspect(service, itemModule)).then((data) => {
      if (data) console.log(data);
      service.saveUnitImages(itemModule.uid, data).then((data) => {
        window.alert("saved");
        console.log(data);
      });
    });
  };

  const facadeOpacity = () => {
    if (!service.sceneCheck().length) {
      window.alert("Добавьте модуль!");
      return;
    }
    service.facadeOpacity();
  };

  return (
    <div className={cnWizardVerticalMenu({}, [className])}>
      {/* SIZES */}
      <div
        className={cnWizardVerticalMenu("Action", {
          active: kitchenOptions.sizesType !== KITCHEN_SIZES_TYPE_NONE,
        })}
        onClick={toggleSizes}
        onMouseEnter={() => {
          setHoverIcon("sizes");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg
          icon={"sizes"}
          className={cnWizardVerticalMenu("Icon", {
            active: kitchenOptions.sizesType !== KITCHEN_SIZES_TYPE_NONE,
          })}
        />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"sizes" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Вкл/выкл размеры")}
        </Popup>
      </div>

      {/* APRONS */}
      <div
        className={cnWizardVerticalMenu("Action", {
          active: kitchenOptions.showAprons !== true,
        })}
        onClick={toogleApronVisible}
        onMouseEnter={() => {
          setHoverIcon("aprons");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg
          icon={"hide"}
          className={cnWizardVerticalMenu("Icon", {
            active: kitchenOptions.showAprons !== true,
          })}
        />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"aprons" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Показать стеновые панели")}
        </Popup>
      </div>

      {/* CORNERS */}
      <div
        className={cnWizardVerticalMenu("Action")}
        onClick={toogleCornerVisible}
        onMouseEnter={() => {
          setHoverIcon("corners");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg
          icon={"hide"}
          className={cnWizardVerticalMenu("Icon", {
            active: kitchenOptions.showCorners !== true,
          })}
        />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"corners" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Показать уголки ")}
        </Popup>
      </div>

      {/* FACADE */}
      <div
        className={cnWizardVerticalMenu("Action")}
        onClick={facadeOpacity}
        onMouseEnter={() => {
          setHoverIcon("facade");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg
          icon={"hide"}
          className={cnWizardVerticalMenu("Icon", {
            active: kitchenOptions.showCorners !== true,
          })}
        />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"facade" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Скрыть фасад")}
        </Popup>
      </div>

      {/* RENDER */}
      <div
        className={cnWizardVerticalMenu("Action")}
        onClick={toogleRenderImage}
        onMouseEnter={() => {
          setHoverIcon("render");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg
          icon={"3d-model"}
          className={cnWizardVerticalMenu("Icon", {
            active: kitchenOptions.showCorners !== true,
          })}
        />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"render" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Render")}
        </Popup>
      </div>

      {/* PLINTH */}
      {/* <div
        className={cnWizardVerticalMenu("Action")}
        onClick={togglePlinthsColor}
        onMouseEnter={() => {
          setHoverIcon("color");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg icon={"pic"} className={cnWizardVerticalMenu("Icon")} />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"color" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Цвет цоколя")}
        </Popup>
      </div> */}
    </div>
  );
};
