import {ThreeEquipment} from '../ThreeEquipment/ThreeEquipment';
import {Vector3} from 'three';
import {SIDE_TYPE_BOTTOM} from '../../../../../../common-code/constants';
import {TLevelBoxes} from '../../../../types/TLevelBoxes';
import {ThreeSize} from '../../ThreeSize/ThreeSize';

export class ThreeBottomEquipment extends ThreeEquipment {
    public setPosition(position?: Vector3): void {
        if (position) {
            this.view3d.position.copy(position);
        } else {
            if (this.view3d.position.y !== this.defaultYPosition()) {
                this.view3d.position.y = this.defaultYPosition();
            }
        }
        this.cover.position.copy(this.view3d.position);
        this.cover.userData.oldPosition =
            this.cover.userData.correctPosition = this.cover.position.clone();
        this.updateAllMatrices();
    }

    public defaultYPosition(): number {
        return this.getSideDistance(SIDE_TYPE_BOTTOM);
    }

    protected setSizesPosition(levelBoxes?: TLevelBoxes) {
        let size: ThreeSize;

        if (!levelBoxes) {
            return;
        }
        for (size of this.sizes) {
            size.setPositionFromLevelBox(levelBoxes.bottom.max.y - this.view3d.position.y);
        }
    }
}