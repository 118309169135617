import {KitchenService} from '../KitchenService';
import {CommonObject} from '../../../objects/CommonObject/CommonObject';
import {ThreeTabletop} from '../../../objects/threeD/details/ThreeTabletop/ThreeTabletop';
import {ThreeApron} from '../../../objects/threeD/details/ThreeApron/ThreeApron';
import {ThreeCorner} from '../../../objects/threeD/details/ThreeCorner/ThreeCorner';
import {ThreePlinth} from '../../../objects/threeD/details/ThreePlinth/ThreePlinth';
import {ThreeKUnit} from '../../../objects/threeD/units/ThreeKUnit/ThreeKUnit';
import {IUnions} from '../../../interfaces/IUnions';
import {ThreeApronUnion} from '../../../objects/threeD/details/ThreeApron/ThreeApronUnion';
import {ThreeTabletopUnion} from '../../../objects/threeD/details/ThreeTabletop/ThreeTabletopUnion';
import {ThreeCornerUnion} from '../../../objects/threeD/details/ThreeCorner/ThreeCornerUnion';
import {ThreePlinthUnion} from '../../../objects/threeD/details/ThreePlinth/ThreePlinthUnion';
import {ThreeUnit} from '../../../objects/threeD/ThreeUnit/ThreeUnit';
import {CommonObjectHelper} from '../../../helpers/CommonObjectHelper/CommonObjectHelper';
import {TCanUnionDetail} from '../../../types/TCanUnionDetail';
import {TDetail} from '../../../types/TDetail';
import {ICoverMainPoints} from '../../../interfaces/ICoverMainPoints';
import {ThreeHelper} from '../../../helpers/ThreeHelper/ThreeHelper';
import {TThreeLine} from '../../../types/TThreeLine';
import {TCalculateUnionDetail} from '../../../types/TCalculateUnionDetail';
import {TUnionDetail} from '../../../types/TUnionDetail';
import {ThreeMathHelper} from '../../../helpers/ThreeMathHelper/ThreeMathHelper';
import {Box3, BoxGeometry, BufferGeometry, Euler, Material, Mesh, MeshStandardMaterial} from 'three';
import {
    APRON_PLANK_TYPE_ANGLE,
    APRON_PLANK_TYPE_CONNECT,
    APRON_PLANK_TYPE_END,
    CLASSNAME_EQUIPMENT_BUILTIN_DISHWASHER,
    CLASSNAME_EQUIPMENT_COOKER,
    CLASSNAME_EQUIPMENT_DISHWASHER,
    CLASSNAME_EQUIPMENT_HOB,
    CLASSNAME_EQUIPMENT_SEPARATE_SINK,
    CLASSNAME_EQUIPMENT_WASHER,
    FACADE_OPEN_TYPE_VERTICAL_PIVOT,
    SIDE_TYPE_BACK,
    SIDE_TYPE_BOTTOM,
    SIDE_TYPE_DEFAULT,
    SIDE_TYPE_FRONT,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_RIGHT,
    SIDE_TYPE_TOP,
    TABLETOP_PLANK_TYPE_ANGLE,
    TABLETOP_PLANK_TYPE_CONNECT,
    TABLETOP_PLANK_TYPE_END,
    TABLETOP_PLANK_TYPE_EURO_ZAPIL
} from '../../../../../common-code/constants';
import {TLevelBoxes} from '../../../types/TLevelBoxes';
import {ThreeSizeUnion} from '../../../objects/threeD/ThreeSize/ThreeSizeUnion';
import {ThreeSize} from '../../../objects/threeD/ThreeSize/ThreeSize';
import {IPositionInfo} from '../../../interfaces/IPositionInfo';
import {TDirectionSideType} from '../../../../../common-code/types/TDirectionSideType';
import {ThreeFacade} from '../../../objects/threeD/details/ThreeFacade/ThreeFacade';
import {
    NEIGHBOR_GAP,
    SIZE_DEFAULT_LINE_HEIGHT,
    SIZE_DEFAULT_LINE_INDENT,
    UNIT_SIZE_TEXT_SIZE
} from '../../../constants';
import {MathHelper} from 'common-code';
import {CommonHelper} from 'common-code';
import {TSideType} from '../../../../../common-code/types/TSideType';
import {TPoint3D} from '../../../../../common-code/types/TPoint3D';
import {TAxisType} from '../../../../../common-code/types/TAxisType';
import {ISaveUnitData} from '../../../../../common-code/interfaces/saveData/ISaveUnitData';
import {IModulePriceData} from '../../../../../common-code/interfaces/catalog/IModulePriceData';
import {TWizardUIOptions} from '../../../../types/TWizardUIOptions';
import {ISaveSizeData} from '../../../../../common-code/interfaces/saveData/ISaveSizeData';
import {TPoint2D} from '../../../../../common-code/types/TPoint2D';
import {i18n} from '../../../../i18n';
import {TNeighborSides} from '../../../types/TNeighborSides';
import {ThreeBuiltInEquipment} from '../../../objects/threeD/equipments/ThreeBuiltInEquipment/ThreeBuiltInEquipment';
import {ThreeKUnitDetail} from '../../../objects/threeD/details/ThreeKUnitDetail/ThreeKUnitDetail';
import {IDetailSideNeighbors} from '../../../interfaces/IDetailSideNeighbors';
import {ITabletopPlanks} from '../../../../../common-code/interfaces/plank/ITabletopPlanks';
import {TUnitSideNeighbor} from '../../../types/TUnitSideNeighbor';
import {TTabletopPlankType} from '../../../../../common-code/types/TTabletopPlankType';
import {ITabletopPlank} from '../../../../../common-code/interfaces/plank/ITabletopPlank';
import {ThreeWasherEquipment} from '../../../objects/threeD/equipments/ThreeBottomEquipment/types/ThreeWasherEquipment';
import {ISideNeighbors} from '../../../interfaces/ISideNeighbors';
import {ICalculateServicesData} from '../../../../../common-code/interfaces/project/ICalculateServicesData';
import {IApronPlanks} from '../../../../../common-code/interfaces/plank/IApronPlanks';
import {IProjectServiceData} from '../../../../../common-code/interfaces/project/IProjectServiceData';
import {IProjectPlanks} from '../../../../../common-code/interfaces/plank/IProjectPlanks';
import {IProjectPlankMeshes} from '../../../interfaces/IProjectPlankMeshes';
import {IProjectPlankMaterials} from '../../../interfaces/IProjectPlankMaterials';
import {IGlobalSidePoints} from '../../../interfaces/IGlobalSidePoints';
import {TLine3D} from '../../../../../common-code/types/TLine3D';
import {TApronPlankType} from '../../../../../common-code/types/TApronPlankType';
import {IApronPlank} from '../../../../../common-code/interfaces/plank/IApronPlank';
import { ThreeIntegratedHandleUnion } from '../../../objects/threeD/details/ThreeIntegratedHandle/ThreeIntegratedHandleUnion';
import { ThreeIntegratedHandle } from '../../../objects/threeD/details/ThreeIntegratedHandle/ThreeIntegratedHandle';

export class RebuildManager {
    service: KitchenService;
    errorObjects: CommonObject[];
    unions: IUnions;
    levelBoxes: TLevelBoxes;
    planksData: IProjectPlanks;
    planksMeshes: IProjectPlankMeshes;
    plankMaterials: IProjectPlankMaterials;

    constructor(service: KitchenService) {
        this.service = service;
        this.errorObjects = [];
        this.unions = RebuildManager.initUnions();
        this.planksData = this.initPlanksData();
        this.planksMeshes = this.initPlanksMeshes();
        this.plankMaterials = this.initPlanksMaterials();
        this.levelBoxes = this.calculateLevelBoxes();
    }

    public remove() {
        this.removeUnions();
        this.errorObjects = [];
        this.levelBoxes = {
            bottom: new Box3(),
            top: new Box3()
        }
    }

    public getSpecPlanks(): [] {
        return [];
    }

    public clearUnitDetails() {
        this.clearAprons();
        this.clearCorners();
        this.clearTabletops();
        this.clearPlinths();
    }

    public rebuildScene(): boolean {
        if (!this.beforeRebuildScene()) {
            return false;
        }
        this.clearErrorObjects();
        this.hideOldUnionModules();
        this.rebuildPositionInfo();
        this.updateUnitNeighbors();
        this.rebuildUnitPlinths();
        this.hideAccessories();
        this.rebuildUnions();
        this.rebuildAccessoryPlanks();
        this.rebuildEuroZapil();
        this.updateLevelBoxes();
        this.rebuildSizes();
        this.rebuildUnionSizes();
        this.calculateAutoServices();
        this.service.calculateProjectPrice();
        this.checkOutRoomObjects();
        this.selectErrorObjects();

        return this.afterRebuildScene();
    }

    public getUnions(): Array<ThreePlinthUnion | ThreeCornerUnion | ThreeApronUnion | ThreeTabletopUnion | ThreeIntegratedHandleUnion> {
        let group: keyof IUnions;
        let unit;
        let units: Array<ThreePlinthUnion | ThreeCornerUnion | ThreeApronUnion | ThreeTabletopUnion | ThreeIntegratedHandleUnion> = [];

        for (group in this.unions) {
            if (!this.unions.hasOwnProperty(group)) {
                continue;
            }
            for (unit of this.unions[group]) {
                if (unit instanceof ThreeSizeUnion) {
                    continue;
                }
                units.push(unit);
            }
        }

        return  units;
    }

    public updateViewType() {
        let group: keyof IUnions;
        let index: string;
        let unit: CommonObject;
        let options: TWizardUIOptions;

        options = this.service.getOptions();
        for (group in this.unions) {
            if (!this.unions.hasOwnProperty(group)) {
                continue;
            }
            for (index in this.unions[group]) {
                if (!this.unions[group].hasOwnProperty(index)) {
                    continue;
                }
                unit = this.unions[group][index];
                unit.setViewType(options.viewType);
            }
        }
    }

    public selectErrorObjects() {
        let allUnits: ThreeUnit[];
        let unit: ThreeUnit;
        let tabletop: ThreeTabletop;

        allUnits = this.service.getObjects();
        for (unit of allUnits) {
            if (unit.hasError()) {
                this.service.getEditor().addErrorSelectedMesh(unit.selectCover);
            }
            if (unit.tabletops) {
                for (tabletop of unit.tabletops) {
                    if (tabletop.hasError()) {
                        this.service.getEditor().addErrorSelectedMesh(tabletop.body);
                    }
                }
            }
        }
    }

    public clearErrorObjects() {
        let allUnits: ThreeUnit[];
        let unit: ThreeUnit;
        let tabletop: ThreeTabletop;
        let facade: ThreeFacade;
        let apron: ThreeApron;
        let corner: ThreeCorner;
        let plinth: ThreePlinth;

        this.service.getEditor().clearErrorSelectedMeshes();
        allUnits = this.service.getObjects();
        for (unit of allUnits) {
            unit.clearErrors();
            if (unit.tabletops) {
                for (tabletop of unit.tabletops) {
                    tabletop.clearErrors();
                }
            }
            if (unit.facades) {
                for (facade of unit.facades) {
                    facade.clearErrors();
                }
            }
            if (unit.aprons) {
                for (apron of unit.aprons) {
                    apron.clearErrors();
                }
            }
            if (unit.corners) {
                for (corner of unit.corners) {
                    corner.clearErrors();
                }
            }
            if (unit.plinths) {
                for (plinth of unit.plinths) {
                    plinth.clearErrors();
                }
            }
        }
    }

    private initPlanksData(): IProjectPlanks {
        return {
            apron: {},
            tabletop: {}
        }
    }

    private initPlanksMeshes(): IProjectPlankMeshes {
        return {
            apron: {},
            tabletop: {}
        }
    }

    private initPlanksMaterials(): IProjectPlankMaterials {
        return {};
    }

    public calculateConstructPrice(servicesData: ICalculateServicesData, service: IProjectServiceData): number {
        let minProductPrice = this.service.appConfig.catalog.services.constructMinComplectPrice;
        let fixConstruct = this.service.appConfig.catalog.services.fixConstructPrice;
        let percent = this.service.appConfig.catalog.services.constructPercent;
        let constructPrice: number;
        let coef: number = 1;

        if (servicesData.complectPrice <= 0) {
            return 0;
        }

        if (service.extraParams !== undefined) {
            if (service.extraParams.minProductPrice !== undefined && !isNaN(+service.extraParams.minProductPrice)) {
                minProductPrice = +service.extraParams.minProductPrice;
            }
            if (service.extraParams.fixConstruct !== undefined && !isNaN(+service.extraParams.fixConstruct)) {
                fixConstruct = +service.extraParams.fixConstruct;
            }
            if (service.extraParams.percent !== undefined && !isNaN(+service.extraParams.percent)) {
                percent = +service.extraParams.percent;
            }
        }

        if (servicesData.complectPrice < minProductPrice && servicesData.complectPrice > 0) {
            constructPrice = fixConstruct * coef;
        } else {
            constructPrice = (servicesData.complectPrice > 0) ? Math.ceil(servicesData.complectPrice / 100 * percent / 10) * 10 * coef : 0;
        }

        return constructPrice;
    }

    private clearTabletops() {
        let tabletop: ThreeTabletop;

        for (tabletop of this.unions.tabletops) {
            tabletop.remove();
        }
        this.unions.tabletops = [];
    }

    private clearAprons() {
        let apron: ThreeApron;

        for (apron of this.unions.aprons) {
            apron.remove();
        }
        this.unions.aprons = [];
    }

    private clearCorners() {
        let corner: ThreeCorner;

        for (corner of this.unions.corners) {
            corner.remove();
        }
        this.unions.corners = [];
    }

    private clearPlinths() {
        let plinth: ThreePlinth;

        for (plinth of this.unions.plinths) {
            plinth.remove();
        }
        this.unions.plinths = [];
    }

    private beforeRebuildScene(): boolean {
        let unit: ThreeUnit;
        let endUnits: ThreeUnit[];
        let newData: ISaveUnitData;
        let newSideType: TDirectionSideType | undefined;
        let index: string;
        let modulePrice: IModulePriceData;
        let angleUnits: ThreeUnit[];
        let positionInfo: IPositionInfo;

        endUnits = this.service.getEndUnits();
        angleUnits = this.service.getAngleUnits();
        if (endUnits.length  > 0 || angleUnits.length > 0) {
            this.updateUnitNeighbors();
            for (unit of endUnits) {
                if ((unit.neighbors.left && !unit.neighbors.right && unit.getSideType() !== SIDE_TYPE_RIGHT)
                    || (unit.neighbors.right && !unit.neighbors.left && unit.getSideType() !== SIDE_TYPE_LEFT)) {
                    newData = {...unit.getData(), sideType: unit.neighbors.left ? SIDE_TYPE_RIGHT : SIDE_TYPE_LEFT};
                    if (newData.facades) {
                        for (index in newData.facades) {
                            newData.facades[index].sideType = unit.neighbors.left ? SIDE_TYPE_RIGHT : SIDE_TYPE_LEFT;
                        }
                    }
                    modulePrice = this.service.calculatePrice(this.service.getUnitSaveDataPriceParams(newData));
                    if (modulePrice.errors.length <= 0) {
                        unit.rebuild(newData);
                    } else {
                        console.log('rebuild error PRICE' + newData.sideType, modulePrice);
                    }
                }
            }
            for (unit of angleUnits) {
                if (unit.getSideType() === SIDE_TYPE_DEFAULT) {
                    continue;
                }
                newSideType = undefined;
                positionInfo = unit.setPositionInfo();
                if (positionInfo.inAngle.rightSide && unit.getSideType() !== SIDE_TYPE_RIGHT) {
                    newSideType = SIDE_TYPE_RIGHT;
                }
                if (positionInfo.inAngle.leftSide && unit.getSideType() !== SIDE_TYPE_LEFT) {
                    newSideType = SIDE_TYPE_LEFT;
                }
                if (newSideType) {
                    newData = {...unit.getData(), sideType: newSideType};
                    if (newData.facades) {
                        for (index in newData.facades) {
                            newData.facades[index].sideType = newSideType;
                        }
                    }
                    modulePrice = this.service.calculatePrice(this.service.getUnitSaveDataPriceParams(newData));
                    if (modulePrice.errors.length <= 0) {
                        unit.rebuild(newData);
                        if (unit.trySetPosition(unit.getPosition().clone(), true)) {
                            unit.afterTryMove(true, true);
                        }
                    } else {
                        console.log('rebuild error PRICE' + newData.sideType, modulePrice);
                    }
                }
            }
        }

        return true;
    }

    private afterRebuildScene(): boolean {
        return true;
    }

    private removeUnions() {
        let group: keyof IUnions;
        let union: ThreeApronUnion | ThreeTabletopUnion | ThreeCornerUnion | ThreePlinthUnion | ThreeSizeUnion | ThreeIntegratedHandleUnion;

        for (group in this.unions) {
            for (union of this.unions[group]) {
                union.remove();
            }
        }
    }

    private checkOutRoomObjects() {
        let units: ThreeUnit[];
        let unit: ThreeUnit;

        units = this.service.getObjects();
        for (unit of units) {
            if (!unit.checkMoveInRoom()) {
                unit.setError({
                    id: 'outRoom_'+unit.getId(),
                    group: 'outRoom',
                    message: i18n.t('Модуль находится за пределами комнаты, исправьте его положение')
                });
            }
        }
    }

    private rebuildSizes() {
        let units: ThreeUnit[];
        let unit: ThreeUnit;

        units = this.service.getObjects();
        for (unit of units) {
            unit.rebuildSizes(this.levelBoxes);
        }
        this.service.rebuildRoomSizes(this.levelBoxes);
    }

    private calculateAutoServices() {
        let services: IProjectServiceData[] | undefined;
        let service: IProjectServiceData;
        let servicesData: ICalculateServicesData;
        let algorithms: {[key: string]: string};
        let algorithm: string;
        let index: string;
        let index2: string;
        let count: any;

        services = this.service.getProjectServices();
        if (!services) {
            return;
        }
        // servicesData используется в формуле eval не удалять!
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        servicesData = this.calculateServicesData();
        algorithms = this.getAutoServiceAlgorithms();

        for (index in services) {
            service = services[index];
            service.isAutoEnable = false;
            for (index2 in algorithms) {
                algorithm = algorithms[index2];
                if ('' + index2 === '' + service.vendorCode) {
                    service.isAutoEnable = true;
                    if (service.isAuto === undefined) {
                        service.isAuto = true;
                    }
                    // servicesData используется здесь
                    try {
                        // eslint-disable-next-line no-eval
                        count = eval(algorithm);
                    } catch {
                        count = undefined;
                    }
                    if (typeof count === 'number') {
                        service.autoCount = +count;
                        if (service.isAuto) {
                            service.count = +count;
                            if (this.service.isEnableServices()) {
                                service.sum = service.count * service.price;
                            }
                        }
                    }
                }
            }
        }
        this.service.setProjectServices(services);
    }

    private calculateServicesData(): ICalculateServicesData {
        return  {
            tables: this.service.calculateTablesCount(),
            cupboards: this.service.calculateCupboardsCount(),
            penBoxes: this.service.calculatePenBoxCount(),
            stretchModules: {
                tables: this.service.calculateStretchTablesCount(),
                cupboards: this.service.calculateStretchCupboardsCount(),
                penBoxes: this.service.calculateStretchPenBoxCount(),
            },
            boxes: this.service.calculateBoxes(),
            tabletops: this.service.calculateTabletopsData(),
            aprons: this.service.calculateApronsData(),
            sockets: this.service.calculateSockets(),
            railings: this.service.calculateRailings(),
            corners: this.service.calculateCornersData(),
            plinths: this.service.calculatePlinthsData(),
            facades: this.service.getAllFacades().length,
            openedFacades: this.service.getAllFacades([FACADE_OPEN_TYPE_VERTICAL_PIVOT]).length,
            endFacades: this.service.getAllFacades(
                undefined,
                ["sideTopCorpusPanel", "sideTopGlassCorpusPanel", "sidePenalPanel"]
            ).length,
            handles: this.service.getAllHandles().length,
            barCounters: this.service.getBarCountersCount(),
            extracts: this.service.getExtractsCount(),
            hobs: this.service.getEquipmentsCount([CLASSNAME_EQUIPMENT_HOB]), // кол-во варочных поверхностей
            ovens: this.service.getEquipmentsCount([CLASSNAME_EQUIPMENT_HOB]), // кол-во духовых шкафов
            dishWashers: this.service.getEquipmentsCount([CLASSNAME_EQUIPMENT_DISHWASHER, CLASSNAME_EQUIPMENT_BUILTIN_DISHWASHER]), // кол-во посудомоейчных
            sinks: this.service.getSinks(), // кол-во моек
            cookers: this.service.getEquipmentsCount([CLASSNAME_EQUIPMENT_COOKER]), // кол-во ЭЛ.ПЛИТА
            gaslifts: this.service.getGasliftsCount(), // МЕХАНИЗМ (ПОДЪЕМНЫЙ/ВЫДВИЖНОЙ) разметка + установка (за комплект)
            complectPrice: this.service.getProjectModulesPrice(), // МЕХАНИЗМ (ПОДЪЕМНЫЙ/ВЫДВИЖНОЙ) разметка + установка (за комплект)
            euroZapil: this.service.getEuroZapil()
        };
    }

    private getAutoServiceAlgorithms(): {[key: string]: string} {
        return {
            "099842": "this.calculateConstructPrice(servicesData, service)",
            "228494": "servicesData.penBoxes",
            "228493": "servicesData.tables",
            "228482": "servicesData.cupboards",
            "228496": "servicesData.cupboards",
            "228499": "servicesData.handles",
            "228500": "servicesData.openedFacades",
            "228523": "servicesData.sinks.separate + servicesData.sinks.builtIn",
            "228511": "servicesData.barCounters",
            "228509": "servicesData.barCounters",
            "228517": "servicesData.extracts.separate + servicesData.extracts.builtIn + servicesData.extracts.mounted",
            "228520": "servicesData.dishWashers",
            "228502": "(servicesData.aprons.isAkril === false) ? Math.round(servicesData.aprons.runningMeters) : 0",
            "228503": "(servicesData.aprons.isAkril === true) ? Math.round(servicesData.aprons.runningMeters) : 0",
            "228505": "servicesData.sinks.builtIn + servicesData.hobs",
            "228525": "servicesData.sinks.builtIn",
            "228501": "servicesData.boxes.length",
            "228495": "servicesData.endFacades",
            "249596": "servicesData.euroZapil"
        };
    }

    private rebuildUnionSizes() {
        let newUnionDetails: {[n: number]: TCalculateUnionDetail};
        let newUnionDetail: TCalculateUnionDetail;
        let existUnionDetails: {[n: string]: TCalculateUnionDetail};
        let index: string;
        let index2: string;
        let sizeData: ISaveSizeData;
        let size: ThreeSizeUnion;
        let sizes: ThreeSize[];

        newUnionDetails = this.getNewUnionDetails('sizes', [SIDE_TYPE_BACK]);
        existUnionDetails = this.deleteOldUnionDetails('sizes', newUnionDetails);
        newUnionDetails = RebuildManager.getCreateNewUnionDetails(newUnionDetails, existUnionDetails);
        for (index in newUnionDetails) {
            newUnionDetail = newUnionDetails[index];
            sizes = [];
            for (index2 in newUnionDetail.details) {
                if (newUnionDetail.details[index2] instanceof ThreeSize) {
                    sizes.push(newUnionDetail.details[index2] as ThreeSize);
                }
            }
            if (sizes.length > 1) {
                sizes = this.sortDetailsBySide(sizes, SIDE_TYPE_BACK) as ThreeSize[];
                sizeData = {
                    id: 0,
                    pointA: {x: 0, y: 0, z: 0},
                    pointB: {x: 0, y: 0, z: 0},
                    textSize: UNIT_SIZE_TEXT_SIZE,
                    textInvert: sizes[0].textInvert,
                    lineHeight: SIZE_DEFAULT_LINE_HEIGHT * 2,
                    lineIndent: SIZE_DEFAULT_LINE_INDENT * 2,
                    textIndent: 0,
                    position: newUnionDetail.position,
                    rotation: newUnionDetail.rotation
                };
                size = new ThreeSizeUnion(sizeData, sizes);
                size.initState();
                size.createView();
                this.unions.sizes.push(size);
            }
        }
    }

    private rebuildEuroZapil() {
        let index;
        let euroZapilTabletops;

        euroZapilTabletops = this.getEuroZapilTabletops();
        if (Object.keys(euroZapilTabletops).length > 0) {
            for (index in euroZapilTabletops) {
                euroZapilTabletops[index].tryRebuildBody();
            }
        }
    }

    private getEuroZapilTabletops(): {[key: number]: ThreeTabletop} {
        let tabletops: ThreeTabletop[];
        let tabletop: ThreeTabletop;
        let tabletopsObject: {[key: number]: ThreeTabletop} = {};
        let index: string;
        let tabletopPlankData: ITabletopPlank;
        let euroZapilTabletops: {[key: number]: ThreeTabletop} = {};

        tabletops = this.service.getTabletops()
        for (tabletop of tabletops) {
            tabletopsObject[tabletop.getId()] = tabletop;
        }
        for (index in this.planksData.tabletop) {
            tabletopPlankData = this.planksData.tabletop[index];
            if (tabletopPlankData.type === TABLETOP_PLANK_TYPE_EURO_ZAPIL) {
                if (tabletopsObject[tabletopPlankData.tabletop1]) {
                    euroZapilTabletops[tabletopPlankData.tabletop1] = tabletopsObject[tabletopPlankData.tabletop1];
                } else {
                    debugger;
                }
                if (tabletopsObject[tabletopPlankData.tabletop2]) {
                    euroZapilTabletops[tabletopPlankData.tabletop2] = tabletopsObject[tabletopPlankData.tabletop2];
                } else {
                    debugger;
                }
            }
        }

        return euroZapilTabletops;
    }

    private rebuildAccessoryPlanks() {
        if (this.service.isShowTabletops()) {
            this.calculateTabletopsNeighbors();
        }
        if (this.service.isShowAprons()) {
            this.calculateApronsNeighbors();
        }
        this.rebuildPlanks();
    }

    private calculateTabletopsNeighbors() {
        let tabletops: ThreeTabletop[];
        let bottomObjects: ThreeUnit[];
        let tabletopNeighbors: ThreeUnit[] = [];
        let bottomObject: ThreeUnit;
        let index1: number;
        let index2: number;
        let neighborSides: TNeighborSides[] | undefined;
        let neighborData: TNeighborSides;

        tabletops = this.service.getTabletops();
        bottomObjects = this.service.getBottomObjects();
        for (bottomObject of bottomObjects) {
            if (this.inTabletopNeighbor(bottomObject)) {
                tabletopNeighbors.push(bottomObject);
            }
        }

        RebuildManager.clearNeighbors(tabletops);
        for (index1 = 0; index1 < tabletops.length; index1++) {
            for (index2 = index1+1; index2 < tabletops.length; index2++) {
                neighborSides = CommonObjectHelper.getHorizontalNeighborSides(tabletops[index1], tabletops[index2], NEIGHBOR_GAP, true);
                if (neighborSides) {
                    for (neighborData of neighborSides) {
                        tabletops[index1].neighbors[neighborData.mainSide] =
                            this.calculateDetailNeighborData(
                                tabletops[index2],
                                neighborData,
                                tabletops[index1].neighbors[neighborData.mainSide],
                                'mainLine'
                            );
                        tabletops[index2].neighbors[neighborData.neighborSide] =
                            this.calculateDetailNeighborData(
                                tabletops[index1],
                                neighborData,
                                tabletops[index2].neighbors[neighborData.neighborSide],
                                'neighborLine'
                            );
                    }
                }
            }
            for (bottomObject of tabletopNeighbors) {
                neighborSides = CommonObjectHelper.getHorizontalNeighborSides(tabletops[index1], bottomObject, NEIGHBOR_GAP, true);
                if (neighborSides) {
                    for (neighborData of neighborSides) {
                        tabletops[index1].neighbors[neighborData.mainSide] =
                            this.calculateDetailNeighborData(
                                bottomObject,
                                neighborData,
                                tabletops[index1].neighbors[neighborData.mainSide],
                                'mainLine'
                            );
                    }
                }
            }
        }
    }

    private inTabletopNeighbor(object: ThreeUnit): boolean {
        let equipment: ThreeBuiltInEquipment;
        if (object.isEquipment()) {
            if (object.getClassName() === CLASSNAME_EQUIPMENT_COOKER) {
                return true;
            }
            if (object.getClassName() === CLASSNAME_EQUIPMENT_WASHER &&
                (!object.tabletops || object.tabletops.length <= 0)) {
                return true;
            }

        }
        if (object instanceof ThreeKUnit) {
            if (object.equipments && object.equipments.length > 0) {
                for (equipment of object.equipments) {
                    if (equipment.getClassName() === CLASSNAME_EQUIPMENT_SEPARATE_SINK) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private calculateDetailNeighborData(
        detail: ThreeKUnitDetail | ThreeUnit,
        neighborData: TNeighborSides,
        sideNeighbors: IDetailSideNeighbors | undefined,
        lineName: 'neighborLine' | 'mainLine'
    ): IDetailSideNeighbors | undefined {
        let result: IDetailSideNeighbors | undefined;

        if (!neighborData.interval) {
            return sideNeighbors;
        }
        result = sideNeighbors;
        if (result === undefined) {
            result = {};
        }
        if (!result[CommonHelper.md5(neighborData[lineName])])  {
            result[CommonHelper.md5(neighborData[lineName])] = {
                side: neighborData[lineName],
                neighbors: {
                    [detail.getId()]: {
                        object: detail,
                        interval: neighborData.interval,
                        neighborSide: lineName === 'neighborLine' ? neighborData.mainSide : neighborData.neighborSide
                    }
                }
            }
        } else {
            result[CommonHelper.md5(neighborData[lineName])].neighbors[detail.getId()] = {
                object: detail,
                interval: neighborData.interval,
                neighborSide: lineName === 'neighborLine' ? neighborData.mainSide : neighborData.neighborSide
            };
        }

        return result;
    }

    private setTabletopLink(
        tabletop: ThreeTabletop,
        tabletopPlanks: ITabletopPlanks,
        unitSideNeighbor: TUnitSideNeighbor,
        type: TTabletopPlankType,
        mainSide: TSideType
    ) {
        let plankId: string;
        let tabletopPlank: ITabletopPlank;

        tabletopPlank = {
            interval: unitSideNeighbor.interval,
            type: type,
            rotation: {x: 0, y: 0, z: 0},
            height: tabletop.getHeight() + 2,
            depth: this.getTabletopPlankDepth(type),
            tabletop1: tabletop.getId(),
            tabletop2: unitSideNeighbor.object.getId(),
            side1: mainSide,
            side2: unitSideNeighbor.neighborSide
        };
        plankId = CommonHelper.md5(tabletopPlank);
        if (!tabletopPlanks[plankId]) {
            tabletopPlanks[plankId] = tabletopPlank;
        }
    }

    private getTabletopPlankDepth(type: TTabletopPlankType): number {
        switch (type) {
            case TABLETOP_PLANK_TYPE_ANGLE:
                return 10;
            case TABLETOP_PLANK_TYPE_END:
                return 4;
            case TABLETOP_PLANK_TYPE_CONNECT:
                return 4;
            default:
                return 6;
        }
    }

    private calculateApronsNeighbors() {
        let aprons: ThreeApron[];
        let index1: number;
        let index2: number;
        let neighborSides: TNeighborSides[] | undefined;
        let neighborData: TNeighborSides;

        aprons = this.service.getAprons();
        RebuildManager.clearNeighbors(aprons);
        for (index1 = 0; index1 < aprons.length; index1++) {
            for (index2 = index1 + 1; index2 < aprons.length; index2++) {
                neighborSides = CommonObjectHelper.getContourNeighborSides(
                    aprons[index1],
                    aprons[index2],
                    NEIGHBOR_GAP
                );
                if (neighborSides) {
                    for (neighborData of neighborSides) {
                        aprons[index1].neighbors[neighborData.mainSide] =
                            this.calculateDetailNeighborData(
                                aprons[index2],
                                neighborData,
                                aprons[index1].neighbors[neighborData.mainSide],
                                'mainLine'
                            );
                        aprons[index2].neighbors[neighborData.neighborSide] =
                            this.calculateDetailNeighborData(
                                aprons[index1],
                                neighborData,
                                aprons[index2].neighbors[neighborData.neighborSide],
                                'neighborLine'
                            );
                    }
                }
            }
            if (aprons[index1].canHasEndPlanks()) {
                if (!aprons[index1].neighbors.left) {
                    aprons[index1].neighbors.left = this.calculateApronEndData(aprons[index1], 'left');
                }
                if (!aprons[index1].neighbors.right) {
                    aprons[index1].neighbors.right = this.calculateApronEndData(aprons[index1], 'right');
                }
            }
        }
    }

    private calculateApronEndData(apron: ThreeApron, side: TSideType): IDetailSideNeighbors {
        let sideNeighbors: IDetailSideNeighbors = {};
        let points: IGlobalSidePoints;
        let interval: TLine3D;

        points = apron.getGlobalSidePoints(apron.selectCover);
        for (interval of points[side]) {
            sideNeighbors[CommonHelper.md5(interval)] = {
                side: interval,
                neighbors: {
                    [apron.getId()]: {
                        object: apron,
                        interval: {
                            pointA: interval.pointA,
                            pointB: interval.pointB,
                            length: MathHelper.getLength(interval.pointA, interval.pointB),
                        },
                        neighborSide: side
                    }
                }
            }
        }

        return sideNeighbors;
    }

    private updateLevelBoxes() {
        this.levelBoxes = this.calculateLevelBoxes();
    }

    private calculateLevelBoxes(): TLevelBoxes {
        let topUnits: ThreeUnit[];
        let bottomUnits: ThreeUnit[];
        let unit: ThreeUnit;
        let unitBox: Box3;
        let topBox: Box3;
        let bottomBox: Box3;

        topBox = new Box3();
        bottomBox = new Box3();
        bottomUnits = this.service.getBottomObjects();
        for (unit of bottomUnits) {
            unitBox = unit.getGlobalPoints(unit.selectCover).box;
            bottomBox.setFromPoints([unitBox.min, unitBox.max]);
        }
        topUnits = this.service.getTopObjects();
        for (unit of topUnits) {
            unitBox = unit.getGlobalPoints(unit.selectCover).box;
            topBox.setFromPoints([unitBox.min, unitBox.max]);
        }

        return {
            top: topBox,
            bottom: bottomBox
        }
    }

    private rebuildPlanks() {
        this.tryRebuildTabletopPlanks();
        this.tryRebuildApronPlanks();
        this.tryRebuildCupboardRail();
        this.tryRebuildCaps();
    }

    private tryRebuildTabletopPlanks() {
        let side: TSideType;
        let tabletops: ThreeTabletop[];
        let tabletop: ThreeTabletop;
        let newTabletopPlanks: ITabletopPlanks = {};
        let index: string;
        let index2: string;
        let detailSideNeighbors: IDetailSideNeighbors | undefined;
        let unitSideNeighbor: TUnitSideNeighbor;
        let washerEquipment: ThreeWasherEquipment;
        let equipment: ThreeBuiltInEquipment;
        let plankType: TTabletopPlankType;

        tabletops = this.service.getTabletops();
        for (tabletop of tabletops) {
            for (side in tabletop.neighbors) {
                detailSideNeighbors = tabletop.neighbors[side];
                if (detailSideNeighbors !== undefined) {
                    for (index in detailSideNeighbors) {
                        for (index2 in detailSideNeighbors[index].neighbors) {
                            unitSideNeighbor = detailSideNeighbors[index].neighbors[index2];
                            if (unitSideNeighbor.object.getClassName() === CLASSNAME_EQUIPMENT_COOKER &&
                                [SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT].includes(side)) {
                                this.setTabletopLink(tabletop, newTabletopPlanks, unitSideNeighbor, TABLETOP_PLANK_TYPE_END, side);
                            } else if (unitSideNeighbor.object.getClassName() === CLASSNAME_EQUIPMENT_WASHER &&
                                [SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT].includes(side)) {
                                washerEquipment = unitSideNeighbor.object as ThreeWasherEquipment;
                                if (!washerEquipment.tabletops || washerEquipment.tabletops.length <= 0) {
                                    this.setTabletopLink(tabletop, newTabletopPlanks, unitSideNeighbor, TABLETOP_PLANK_TYPE_END, side);
                                }
                            } else if (unitSideNeighbor.object instanceof ThreeKUnit &&
                                [SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT].includes(side)) {
                                if (unitSideNeighbor.object.equipments && unitSideNeighbor.object.equipments.length > 0) {
                                    for (equipment of unitSideNeighbor.object.equipments) {
                                        if (equipment.getClassName() === CLASSNAME_EQUIPMENT_SEPARATE_SINK) {
                                            this.setTabletopLink(tabletop, newTabletopPlanks, unitSideNeighbor, TABLETOP_PLANK_TYPE_END, side);
                                        }
                                    }
                                }
                            } else if ([SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT].includes(side)) {
                                plankType = [SIDE_TYPE_FRONT].includes(unitSideNeighbor.neighborSide) ?
                                    TABLETOP_PLANK_TYPE_ANGLE : TABLETOP_PLANK_TYPE_CONNECT;
                                this.setTabletopLink(tabletop, newTabletopPlanks, unitSideNeighbor, plankType, side);
                            } else if ([SIDE_TYPE_BACK, SIDE_TYPE_FRONT].includes(side)){
                                plankType = side === SIDE_TYPE_FRONT &&
                                [SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT].includes(unitSideNeighbor.neighborSide) ?
                                    TABLETOP_PLANK_TYPE_ANGLE : TABLETOP_PLANK_TYPE_CONNECT;
                                this.setTabletopLink(tabletop, newTabletopPlanks, unitSideNeighbor, plankType, side);
                            }
                        }

                    }
                }
            }
        }
        if (CommonHelper.md5(newTabletopPlanks) !== CommonHelper.md5(this.planksData.tabletop)) {
            this.rebuildTabletopPlanks(newTabletopPlanks);
        }
    }

    private rebuildTabletopPlanks(tabletopPlanksData: ITabletopPlanks) {
        let index: string;
        for (index in this.planksMeshes.tabletop) {
            this.service.removeFromSceneObject3D(this.planksMeshes.tabletop[index]);
        }
        this.planksData.tabletop = tabletopPlanksData;
        for (index in this.planksData.tabletop) {
            this.createTabletopPlankMesh(this.planksData.tabletop[index]);
        }
    }

    private getTabletopPlankMaterial(tabletopPlankData: ITabletopPlank): Material {
        if (!this.plankMaterials.tabletop) {
            this.plankMaterials.tabletop = {};
        }
        switch (tabletopPlankData.type) {
            case TABLETOP_PLANK_TYPE_ANGLE:
                if (!this.plankMaterials.tabletop[TABLETOP_PLANK_TYPE_ANGLE]) {
                    this.plankMaterials.tabletop[TABLETOP_PLANK_TYPE_ANGLE] =
                        new MeshStandardMaterial({color: '#a8a8a8',envMapIntensity: 5});
                }
                break;
            case TABLETOP_PLANK_TYPE_CONNECT:
                if (!this.plankMaterials.tabletop[TABLETOP_PLANK_TYPE_CONNECT]) {
                    this.plankMaterials.tabletop[TABLETOP_PLANK_TYPE_CONNECT] =
                        new MeshStandardMaterial({color: '#a8a8a8',envMapIntensity: 5});
                }
                break;
            case TABLETOP_PLANK_TYPE_END:
                if (!this.plankMaterials.tabletop[TABLETOP_PLANK_TYPE_END]) {
                    this.plankMaterials.tabletop[TABLETOP_PLANK_TYPE_END] =
                        new MeshStandardMaterial({color: '#a8a8a8',envMapIntensity: 5});
                }
                break;
            default:
                if (!this.plankMaterials.tabletop["all"]) {
                    this.plankMaterials.tabletop["all"] =
                        new MeshStandardMaterial({color: '#a8a8a8',envMapIntensity: 5});
                }
                break;
        }

        return this.plankMaterials.tabletop[tabletopPlankData.type] ?
            this.plankMaterials.tabletop[tabletopPlankData.type] : this.plankMaterials.tabletop["all"];
    }

    private createTabletopPlankMesh(tabletopPlankData: ITabletopPlank): Mesh {
        let mesh: Mesh;

        mesh = new Mesh<BufferGeometry, Material | Material[]>(
            new BoxGeometry(
                MathHelper.getLength(tabletopPlankData.interval.pointA, tabletopPlankData.interval.pointB),
                tabletopPlankData.height,
                tabletopPlankData.depth
            ),
            this.getTabletopPlankMaterial(tabletopPlankData)
        )
        mesh.position.set(
            (tabletopPlankData.interval.pointA.x + tabletopPlankData.interval.pointB.x)/2,
            (tabletopPlankData.interval.pointA.y + tabletopPlankData.interval.pointB.y)/2,
            (tabletopPlankData.interval.pointA.z + tabletopPlankData.interval.pointB.z)/2
        )
        mesh.rotation.set(
            0,
            MathHelper.getNormalAngle({x: tabletopPlankData.interval.pointB.x - tabletopPlankData.interval.pointA.x, y: tabletopPlankData.interval.pointB.z - tabletopPlankData.interval.pointA.z}),
            0
        );
        this.service.addToSceneObject3D(mesh);
        this.planksMeshes.tabletop[CommonHelper.md5(tabletopPlankData)] = mesh;

        return mesh;
    }

    private tryRebuildCaps() {
        // TODO доделать
        // let corners;
        // let corner;
        // let cap;
        // let capOffer;
        // let capsCount;
        // let index;
        // let datum;
        // let collection;
        //
        // this.caps = undefined;
        // corners = this.wizard.getAllAssembledParts('corners');
        // cap = this.wizard.jsonPlankGoods ?
        //     this.wizard.jsonPlankGoods.cap[Object.keys(this.wizard.jsonPlankGoods.cap)[0]] : undefined;
        // if (corners.length > 0 && cap !== undefined) {
        //     capsCount = 0;
        //     collection = this.wizard.selectCorner.collection;
        //     datum = collection !== undefined && cap.collections[collection] !== undefined ?
        //         cap.collections[collection] : cap.collections.default;
        //     for (index in cap.offers) {
        //         if (cap.offers[index].datum.xmlId === datum) {
        //             capOffer = $.extend(true, {}, cap.offers[index]);
        //         }
        //     }
        //     if (capOffer.name.indexOf('цвет:') === -1) {
        //         capOffer.name += ' цвет: ' + capOffer.datum.name;
        //     }
        //     if (capOffer.name.indexOf('код:') === -1) {
        //         capOffer.name += ' код: ' + capOffer.article;
        //     }
        //     for (corner of corners) {
        //         capsCount += Math.ceil(corner.getWidth()/3000);
        //     }
        //     this.caps = {
        //         data: capOffer,
        //         isOk: true,
        //         priceData: {
        //             count: Math.ceil(capsCount/2),
        //             price: capOffer.price,
        //             amount: capOffer.amount
        //         },
        //         typeCode: 'cap',
        //     };
        // }
        //
        // return this.caps;
    }

    private tryRebuildApronPlanks() {
        let side: TSideType;
        let aprons: ThreeApron[];
        let apron: ThreeApron;
        let newApronPlanks: IApronPlanks = {};
        let index: string;
        let index2: string;
        let detailSideNeighbors: IDetailSideNeighbors | undefined;
        let unitSideNeighbor: TUnitSideNeighbor;

        aprons = this.service.getAprons();
        for (apron of aprons) {
            for (side in apron.neighbors) {
                detailSideNeighbors = apron.neighbors[side];
                if (detailSideNeighbors !== undefined) {
                    for (index in detailSideNeighbors) {
                        for (index2 in detailSideNeighbors[index].neighbors) {
                            unitSideNeighbor = detailSideNeighbors[index].neighbors[index2];
                            this.setApronNeighborLink(apron, newApronPlanks, unitSideNeighbor, side);
                        }
                    }
                }
            }
        }
        if (CommonHelper.md5(newApronPlanks) !== CommonHelper.md5(this.planksData.apron)) {
            this.rebuildApronPlanks(newApronPlanks);
        }
    }

    private getApronPlankType(
        mainApron: ThreeApron,
        neighborApron: ThreeApron,
        mainSide: TSideType
    ): TApronPlankType {
        let angle: number;

        angle = ThreeMathHelper.getAngle3D(mainApron.getGlobalFrontVector(), neighborApron.getGlobalFrontVector());
        if (Math.abs(angle) > (Math.PI/2 - 0.05) &&
            Math.abs(angle) < (Math.PI/2 + 0.05)) {
            return APRON_PLANK_TYPE_ANGLE;
        } else if (mainApron.getId() === neighborApron.getId() && [SIDE_TYPE_RIGHT, SIDE_TYPE_LEFT].includes(mainSide)) {
            return APRON_PLANK_TYPE_END;
        } else if (Math.abs(angle) < 0.05 && Math.abs(angle) > -0.05) {
            return APRON_PLANK_TYPE_CONNECT;
        }

        throw new Error('error getApronPlankType');
    }

    private setApronNeighborLink(
        apron: ThreeApron,
        apronPlanks: IApronPlanks,
        unitSideNeighbor: TUnitSideNeighbor,
        mainSide: TSideType
    ) {
        let plankId: string;
        let apronPlank: IApronPlank;
        let type: TApronPlankType;

        apron.getGlobalRotation()
        type = this.getApronPlankType(apron, unitSideNeighbor.object as ThreeApron, mainSide);
        apronPlank = {
            interval: unitSideNeighbor.interval,
            type: type,
            height: this.getApronPlankHeight(type, apron.getHeight()),
            depth: this.getApronPlankDepth(type, apron.getHeight()),
            rotation: {x: 0, y: 0, z: 0}
        }
        plankId = CommonHelper.md5(apronPlank);
        if (!apronPlanks[plankId]) {
            apronPlanks[plankId] = apronPlank;
        }
    }

    private getApronPlankDepth(type: TApronPlankType, apronHeight: number) {
        switch (type) {
            case APRON_PLANK_TYPE_ANGLE:
            case APRON_PLANK_TYPE_END:
            case APRON_PLANK_TYPE_CONNECT:
            default:
                return apronHeight;
        }
    }

    private getApronPlankHeight(type: TApronPlankType, apronHeight: number) {
        switch (type) {
            case APRON_PLANK_TYPE_ANGLE:
            case APRON_PLANK_TYPE_CONNECT:
            case APRON_PLANK_TYPE_END:
            default:
                return apronHeight;
        }
    }

    private rebuildApronPlanks(apronPlanksData: IApronPlanks) {
        let index: string;
        for (index in this.planksMeshes.apron) {
            this.service.removeFromSceneObject3D(this.planksMeshes.apron[index]);
        }
        this.planksData.apron = apronPlanksData;
        for (index in this.planksData.apron) {
            this.createApronPlankMesh(this.planksData.apron[index]);
        }
    }

    private createApronPlankMesh(apronPlankData: IApronPlank): Mesh {
        let mesh: Mesh;

        mesh = new Mesh<BufferGeometry, Material | Material[]>(
            this.getApronPlankGeometry(apronPlankData),
            this.getApronPlankMaterial(apronPlankData)
        )
        mesh.position.set(
            (apronPlankData.interval.pointA.x + apronPlankData.interval.pointB.x)/2,
            (apronPlankData.interval.pointA.y + apronPlankData.interval.pointB.y)/2,
            (apronPlankData.interval.pointA.z + apronPlankData.interval.pointB.z)/2
        )
        mesh.rotation.set(
            MathHelper.getNormalAngle({
                x: apronPlankData.interval.pointB.y - apronPlankData.interval.pointA.y,
                y: apronPlankData.interval.pointB.z - apronPlankData.interval.pointA.z
            }, true) + apronPlankData.rotation.x,
            MathHelper.getNormalAngle({
                x: apronPlankData.interval.pointB.x - apronPlankData.interval.pointA.x,
                y: apronPlankData.interval.pointB.z - apronPlankData.interval.pointA.z
            }, true) + apronPlankData.rotation.y,
            MathHelper.getNormalAngle({
                x: apronPlankData.interval.pointB.z - apronPlankData.interval.pointA.z,
                y: apronPlankData.interval.pointB.y - apronPlankData.interval.pointA.y
            }, true) + apronPlankData.rotation.z
        );

        this.service.addToSceneObject3D(mesh);
        this.planksMeshes.apron[CommonHelper.md5(apronPlankData)] = mesh;

        return mesh;
    }

    private getApronPlankMaterial(apronPlankData: IApronPlank): Material {
        if (!this.plankMaterials.apron) {
            this.plankMaterials.apron = {};
        }
        switch (apronPlankData.type) {
            case APRON_PLANK_TYPE_CONNECT:
                if (!this.plankMaterials.apron[APRON_PLANK_TYPE_CONNECT]) {
                    this.plankMaterials.apron[APRON_PLANK_TYPE_CONNECT] =
                        new MeshStandardMaterial({color: '#9f9f9f', roughness: 0.1,envMapIntensity: 5});
                }
                break;
            case APRON_PLANK_TYPE_END:
                if (!this.plankMaterials.apron[APRON_PLANK_TYPE_END]) {
                    this.plankMaterials.apron[APRON_PLANK_TYPE_END] =
                        new MeshStandardMaterial({color: '#9f9f9f', roughness: 0.1,envMapIntensity: 5});
                }
                break;
            case APRON_PLANK_TYPE_ANGLE:
                if (!this.plankMaterials.apron[APRON_PLANK_TYPE_ANGLE]) {
                    this.plankMaterials.apron[APRON_PLANK_TYPE_ANGLE] =
                        new MeshStandardMaterial({color: '#9f9f9f', roughness: 0.1,envMapIntensity: 5});
                }
                break;
            default:
                if (!this.plankMaterials.apron['all']) {
                    this.plankMaterials.apron['all'] =
                        new MeshStandardMaterial({color: '#9f9f9f', roughness: 0.1,envMapIntensity: 5});
                }
                break;
        }

        return this.plankMaterials.apron[apronPlankData.type] ? this.plankMaterials.apron[apronPlankData.type] :
            this.plankMaterials.apron['all'];
    }

    private getApronPlankGeometry(apronPlankData: IApronPlank): BufferGeometry {
        let geometry: BufferGeometry;

        geometry = new BoxGeometry(
            MathHelper.getLength(apronPlankData.interval.pointA, apronPlankData.interval.pointB),
            apronPlankData.height,
            apronPlankData.depth
        );

        return geometry;
    }

    private tryRebuildCupboardRail() {
        // TODO доделать
        // let topUnits, length, unit, cupboardRail, countRail;
        //
        // this.cupboardRail = undefined;
        // cupboardRail = this.wizard.jsonPlankGoods ?
        //     this.wizard.jsonPlankGoods.cupboardRail[Object.keys(this.wizard.jsonPlankGoods.cupboardRail)[0]] : undefined;
        // topUnits = this.wizard.getUnitsByType('topUnits', []);
        // length = 0;
        // for (unit of topUnits) {
        //     length += unit.getWallWidth();
        // }
        // if (cupboardRail !== undefined && length > 0 &&
        //     this.wizard.getKitCode() !== 'cheap') {
        //     countRail = Math.ceil(length/+cupboardRail.width);
        //     if (cupboardRail.name.indexOf('код:') === -1) {
        //         cupboardRail.name += ' общая длина: ' + length + '; код: ' + cupboardRail.article;
        //     }
        //     this.cupboardRail = {
        //         length: length,
        //         data: cupboardRail,
        //         isOk: true,
        //         priceData: {
        //             count: countRail,
        //             price: cupboardRail.price,
        //             amount: cupboardRail.amount
        //         },
        //         typeCode: 'cupboardRail',
        //     };
        //
        // }
        //
        // return this.cupboardRail;
    }

    private hideOldUnionModules() {
        let tabletop: ThreeTabletopUnion;
        let apron: ThreeApronUnion;
        let corner: ThreeCornerUnion;
        let plinth: ThreePlinthUnion;
        let size: ThreeSizeUnion;

        for (tabletop of this.unions.tabletops) {
            tabletop.hide();
        }
        for (apron of this.unions.aprons) {
            apron.hide();
        }
        for (corner of this.unions.corners) {
            corner.hide();
        }
        for (plinth of this.unions.plinths) {
            plinth.hide();
        }
        for (size of this.unions.sizes) {
            size.hide();
        }
    }

    private updateUnitNeighbors() {
        RebuildManager.setNeighbors(this.service.getBottomObjects());
        RebuildManager.setNeighbors(this.service.getTopObjects());
    }

    private static initUnions(): IUnions {
        return {
            aprons: [],
            corners: [],
            plinths: [],
            tabletops: [],
            sizes: [],
            integratedHandles:[]
        };
    }

    private static clearNeighbors(units: ThreeUnit[] | ThreeKUnitDetail[]) {
        let unit: ThreeUnit | ThreeKUnitDetail;

        for (unit of units) {
            unit.clearNeighbors();
        }
    }

    private static setNeighbors(units: ThreeUnit[]) {
        let index1, index2;
        let neighborSides: TNeighborSides[] | undefined;

        RebuildManager.clearNeighbors(units);
        for (index1 = 0; index1 < units.length; index1++) {
            for (index2 = index1+1; index2 < units.length; index2++) {
                neighborSides = CommonObjectHelper.getHorizontalNeighborSides(units[index1], units[index2], NEIGHBOR_GAP, true);
                if (neighborSides) {
                    this.setNeighborsToUnits(neighborSides, units[index1], units[index2]);
                }
                neighborSides = CommonObjectHelper.getVerticalNeighborSides(units[index1], units[index2]);
                if (neighborSides) {
                    this.setNeighborsToUnits(neighborSides, units[index1], units[index2]);
                }
            }
        }
    }

    private static setNeighborsToUnits(neighborSides: TNeighborSides[], mainLineUnit: ThreeUnit, neighborLineUnit: ThreeUnit) {
        let neighborData: TNeighborSides;

        for (neighborData of neighborSides) {
            mainLineUnit.neighbors[neighborData.mainSide] =
                this.calculateUnitNeighborData(
                    neighborLineUnit,
                    neighborData,
                    mainLineUnit.neighbors[neighborData.mainSide],
                    'mainLine'
                );
            neighborLineUnit.neighbors[neighborData.neighborSide] =
                this.calculateUnitNeighborData(
                    mainLineUnit,
                    neighborData,
                    neighborLineUnit.neighbors[neighborData.neighborSide],
                    'neighborLine'
                );
        }
    }

    private static calculateUnitNeighborData(
        neighborUnit: ThreeUnit,
        neighborData: TNeighborSides,
        sideNeighbors: ISideNeighbors | undefined,
        lineName: 'neighborLine' | 'mainLine'
    ): ISideNeighbors | undefined {
        let resultSideNeighbors: ISideNeighbors | undefined;

        if (!neighborData.interval) {
            return sideNeighbors;
        }
        resultSideNeighbors = sideNeighbors;
        if (resultSideNeighbors === undefined) {
            resultSideNeighbors = {side: neighborData[lineName], neighbors: {}};
        }
        resultSideNeighbors.neighbors[neighborUnit.getId()] = {
            interval: neighborData.interval,
            object: neighborUnit,
            neighborSide: lineName === 'neighborLine' ? neighborData.mainSide : neighborData.neighborSide
        }

        return resultSideNeighbors;
    }

    private rebuildUnitPlinths() {
        let units: ThreeUnit[];
        let unit: ThreeUnit;

        units = this.service.getUnitsWithPlinths();
        for (unit of units) {
            unit.checkRebuildPlinths();
        }
    }

    private hideAccessories() {
        this.hideTabletops();
        this.hideAprons();
        this.hideCorners();
        this.hidePlinths();
    }

    private rebuildUnions() {
        if (this.service.isShowTabletops()) {
            this.rebuildUnionTabletops();
        }
        if (this.service.isShowAprons()) {
            this.rebuildUnionAprons();
        }
        if (this.service.isShowCorners()) {
            this.rebuildUnionCorners();
        }
        if (this.service.isShowPlinths()) {
            this.rebuildUnionPlinths();
        }
        if(this.service.isShowIntegratedHandles()){
            this.rebuildUnionIntegratedHandles();
        }
    }

    private rebuildUnionTabletops() {
        let newUnionDetails: {[n: number]: TCalculateUnionDetail};
        let newUnionDetail: TCalculateUnionDetail;
        let existUnionDetails: {[n: string]: TCalculateUnionDetail};
        let index: string;
        let index2: string;
        let tabletop: ThreeTabletopUnion;
        let tabletops: ThreeTabletop[];

        newUnionDetails = this.getNewUnionDetails('tabletops', [SIDE_TYPE_BACK, SIDE_TYPE_FRONT]);
        existUnionDetails = this.deleteOldUnionDetails('tabletops', newUnionDetails);
        newUnionDetails = RebuildManager.getCreateNewUnionDetails(newUnionDetails, existUnionDetails);
        for (index in newUnionDetails) {
            newUnionDetail = newUnionDetails[index];
            tabletops = [];
            for (index2 in newUnionDetail.details) {
                if (newUnionDetail.details[index2] instanceof ThreeTabletop) {
                    tabletops.push(newUnionDetail.details[index2] as ThreeTabletop);
                }
            }
            tabletops = this.sortDetailsBySide(tabletops, SIDE_TYPE_BACK) as ThreeTabletop[];
            if (tabletops.length > 1) {
                tabletop = new ThreeTabletopUnion({
                    id: 0,
                    material: tabletops[0].materialData.id,
                    isCalculate: tabletops[0].isCalculate()
                }, tabletops);
                tabletop.initState();
                tabletop.createView();
                this.unions.tabletops.push(tabletop);
            }
        }
    }

    private rebuildUnionAprons() {
        let newUnionDetails: {[n: number]: TCalculateUnionDetail};
        let newUnionDetail: TCalculateUnionDetail;
        let existUnionDetails: {[n: string]: TCalculateUnionDetail};
        let index: string;
        let index2: string;
        let apron: ThreeApronUnion;
        let aprons: ThreeApron[];

        newUnionDetails = this.getNewUnionDetails('aprons', [SIDE_TYPE_BOTTOM, SIDE_TYPE_TOP]);
        existUnionDetails = this.deleteOldUnionDetails('aprons', newUnionDetails);
        newUnionDetails = RebuildManager.getCreateNewUnionDetails(newUnionDetails, existUnionDetails);
        for (index in newUnionDetails) {
            newUnionDetail = newUnionDetails[index];
            aprons = [];
            for (index2 in newUnionDetail.details) {
                if (newUnionDetail.details[index2] instanceof ThreeApron) {
                    aprons.push(newUnionDetail.details[index2] as ThreeApron);
                }
            }
            aprons = this.sortDetailsBySide(aprons, SIDE_TYPE_BOTTOM) as ThreeApron[];
            if (aprons.length > 1) {
                apron = new ThreeApronUnion({
                    id: 0,
                    material: aprons[0].materialData.id,
                    isCalculate: aprons[0].isCalculate()
                }, aprons);
                apron.initState();
                apron.createView();
                this.unions.aprons.push(apron);
            }
        }
    }

    private rebuildUnionCorners() {
        let newUnionDetails: {[n: number]: TCalculateUnionDetail};
        let newUnionDetail: TCalculateUnionDetail;
        let existUnionDetails: {[n: string]: TCalculateUnionDetail};
        let index: string;
        let index2: string;
        let corner: ThreeCornerUnion;
        let corners: ThreeCorner[];

        newUnionDetails = this.getNewUnionDetails('corners', [SIDE_TYPE_BOTTOM]);
        existUnionDetails = this.deleteOldUnionDetails('corners', newUnionDetails);
        newUnionDetails = RebuildManager.getCreateNewUnionDetails(newUnionDetails, existUnionDetails);
        for (index in newUnionDetails) {
            newUnionDetail = newUnionDetails[index];
            corners = [];
            for (index2 in newUnionDetail.details) {
                if (newUnionDetail.details[index2] instanceof ThreeCorner) {
                    corners.push(newUnionDetail.details[index2] as ThreeCorner);
                }
            }
            corners = this.sortDetailsBySide(corners, SIDE_TYPE_BOTTOM) as ThreeCorner[];
            if (corners.length > 1) {
                corner = new ThreeCornerUnion({
                    id: 0,
                    contourPath: corners[0].saveData.contourPath,
                    material: corners[0].materialData.id,
                    isCalculate: corners[0].isCalculate()
                }, corners);
                corner.initState();
                corner.createView();
                this.unions.corners.push(corner);
            }
        }
    }

    private rebuildUnionPlinths() {
        let newUnionDetails: {[n: number]: TCalculateUnionDetail};
        let newUnionDetail: TCalculateUnionDetail;
        let existUnionDetails: {[n: string]: TCalculateUnionDetail};
        let index: string;
        let index2: string;
        let plinth: ThreePlinthUnion;
        let plinths: ThreePlinth[];

        newUnionDetails = this.getNewUnionDetails('plinths', [SIDE_TYPE_BOTTOM, SIDE_TYPE_TOP]);
        existUnionDetails = this.deleteOldUnionDetails('plinths', newUnionDetails);
        newUnionDetails = RebuildManager.getCreateNewUnionDetails(newUnionDetails, existUnionDetails);
        for (index in newUnionDetails) {
            newUnionDetail = newUnionDetails[index];
            plinths = [];
            for (index2 in newUnionDetail.details) {
                if (newUnionDetail.details[index2] instanceof ThreePlinth) {
                    plinths.push(newUnionDetail.details[index2] as ThreePlinth);
                }
            }
            plinths = this.sortDetailsBySide(plinths, SIDE_TYPE_BOTTOM) as ThreePlinth[];
            if (plinths.length > 1) {
                plinth = new ThreePlinthUnion({
                    id: 0,
                    material: plinths[0].materialData.id,
                    isCalculate: plinths[0].isCalculate()
                }, plinths);
                plinth.initState();
                plinth.createView();
                this.unions.plinths.push(plinth);
            }
        }
    }

    private rebuildUnionIntegratedHandles() {
        let newUnionDetails: {[n: number]: TCalculateUnionDetail};
        let newUnionDetail: TCalculateUnionDetail;
        let existUnionDetails: {[n: string]: TCalculateUnionDetail};
        let index: string;
        let index2: string;
        let integratedHandle: ThreeIntegratedHandleUnion;
        let integratedHandles: ThreeIntegratedHandle[];

        newUnionDetails = this.getNewUnionDetails('integratedHandles', [SIDE_TYPE_BOTTOM]);
        existUnionDetails = this.deleteOldUnionDetails('integratedHandles', newUnionDetails);
        newUnionDetails = RebuildManager.getCreateNewUnionDetails(newUnionDetails, existUnionDetails);

        for(index in newUnionDetails){
            newUnionDetail = newUnionDetails[index];
            integratedHandles = [];
            for (index2 in newUnionDetail.details) {
                if (newUnionDetail.details[index2] instanceof ThreeIntegratedHandle) {
                    integratedHandles.push(newUnionDetail.details[index2] as ThreeIntegratedHandle);
                }
            }
            integratedHandles = this.sortDetailsBySide(integratedHandles, SIDE_TYPE_BOTTOM) as ThreeIntegratedHandle[];
            if (integratedHandles.length > 1) {
                integratedHandle = new ThreeIntegratedHandleUnion({
                    id: 0,
                    material: integratedHandles[0].materialData.id,
                    isCalculate: integratedHandles[0].isCalculate(),
                    height: integratedHandles[0].saveData.height,
                    width: integratedHandles[0].saveData.width,
                    functionalType: integratedHandles[0].saveData.functionalType
                },integratedHandles)
                integratedHandle.initState();
                integratedHandle.createView();
                this.unions.integratedHandles.push(integratedHandle);

            }
        }  
    }

    private static getCreateNewUnionDetails(
        calculateUnionDetails: {[n: number]: TCalculateUnionDetail},
        existUnionDetails: {[n: string]: TCalculateUnionDetail}
    ) {
        let index: string;
        let newUnionDetails: {[n: number]: TCalculateUnionDetail} = {};

        for (index in calculateUnionDetails) {
            if (existUnionDetails[index]) {
                continue;
            }
            newUnionDetails[index] = calculateUnionDetails[index];
        }

        return newUnionDetails;
    }

    private deleteOldUnionDetails(
        detailName: TCanUnionDetail,
        calculateUnionDetails: {[n: number]: TCalculateUnionDetail}
    ): { [s: string]: TCalculateUnionDetail } {
        let unionDetail: TUnionDetail;
        let  calculateUnionDetail: TCalculateUnionDetail;
        let isDelete: boolean;
        let index: string;
        let index2: string;
        let index3: string;
        let detailIds: { [n: number]: number };
        let sortDetailIds: string[];
        let deletingDetails: { [s: string]: number } = {};
        let existDetails: { [s: string]: TCalculateUnionDetail } = {};

        for (index in this.unions[detailName]) {
            unionDetail = this.unions[detailName][index];
            isDelete = true;
            for (index2 in calculateUnionDetails) {
                calculateUnionDetail = calculateUnionDetails[index2];
                detailIds = {};
                if (Object.keys(calculateUnionDetail.details).length !== unionDetail.getChildren().length) {
                    continue;
                }
                if (MathHelper.isEqualPoints(ThreeMathHelper.toPoint3D(unionDetail.view3d.position), calculateUnionDetail.position) &&
                    MathHelper.isEqualPoints(
                        {x: unionDetail.view3d.rotation.x, y: unionDetail.view3d.rotation.y, z: unionDetail.view3d.rotation.z},
                        calculateUnionDetail.rotation)) {
                    for (index3 in calculateUnionDetail.details) {
                        detailIds[calculateUnionDetail.details[index3].getId()] = calculateUnionDetail.details[index3].getId();
                    }
                    sortDetailIds = Object.keys(detailIds).sort((a, b) => {return +a - +b;});
                    if (CommonHelper.md5(unionDetail.getSortChildIds()) === CommonHelper.md5(sortDetailIds)) {
                        existDetails[index2] = calculateUnionDetail;
                        unionDetail.show();
                        isDelete = false;
                        break;
                    }

                }
            }
            if (isDelete) {
                deletingDetails[index] = unionDetail.getId();
                unionDetail.remove();
            }
        }
        for (index in deletingDetails) {
            for (index2 in this.unions[detailName]) {
                if (this.unions[detailName][index2].getId() === deletingDetails[index]) {
                    this.unions[detailName].splice(+index2, 1);
                    break;
                }
            }
        }

        return existDetails;
    }

    private getNewUnionDetails(detailName: TCanUnionDetail, sides: TSideType[]): {[n: number]: TCalculateUnionDetail} {
        let index;
        let index2;
        let details: TDetail[];
        let groupsByLine: {[s: string]: TDetail[]} ;
        let groupsByNeighbor;
        let newUnionGroup: {[n: number]: TCalculateUnionDetail} = {};
        let newUnionDetails: {[n: string]: TCalculateUnionDetail} = {};

        details = this.getDetailsByName(detailName, true);
        if (details.length <= 0) {
            return newUnionDetails;
        }
        groupsByLine = RebuildManager.groupModulesByLine(details, sides);
        for (index in groupsByLine) {
            if (groupsByLine.hasOwnProperty(index)) {
                groupsByNeighbor = this.groupModulesByNeighbor(groupsByLine[index], sides, detailName);
                newUnionGroup = this.calculateUnionDetails(groupsByNeighbor, sides[0]);
                if (Object.keys(newUnionGroup).length > 0) {
                    for (index2 in newUnionGroup) {
                        newUnionDetails[index + '_' + index2] = newUnionGroup[index2];
                    }
                }
            }
        }

        return newUnionDetails;
    }

    private calculateUnionDetails(
        groupsByNeighbor: {[n: number]: {[n: number]: TDetail}},
        sortSide: TSideType
    ): {[n: number]: TCalculateUnionDetail} {
        let group: {[n: number]: TDetail};
        let index: string;
        let unionDetails: {[n: number]: TCalculateUnionDetail};

        unionDetails = {};
        for (index in groupsByNeighbor) {
            group = groupsByNeighbor[index];
            if (Object.keys(group).length === 1) {
                continue;
            }
            if (!unionDetails[+index]) {
                unionDetails[+index] = {
                    details: group,
                    position: this.calculateUnionDetailPosition(group, sortSide),
                    rotation: this.calculateUnionDetailRotation(group, sortSide)
                };
            }
        }

        return unionDetails;
    }

    private calculateUnionDetailPosition(group: {[n: number]: TDetail}, sortSide: TSideType): TPoint3D {
        let details: TDetail[];
        let position2D: TPoint2D;
        details = Object.keys(group).map(function(index){
            return group[+index];
        });
        details = this.sortDetailsBySide(details, sortSide);
        position2D = CommonObjectHelper.calculateUnionPosition2DByDetails(details);
        return {x: position2D.x, y: details[0].unit.getUnionDetailYPosition(details[0]), z: position2D.y};
    }

    private calculateUnionDetailRotation(group: {[n: number]: TDetail}, sortSide: TSideType): TPoint3D {
        let details: TDetail[];
        let rotation: Euler;
        details = Object.keys(group).map(function(index){
            return group[+index];
        });
        details = this.sortDetailsBySide(details, sortSide);
        rotation = CommonObjectHelper.calculateUnionRotationByDetails(details)
        return {
            x: +rotation.x.toFixed(10),
            y: +rotation.y.toFixed(10),
            z: +rotation.z.toFixed(10)
        };
    }

    private groupModulesByNeighbor(
        details: TDetail[],
        sides: TSideType[],
        detailName: TCanUnionDetail
    ): {[n: number]: {[n: number]: TDetail}} {
        let index;
        let groupModules: {[n: number]: {[n: number]: TDetail}} = {};
        let groupIndex: number;
        let groupLength: number;
        let maxLength: number | undefined;


        if (details.length === 1 && details[0]) {
            groupModules = {1: details};
            return groupModules;
        }
        details = this.sortDetailsBySide(details, sides[0]);
        maxLength = this.service.getMaxDetailLength(detailName, details[0]);
        groupIndex = 1;
        groupLength = details[0].getLength();
        for (index = 0; index < details.length - 1; index++) {
            if (RebuildManager.isSameNeighborDetails(details[index], details[index + 1], sides[0])) {
                continue;
            }
            groupLength += details[index + 1].getLength();
            if (RebuildManager.isNeighborModules(details[index], details[index + 1], sides) &&
                (!maxLength || maxLength >= groupLength)) {
                if (!groupModules[groupIndex]) {
                    groupModules[groupIndex] = {};
                }
                groupModules[groupIndex][details[index].getId()] = details[index];
                groupModules[groupIndex][details[index + 1].getId()] = details[index + 1];
            } else {
                if (!groupModules[groupIndex]) {
                    groupModules[groupIndex] = {};
                }
                groupModules[groupIndex][details[index].getId()] = details[index];
                groupIndex++;
                groupLength = details[index + 1].getLength();
                if (!groupModules[groupIndex]) {
                    groupModules[groupIndex] = {};
                }
                groupModules[groupIndex][details[index + 1].getId()] = details[index + 1];
            }
        }

        return groupModules;
    }

    private static isSameNeighborDetails(detail1: TDetail, detail2: TDetail, side: TSideType): boolean {
        let points1: ICoverMainPoints, points2: ICoverMainPoints;

        points1 = detail1.getGlobalMainPoints();
        points2 = detail2.getGlobalMainPoints();
        return (ThreeMathHelper.isEqualPoints(points1[side].pointA, points2[side].pointA, 1) &&
            ThreeMathHelper.isEqualPoints(points1[side].pointB, points2[side].pointB, 1)) ||
            (ThreeMathHelper.isEqualPoints(points1[side].pointA, points2[side].pointB, 1) &&
                ThreeMathHelper.isEqualPoints(points1[side].pointB, points2[side].pointA, 1));
    }

    private static isNeighborModules(detail1: TDetail, detail2: TDetail, sides: TSideType[]) {
        let points1, points2;
        let primaryLength1, secondaryLength1, primaryLength3, primaryLength4;
        let primaryLength2, secondaryLength2, secondaryLength3, secondaryLength4;

        points1 = detail1.getGlobalMainPoints();
        points2 = detail2.getGlobalMainPoints();
        if ((points1[sides[0]].pointA.y).toFixed(0) !== (points2[sides[0]].pointA.y).toFixed(0)) {
            return false;
        }
        primaryLength1 = MathHelper.getLength2D(
            {x: points1[sides[0]].pointB.x, y: points1[sides[0]].pointB.z},
            {x: points2[sides[0]].pointA.x, y: points2[sides[0]].pointA.z}
        );
        primaryLength2 = MathHelper.getLength2D(
            {x: points1[sides[0]].pointA.x, y: points1[sides[0]].pointA.z},
            {x: points2[sides[0]].pointB.x, y: points2[sides[0]].pointB.z}
        );
        primaryLength3 = MathHelper.getLength2D(
            {x: points1[sides[0]].pointA.x, y: points1[sides[0]].pointA.z},
            {x: points2[sides[0]].pointA.x, y: points2[sides[0]].pointA.z}
        );
        primaryLength4 = MathHelper.getLength2D(
            {x: points1[sides[0]].pointB.x, y: points1[sides[0]].pointB.z},
            {x: points2[sides[0]].pointB.x, y: points2[sides[0]].pointB.z}
        );
        if (sides.hasOwnProperty(1)) {
            secondaryLength1 = MathHelper.getLength2D(
                {x: points1[sides[1]].pointB.x, y: points1[sides[1]].pointB.z},
                {x: points2[sides[1]].pointA.x, y: points2[sides[1]].pointA.z}
            );
            secondaryLength2 = MathHelper.getLength2D(
                {x: points1[sides[1]].pointA.x, y: points1[sides[1]].pointA.z},
                {x: points2[sides[1]].pointB.x, y: points2[sides[1]].pointB.z}
            );
            secondaryLength3 = MathHelper.getLength2D(
                {x: points1[sides[1]].pointA.x, y: points1[sides[1]].pointA.z},
                {x: points2[sides[1]].pointA.x, y: points2[sides[1]].pointA.z}
            );
            secondaryLength4 = MathHelper.getLength2D(
                {x: points1[sides[1]].pointB.x, y: points1[sides[1]].pointB.z},
                {x: points2[sides[1]].pointB.x, y: points2[sides[1]].pointB.z}
            );
        } else {
            secondaryLength1 = 0;
            secondaryLength2 = 0;
            secondaryLength3 = 0;
            secondaryLength4 = 0;
        }
        return (primaryLength1 <= NEIGHBOR_GAP && secondaryLength1 <= NEIGHBOR_GAP) ||
            (primaryLength2 <= NEIGHBOR_GAP && secondaryLength2 <= NEIGHBOR_GAP) ||
            (primaryLength3 <= NEIGHBOR_GAP && secondaryLength3 <= NEIGHBOR_GAP) ||
            (primaryLength4 <= NEIGHBOR_GAP && secondaryLength4 <= NEIGHBOR_GAP);
    }

    private sortDetailsBySide(details: TDetail[], sortSide: TSideType) {
        let axis: TAxisType;

        axis = RebuildManager.getSortAxis(details[0], sortSide);
        details.sort((a, b) => {
            let pointA: number = a.getGlobalMainPoints()[sortSide].pointA[axis] > a.getGlobalMainPoints()[sortSide].pointB[axis] ?
                a.getGlobalMainPoints()[sortSide].pointB[axis] : a.getGlobalMainPoints()[sortSide].pointA[axis];
            let pointB: number = b.getGlobalMainPoints()[sortSide].pointA[axis] > b.getGlobalMainPoints()[sortSide].pointB[axis] ?
                b.getGlobalMainPoints()[sortSide].pointB[axis] : b.getGlobalMainPoints()[sortSide].pointA[axis];

            return pointA - pointB;
        });

        return details;
    }

    private static getSortAxis(detail: TDetail, sortSide: TSideType): TAxisType {
        let axe: TAxisType, points: TThreeLine;

        points = detail.getGlobalMainPoints()[sortSide];
        axe = (
            Math.abs(points.pointB.x - points.pointA.x) <
            Math.abs(points.pointB.z - points.pointA.z)
        )? 'z' : 'x';

        return axe;
    }

    private static groupModulesByLine(details: TDetail[], sides: TSideType[]): {[s: string]: TDetail[]}  {
        let groupsModule: {[s: string]: TDetail[]} = {};
        let primaryPoints;
        let secondaryPoints;
        let primaryHashCode;
        let secondaryHashCode;
        let globalPoints;
        let alreadyGlobalPoints: ICoverMainPoints[] = [];
        let detail: TDetail;

        for (detail of details) {
            globalPoints = detail.getGlobalMainPoints();
            if (RebuildManager.isSameGlobalPoints(globalPoints, detail, sides)) {
                continue;
            }
            if (RebuildManager.isExistGlobalPoints(globalPoints, alreadyGlobalPoints)) {
                continue;
            }
            alreadyGlobalPoints.push(globalPoints);
            primaryPoints = globalPoints[sides[0]];
            secondaryPoints = sides.hasOwnProperty(1) ? globalPoints[sides[1]] : undefined;
            primaryHashCode = primaryPoints.pointA.y.toFixed(0) + '_' + ThreeHelper.getLineHashCode(
                primaryPoints.pointA,
                primaryPoints.pointB
            ) + '_' + detail.getMaterialId() +'_' + detail.isCalculate();
            if (secondaryPoints) {
                secondaryHashCode = '_' + secondaryPoints.pointA.y.toFixed(0) + '_' + ThreeHelper.getLineHashCode(
                    secondaryPoints.pointA,
                    secondaryPoints.pointB
                ) + '_' + detail.getMaterialId() +'_' + detail.isCalculate();
            } else {
                secondaryHashCode = '';
            }
            if (!groupsModule[primaryHashCode + secondaryHashCode]) {
                groupsModule[primaryHashCode + secondaryHashCode] = [];
            }
            groupsModule[primaryHashCode + secondaryHashCode].push(detail);
        }

        return groupsModule;
    }

    private static isSameGlobalPoints(globalPoints: ICoverMainPoints, detail: TDetail, sides: TSideType[]): boolean {
        let primaryPoints;

        if (detail instanceof ThreeSize) {
            primaryPoints = globalPoints[sides[0]];

            return MathHelper.isEqualPoints2D(
                {x: primaryPoints.pointA.x, y: primaryPoints.pointA.z},
                {x: primaryPoints.pointB.x, y: primaryPoints.pointB.z},
                0.0001
            );
        }

        return false;
    }

    private static isExistGlobalPoints(globalPoints: ICoverMainPoints, alreadyGlobalPoints: ICoverMainPoints[]) {
        let alreadyGlobalPoint: ICoverMainPoints;
        let side: keyof ICoverMainPoints;
        let isExist;

        for (alreadyGlobalPoint of alreadyGlobalPoints) {
            isExist = true;
            for (side in alreadyGlobalPoint) {
                if (!alreadyGlobalPoint.hasOwnProperty(side)) {
                    continue;
                }
                isExist = isExist && globalPoints[side] &&
                    MathHelper.isNearPoints(globalPoints[side].pointA, alreadyGlobalPoint[side].pointA, 3) &&
                    MathHelper.isNearPoints(globalPoints[side].pointB, alreadyGlobalPoint[side].pointB, 3);
            }
            if (isExist && Object.keys(alreadyGlobalPoint).length > 0) {
                return true;
            }
        }
        return false;
    }

    private getDetailsByName(detailName: TCanUnionDetail, canUnion?: boolean): TDetail[] {
        let unit: ThreeUnit;
        let details: Array<ThreeTabletop | ThreeApron | ThreeCorner | ThreePlinth | ThreeSize | ThreeIntegratedHandle | undefined> = [];
        let detail: ThreeTabletop | ThreeApron | ThreeCorner | ThreePlinth | ThreeSize | ThreeIntegratedHandle | undefined;
        let resultDetails: TDetail[] = [];
        let units: ThreeUnit[];

        units = this.service.getObjects();
        for (unit of units) {
            if (unit.hasOwnProperty(detailName) && unit[detailName] !== undefined) {
                details = details.concat(unit[detailName]);
            }
        }
        for (detail of details) {
            if (detail && detail.isView3dVisible()) {
                if (canUnion !== undefined) {
                    if (detail.isCanUnion() === canUnion) {
                        resultDetails.push(detail);
                    }
                } else {
                    resultDetails.push(detail);
                }
            }
        }

        return resultDetails;
    }

    private hideTabletops() {
        let units: ThreeUnit[];
        let unit: ThreeUnit;

        units = this.service.getObjects();
        for (unit of units) {
            unit.hideTabletops();
        }
    }

    private hideAprons() {
        let units: ThreeUnit[];
        let unit: ThreeUnit;

        units = this.service.getObjects();
        for (unit of units) {
            unit.hideAprons();
        }
    }

    private hideCorners() {
        let units: ThreeUnit[];
        let unit: ThreeUnit;

        units = this.service.getObjects();
        for (unit of units) {
            unit.hideCorners();
        }
    }

    private hidePlinths() {
        let units: ThreeUnit[];
        let unit: ThreeUnit;

        units = this.service.getObjects();
        for (unit of units) {
            if (unit instanceof ThreeKUnit) {
                unit.hidePlinths();
            }
        }
    }

    private rebuildPositionInfo() {
        let units: ThreeUnit[];
        let unit: ThreeUnit;

        units = this.service.getObjects();
        for (unit of units) {
            unit.setPositionInfo();
        }
    }

}