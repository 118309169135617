import {IClassNameProps} from '@bem-react/core';
import React from 'react';
import {cnCollectionDetail} from '../CollectionDetail';
import {ICollectionDto} from '../../../../../common-code/interfaces/ICollectionDto';
import {Button, cnButton} from '../../../components/Button';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/AppStore';

export interface ICollectionDetailButtonsProps extends IClassNameProps {
    collection: ICollectionDto;
}
export const CollectionDetailButtons: React.FC<ICollectionDetailButtonsProps> = ({collection}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    

    const navigateToWizard = (collection: ICollectionDto) => {
        const searchParams = new URLSearchParams();
        if (!collection.collectionId || collection.collectionId === '') {
            return;
        }
        if (collection.collectionId) {
            searchParams.append("collection", collection.collectionId);
        }
        if (collection.b_facade && collection.b_facade.length) {
            searchParams.append("b_facade", collection.b_facade);
        }
        if (collection.t_facade && collection.t_facade.length) {
            searchParams.append("t_facade", collection.t_facade);
        }
        if (collection.corpusMaterial && collection.corpusMaterial.length) {
            searchParams.append("corpus", collection.corpusMaterial);
        }

        navigate('/wizard/?'+searchParams.toString());
    }

    const navigateToWizardEdit = (collection: ICollectionDto) => {
        const searchParams = new URLSearchParams();
        if (!collection.collectionId || collection.collectionId === '') {
            return;
        }
        if (collection.collectionId) {
            searchParams.append("collection", collection.collectionId);
        }
        if (collection.b_facade) {
            searchParams.append("b_facade", collection.b_facade);
        }
        if (collection.t_facade) {
            searchParams.append("t_facade", collection.t_facade);
        }
        if (collection.corpusMaterial) {
            searchParams.append("corpus", collection.corpusMaterial);
        }
        if (collection.xmlId) {
            searchParams.append("xmlId", collection.xmlId);
        }

        navigate('/wizardEdit/?'+searchParams.toString());
    }

    const user = useSelector((state: AppState) => state.user);

    return (
        <div className={cnCollectionDetail('Buttons')}>
            {collection['3d'] ?
            <Button
                className={cnCollectionDetail(cnButton())}
                type={'button'}
                leftIcon={{path: 'create', width: 20, height: 20, marginRight: 8}}
                text={t('Создать проект') || undefined}
                mods={{mods: {color: 'white', bkg: 'lightGreen', border: 'green', fill: 'white'}}}
                callBack={() => {
                    navigateToWizard(collection);
                }}
            /> : null}

            {collection['3d'] && user?.roles?.includes("ADMIN") ?
            <Button
                className={cnCollectionDetail(cnButton())}
                type={'button'}
                leftIcon={{path: 'create', width: 20, height: 20, marginRight: 8}}
                text={t('Редактировать коллекцию') || undefined}
                mods={{mods: {color: 'white', bkg: 'lightGreen', border: 'green', fill: 'white'}}}
                callBack={() => {
                    navigateToWizardEdit(collection);
                }}
            /> : null}

            {collection.autofit ?
                <Button
                    className={cnCollectionDetail(cnButton())}
                    type={'button'}
                    leftIcon={{path: 'magic', width: 20, height: 20, marginRight: 8}}
                    text={t('Авторасчет') || undefined}
                    mods={{mods: {color: 'white', bkg: 'lightOrange', border: 'orange', fill: 'white'}}}
                    callBack={() => {
                        // TODO!
                        console.warn('Выполнить переадресацию на страницу autofit!');
                    }}
                /> : null
            }
        </div>
    );
}