import {
    Box3,
    BufferGeometry, ColorRepresentation,
    DoubleSide,
    EdgesGeometry,
    Euler,
    Group,
    Line,
    LineBasicMaterial,
    LineSegments,
    Mesh,
    MeshBasicMaterial, MeshPhongMaterial, Object3D, SphereGeometry,
    Vector3
} from 'three';
import {TInterval} from '../../types/TInterval';
import {i18n} from '../../../i18n';
import {
    ACTION_DELETE,
    KITCHEN_VIEW_SKETCH, KITCHEN_VIEW_VISUAL, SETTING_GROUP_GENERAL,
} from '../../../constants';
import {ThreeMathHelper} from '../../helpers/ThreeMathHelper/ThreeMathHelper';
import {ICoverPoints} from '../../interfaces/ICoverPoints';
import {ICoverMainPoints} from '../../interfaces/ICoverMainPoints';
import {TError} from '../../../../common-code/types/TError';
import {BufferAttribute} from 'three/src/core/BufferAttribute';
import {InterleavedBufferAttribute} from 'three/src/core/InterleavedBufferAttribute';
import {Sphere} from 'three/src/math/Sphere';
import {
    onAfterRenderTransparentForBack,
    onBeforeRenderTransparentForBack,
} from '../../helpers/ThreeHelper/ThreeHelper';
import {TDetail} from '../../types/TDetail';
import {IThreeService} from '../../interfaces/IThreeService';
import {ISaveData} from '../../../../common-code/interfaces/saveData/ISaveData';
import {TKitchenView} from '../../../types/TKitchenView';
import {CommonHelper} from 'common-code';
import {GLBufferAttribute} from 'three/src/core/GLBufferAttribute';
import {ISettingGroup} from '../../../interfaces/settingData/ISettingGroup';
import {COVER_CORRECTION_SIZE, SELECT_COLOR_ERROR} from '../../constants';
import {IContextIcon} from '../../../interfaces/IContextIcon';
import {TContextActionData} from '../../../types/TContextActionData';
import {IGlobalSidePoints} from '../../interfaces/IGlobalSidePoints';
import {TWallOffsetPoints} from '../../types/TWallOffsetPoints';
import {radToDeg} from 'three/src/math/MathUtils';
import {ISettingGroupGeneral} from '../../../interfaces/settingData/ISettingGroupGeneral';

export class CommonObject {
    id: number;
    view3d: Group;
    service: IThreeService;
    globalFrontVector?: Vector3;
    frontPoints: TInterval;
    frontGlobalPoints: TInterval;
    coverPoints: Vector3[];
    canMove: boolean;
    canVerticalMove: boolean;
    saveData: ISaveData;
    /**
     * Готов к использованию
     */
    ready: boolean;
    hasCover: boolean;
    cover: Mesh;
    selectCover: Mesh;
    errors: TError[];
    viewType: TKitchenView;

    /**
     * Может выделяться
     */
    canSelect: boolean;
    transparentForBack: boolean;
    globalSphere: Sphere;
    defaultMainPoint: Vector3;
    globalPosition: Vector3;
    localCoverInfo: {[s: string]: ICoverPoints};
    globalCoverInfo: {[s: string]: ICoverPoints};
    wallOffsetPoints: {[s: string]: TWallOffsetPoints};
    globalMainPoints: {[s: string]: ICoverMainPoints};
    globalSidePoints: {[s: string]: IGlobalSidePoints};


    constructor(options: ISaveData, service: IThreeService) {
        this.view3d = new Group();
        this.service = service;
        this.id = this.service.generateUId(options.id);
        this.frontPoints = {
            pointA: new Vector3(0, 0, 0),
            pointB: new Vector3(0, 0, 1),
        };
        this.frontGlobalPoints = {
            pointA: new Vector3(0, 0, 0),
            pointB: new Vector3(0, 0, 1),
        };
        this.coverPoints = [];
        this.canMove = true;
        this.canVerticalMove = false;
        this.saveData = this.initSaveData(options);
        this.ready = false;
        this.hasCover = true;
        this.canSelect = true;
        this.transparentForBack = false;
        this.cover = new Mesh(undefined, this.createCoverMaterial());
        this.selectCover = new Mesh(undefined, this.createCoverMaterial());
        this.errors = [];
        this.viewType = this.initViewType();
        this.globalSphere = new Sphere();
        this.defaultMainPoint = new Vector3();
        this.globalPosition = new Vector3();
        this.localCoverInfo = {};
        this.globalCoverInfo = {};
        this.wallOffsetPoints = {};
        this.globalMainPoints = {};
        this.globalSidePoints = {};
    }

    public getData(): ISaveData {
        let data: ISaveData = CommonHelper.deepCopy(this.saveData);
        data.selfVerticalRotation = this.getSelfVerticalRotation();
        data.position = {x: this.view3d.position.x, y: this.view3d.position.y, z: this.view3d.position.z};
        data.rotation = {x: this.view3d.rotation.x, y: (this.view3d.rotation.y - this.getSelfVerticalRotation()), z: this.view3d.rotation.z};

        return data;
    }

    public getSelfVerticalRotation(): number {
        return this.saveData.selfVerticalRotation || 0;
    }

    protected initSaveData(options: ISaveData): ISaveData {
        options.id = this.id;
        return options;
    }

    protected initViewType(): TKitchenView {
        return this.service.getOptions().viewType;
    }

    public isReady() {
        return this.ready;
    }
    public initState(isRebuild?: boolean) {
        this.setIsDimensions();
        this.setIsStick();
        this.setIsLevelStick();
        this.setIsWallStick();
        this.setClassToObject();
        this.calculateGlobalFrontVector();
    }

    public createView(isRebuild?: boolean) {
        this.createCover(isRebuild);
        this.createSelectCover(isRebuild);
        if (isRebuild !== true) {
            this.service.addToScene(this);
        }
        this.view3d.userData.vector = new Vector3();
        this.view3d.matrixAutoUpdate = false;
        this.view3d.name = "VIEW_3D"
        this.setPosition(this.initDefaultPosition());
        this.setPosition(this.initPosition());
        this.setRotation(this.initRotation());
        this.setCenterPosition();
        this.updateAllMatrices();
        if (this.transparentForBack) {
            this.setTransparentForBack();
        }
        this.updateViewType();
    }

    public createViewEDIT(isRebuild?: boolean) {   
        this.createCover(isRebuild);
        this.createSelectCover(isRebuild);
        if (isRebuild !== true) {
            this.service.addToSceneEDIT(this);
        }
        this.view3d.userData.vector = new Vector3();
        this.view3d.matrixAutoUpdate = false;
        this.view3d.name = "VIEW_3D"
        this.setPosition(this.initDefaultPosition());
    }

    public setPosition(position?: Vector3): void {
        if (position) {
            this.view3d.position.copy(position);
        }
        this.afterSetPosition();
    }

    public setEquipmentPosition(position: Vector3, equipment:any): void {
        if (position) {
            equipment.position.copy(position);
        }
        this.afterSetPosition();
    }

    public setBuildInEquipmentPositionLocal(positionX: number, equipment:any): void {
        if (positionX) {
            equipment.view3d.position.set(positionX,equipment.view3d.position.y,equipment.view3d.position.z);
        }
        this.afterSetPosition();
    }

    public setRotation(rotation?: Euler): void {
        if (rotation) {
            this.view3d.rotation.copy(rotation);
        }
        this.cover.rotation.copy(this.view3d.rotation);
        this.updateAllMatrices();
    }

    public getYRotation(): number {
        return +this.view3d.rotation.y;
    }

    public isEquipment(): boolean {
        return false;
    }

    public getCoverPoints(cover?: Mesh, gap?: number): ICoverPoints {
        let box: Box3;
        let coverPoints: ICoverPoints;
        let scalar: number;
        let coverVertices: Vector3[] = [];
        let positionAttribute: BufferAttribute | InterleavedBufferAttribute | GLBufferAttribute;
        let vertexIndex: number;
        let coverId: string;

        if (!cover) {
            cover = this.cover;
        }
        scalar =  (gap !== undefined && !isNaN(+gap)) ? +gap : 0;
        coverId = cover.uuid + scalar;
        if (this.localCoverInfo[coverId]) {
            return this.localCoverInfo[coverId];
        }
        positionAttribute = cover.geometry.getAttribute( 'position' );
        if ('isGLBufferAttribute' in positionAttribute) {
            throw new Error('positionAttribute isGLBufferAttribute');
        }
        for (vertexIndex = 0; vertexIndex < positionAttribute.count; vertexIndex ++ ) {
            coverVertices.push(new Vector3().fromBufferAttribute( positionAttribute, vertexIndex ))
        }
        box = new Box3().setFromPoints(coverVertices);
        box.min.addScalar(scalar);
        box.max.addScalar(-scalar);
        coverPoints = {
            bottom: {
                A: new Vector3(box.min.x, box.min.y, box.min.z),
                B: new Vector3(box.max.x, box.min.y, box.min.z),
                C: new Vector3(box.min.x, box.min.y, box.max.z),
                D: new Vector3(box.max.x, box.min.y, box.max.z),
            },
            top: {
                A: new Vector3(box.min.x, box.max.y, box.min.z),
                B: new Vector3(box.max.x, box.max.y, box.min.z),
                C: new Vector3(box.min.x, box.max.y, box.max.z),
                D: new Vector3(box.max.x, box.max.y, box.max.z),
            },
            box: box,
            polygon: [],
            position: {x: 0, y: 0, z: 0}
        };
        coverPoints.polygon = [
            {x: coverPoints.bottom.A.x, y: coverPoints.bottom.A.z},
            {x: coverPoints.bottom.B.x, y: coverPoints.bottom.B.z},
            {x: coverPoints.bottom.D.x, y: coverPoints.bottom.D.z},
            {x: coverPoints.bottom.C.x, y: coverPoints.bottom.C.z},
        ];
        if (Object.keys(this.localCoverInfo).length > 30) {
            delete this.localCoverInfo[Object.keys(this.localCoverInfo)[0]];
        }
        this.localCoverInfo[coverId] = coverPoints;

        return coverPoints;
    }

    public getGlobalPoints(cover: Mesh = this.cover, gap?: number): ICoverPoints {
        let coverPoints: ICoverPoints;
        let globalCoverPoints: ICoverPoints;
        let scalar: number;
        let coverId: string;
        let position: Vector3;

        scalar =  (gap !== undefined && !isNaN(+gap)) ? +gap : 0;
        coverId = CommonHelper.md5({
            id: cover.uuid,
            gap: scalar,
            position: cover.name === 'selectCover'? this.getGlobalPosition().toArray() : cover.position.toArray(),
            rotation: cover.name === 'selectCover'? this.getGlobalRotation().toArray() : cover.rotation.toArray(),
        });
        if (this.globalCoverInfo[coverId]) {
            return this.globalCoverInfo[coverId];
        }
        cover.updateMatrixWorld();
        coverPoints = this.getCoverPoints(cover, scalar);
        position = new Vector3().applyMatrix4(cover.matrixWorld);
        globalCoverPoints = {
            bottom: {
                A: coverPoints.bottom.A.clone().applyMatrix4(cover.matrixWorld),
                B: coverPoints.bottom.B.clone().applyMatrix4(cover.matrixWorld),
                C: coverPoints.bottom.C.clone().applyMatrix4(cover.matrixWorld),
                D: coverPoints.bottom.D.clone().applyMatrix4(cover.matrixWorld),
            },
            top: {
                A: coverPoints.top.A.clone().applyMatrix4(cover.matrixWorld),
                B: coverPoints.top.B.clone().applyMatrix4(cover.matrixWorld),
                C: coverPoints.top.C.clone().applyMatrix4(cover.matrixWorld),
                D: coverPoints.top.D.clone().applyMatrix4(cover.matrixWorld),
            },
            box: new Box3(),
            polygon: [],
            position: {x: position.x, y: position.y, z: position.z}
        };

        globalCoverPoints.box = globalCoverPoints.box.setFromPoints([
            globalCoverPoints.bottom.A, globalCoverPoints.bottom.B,
            globalCoverPoints.bottom.C, globalCoverPoints.bottom.D,
            globalCoverPoints.top.A, globalCoverPoints.top.B,
            globalCoverPoints.top.C, globalCoverPoints.top.D
        ]);
        globalCoverPoints.polygon = [
            {x: globalCoverPoints.bottom.A.x, y: globalCoverPoints.bottom.A.z},
            {x: globalCoverPoints.bottom.B.x, y: globalCoverPoints.bottom.B.z},
            {x: globalCoverPoints.bottom.D.x, y: globalCoverPoints.bottom.D.z},
            {x: globalCoverPoints.bottom.C.x, y: globalCoverPoints.bottom.C.z},
        ];
        if (Object.keys(this.globalCoverInfo).length > 30) {
            delete this.globalCoverInfo[Object.keys(this.globalCoverInfo)[0]];
        }
        this.globalCoverInfo[coverId] = globalCoverPoints;

        return globalCoverPoints;
    }

    public getGlobalSphere(): Sphere {
        if (!this.cover.geometry.boundingSphere) {
            this.cover.geometry.computeBoundingSphere();
        }
        if (!this.cover.geometry.boundingSphere) {
            throw new Error('error-CommonObject-getGlobalSphere');
        }
        this.globalSphere.set(this.view3d.position, this.cover.geometry.boundingSphere.radius)

        return this.globalSphere;
    }

    public getGlobalSidePoints(cover: Mesh = this.cover): IGlobalSidePoints {
        let mainPoints: ICoverMainPoints;
        mainPoints = this.getGlobalMainPoints(cover);

        return {
            bottom: [{pointA: ThreeMathHelper.toPoint3D(mainPoints.bottom.pointA), pointB: ThreeMathHelper.toPoint3D(mainPoints.bottom.pointB)}],
            top: [{pointA: ThreeMathHelper.toPoint3D(mainPoints.top.pointA), pointB: ThreeMathHelper.toPoint3D(mainPoints.top.pointB)}],
            back: [{pointA: ThreeMathHelper.toPoint3D(mainPoints.back.pointA), pointB: ThreeMathHelper.toPoint3D(mainPoints.back.pointB)}],
            front: [{pointA: ThreeMathHelper.toPoint3D(mainPoints.front.pointA), pointB: ThreeMathHelper.toPoint3D(mainPoints.front.pointB)}],
            left: [{pointA: ThreeMathHelper.toPoint3D(mainPoints.left.pointA), pointB: ThreeMathHelper.toPoint3D(mainPoints.left.pointB)}],
            right: [{pointA: ThreeMathHelper.toPoint3D(mainPoints.right.pointA), pointB: ThreeMathHelper.toPoint3D(mainPoints.right.pointB)}]
        }
    }
    public getGlobalMainPoints(cover: Mesh = this.cover): ICoverMainPoints {
        let coverPoints: ICoverPoints;
        let mainPoints: ICoverMainPoints;
        let coverId: string;

        coverId = CommonHelper.md5({
            id: cover.uuid,
            position: cover.name === 'selectCover'? this.getGlobalPosition().toArray() : cover.position.toArray(),
            rotation: cover.name === 'selectCover'? this.getGlobalRotation().toArray() : cover.rotation.toArray(),
        });
        if (this.globalMainPoints[coverId]) {
            return this.globalMainPoints[coverId];
        }
        cover.updateMatrixWorld();
        coverPoints = this.getCoverPoints(cover);
        mainPoints = {
            back: {
                pointA: new Vector3(
                    coverPoints.bottom.A.x,
                    (coverPoints.bottom.A.y + coverPoints.top.A.y)/2,
                    coverPoints.bottom.A.z
                ).applyMatrix4(cover.matrixWorld),
                pointB: new Vector3(
                    coverPoints.bottom.B.x,
                    (coverPoints.bottom.A.y + coverPoints.top.A.y)/2,
                    coverPoints.bottom.B.z
                ).applyMatrix4(cover.matrixWorld)
            },
            front: {
                pointA: new Vector3(
                    coverPoints.bottom.C.x,
                    (coverPoints.bottom.A.y + coverPoints.top.A.y)/2,
                    coverPoints.bottom.C.z
                ).applyMatrix4(cover.matrixWorld),
                pointB: new Vector3(
                    coverPoints.bottom.D.x,
                    (coverPoints.bottom.A.y + coverPoints.top.A.y)/2,
                    coverPoints.bottom.D.z
                ).applyMatrix4(cover.matrixWorld)
            },
            top: {
                pointA: new Vector3(
                    coverPoints.top.A.x,
                    coverPoints.top.A.y,
                    (coverPoints.top.A.z + coverPoints.top.C.z)/2
                ).applyMatrix4(cover.matrixWorld),
                pointB: new Vector3(
                    coverPoints.top.B.x,
                    coverPoints.top.B.y,
                    (coverPoints.top.A.z + coverPoints.top.C.z)/2
                ).applyMatrix4(cover.matrixWorld)
            },
            bottom: {
                pointA: new Vector3(
                    coverPoints.bottom.A.x,
                    coverPoints.bottom.A.y,
                    (coverPoints.bottom.A.z + coverPoints.bottom.C.z)/2
                ).applyMatrix4(cover.matrixWorld),
                pointB: new Vector3(
                    coverPoints.bottom.B.x,
                    coverPoints.bottom.A.y,
                    (coverPoints.bottom.A.z + coverPoints.bottom.C.z)/2
                ).applyMatrix4(cover.matrixWorld)
            },
            left: {
                pointA: new Vector3(
                    coverPoints.bottom.A.x,
                    (coverPoints.bottom.A.y + coverPoints.top.A.y)/2,
                    coverPoints.bottom.A.z
                ).applyMatrix4(cover.matrixWorld),
                pointB: new Vector3(
                    coverPoints.bottom.C.x,
                    (coverPoints.bottom.A.y + coverPoints.top.A.y)/2,
                    coverPoints.bottom.C.z
                ).applyMatrix4(cover.matrixWorld)
            },
            right: {
                pointA: new Vector3(
                    coverPoints.bottom.B.x,
                    (coverPoints.bottom.A.y + coverPoints.top.A.y)/2,
                    coverPoints.bottom.B.z
                ).applyMatrix4(cover.matrixWorld),
                pointB: new Vector3(
                    coverPoints.bottom.D.x,
                    (coverPoints.bottom.A.y + coverPoints.top.A.y)/2,
                    coverPoints.bottom.D.z
                ).applyMatrix4(cover.matrixWorld)
            }
        };
        if (Object.keys(this.globalMainPoints).length > 30) {
            delete this.globalMainPoints[Object.keys(this.globalMainPoints)[0]];
        }
        this.globalMainPoints[coverId] = mainPoints;

        return mainPoints;
    }

    public setCenterPosition() {
        this.view3d.userData.centerPosition = this.view3d.position;
    }

    public remove() {
        this.removeChildren();
        this.service.removeFromScene(this);
    }

    public removeChildren() {
        this.view3d.traverse(function (mesh) {
            mesh.userData = {};
        });
        while (this.view3d.children.length) {
            this.view3d.remove(this.view3d.children[0]);
        }
    }

    public removeChildrenByName(object: Object3D, name: string): boolean {
        const findObject: Object3D | undefined = object.getObjectByName(name);

        if (findObject) {
            object.remove(findObject);
            return true;
        }

        return false;
    }

    public getId(): number {
        return this.id;
    }

    public getUuid(): string {
        return this.view3d.uuid;
    }

    public hasWalls(): boolean {
        return false;
    }

    protected afterSetPosition() {
        this.cover.position.copy(this.view3d.position);
        this.cover.userData.oldPosition =
            this.cover.userData.correctPosition = this.cover.position.clone();
        this.cover.userData.oldRotation =
            this.cover.userData.correctRotation = this.cover.rotation.clone();
        this.updateAllMatrices();
    }

    protected setIsDimensions() {
        this.saveData.isDimensions = this.saveData.isDimensions ?? true;
    }

    protected setIsStick() {
        this.saveData.isStick = this.saveData.isStick ?? true;
    }

    protected setIsLevelStick() {
        this.saveData.isLevelStick = this.saveData.isLevelStick ?? true;
    }

    protected setIsWallStick() {
        this.saveData.isWallStick = this.saveData.isWallStick ?? true;
    }

    protected getSettingsVerticalRotation(): number | undefined {
        return Math.round(radToDeg(this.getSelfVerticalRotation()));
    }

    public getIsDimensions(): boolean {
        return this.saveData.isDimensions ?? true;
    }

    public getIsStick(): boolean {
        return this.saveData.isStick ?? true;
    }

    public getIsLevelStick(): boolean {
        return this.saveData.isLevelStick ?? true;
    }

    public getIsWallStick(): boolean {
        return this.saveData.isWallStick ?? true;
    }

    public setClassToObject() {
        this.view3d.userData.commonObject = this;
    }

    public getGlobalFrontVector(): Vector3 {
        if (!this.globalFrontVector) {
            this.calculateGlobalFrontVector();
        }
        if (!this.globalFrontVector) {
            throw new Error('error-CommonObject-getGlobalFrontVector');
        }

        return this.globalFrontVector;
    }

    public calculateGlobalFrontVector() {
        let globalPoints: TInterval;
        let vector: Vector3;

        vector = new Vector3();
        globalPoints = this.getGlobalFrontLine();
        this.globalFrontVector = vector.subVectors(globalPoints.pointA, globalPoints.pointB);
        this.calculateChildrenGlobalFrontVector();
    }

    public getGlobalPosition(): Vector3 {
        return this.view3d.position;
    }

    public getSettingsGroups(): {[key: string]: ISettingGroup} {
        let groups: {[key: string]: ISettingGroup} = {};

        groups[SETTING_GROUP_GENERAL] = {
            id: SETTING_GROUP_GENERAL,
            title: i18n.t('Общее'),
            hideTitle: true,
            data: {
                isStick: this.getIsStick(),
                selfVerticalRotation: this.getSettingsVerticalRotation(),
                isDimensions: this.getIsDimensions(),
                isLevelStick: this.getIsLevelStick(),
                isWallStick: this.getIsWallStick(),
                sizes: [],
                other: [],
            } as ISettingGroupGeneral,
        };

        return groups;
    }

    public getPrice(): number {
        return 0;
    }

    public getOldPrice(): number {
        return 0;
    }

    public getStock(): number {
        return 0;
    }

    public getFormatStock(stock?: number): string {
        let itemStock: number = stock ? stock : this.getStock();
        return itemStock ? itemStock + ' ' + i18n.t('шт') : '-';
    }

    public getFormatCount(count: number): string {
        return count ? count + ' ' + i18n.t('шт') : '-';
    }

    public getFormatPrice(): string {
        return '-';
    }

    // Temporary solution, will be removed
    protected initDefaultPosition(): Vector3 {
        const initPosition: Vector3 = new Vector3(0, 0, 0);
        return initPosition;
    }

    protected initPosition(): Vector3 {
        const initPosition: Vector3 = new Vector3();
        if (this.saveData.initPosition) {
            if (this.saveData.initPosition.x !== undefined && !isNaN(+this.saveData.initPosition.x)) {
                initPosition.x = +this.saveData.initPosition.x;
            }
            if (this.saveData.initPosition.y !== undefined && !isNaN(+this.saveData.initPosition.y)) {
                initPosition.y = +this.saveData.initPosition.y;
            }
            if (this.saveData.initPosition.z !== undefined && !isNaN(+this.saveData.initPosition.z)) {
                initPosition.z = +this.saveData.initPosition.z;
            }
        }
        if (this.saveData.position) {
            if (this.saveData.position.x !== undefined && !isNaN(+this.saveData.position.x)) {
                initPosition.x = this.saveData.position.x;
            }
            if (this.saveData.position.y !== undefined && !isNaN(+this.saveData.position.y)) {
                initPosition.y = this.saveData.position.y;
            }
            if (this.saveData.position.z !== undefined && !isNaN(+this.saveData.position.z)) {
                initPosition.z = this.saveData.position.z;
            }
        }

        return initPosition;
    }

    protected initRotation(): Euler {
        let initRotation: Euler;

        initRotation = new Euler();
        if (this.saveData.initRotation) {
            if (this.saveData.initRotation.x !== undefined && !isNaN(+this.saveData.initRotation.x)) {
                initRotation.x = +this.saveData.initRotation.x;
            }
            if (this.saveData.initRotation.y !== undefined && !isNaN(+this.saveData.initRotation.y)) {
                initRotation.y = +this.saveData.initRotation.y;
            }
            if (this.saveData.initRotation.z !== undefined && !isNaN(+this.saveData.initRotation.z)) {
                initRotation.z = +this.saveData.initRotation.z;
            }
        }
        if (this.saveData.rotation) {
            if (this.saveData.rotation.x !== undefined && !isNaN(+this.saveData.rotation.x)) {
                initRotation.x = this.saveData.rotation.x;
            }
            if (this.saveData.rotation.y !== undefined && !isNaN(+this.saveData.rotation.y)) {
                initRotation.y = this.saveData.rotation.y;
            }
            if (this.saveData.rotation.z !== undefined && !isNaN(+this.saveData.rotation.z)) {
                initRotation.z = this.saveData.rotation.z;
            }
        }

        return initRotation;
    }

    protected setGlobalPosition(newPosition: Vector3): void {
        let {x, y, z} = newPosition;
        this.view3d.position.set(x, y, z);
    }

    public getGlobalRotation(): Euler {
        return this.view3d.rotation;
    }

    public getPosition(): Vector3 {
        return this.view3d.position;
    }

    public getRotation(): Euler {
        return this.view3d.rotation;
    }

    protected calculateChildrenGlobalFrontVector() {

    }

    public syncCoverPositionRotation() {
        this.cover.position.copy(this.view3d.position);
        this.cover.rotation.copy(this.view3d.rotation);
        this.cover.updateMatrixWorld();
    }

    public getGlobalFrontLine(): TInterval {
        let points: TInterval;

        this.view3d.updateMatrixWorld();
        points = {
            pointA: this.frontGlobalPoints.pointA.copy(this.frontPoints.pointA).applyMatrix4(this.view3d.matrixWorld),
            pointB: this.frontGlobalPoints.pointB.copy(this.frontPoints.pointB).applyMatrix4(this.view3d.matrixWorld),
        };

        return points;
    }

    public updateAllMatrices() {
        this.view3d.updateMatrixWorld();
        if (!this.view3d.matrixAutoUpdate) {
            this.view3d.updateMatrix();
        }
        this.view3d.traverse(function (node) {
            if (((node as Line).isLine  || (node as Mesh).isMesh || (node as Group).isGroup) &&
                !node.matrixAutoUpdate) {
                node.updateMatrix();
            }
        });
        this.calculateGlobalFrontVector();
    }

    public setCoverSelectColor() {
        (this.cover.material as MeshBasicMaterial).visible = true;
        (this.cover.material as MeshBasicMaterial).color.set(this.getSelectColor());
        if (this.cover.userData.carcass) {
            this.cover.userData.carcass.visible = true;
            this.cover.userData.carcass.material.color.set(this.getSelectColor());
        }
    }

    public setSelectCoverSelectColor(color: ColorRepresentation = this.getSelectColor()) {
        (this.selectCover.material as MeshBasicMaterial).visible = true;
        (this.selectCover.material as MeshBasicMaterial).color.set(color);
        if (this.selectCover.userData.carcass) {
            this.selectCover.userData.carcass.visible = true;
            this.selectCover.userData.carcass.material.color.set(color);
        }
    }

    public setSelect() {
        this.setCoverSelectColor();
        this.setSelectCoverSelectColor();
    }

    public clearCoverSelectColor() {
        (this.cover.material as MeshBasicMaterial).visible = false;
        if (this.cover.userData.carcass) {
            this.cover.userData.carcass.visible = false;
        }
    }

    public clearSelectCoverSelectColor(force?: boolean) {
        (this.selectCover.material as MeshBasicMaterial).visible = false;
        if (this.selectCover.userData.carcass) {
            this.selectCover.userData.carcass.visible = false;
        }
        if (this.errors && this.errors.length > 0 && !force && (this.service.isSelectErrorObjects() || this.checkPriceError())) {
            this.setSelectCoverSelectColor(SELECT_COLOR_ERROR);
        }
    }

    public clearSelect() {
        this.cover.position.copy(this.view3d.position);
        this.clearCoverSelectColor();
        this.clearSelectCoverSelectColor();
    }

    public getFrontVectorAngle(): number {
        let frontVector: Vector3;
        let angle: number;
        let y: number;
        let x: number;

        frontVector = this.getGlobalFrontVector();
        x = +frontVector.x;
        y = +frontVector.z;
        angle = Math.atan2(x, y);
        angle += Math.PI;
        if (angle < 0) {
            angle += 2 * Math.PI;
        }
        if (angle >= 2 * Math.PI) {
            angle -= 2 * Math.PI;
        }

        return angle;
    }

    public getContextIcons(): IContextIcon[] {
        let icons: IContextIcon[];
        let actionData = this.actionData();

        icons = [
            {
                channelName: 'CommonObject',
                action: ACTION_DELETE,
                actionData: actionData,
                popup: false,
                icon: 'delete',
                hide: true,
                title: i18n.t('Удалить'),
                sort: 500
            }
        ];

        return icons;
    }

    public hasError(): boolean {
        return this.errors.length > 0;
    }

    public setError(error: TError) {
        this.errors.push(error);
        if (this.service.isSelectErrorObjects() || this.checkPriceError()) {
            this.setSelectCoverSelectColor(SELECT_COLOR_ERROR);
        }
    }

    public checkPriceError(): boolean {
        return false;
    }

    public clearErrors() {
        if (this.errors && this.errors.length > 0) {
            this.clearSelectCoverSelectColor(true);
        }
        this.errors = [];
    }

    public setLoadModel(type: string, details: Object3D[]) {
        throw new Error('replace-CommonObject-setLoadModel');
    }

    public setTransparentForBack() {
        this.view3d.traverse((child) => {
            if (child.userData.notTransparentForBack) {
                return;
            }
            if (child instanceof Mesh) {
                child.onBeforeRender = onBeforeRenderTransparentForBack;
                child.onAfterRender = onAfterRenderTransparentForBack;
            }
        })
    }

    public setViewType(viewType: TKitchenView) {
        this.viewType = viewType;
        this.updateViewType();
    }

    public updateViewType() {
        switch (this.viewType) {
            case KITCHEN_VIEW_SKETCH:
                this.setSketchView(this.view3d);
                break;
            case KITCHEN_VIEW_VISUAL:
            default:
                this.setVisualView(this.view3d);
                break;
        }
    }

    public getClassName(): string {
        return this.constructor.name;
    }

    protected setVisualView(object: Object3D) {
        let child;
        let objectArray;

        if (object instanceof LineSegments) {
            return;
        }
        if (object instanceof Group) {
            objectArray = object.children;
        }
        if (objectArray) {
            for (child of objectArray) {
                this.setVisualView(child);
            }
        } else if (object instanceof Mesh) {
            this.setMeshToVisualView(object);
        }
    }

    protected setMeshToVisualView(mesh: Mesh) {
        if (!mesh.userData) {
            return;
        }
        if (Array.isArray(mesh.material)) {
            return;
        }
        if (mesh.userData.carcass) {
            mesh.userData.carcass.visible = false;
        }
        if (mesh.userData.visualView && mesh.userData.visualView.material) {
            mesh.material = mesh.userData.visualView.material;
            if (Array.isArray(mesh.material)) {
                return;
            }
            mesh.material.opacity = +mesh.userData.visualView.opacity;
            mesh.material.transparent = false;
            mesh.material.needsUpdate = true;
        }
    }

    protected setSketchView(object: Object3D) {
        let child: Object3D;
        let objectArray: Object3D[] | undefined;
        if (!this.checkSketchView(object)) {
            return;
        }
        if (object instanceof Group) {
            objectArray = object.children;
        }
        if (objectArray) {
            for (child of objectArray) {
                this.setSketchView(child);
            }
        } else if (object instanceof Mesh) {
            this.setMeshToSketchView(object);
        }
    }

    protected getSketchViewMaterial(): MeshBasicMaterial {
        return this.service.getDefaultSketchViewMaterial()
    }

    protected checkSketchView(object: Object3D): boolean {
        return !object.userData.notSwitchView;
    }

    protected setMeshToSketchView(mesh: Mesh) {
        let isTransparent;
        if (Array.isArray(mesh.material)) {
            return;
        }
        if (!this.checkSketchView(mesh)) {
            return;
        }

        if (!mesh.userData.visualView || !mesh.userData.visualView.material) {
            mesh.userData.visualView = {};
            mesh.userData.visualView.material = mesh.material;
            mesh.userData.visualView.material.name = 'visualView';
            mesh.userData.visualView.opacity = +mesh.material.opacity;
        }
        if (mesh.userData.sketchMaterial instanceof MeshBasicMaterial) {    
            mesh.material = mesh.userData.sketchMaterial;
        } else {     
            mesh.material = this.getSketchViewMaterial();
        }
        isTransparent = (mesh.userData && mesh.userData.detailType &&
            ['facade', 'glass'].indexOf(mesh.userData.detailType) !== -1);
        if ((mesh.userData.notCreateCarcass || isTransparent)) {
            mesh.material.opacity = 0.3;
            mesh.material.transparent = true;
            mesh.material.needsUpdate = true;
        } else {
            if (!mesh.userData.carcass) {
                this.createMeshCarcass(mesh);
            }
            mesh.userData.carcass.visible = true;
        }

        mesh.receiveShadow = false;
        mesh.castShadow = false;
        mesh.material.needsUpdate = true;
    }

    protected actionData(): TContextActionData {
        return {
            id: this.getId(),
            className: this.getClassName()
        };
    }

    protected getRealVerticalRotation(): number {
        let rotation;

        rotation = +this.view3d.rotation.y;
        rotation -= this.getSelfVerticalRotation();

        return rotation;
    }

    protected calculateMeshCoverPoints(mesh: Mesh): Vector3[] {
        let boundBox: Box3;
        boundBox = new Box3().setFromObject(mesh);

        return [boundBox.min, boundBox.max];
    }

    /**
     * Добавление точек для оболочки.
     * @param data
     * @protected
     */
    public addCoverPoints(data: Vector3[]) {
        let point: Vector3;
        for (point of data) {
            if (Number.isFinite(point.x) && Number.isFinite(point.y) && Number.isFinite(point.z)) {
                this.coverPoints.push(point);
            }
        }
    }

    public getSelectColor() {
        return this.service.getSelectColor();
    }

    protected addDetailToCoverPoints(detail: TDetail) {
        let box;
        let position: BufferAttribute | InterleavedBufferAttribute | GLBufferAttribute;
        let point;
        let points;
        let index;
        let length;
        let vector = new Vector3();

        detail.view3d.updateMatrixWorld();
        points = [];
        position = detail.body.geometry.attributes.position;
        for (index = 0, length = position.count; index < length; index++) {
            if ('isGLBufferAttribute' in position) {
                continue;
            }
            vector.fromBufferAttribute(position, index);
            points.push(vector.clone());
        }
        if (points.length > 0) {
            for (point of points) {
                this.view3d.worldToLocal(point.applyMatrix4(detail.view3d.matrixWorld));
            }
            box = new Box3().setFromPoints(points);
            this.addCoverPoints([box.min, box.max]);
        }
    }

    protected createCover(isRebuild?: boolean) {
        let geometry: BufferGeometry;
        let carcass: LineSegments;

        if (!this.hasCover) {
            return;
        }
        if (this.coverPoints.length <= 0) {
            throw new Error('error-CommonObject-createCover');
        }

        geometry = this.createCoverGeometry();
        this.cover.geometry.dispose();
        this.cover.geometry = geometry;
        this.cover.renderOrder = 3;
        this.cover.userData.commonObject = this;
        this.cover.name = "cover";
        this.removeChildrenByName(this.cover, 'carcass');
        carcass = this.createCoverCarcass(geometry, this.getSelectColor());
        this.cover.userData.carcass = carcass;
        this.cover.add(carcass);
    }

    protected createSelectCover(isRebuild?: boolean) {
        if (!this.hasCover) {
            return;
        }
        if (this.coverPoints.length <= 0) {
            throw new Error('error-CommonObject-createSelectCover');
        }
        let geometry: BufferGeometry;
        let carcass: LineSegments;

        geometry = this.createCoverGeometry(0);
        this.selectCover.geometry.dispose();
        this.selectCover.geometry = geometry;
        this.selectCover.userData.notTransparentForBack = true;
        this.selectCover.renderOrder = 3;
        this.selectCover.userData.notSwitchView = true;
        this.selectCover.name = "selectCover";
        this.removeChildrenByName(this.selectCover, 'carcass');
        carcass = this.createCoverCarcass(geometry, this.getSelectColor());
        this.selectCover.userData.carcass = carcass;
        this.selectCover.add(carcass);
        this.view3d.add(this.selectCover);
    }

    public createCoverCarcass(geometry: BufferGeometry, color: ColorRepresentation): LineSegments {
        let carcassGeometry: BufferGeometry;
        let carcassMaterial: LineBasicMaterial;
        let carcass: LineSegments;

        carcassGeometry = new EdgesGeometry(geometry);
        carcassMaterial = new LineBasicMaterial({
            color: color,
            linewidth: 1,
            depthTest: false,
            depthWrite: false,
        });
        carcass = new LineSegments(carcassGeometry, carcassMaterial);
        carcass.name = 'carcass';
        carcass.visible = false;
        carcass.renderOrder = 3;

        return carcass;
    }

    protected createMeshCarcass(mesh: Mesh, color: ColorRepresentation = '#000000', name: string = 'carcass', lineWidth: number = 2): LineSegments {
        let carcassGeometry;
        let carcassMaterial;
        let carcass;

        carcassGeometry = new EdgesGeometry(mesh.geometry);
        carcassMaterial = new LineBasicMaterial({color: color});
        carcass = new LineSegments(carcassGeometry, carcassMaterial);
        carcass.material.linewidth = lineWidth;
        carcass.name = name;
        carcass.matrixAutoUpdate = false;
        carcass.updateMatrix();
        mesh.userData[name] = carcass;
        mesh.add(carcass);

        return carcass;
    }

    protected createCoverMaterial(): MeshBasicMaterial {
        return new MeshBasicMaterial({
            color: this.getSelectColor(),
            transparent: true,
            opacity: 0.085,
            visible: false,
            depthTest: false,
            depthWrite: false,
            side: DoubleSide,
        });
    }

    protected createCoverGeometry(gap: number = COVER_CORRECTION_SIZE) {
        let geometry: BufferGeometry;
        let boundBox: Box3;

        boundBox = this.getCoverBox(gap);

        geometry = ThreeMathHelper.createBoxGeometry({
            points1: {
                A: new Vector3(boundBox.min.x, boundBox.min.y, boundBox.min.z),
                B: new Vector3(boundBox.max.x, boundBox.min.y, boundBox.min.z),
                C: new Vector3(boundBox.min.x, boundBox.min.y, boundBox.max.z),
                D: new Vector3(boundBox.max.x, boundBox.min.y, boundBox.max.z)
            },
            points2: {
                A: new Vector3(boundBox.min.x, boundBox.max.y, boundBox.min.z),
                B: new Vector3(boundBox.max.x, boundBox.max.y, boundBox.min.z),
                C: new Vector3(boundBox.min.x, boundBox.max.y, boundBox.max.z),
                D: new Vector3(boundBox.max.x, boundBox.max.y, boundBox.max.z)
            }
        });
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();

        return geometry;
    }

    protected addGapToBoundingBox(boundBox: Box3, gap: number): Box3 {
        if (!this.checkBoundingBox(boundBox)) {
            throw new Error('error-CommonObject-addGapToBoundingBox');
        }
        // Уменьшаем cover на минимальную величину, чтобы при расчете пересечения не было коллизий
        boundBox.min.addScalar(gap);
        boundBox.max.addScalar(-gap);

        return boundBox;
    }

    protected checkBoundingBox(boundBox: Box3): boolean {
        return (Number.isFinite(boundBox.min.x) &&
            Number.isFinite(boundBox.max.x) &&
            Number.isFinite(boundBox.min.y) &&
            Number.isFinite(boundBox.max.y) &&
            Number.isFinite(boundBox.min.z) &&
            Number.isFinite(boundBox.max.z));
    }

    public getCoverBox(gap: number = COVER_CORRECTION_SIZE): Box3 {
        let boundBox: Box3;

        boundBox = new Box3().setFromPoints(this.coverPoints);
        if (!Number.isFinite(boundBox.min.x) || !Number.isFinite(boundBox.max.x) ||
            !Number.isFinite(boundBox.min.y) || !Number.isFinite(boundBox.max.y) ||
            !Number.isFinite(boundBox.min.z) || !Number.isFinite(boundBox.max.z)) {
            throw new Error('error-CommonObject-getCoverBox');
        }
        // Уменьшаем cover на минимальную величину, чтобы при расчете пересечения не было коллизий
        boundBox.min.addScalar(gap);
        boundBox.max.addScalar(-gap);

        return boundBox;
    }

    public getTabletopHeight(): number {
        return 0;
    }

    public static createSphere(radius: number = 100, color: ColorRepresentation = '#ffff00'): Mesh {
        return new Mesh(new SphereGeometry(radius), new MeshBasicMaterial({color: color}));
    }
}
