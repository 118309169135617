import { ISaveFacadeData } from "common-code/lib/interfaces/saveData/ISaveFacadeData";
import { IFacade } from "./initialFacade";
import {
  FACADE_CALCULATE_NONE,
  FACADE_POSITION_TYPE_ANGLE,
} from "common-code/lib/constants";
import { TFacadeOpenType } from "common-code/lib/types/TFacadeOpenType";

export const createFacade = (
  facadeNew: IFacade,
  IsEndUnit: boolean,
  index: number | null
) => {
  const {
    initSizesWidth,
    initSizesHeight,
    gap,
    gapBottom,
    gapLeft,
    gapRight,
    gapTop,
    geometryType,
    sideType,
    align,
    alignX,
    alignY,
    modelType,
    handle,
    handleAlign,
    handleAlignX,
    handleAlignY,
    location,
    isGroup,
    isParent,
    isCalcSizes,
    isChild,
    groupId,
    handleMargin,
    handleMarginX,
    handleMarginY,
    handleMarginZ,
    handleRotation,
    handleRotationX,
    handleRotationY,
    handleRotationZ,
    handleModelType,
    functionalType,
    openType,
    calcSizesWidth,
    calcSizesHeight,
    facadeFor,
    facadeMaterial,
    isFlipY,
    rotation,
    rotationX,
    rotationY,
    rotationZ,
    margin,
    marginX,
    marginY,
    marginZ,
    hinges,
    arrHinges,
  } = facadeNew;

  const dataFacade: ISaveFacadeData = {
    id: 0,
    initSizes: { width: initSizesWidth, height: initSizesHeight },
    geometryType,
    sideType,
    number: 0,
    modelType,
    functionalType,
  };

  if (handle) {
    dataFacade.handle = {
      id: 0,
      location: location,
    };

    if (handleAlign) {
      dataFacade.handle.align = { x: handleAlignX, y: handleAlignY };
    }

    if (handleMargin) {
      dataFacade.handle.margin = {
        x: handleMarginX,
        y: handleMarginY,
        z: handleMarginZ,
      };
    }

    if (handleModelType) {
      dataFacade.handle.modelType = handleModelType;
    }

    if (handleRotation) {
      dataFacade.handle.rotation = {
        x: (Number(handleRotationX) / 180) * Math.PI,
        y: (Number(handleRotationY) / 180) * Math.PI,
        z: (Number(handleRotationZ) / 180) * Math.PI,
      };
    }
  }

  if (align) {
    dataFacade.align = { x: alignX, y: alignY };
  }

  if (gap) {
    dataFacade.gap = {
      bottom: Number(gapBottom),
      left: Number(gapLeft),
      right: Number(gapRight),
      top: Number(gapTop),
    };
  }
  if ( index === 0 && isGroup) {
    // для крайних модулей добавляем тип позиции "угол"
    if (IsEndUnit) {
      dataFacade.positionType = FACADE_POSITION_TYPE_ANGLE;
    }
  }
  if(isCalcSizes && !isChild) {
    dataFacade.calculateSizes = {
      width: calcSizesWidth,
      height: calcSizesHeight,
    };
  }

  if (isGroup && isParent) {
    dataFacade.groupId = Number(groupId);
  }

  if (isGroup && isChild ) {
    dataFacade.groupId = Number(groupId);
    dataFacade.calculateType = FACADE_CALCULATE_NONE;
  }

  if (isGroup && !isChild && !isParent) {
    dataFacade.groupId = Number(groupId);
  }

  if (openType) {
    dataFacade.openType = openType as TFacadeOpenType;
  }

  if (facadeFor) {
    dataFacade.for = "second";
    dataFacade.facadeMaterial = facadeMaterial;
  }

  if (isFlipY) {
    dataFacade.isFlipY = isFlipY;
  }
  if (rotation) {
    dataFacade.rotation = {
      x: (Number(rotationX) / 180) * Math.PI,
      y: (Number(rotationY) / 180) * Math.PI,
      z: (Number(rotationZ) / 180) * Math.PI,
    };
  }

  if (margin) {
    dataFacade.margin = {
      x: Number(marginX),
      y: Number(marginY),
      z: Number(marginZ),
    };
  }

  if (hinges) {
    dataFacade.hinges = arrHinges;
  }

  return dataFacade;
};
