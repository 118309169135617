import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React, {CSSProperties, ReactNode, RefObject, useRef} from "react";
import "./Popup.css";
import usePointerEvent from "../../../hooks/usePointerEvent";
import useInstanceOfRootElement from "../../../hooks/useInstanceOfRootElement";
import {TPoint2D} from "../../../../common-code/types/TPoint2D";
import {IInstanceOfRootElement} from "../../../interfaces/IInstanceOfRootElement";

export const cnPopup = cn('Popup');

export interface IPopupProps extends IClassNameProps {
    children: ReactNode;
    active: boolean;
    position: TPoint2D;
    setShowPopup?: (value: boolean) => void;
    ignoreElements?: RefObject<HTMLElement>[];
}

export const Popup: React.FC<IPopupProps> = ({active, className, children, position, setShowPopup, ignoreElements}) => {
    const currentElement = useRef<HTMLDivElement>(null);
    const instanceOfRootElement = useInstanceOfRootElement();

    const downEffect = (event: PointerEvent) => {
        if (!setShowPopup) {
            return;
        }

        const instanceOfRootElementOptions: IInstanceOfRootElement = {
            instance: event.target as HTMLElement,
            rootElement: currentElement.current as HTMLElement
        }

        if (ignoreElements) {
            let elements: HTMLElement[] = [];

            for (let element of ignoreElements) {
                if (element.current) {
                    elements.push(element.current);
                }
            }

            instanceOfRootElementOptions.ignoreElements = elements;
        }

        const isInstance: boolean = instanceOfRootElement(instanceOfRootElementOptions);
        if (!isInstance) {
            setShowPopup(false);
        }
    }

    usePointerEvent({downEffect}, []);

    if (!active) {
        return null;
    }
    const calculatePosition = (position: TPoint2D): CSSProperties => {
        let properties: CSSProperties = {};
        if (position.x === 0) {
            if (Object.is(position.x, -0)) {
                properties.right = '0';
            } else {
                properties.left = '0';
            }
        } else if (position.x >= 0) {
            properties.left = `${Math.abs(position.x)}px`;
        } else {
            properties.right = `${Math.abs(position.x)}px`;
        }
        if (position.y === 0) {
            if (Object.is(position.y, -0)) {
                properties.bottom = '0';
            } else {
                properties.top = '0';
            }
        } else if (position.y >= 0) {
            properties.top = `${Math.abs(position.y)}px`;
        } else {
            properties.border = `${Math.abs(position.y)}px`;
        }

        return properties;
    }

    return (
        <div
            className={cnPopup({}, [className])}
            style={calculatePosition(position)}
            ref={currentElement}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <div className={cnPopup('Wrapper')}>
                {children}
            </div>
        </div>
    );
};