import { Group } from "three";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import { IItemCreateObjectData } from "../FormModule/FormModule";
import { TAspectData } from "../../../../../common-code/types/TAspectData";

export const leftCameraAspect = (
  service: KitchenService,
  itemModule: IItemCreateObjectData
): TAspectData => {
  let yCoord = service?.sceneChildren().filter((el) => el instanceof Group)[0]
    .position.y;

  if (!yCoord) return service.getDefaultAspect();

  let leftAspect;
  let moduleHeight = +itemModule.heightText
    .split("")
    .filter((el) => el !== " " && !el.includes("м"))
    .join("");

  leftAspect = {
    target: {
      x: 0,
      y: yCoord,
      z: 0,
    },
    camera: {
      x: 350,
      y: yCoord === 1750 ? yCoord - 500 + 60 : yCoord + 500 + 60,
      z: moduleHeight > 1000 ? 3500 : 1700,
    },
  };

  return leftAspect;
};
