import { ThreeBottomEquipment } from "../ThreeBottomEquipment";
import { KitchenService } from "../../../../../services/KitchenService/KitchenService";
import { Box3, Mesh, Object3D, Vector3 } from "three";
import { ThreeTabletop } from "../../../details/ThreeTabletop/ThreeTabletop";
import {
  SIDE_TYPE_BACK,
  SIDE_TYPE_LEFT,
  SIDE_TYPE_RIGHT,
} from "../../../../../../../common-code/constants";
import { ISaveWasherEquipmentData } from "../../../../../../../common-code/interfaces/saveData/ISaveWasherEquipmentData";
import { COVER_CORRECTION_SIZE } from "../../../../../constants";
import { ISaveDishwasherEquipmentData } from "../../../../../../../common-code/interfaces/saveData/ISaveDishwasherEquipmentData";
import { ISaveKUnitDetailData } from "../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData";

export class ThreeWasherEquipment extends ThreeBottomEquipment {
  saveData: ISaveWasherEquipmentData;

  constructor(options: ISaveWasherEquipmentData, service: KitchenService) {
    super(options, service);
    this.saveData = options;
  }

  public initState(isRebuild?: boolean) {
    this.addCoverPoints([
      new Vector3(
        0,
        this.getHeight() / 2 + this.service.getTabletopHeight(),
        0
      ),
    ]);
    super.initState(isRebuild);
  }

  public getInitTabletopPosition(tabletop: ThreeTabletop): Vector3 {
    let coverBox: Box3;

    coverBox = this.getCoverBox(0);
    return new Vector3(
      (coverBox.min.x + coverBox.max.x) / 2,
      coverBox.max.y - tabletop.getHeight() / 2,
      this.getZInitTabletopPosition(tabletop)
    );
  }

  public getCorpusCoverBox(gap: number = COVER_CORRECTION_SIZE): Box3 {
    let boundBox: Box3;

    boundBox = new Box3().setFromPoints([
      new Vector3(
        -this.getWidth() / 2,
        -this.getHeight() / 2,
        -this.getDepth() / 2
      ),
      new Vector3(
        this.getWidth() / 2,
        this.getHeight() / 2 + this.service.getTabletopHeight(),
        this.getDepth() / 2
      ),
    ]);
    boundBox = this.addGapToBoundingBox(boundBox, gap);

    return boundBox;
  }

  public setLoadModel(type: string, details: Object3D[]) {
    super.setLoadModel(type, details);
    let child: Object3D;

    if (this.saveData.hasTabletop) {
      for (child of this.threeModel.children) {
        if (child.name === "tabletop") {
          child.visible = false;
        }

        child.children.forEach((el) => {
          el.castShadow = true;
          el.receiveShadow = true;
        });
      }
    }
  }

  protected initSaveData(
    options: ISaveDishwasherEquipmentData
  ): ISaveDishwasherEquipmentData {
    super.initSaveData(options);
    options.sizes.height =
      this.service.getBottomUnitHeight() +
      this.service.getBottomUnitLegsHeight();
    return options;
  }

  protected initTabletopsData(
    tabletops?: ISaveKUnitDetailData[]
  ): ISaveKUnitDetailData[] | undefined {
    if (this.saveData.hasTabletop) {
      return [tabletops && tabletops[0] ? tabletops[0] : { id: 0 }];
    } else {
      return undefined;
    }
  }

  protected initApronsData(
    aprons?: ISaveKUnitDetailData[]
  ): ISaveKUnitDetailData[] | undefined {
    if (this.saveData.hasAprons) {
      return [
        aprons && aprons[0]
          ? aprons[0]
          : {
              id: 0,
              positionType: SIDE_TYPE_BACK,
            },
        aprons && aprons[1]
          ? aprons[1]
          : {
              id: 0,
              positionType: SIDE_TYPE_LEFT,
            },
        aprons && aprons[2]
          ? aprons[2]
          : {
              id: 0,
              positionType: SIDE_TYPE_RIGHT,
            },
      ];
    } else {
      return undefined;
    }
  }

  protected initCornersData(
    corners?: ISaveKUnitDetailData[]
  ): ISaveKUnitDetailData[] | undefined {
    if (this.saveData.hasCorners) {
      return [
        corners && corners[0]
          ? corners[0]
          : {
              id: 0,
              positionType: SIDE_TYPE_BACK,
            },
        corners && corners[1]
          ? corners[1]
          : {
              id: 0,
              positionType: SIDE_TYPE_LEFT,
            },
        corners && corners[2]
          ? corners[2]
          : {
              id: 0,
              positionType: SIDE_TYPE_RIGHT,
            },
      ];
    } else {
      return undefined;
    }
  }
}
