import {ThreeEquipment} from '../ThreeEquipment/ThreeEquipment';
import {TEquipmentCellName} from '../../../../../../common-code/types/TEquipmentCellName';
import {ThreeKUnit} from '../../units/ThreeKUnit/ThreeKUnit';
import {ISaveBuiltInEquipmentData} from '../../../../../../common-code/interfaces/saveData/ISaveBuiltInEquipmentData';
import {CommonHelper} from 'common-code';

export class ThreeBuiltInEquipment extends ThreeEquipment {
    saveData: ISaveBuiltInEquipmentData;
    unit: ThreeKUnit;

    constructor(options: ISaveBuiltInEquipmentData, unit: ThreeKUnit) {
        super(options, unit.service);
        this.saveData = options;
        this.unit = unit;
    }

    public createView(isRebuild?: boolean) {
        this.view3d.matrixAutoUpdate = false;
        if (this.coverPoints.length > 0) {
            this.createSelectCover(isRebuild);
        }
        if (this.transparentForBack) {
            this.setTransparentForBack();
        }
        this.setPosition(this.initPosition());
        this.setRotation(this.initRotation());
        this.updateViewType();
        this.updateAllMatrices();

        

        this.unit.view3d.add(this.view3d);
    }

    public getCellName(): TEquipmentCellName {
        return this.saveData.cellName;
    }

    public getData(): ISaveBuiltInEquipmentData {
        let data: ISaveBuiltInEquipmentData = CommonHelper.deepCopy(super.getData());

        return data;
    }
}