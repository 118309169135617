import {ThreeConstructive} from '../ThreeConstructive/ThreeConstructive';
import {DoubleSide, Mesh, MeshStandardMaterial, Vector3} from 'three';
import {IMaterialTextures} from '../../../../interfaces/IMaterialTextures';
import {ThreeRoom} from '../../rooms/ThreeRoom/ThreeRoom';
import {ThreeWall} from '../../rooms/ThreeWall/ThreeWall';
import {IMaterialData} from '../../../../../../common-code/interfaces/materials/IMaterialData';
import {ISaveWallIslandData} from '../../../../../../common-code/interfaces/saveData/ISaveWallIslandData';
import {CONNECTION_TYPE_DEFAULT, POINT_TYPE_ROOM, SIDE_TYPE_BOTTOM} from '../../../../../../common-code/constants';
import {ThreeRoomPoint} from '../../../../points/ThreeRoomPoint/ThreeRoomPoint';
import {TPlaneType} from '../../../../types/TPlaneType';
import {PLANE_TYPE_HORIZONTAL} from '../../../../../constants';
import {ISavePoint2DData} from '../../../../../../common-code/interfaces/saveData/ISavePoint2DData';
import {ICoverPoints} from '../../../../interfaces/ICoverPoints';
import {ISaveWallData} from '../../../../../../common-code/interfaces/saveData/ISaveWallData';

export class ThreeWallIsland extends ThreeConstructive {
    body: Mesh;
    material?: MeshStandardMaterial;
    materialData: IMaterialData;
    materialTextures: IMaterialTextures;
    protected points: ThreeRoomPoint[];
    protected walls: ThreeWall[];

    constructor(options: ISaveWallIslandData, room: ThreeRoom) {
        super(options, room);
        this.body = new Mesh();
        this.materialData = this.initMaterialData(options.materialId);
        this.materialTextures = this.loadTextures();
        this.points = [];
        this.walls = [];
    }

    public createView(isRebuild?: boolean) {
        this.createBody();
        this.calculateGlobalFrontVector();
        super.createView(isRebuild);
        this.createPoints();
        this.createWalls();
        this.setWallNeighbors();
        this.createWallViews();
    }

    public removeChildren() {
        this.removeWalls();
        this.removePoints();
        super.removeChildren();
    }

    public rebuildView() {
        this.removeWalls();
        this.removePoints();
        this.createPoints();
        this.createWalls();
        this.setWallNeighbors();
        this.createWallViews();
    }

    public hasWalls(): boolean {
        return true;
    }

    public getHeight(): number {
        return this.room.getHeight();
    }

    public defaultYPosition(): number {
        return this.getSideDistance(SIDE_TYPE_BOTTOM);
    }

    public afterHistoryMove() {
        this.rebuildView();
    }

    public afterTryMove(isMove: boolean, isFinal?: boolean): boolean {
        super.afterTryMove(isMove, isFinal);
        if (isMove && isFinal) {
            this.rebuildView();
        }
        return isMove;
    }

    protected isSelfWall(wall: ThreeWall): boolean {
        return this.walls.filter(item => item.getId() === wall.getId()).length > 0;
    }

    protected removeWalls() {
        let wall: ThreeWall;

        for (wall of this.walls) {
            this.room.removeWallIslandWall(wall.getId());
            wall.remove();
        }
        this.walls = [];
    }

    protected removePoints() {
        let point: ThreeRoomPoint;

        for (point of this.points) {
            this.room.removeWallIslandPoint(point.getId());
            point.remove();
        }
        this.points = [];
    }

    protected setIsStick() {
        this.saveData.isStick = this.saveData.isStick ?? false;
    }

    protected setIsLevelStick() {
        this.saveData.isLevelStick = this.saveData.isLevelStick ?? false;
    }

    protected setIsWallStick() {
        this.saveData.isWallStick = this.saveData.isWallStick ?? false;
    }

    protected createSizes() {
        return;
    }

    protected initPosition(): Vector3 {
        let initPosition: Vector3;

        initPosition = super.initPosition();
        if (initPosition.y !== this.defaultYPosition()) {
            initPosition.y = this.defaultYPosition();
        }

        return initPosition;
    }

    protected getIntersectsTypes(): TPlaneType[] {
        return [
            PLANE_TYPE_HORIZONTAL,
        ];
    }

    protected correctMovePosition(movePosition: Vector3) {
        if (movePosition.y !== this.defaultYPosition()) {
            movePosition.y = this.defaultYPosition();
        }
    }

    protected createBody() {
        this.addCoverPoints([
            new Vector3(-this.getWidth()/2, -this.getHeight()/2, -this.getDepth()/2),
            new Vector3(this.getWidth()/2, this.getHeight()/2, this.getDepth()/2),
        ]);
    }

    protected createPoints() {
        let pointsData: ISavePoint2DData[];
        let pointData: ISavePoint2DData;
        let roomPoint;

        pointsData = this.getPointsData();
        for (pointData of pointsData) {
            roomPoint = new ThreeRoomPoint(pointData, this.service);
            roomPoint.initState();
            roomPoint.createView();
            this.points.push(roomPoint);
            this.room.addWallIslandPoint(roomPoint);
        }
    }

    protected getPointsData(): ISavePoint2DData[] {
        let points: ISavePoint2DData[] = [];
        let globalPoints: ICoverPoints;
        let index: number;

        globalPoints = this.getGlobalPoints(this.selectCover);
        for (index = globalPoints.polygon.length -1; index >= 0; index--) {
            points.push({
                id: 0,
                type: POINT_TYPE_ROOM,
                connectionType: CONNECTION_TYPE_DEFAULT,
                sort: 0,
                value: {...globalPoints.polygon[index]}
            })
        }

        return points;
    }

    protected createWalls() {
        let wallsData: ISaveWallData[];
        let wallData: ISaveWallData;
        let wall: ThreeWall;

        wallsData = this.getWallsData();
        for (wallData of wallsData) {
            wall = new ThreeWall(wallData, this.service.getRoom());
            this.walls.push(wall);
            this.room.addWallIslandWall(wall);
        }
    }

    protected setWallNeighbors() {
        let index;
        let index2;

        for (index in this.walls) {
            for (index2 in this.walls) {
                if (index === index2) {
                    continue;
                }
                if (this.walls[index].pointA.getId() === this.walls[index2].pointB.getId()) {
                    this.walls[index].setLeftNeighbor(this.walls[index2]);
                    this.walls[index2].setRightNeighbor(this.walls[index]);
                }
            }
        }
    }

    protected getWallsData(): ISaveWallData[] {
        let wallsData: ISaveWallData[] = [];
        let index: number;
        let pointA: ThreeRoomPoint;
        let pointB: ThreeRoomPoint;

        for (index = 0; index < this.points.length; index++) {
            pointA = this.points[index];
            pointB = this.points[index + 1] !== undefined ?  this.points[index + 1] : this.points[0];
            wallsData.push({
                id: 0,
                pointA: pointA.getId(),
                pointB: pointB.getId(),
                sort: 0,
                depth: this.getMinSize()/2,
                direction: true
            })
        }

        return wallsData;
    }

    protected createWallViews() {
        let wall: ThreeWall;

        for (wall of this.walls) {
            wall.initState();
            wall.createView();
        }
    }

    protected getMinSize(): number {
        return this.getWidth() < this.getDepth() ? this.getWidth() : this.getDepth();
    }

    protected initMaterialData(materialId?: string): IMaterialData {
        return this.service.getWallMaterial(materialId);
    }

    protected loadTextures(): IMaterialTextures {
        return this.service.loadMaterialTextures(this.materialData.id, this.materialData.textures);
    }

    protected getBodyMaterial(): MeshStandardMaterial {
        if (!this.material) {
            this.material = new MeshStandardMaterial({
                    side: DoubleSide,
                    color: this.materialData.color || '#fffce6',
                    emissive: this.materialData.emissiveColor || '',
                    emissiveIntensity: 0.1,
                    map: this.materialTextures.texture || null,
                    normalMap: this.materialTextures.normal || null,
                    roughnessMap: this.materialTextures.roughness || null,
                    envMapIntensity: 5
                }
            );
        }

        return this.material;
    }

}