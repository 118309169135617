import { ITableTop } from "./TableTops";
export const initionalTabletop = (): ITableTop => {
  return {
    id: 0,
    functionalType: "",
    initSizes: {
      length: 0,
      width: 0,
    },
  };
};
