import { TPoint3D } from "common-code/lib/types/TPoint3D";
import { TRotation } from "../../../../../../../common-code/types/TRotation";

export interface IValue {
  id: number;
  positionType: string;
  height?: number;
  initSizes?: {
    length: number;
  };
  rotation?: TRotation;
  margin?: TPoint3D;
  initPosition?: TPoint3D;
}

export interface IParamsValue {
  positionType: string;
  initSizes: boolean;
  initSizesLength: string;
  rotation: boolean;
  rotationX: string;
  rotationY: string;
  rotationZ: string;
  margin: boolean;
  marginX: string;
  marginY: string;
  marginZ: string;
  initPosition: boolean;
  initPositionX: string;
  initPositionY: string;
  initPositionZ: string;
  error: string;
  modal: boolean;
}

export const initialValue: (value: IValue | null) => IParamsValue = (value) => {
  return value
    ? {
        positionType: value.positionType,
        initSizes: !!value.initSizes,
        initSizesLength: value.initSizes?.length?.toString() || "0",
        rotation:
          value.rotation?.x || value.rotation?.y || value.rotation?.z
            ? true
            : false,
        rotationX: value.rotation?.x
          ? ((value.rotation.x * 180) / Math.PI).toString()
          : "0",
        rotationY: value.rotation?.y
          ? ((value.rotation.y * 180) / Math.PI).toString()
          : "0",
        rotationZ: value.rotation?.z
          ? ((value.rotation.z * 180) / Math.PI).toString()
          : "0",
        margin:
          !!value.margin?.x || !!value.margin?.y || !!value.margin?.z,
        marginX: value.margin?.x ? value.margin.x.toString() : "0",
        marginY: value.margin?.y ? value.margin.y.toString() : "0",
        marginZ: value.margin?.z ? value.margin.z.toString() : "0",
        initPosition:
          !!value.initPosition?.x ||
          !!value.initPosition?.y ||
          !!value.initPosition?.z,
        initPositionX: value.initPosition?.x
          ? value.initPosition.x.toString()
          : "0",
        initPositionY: value.initPosition?.y
          ? value.initPosition.y.toString()
          : "0",
        initPositionZ: value.initPosition?.z
          ? value.initPosition.z.toString()
          : "0",
        error: "",
        modal: false,
      }
    : {
        positionType: "",
        initSizes: false,
        initSizesLength: "",
        rotation: false,
        rotationX: "0",
        rotationY: "0",
        rotationZ: "0",
        margin: false,
        marginX: "0",
        marginY: "0",
        marginZ: "0",
        initPosition: false,
        initPositionX: "0",
        initPositionY: "0",
        initPositionZ: "0",
        error: "",
        modal: false,
      };
};
