import {CommonSize} from '../../CommonSize/CommonSize';
import {Box3, Euler, Mesh, Vector3} from 'three';
import {SIZE_TYPE_DEPTH, SIZE_TYPE_HEIGHT} from '../../../../../common-code/constants';
import {ThreeUnit} from '../ThreeUnit/ThreeUnit';
import {ThreeWall} from '../rooms/ThreeWall/ThreeWall';
import {KitchenService} from '../../../services/KitchenService/KitchenService';
import {ICoverMainPoints} from '../../../interfaces/ICoverMainPoints';
import {ICoverPoints} from '../../../interfaces/ICoverPoints';
import {ISaveSizeData} from '../../../../../common-code/interfaces/saveData/ISaveSizeData';
import {CommonHelper} from 'common-code';

export class ThreeSize extends CommonSize {
    pointA: Vector3;
    pointB: Vector3;
    unit: ThreeUnit | ThreeWall;
    service: KitchenService;
    body: Mesh;

    constructor(options: ISaveSizeData, parent: ThreeUnit | ThreeWall) {
        super(options, parent);
        this.service = parent.service;
        this.unit = parent;
        this.pointA = new Vector3(options.pointA.x, options.pointA.y, options.pointA.z);
        this.pointB = new Vector3(options.pointB.x, options.pointB.y, options.pointB.z);
        this.body = new Mesh();
    }

    public getGlobalMainPoints(cover: Mesh = this.cover): ICoverMainPoints {
        let mainPoints: ICoverMainPoints;
        let coverId: string;

        coverId = CommonHelper.md5({
            uuid: this.view3d.uuid,
            position: this.view3d.position.toArray(),
            positionA: this.meshPointA.position.toArray(),
            positionB: this.meshPointB.position.toArray(),
            rotation: this.view3d.rotation.toArray(),
            parentPosition: this.unit.getGlobalPosition().toArray(),
            parentRotation: this.unit.getGlobalRotation().toArray(),
            direction: this.direction,
        });
        if (this.globalMainPoints[coverId]) {
            return this.globalMainPoints[coverId];
        }

        this.view3d.updateMatrixWorld();
        mainPoints = {
            back: {
                pointA: this.meshPointA.position.clone().applyMatrix4(this.view3d.matrixWorld),
                pointB: this.meshPointB.position.clone().applyMatrix4(this.view3d.matrixWorld),
            },
            front: {
                pointA: this.defaultMainPoint,
                pointB: this.defaultMainPoint
            },
            top: {
                pointA: this.defaultMainPoint,
                pointB: this.defaultMainPoint
            },
            bottom: {
                pointA: this.defaultMainPoint,
                pointB: this.defaultMainPoint
            },
            left: {
                pointA: this.defaultMainPoint,
                pointB: this.defaultMainPoint
            },
            right: {
                pointA: this.defaultMainPoint,
                pointB: this.defaultMainPoint
            }
        };
        if (Object.keys(this.globalMainPoints).length > 30) {
            delete this.globalMainPoints[Object.keys(this.globalMainPoints)[0]];
        }
        this.globalMainPoints[coverId] = mainPoints;

        return mainPoints;
    }

    public getGlobalPoints(): ICoverPoints {
        let globalPointA: Vector3;
        let globalPointB: Vector3;

        if (!this.meshPointA.userData.centerPosition ||
            !(this.meshPointA.userData.centerPosition instanceof Vector3)) {
            this.meshPointA.userData.centerPosition = new Vector3()
        }
        if (!this.meshPointB.userData.centerPosition ||
            !(this.meshPointB.userData.centerPosition instanceof Vector3)) {
            this.meshPointB.userData.centerPosition = new Vector3()
        }

        globalPointA = this.meshPointA.userData.centerPosition.copy(this.meshPointA.position).applyMatrix4(this.view3d.matrixWorld);
        globalPointB = this.meshPointB.userData.centerPosition.copy(this.meshPointB.position).applyMatrix4(this.view3d.matrixWorld);

        return {
            top: {
                A: globalPointA,
                B: globalPointB,
                C: globalPointA,
                D: globalPointB,
            },
            bottom: {
                A: globalPointA,
                B: globalPointB,
                C: globalPointA,
                D: globalPointB,
            },
            box: new Box3().setFromPoints([globalPointA, globalPointB]),
            polygon: [{x: globalPointA.x, y: globalPointA.z}, {x: globalPointB.x, y: globalPointB.z}],
            position: {
                x: (globalPointA.x + globalPointB.x) / 2,
                y: (globalPointA.y + globalPointB.y) / 2,
                z: (globalPointA.z + globalPointB.z) / 2
            }
        }
    }

    public getGlobalPosition(): Vector3 {
        this.unit.view3d.updateMatrixWorld()
        return this.globalPosition.copy(this.view3d.position).applyMatrix4(this.unit.view3d.matrixWorld);
    }

    public getGlobalRotation(): Euler {
        return this.unit.view3d.rotation;
    }

    public setDepthToLeft(sizeGap: number = 0) {
        if (this.getType() !== SIZE_TYPE_DEPTH) {
            return;
        }
        let coverBox: Box3;

        coverBox = this.unit.getCoverBox(0);
        this.view3d.position.x = coverBox.min.x + sizeGap;
        this.view3d.rotation.y = 0.5 * Math.PI;
        this.globalMainPoints = {};
        this.updateAllMatrices();
    }

    public setDepthToRight(sizeGap: number = 0) {
        if (this.getType() !== SIZE_TYPE_DEPTH) {
            return;
        }
        let coverBox: Box3;

        coverBox = this.unit.getCoverBox(0);
        this.view3d.position.x = coverBox.max.x - sizeGap;
        this.view3d.rotation.y = -0.5 * Math.PI;
        this.globalMainPoints = {};
        this.updateAllMatrices();
    }

    public setHeightToLeft() {
        if (this.getType() !== SIZE_TYPE_HEIGHT) {
            return;
        }
        let coverBox: Box3;

        coverBox = this.unit.getCoverBox(0);
        this.view3d.position.x = coverBox.min.x;
        this.view3d.rotation.z = 0.5 * Math.PI;
        this.globalMainPoints = {};
        this.updateAllMatrices();
    }

    public setHeightToRight() {
        if (this.getType() !== SIZE_TYPE_HEIGHT) {
            return;
        }
        let coverBox: Box3;

        coverBox = this.unit.getCoverBox(0);
        this.view3d.position.x = coverBox.max.x;
        this.view3d.rotation.z = -0.5 * Math.PI;
        this.globalMainPoints = {};
        this.updateAllMatrices();
    }

    public getUnionYPosition(): number {
        return 0;
    }

    public getMaterialId(): string {
        return 'size';
    }

    public isCalculate(): boolean {
        return false;
    }

    public getLength(): number {
        return 0;
    }

    public setPositionFromLevelBox(positionY: number) {
        // TODO доработать алгоритм перемещения размеров модулей,
        // чтобы стоить правильные общие размеры
        // if (this.getType() === SIZE_TYPE_HEIGHT) {
        //     return;
        // }
        // this.view3d.position.y = positionY;
        // this.globalMainPoints = {};
        // this.updateAllMatrices();
    }

    protected initPosition(): Vector3 {
        let position: Vector3;

        position = new Vector3(
            (this.pointB.x + this.pointA.x)/2,
            (this.pointB.y + this.pointA.y)/2,
            (this.pointB.z + this.pointA.z)/2
        );

        if (this.saveData.position) {
            if (this.saveData.position.x !== undefined && !isNaN(+this.saveData.position.x)) {
                position.x = +this.saveData.position.x;
            }
            if (this.saveData.position.y !== undefined && !isNaN(+this.saveData.position.y)) {
                position.y = +this.saveData.position.y;
            }
            if (this.saveData.position.z !== undefined && !isNaN(+this.saveData.position.z)) {
                position.z = +this.saveData.position.z;
            }
        }

        return position;
    }

    public isCanUnion(): boolean {
        return this.saveData.canUnion === true || this.saveData.canUnion === undefined;
    }

}