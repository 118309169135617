import {IClassNameProps} from "@bem-react/core";
import {cn} from "@bem-react/classname";
import React, {useEffect, useMemo, useRef, useState} from "react";
import './PageCollections.css';
import {useTranslation} from "react-i18next";
import {cnHeader, Header} from "../../ui/layouts/Header";
import axios from 'axios';
import {CatalogItem, cnCatalogItem} from "../../ui/elements/CatalogItem";
import {LoadingApiData} from "../../ui/helpers/LoadingApiData/LoadingApiData";
import {Button, cnButton} from "../../ui/components/Button";
import {cnPopup, Popup} from "../../ui/components/Popup";
import {TSortName} from "../../../common-code/types/TSortName";
import {TSortOrder} from "../../../common-code/types/TSortOrder";
import {ORDER_ASC, ORDER_DESC, SORT_NAME, SORT_PRICE, SORT_RATE} from "../../../common-code/constants";
import {IFilter} from "../../../common-code/interfaces/IFilter";
import {Svg} from "../../ui/components/Svg";
import {IBreadcrumbs} from "../../interfaces/IBreadcrumbs";
import {ICollectionDto} from '../../../common-code/interfaces/ICollectionDto';
import {Breadcrumbs, cnBreadcrumbs} from '../../ui/components/Breadcrumbs ';
import {cnPage} from '../../main/App/Content/App-Content';

export const cnPageCollections = cn('PageCollections');

export interface IPageCollectionsProps extends IClassNameProps {

}

export const PageCollections: React.FC<IPageCollectionsProps> = ({className}) => {
    const {t} = useTranslation();
    const [filter, setFilter] = useState<IFilter>({id: '', name: t('Выберите коллекцию')});
    const [filterList, setFilterList] = useState<IFilter[]>([]);
    const [sortName, setSortName] = useState<TSortName>(SORT_NAME);
    const [sortOrder, setSortOrder] = useState<TSortOrder>(ORDER_ASC);
    const [collections, setCollections] = useState<ICollectionDto[]>([]);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const buttonElement = useRef<HTMLButtonElement>(null);
    const [hoverFilter, setHoverFilter] = useState<string>('');
    const [hoverSort, setHoverSort] = useState<string>('');

    const breadcrumbs = useMemo(() => {
        return [
            {text: t("Коллекции модульных гарнитуров"), href: '/collections'}
        ] as IBreadcrumbs[];
    }, [t]);

    useEffect(() => {
        setShowPopup(false);
        // получаем список доступных коллекций
        axios.get('/api/collection/list', {params: {collection: filter.id, sort: sortName, order: sortOrder}})
            .then((response) => {
                if (response.data && response.data.filter && response.data.filter.length > 0) {
                    response.data.filter.forEach((item: any) => {
                        if (!filterList.length) {
                            setFilterList(item.values);
                        }
                    });
                }
                if (response.data && response.data.items && response.data.items.length > 0) {
                    setCollections(response.data.items);
                }
            })
            .catch(() => {
                setCollections([]);
            });
    }, [filter, filterList, sortName, sortOrder]);

    const sortItems = (sortItem: TSortName) => {
        if (sortName !== sortItem) {
            setSortName(sortItem);
        } else if (sortOrder === ORDER_ASC) {
            setSortOrder(ORDER_DESC);
        } else {
            setSortOrder(ORDER_ASC);
        }
    }

    const renderSortItems = (sortItem: TSortName) => {
        let itemName: string;

        switch (sortItem) {
            case SORT_RATE:
                itemName = t('Рейтингу');
                break;
            case SORT_PRICE:
                itemName = t('Цене');
                break;
            case SORT_NAME:
            default:
                itemName = t('Названию');
        }

        return (
            <div
                className={cnPageCollections('Group', {hover: sortItem === hoverSort})}
                onMouseEnter={() => {setHoverSort(sortItem)}}
                onMouseLeave={() => {setHoverSort('')}}
                onClick={() => {sortItems(sortItem)}}
            >
                <p className={cnPageCollections('Label', {active: sortName === sortItem})}>{itemName}</p>
                <div className={cnPageCollections('Icons')}>
                    <Svg
                        className={cnPageCollections('Icon', {active: sortName === sortItem && sortOrder === ORDER_ASC})}
                        icon={'arrowUp'}
                        label={itemName}
                        style={{width: `${8}px`, height: `${8}px`}}
                    />
                    <Svg
                        className={cnPageCollections('Icon', {active: sortName === sortItem && sortOrder === ORDER_DESC})}
                        icon={'arrowDown'}
                        label={itemName}
                        style={{width: `${8}px`, height: `${8}px`}}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={cnPageCollections({}, [className])}>
            <Header className={cnPageCollections(cnHeader())} title={t("Коллекции модульных гарнитуров") || undefined} />
            <div className={cnPage('Wrapper')}>
                <div className={cnPage('Sections')}>
                    <Breadcrumbs className={cnPage(cnBreadcrumbs())} breadcrumbs={breadcrumbs}/>
                    <h1 className={cnPage('Title')}>{t("Коллекции модульных гарнитуров")}</h1>
                    <div className={cnPageCollections('FilterSort')}>
                        <div className={cnPageCollections('Filter')}>
                            <Button
                                className={cnPageCollections(cnButton())}
                                type={'button'}
                                ref={buttonElement}
                                rightIcon={{path: 'arrowDown', width: 15, height: 15, marginLeft: 3}}
                                text={filter.name}
                                mods={{mods: {color: 'lightBlack', bkg: 'white', border: 'gray', fill: 'lightBlack'}}}
                                callBack={() => {setShowPopup(!showPopup)}}
                            />
                            {filterList.length > 0 &&
                                <Popup
                                    active={showPopup}
                                    className={cnPageCollections(cnPopup())}
                                    position={{x: 15, y: 41}}
                                    setShowPopup={setShowPopup}
                                    ignoreElements={[buttonElement]}
                                >
                                    <div className={cnPageCollections('Scroller')}>
                                        {filterList.map(item => {
                                            return (
                                                <div
                                                    className={cnPageCollections('Item', {hover: item.name === hoverFilter, checked: item.id === filter.id})}
                                                    key={item.id}
                                                    onClick={() => {setFilter(item)}}
                                                    onMouseEnter={() => {setHoverFilter(item.name)}}
                                                    onMouseLeave={() => {setHoverFilter('')}}
                                                >
                                                    {item.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Popup>
                            }
                        </div>
                        <div className={cnPageCollections('Sort')}>
                            <p className={cnPageCollections('Text')}>{t('Сортировать по')}:</p>
                            {renderSortItems(SORT_RATE)}
                            {renderSortItems(SORT_PRICE)}
                            {renderSortItems(SORT_NAME)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={cnPage('Divider')}></div>
            <div className={cnPageCollections('Collections')}>
                {!collections.length ?
                    <LoadingApiData loading={!collections.length}/> :
                    collections.map(collection => <CatalogItem className={cnPageCollections(cnCatalogItem())} key={collection.id} collectionData={collection}/>)}
            </div>
        </div>
    );
}