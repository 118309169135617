import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeTabletop} from '../../../details/ThreeTabletop/ThreeTabletop';
import {Box3, Euler, Vector3} from 'three';
import {TTabletopsCalculateData} from '../../../../../types/TTabletopsCalculateData';
import {TPoint2D} from '../../../../../../../common-code/types/TPoint2D';
import {
    FACADE_CELL_DEFAULT,
    SIDE_TYPE_FRONT,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_NONE,
    SIDE_TYPE_RIGHT,
    UNIT_PLINTHS_TYPE_BACK_NEIGHBOR, UNIT_PLINTHS_TYPE_DEFAULT
} from '../../../../../../../common-code/constants';
import {TPoint3D} from '../../../../../../../common-code/types/TPoint3D';
import {ThreeFacade} from '../../../details/ThreeFacade/ThreeFacade';
import {
    ThreeBottomAngleFullLengthSidewallCubeSinkCorpus
} from "../../../details/ThreeCorpus/types/ThreeBottomAngleFullLengthSidewallCubeSinkCorpus";
import {TDetailOptionalSizes} from "../../../../../../../common-code/types/TDetailOptionalSizes";
import {TDetail} from "../../../../../types/TDetail";
import {
    ISaveBottomUnitAngleFullLengthSidewallCubeSinkData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitAngleFullLengthSidewallCubeSinkData';
import {
    ISaveBottomAngleFullLengthSidewallCubeSinkCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomAngleFullLengthSidewallCubeSinkCorpusData';
import {CommonHelper} from 'common-code';
import {BufferAttribute} from 'three/src/core/BufferAttribute';
import {InterleavedBufferAttribute} from 'three/src/core/InterleavedBufferAttribute';
import {GLBufferAttribute} from 'three/src/core/GLBufferAttribute';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {MathHelper} from 'common-code';

export class ThreeBottomUnitAngleFullLengthSidewallCubeSink extends ThreeBottomUnit {
    saveData: ISaveBottomUnitAngleFullLengthSidewallCubeSinkData;
    corpus: ThreeBottomAngleFullLengthSidewallCubeSinkCorpus;

    constructor(options: ISaveBottomUnitAngleFullLengthSidewallCubeSinkData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public getInitTabletopPosition(tabletop: ThreeTabletop): Vector3 {
        let position: Vector3;
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);
        position = new Vector3(
            (coverBox.min.x + coverBox.max.x) / 2,
            coverBox.max.y + tabletop.getHeight() / 2,
            this.getZInitTabletopPosition(tabletop)
        );

        return position;
    }

    protected initCorpus(corpusData: ISaveBottomAngleFullLengthSidewallCubeSinkCorpusData): ThreeBottomAngleFullLengthSidewallCubeSinkCorpus {
        return new ThreeBottomAngleFullLengthSidewallCubeSinkCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    protected addDetailToCoverPoints(detail: TDetail) {
        let box;
        let position: BufferAttribute | InterleavedBufferAttribute | GLBufferAttribute;
        let point;
        let points;
        let index;
        let length;
        let vector = new Vector3();
        if (detail.saveData.isDimensions === false) {
            return;
        }

        detail.view3d.updateMatrixWorld();
        points = [];
        position = detail.body.geometry.attributes.position;
        for (index = 0, length = position.count; index < length; index++) {
            if ('isGLBufferAttribute' in position) {
                continue;
            }
            vector.fromBufferAttribute(position, index);
            points.push(vector.clone());
        }
        if (points.length > 0) {
            for (point of points) {
                this.view3d.worldToLocal(point.applyMatrix4(detail.view3d.matrixWorld));
            }
            box = new Box3().setFromPoints(points);
            this.addCoverPoints([box.min, box.max]);
        }
    }

    protected initTabletopsData(tabletops?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] {
        let calculateTabletop;

        calculateTabletop = this.calculateTabletopsData();

        return [
            this.getMainTabletop(calculateTabletop),
            this.getSecondTabletop(calculateTabletop)
        ];
    }

    protected getMainTabletop(calculateTabletop: TTabletopsCalculateData): ISaveKUnitDetailData {
        return {
            id: 0,
            sizes: {
                length: calculateTabletop.main.length,
                width: calculateTabletop.main.width,
                height: calculateTabletop.main.height
            },
            leftPoints: this.getMainTabletopPath(calculateTabletop),
            rightPoints: this.getMainTabletopPath(calculateTabletop),
            position: {
                x: calculateTabletop.main.pointCenter.x,
                y: (this.corpus.getHeight() / 2 + calculateTabletop.main.height / 2),
                z: calculateTabletop.main.pointCenter.y
            },
            rotation: {y: 0.25 * Math.PI}
        };
    }

    protected getMainTabletopPath(calculateTabletop: TTabletopsCalculateData): TPoint2D[] {
        let points = [], minGap;

        minGap = calculateTabletop.main.bottomGap < calculateTabletop.main.topGap ?
            calculateTabletop.main.bottomGap : calculateTabletop.main.topGap;
        points.push({x: calculateTabletop.main.bottomGap - minGap, y: -calculateTabletop.main.width / 2});
        points.push({
            x: calculateTabletop.main.middleGap - minGap,
            y: -calculateTabletop.main.width / 2 + calculateTabletop.main.middleHeight
        });
        points.push({x: calculateTabletop.main.topGap - minGap, y: calculateTabletop.main.width / 2});

        return points;
    }

    protected getSecondTabletopPath(calculateTabletop: TTabletopsCalculateData): TPoint2D[] {
        let points = [];

        points.push({x: 0, y: -calculateTabletop.second.width / 2});
        points.push({x: calculateTabletop.second.length / 2, y: calculateTabletop.second.width / 2});

        return points
    }

    protected getSecondTabletop(calculateTabletop: TTabletopsCalculateData): ISaveKUnitDetailData {
        return {
            id: 0,
            sizes: {
                length: calculateTabletop.second.length,
                width: calculateTabletop.second.width,
                height: calculateTabletop.second.height
            },
            leftPoints: this.getSecondTabletopPath(calculateTabletop),
            rightPoints: this.getSecondTabletopPath(calculateTabletop),
            position: {
                x: calculateTabletop.second.pointCenter.x,
                y: this.corpus.getHeight() / 2 + calculateTabletop.second.height * 1.5,
                z: calculateTabletop.second.pointCenter.y
            },
            rotation: {y: 0.25 * Math.PI},
            isDimensions: false,
        };
    }

    public getBackGap() {
        return this.getDepth() - this.corpus.getDepth();
    }

    protected calculateFacadePlinthLength() {
        let length;

        length = Math.sqrt(Math.pow(this.corpus.getWidth() - (this.corpus.getSideDepth() + this.corpus.getThickness()), 2) * 2);

        return length;
    }

    protected calculateFacadePlinthPosition(): TPoint3D {
        let catheter, hypotenuse, position: TPoint3D;

        hypotenuse = this.getWidth() - this.corpus.getSideDepth() - this.getBackGap();
        catheter = Math.sqrt(Math.pow(hypotenuse, 2) / 2);
        hypotenuse = catheter + this.service.getPlinthDepth() / 2;
        catheter = Math.sqrt(Math.pow(hypotenuse, 2) / 2);
        position = {
            x: this.corpus.getWidth() / 2 - catheter,
            y: -this.corpus.getHeight() / 2 - this.service.getPlinthHeight() / 2,
            z: this.corpus.getDepth() / 2 - catheter
        }

        return position;
    }

    protected calculatePlinthsData(plinths?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        let index;
        let length: number;

        if (plinths) {
            for (index in plinths) {
                switch (plinths[index].positionType) {
                    case SIDE_TYPE_LEFT:
                        break;
                    case SIDE_TYPE_RIGHT:
                        switch (this.getPlinthsType()) {
                            case UNIT_PLINTHS_TYPE_BACK_NEIGHBOR:
                                plinths[index].sizes = {length: this.corpus.getSideDepth() + this.getBackGap()};
                                break;
                            case UNIT_PLINTHS_TYPE_DEFAULT:
                                plinths[index].sizes = {length: this.corpus.getSideDepth()};
                                break;
                        }
                        break;
                    case SIDE_TYPE_FRONT:
                        switch (this.getPlinthsType()) {
                            case UNIT_PLINTHS_TYPE_BACK_NEIGHBOR:
                                length = this.corpus.getSideDepth() + this.getBackGap();
                                plinths[index].sizes = {length: length};
                                plinths[index].position = {x: -this.getWidth() / 2 - this.getBackGap() / 2 + length / 2};
                                break;
                            case UNIT_PLINTHS_TYPE_DEFAULT:
                                length = this.corpus.getSideDepth();
                                plinths[index].sizes = {length: length};
                                plinths[index].position = {x: -this.corpus.getWidth() / 2 + length / 2};
                                break;
                        }
                        break;
                    case SIDE_TYPE_NONE:
                        let initSizes: TDetailOptionalSizes | undefined;
                        let width: number | undefined;
                        if (plinths[index].initSizes !== undefined) {
                            initSizes = plinths[index].initSizes;
                        }
                        if (initSizes !== undefined) {
                            width = initSizes.width ? + initSizes.width : undefined;
                        }

                        plinths[index].sizes = {length: this.calculateFacadePlinthLength(), width: width};
                        plinths[index].position = this.calculateFacadePlinthPosition();

                        break;
                }
            }
        }

        return plinths;
    }

    protected calculateTabletopsData(): TTabletopsCalculateData {
        let result, tabletopDepth,
            pointA, pointB, offsetPoints, halfOffsetPoints, pointCenter;
        let backWidth: number, backDepth: number;
        let frontWidth: number, frontDepth: number;

        backWidth = -this.corpus.getWidth() / 2 - this.getBackGap();
        backDepth = -this.corpus.getDepth() / 2 - this.getBackGap();
        frontWidth = this.corpus.getWidth() / 2;
        frontDepth = this.corpus.getDepth() / 2;
        tabletopDepth = this.service.getTabletopWidth();
        result = {
            main: {
                height: this.service.getTabletopHeight(),
                length: Math.sqrt(Math.pow(this.getWidth(), 2) * 2),
                width: tabletopDepth,
                middleGap: Math.sqrt(Math.pow(this.getWidth(), 2) * 2) / 2,
                topGap: 0,
                bottomGap: Math.sqrt(Math.pow(this.getWidth(), 2) * 2) / 2,
                middleHeight: 0,
                pointCenter: {x: 0, y: 0}
            },
            second: {
                height: this.service.getTabletopHeight(),
                width: 0,
                length: 0,
                pointCenter: {x: 0, y: 0}
            }
        };
        pointA = {
            x: backWidth + tabletopDepth,
            y: frontDepth
        };
        pointB = {
            x: frontWidth,
            y: backDepth + tabletopDepth
        };
        offsetPoints = MathHelper.getParallelLinePoints(pointA, pointB, tabletopDepth);
        halfOffsetPoints = MathHelper.getParallelLinePoints(pointA, pointB, tabletopDepth / 2);
        result.main.topGap = MathHelper.getLength2D(pointA, pointB) / 2;
        pointA = MathHelper.getIntersectionPoint(
            {
                pointA: {x: backWidth, y: backDepth},
                pointB: {x: frontWidth, y: frontDepth}
            },
            offsetPoints
        );
        pointB = MathHelper.getIntersectionPoint(
            {
                pointA: {x: backWidth, y: backDepth},
                pointB: {x: backWidth, y: frontDepth}
            },
            offsetPoints
        );
        if (pointA && pointB) {
            result.main.bottomGap = MathHelper.getLength2D(pointA, pointB);
            result.second.width = MathHelper.getLength2D(pointA, {x: backWidth, y: backDepth});
            result.second.pointCenter = MathHelper.getPointByRatio2D(
                pointA,
                {x: backWidth, y: backDepth},
                0.5,
            );
        }
        pointA = MathHelper.getIntersectionPoint(
            {
                pointA: {x: backWidth, y: backDepth},
                pointB: {x: frontWidth, y: backDepth}
            },
            offsetPoints
        );
        pointB = MathHelper.getIntersectionPoint(
            {
                pointA: {x: backWidth, y: backDepth},
                pointB: {x: backWidth, y: frontDepth}
            },
            offsetPoints
        );
        if (pointA && pointB) {
            result.second.length = MathHelper.getLength2D(pointA, pointB);
            pointCenter = MathHelper.getIntersectionPoint(
                {
                    pointA: {x: backWidth, y: backDepth},
                    pointB: {x: frontWidth, y: frontDepth}
                },
                halfOffsetPoints
            );
            if (pointCenter) {
                result.main.pointCenter = pointCenter;
            }
        }
        pointA = MathHelper.getIntersectionPoint(
            {
                pointA: {x: backWidth, y: frontDepth},
                pointB: {x: frontWidth, y: backDepth}
            },
            {
                pointA: {x: backWidth, y: backDepth},
                pointB: {x: frontWidth, y: frontDepth}
            }
        );
        pointB = MathHelper.getIntersectionPoint(
            {
                pointA: {x: backWidth, y: backDepth},
                pointB: {x: frontWidth, y: frontDepth}
            },
            offsetPoints
        );
        if (pointA && pointB) {
            result.main.middleHeight = MathHelper.getLength2D(pointA, pointB);
        }

        return result;
    }

    protected createFacades() {
        super.createFacades();
        let facade: ThreeFacade;
        let catheter, hypotenuse;

        for (facade of this.facades) {
            if (facade.getCellName() !== FACADE_CELL_DEFAULT) {
                continue;
            }
            hypotenuse = this.corpus.getWidth() - this.corpus.getBackThickness() - this.corpus.getSideDepth() - 2;
            catheter = Math.sqrt(Math.pow(hypotenuse, 2) / 2);
            hypotenuse = catheter - this.corpus.getThickness() / 2;
            catheter = Math.sqrt(Math.pow(hypotenuse, 2) / 2) + this.corpus.getThickness();
            if (facade.saveData.margin) {
                facade.setPosition(new Vector3(this.corpus.getWidth() / 2 - catheter, facade.saveData.margin.y, this.corpus.getDepth() / 2 - catheter));
            } else {
                facade.setPosition(new Vector3(this.corpus.getWidth() / 2 - catheter, 0, this.corpus.getDepth() / 2 - catheter));
            }
            facade.setRotation(new Euler(0, 0.25 * Math.PI, 0));
        }
    }

}