import {KitchenService} from '../../../../services/KitchenService/KitchenService';
import {ThreeUnit} from '../../ThreeUnit/ThreeUnit';
import {Vector3} from 'three';
import {ISaveFacadeUnitData} from '../../../../../../common-code/interfaces/saveData/ISaveFacadeUnitData';
import {NONE_MATERIAL} from '../../../../../../common-code/constants';
import {ThreeFacade} from '../../details/ThreeFacade/ThreeFacade';

export class ThreeFacadeUnit extends ThreeUnit {
    saveData: ISaveFacadeUnitData;

    constructor(options: ISaveFacadeUnitData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.canVerticalMove = true;
    }

    public getCorpusMaterialId(): string | undefined {
        return NONE_MATERIAL;
    }

    public initState(isRebuild?: boolean) {
        this.addCoverPoints([
            new Vector3(-this.getWidth()/2, -this.getHeight()/2, -this.getDepth()/2),
            new Vector3(this.getWidth()/2, this.getHeight()/2, this.getDepth()/2)
        ]);
        super.initState(isRebuild);
    }

    protected createFacades() {
        super.createFacades();
        let facade: ThreeFacade;

        for (facade of this.facades) {
            facade.setDummyTransparent(false);
        }
    }
}