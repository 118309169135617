import {IClassNameProps} from '@bem-react/core';
import React, {ChangeEvent, useState} from 'react';
import './AccessoryPlankList.css';
import {ObjectSelectItem} from '../../components/ObjectSelectItem/ObjectSelectItem';
import {cn} from '@bem-react/classname';
import {ICornerData} from '../../../../common-code/interfaces/materials/ICornerData';
import {searchInMaterialData} from '../../../helpers/searchInMaterialData';
import {Textinput} from '@yandex/ui/Textinput/desktop/bundle';
import {Button} from '../../components/Button';
import {useTranslation} from 'react-i18next';
import {IFacadeMaterialData} from '../../../../common-code/interfaces/materials/IFacadeMaterialData';
import {isMaterialDisable} from '../../../helpers';

export const cnAccessoryPlankList = cn('AccessoryPlankList');

export interface IAccessoryPlankListProps extends IClassNameProps {
    listMaterialData?: ICornerData[];
    materialData: ICornerData;
    onSelectObject: (objectData: ICornerData) => void;
    title?: string;
    showObject?: boolean;
    facadeMaterials?: IFacadeMaterialData[];
}

export const AccessoryPlankList: React.FC<IAccessoryPlankListProps> =
    ({
         className,
         listMaterialData,
         materialData,
         onSelectObject,
         showObject,
         title,
         facadeMaterials,
     }) => {
        const {t} = useTranslation();
        const [searchText, setSearchText] = useState<string>('');
        const filterList: ICornerData[] | undefined = listMaterialData?.filter(materialData => searchInMaterialData(materialData, searchText));

        return (
            <div className={cnAccessoryPlankList({}, [className])}>
                <div className={cnAccessoryPlankList('Header')}>
                    {title ? <div className={cnAccessoryPlankList('Title')}>{title}</div> : null}
                    <div className={cnAccessoryPlankList('Search')}>
                        <Textinput
                            className={cnAccessoryPlankList('SearchText')}
                            placeholder={t('Поиск по назв., коду, описанию')}
                            value={searchText}
                            minLength={3}
                            debounceTimeout={300}
                            onInput={(value: ChangeEvent<HTMLInputElement>) => {
                                setSearchText(value.target.value);
                            }}
                        />
                        <Button className={cnAccessoryPlankList('SearchButton')}
                                type={'button'} mods={{}}
                                leftIcon={{path: 'search2', width: 20, height: 20, fill: 'lightBlack'}}
                                callBack={() => {
                                }}/>
                    </div>
                </div>
                <div className={cnAccessoryPlankList('Wrapper')}>
                    {!filterList || filterList.length <= 0 ?
                        <div className={cnAccessoryPlankList('Empty')}>{t('Не найдено ни одного объекта')}</div> :
                        filterList.map((material) => {
                            if (isMaterialDisable(material, facadeMaterials)) {
                                return null;
                            }
                            return (
                                <ObjectSelectItem
                                    key={material.id}
                                    className={cnAccessoryPlankList('Item')}
                                    title={material.title}
                                    image={material.image}
                                    color={material.color}
                                    active={material.id === materialData.id}
                                    disabled={!showObject}
                                    detailData={material.id === materialData.id ? materialData : material}
                                    callBack={() => {
                                        onSelectObject(material);
                                    }}
                                />
                            );
                        })}
                </div>
            </div>
        );
    }