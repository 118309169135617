import {ThreeBottomEquipment} from '../ThreeBottomEquipment';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {Box3, Object3D, Vector3} from 'three';
import {
    ALIGN_CENTER, ALIGN_TOP, FACADE_CALCULATE_SELF_AMOUNT, FACADE_FUNCTIONAL_TYPE_DISHWASHER,
    FACADE_MODEL_TYPE_DEFAULT,
    GEOMETRY_TYPE_SQUARE, HANDLE_TYPE_HORIZONTAL, LEVEL_BOTTOM, SIDE_TYPE_BACK,
    SIDE_TYPE_DEFAULT, SIDE_TYPE_FRONT, SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT
} from '../../../../../../../common-code/constants';
import {ThreeTabletop} from '../../../details/ThreeTabletop/ThreeTabletop';
import {TFacadeGap} from '../../../../../../../common-code/types/TFacadeGap';
import {
    ISaveDishwasherEquipmentData
} from '../../../../../../../common-code/interfaces/saveData/ISaveDishwasherEquipmentData';
import {COVER_CORRECTION_SIZE} from '../../../../../constants';
import {ISaveFacadeData} from '../../../../../../../common-code/interfaces/saveData/ISaveFacadeData';
import {ISaveHandleData} from '../../../../../../../common-code/interfaces/saveData/ISaveHandleData';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';

export class ThreeDishwasherEquipment extends ThreeBottomEquipment {
    saveData: ISaveDishwasherEquipmentData;

    constructor(options: ISaveDishwasherEquipmentData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
    }

    public initState(isRebuild?: boolean) {
        this.addCoverPoints([new Vector3(0, this.getHeight()/2 + this.service.getTabletopHeight(), 0)]);
        super.initState(isRebuild);
    }

    public getInitTabletopPosition(tabletop: ThreeTabletop): Vector3 {
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);
        return new Vector3(
            (coverBox.min.x + coverBox.max.x) / 2,
            coverBox.max.y - tabletop.getHeight()/2,
            this.getZInitTabletopPosition(tabletop)
        );
    }

    protected initSaveData(options: ISaveDishwasherEquipmentData): ISaveDishwasherEquipmentData {
        super.initSaveData(options);
        options.sizes.height = this.service.getBottomUnitHeight()+this.service.getBottomUnitLegsHeight()
        return options;
    }

    public getCorpusCoverBox(gap: number = COVER_CORRECTION_SIZE): Box3 {
        let boundBox: Box3;

        boundBox = new Box3().setFromPoints([
            new Vector3(-this.getWidth()/2, -this.getHeight()/2, -this.getDepth()/2),
            new Vector3(this.getWidth()/2, this.getHeight()/2 + this.service.getTabletopHeight(), this.getDepth()/2),
        ]);
        boundBox = this.addGapToBoundingBox(boundBox, gap);

        return boundBox;
    }

    public isCanHasFacade(): boolean {
        return this.service.isCanHasDishwasherFacade(
            this.getWidth() - this.getFacadeWidthGaps(),
            this.service.getFacadeMaterial(LEVEL_BOTTOM).id);
    }

    protected calculateFacadesData(facades?: ISaveFacadeData[]): ISaveFacadeData[] | undefined {
        if (this.saveData.hasFacade && this.isCanHasFacade()) {
            return [
                {
                    id: 0,
                    initSizes: {width: ''+this.getWidth(), height: ''+this.service.getBottomUnitHeight()},
                    gap: this.getFacadeGaps(),
                    geometryType: GEOMETRY_TYPE_SQUARE,
                    sideType: SIDE_TYPE_DEFAULT,
                    number: 0,
                    modelType: FACADE_MODEL_TYPE_DEFAULT,
                    align: {x: ALIGN_CENTER, y: ALIGN_TOP},
                    functionalType: FACADE_FUNCTIONAL_TYPE_DISHWASHER,
                    calculateType: FACADE_CALCULATE_SELF_AMOUNT,
                    handle: this.getFacadeHandle()
                },
            ];
        } else {
            return undefined;
        }
    }

    protected getFacadeWidthGaps(): number {
        let gaps: TFacadeGap;
        let value: number = 0;

        gaps = this.getFacadeGaps();
        if (gaps.left !== undefined) {
            value += gaps.left;
        }
        if (gaps.right !== undefined) {
            value += gaps.right;
        }

        return value;
    }

    protected getFacadeGaps(): TFacadeGap {
        if (this.saveData.facadeGaps) {
            return this.saveData.facadeGaps;
        }
        return {bottom: 2, left: 2, right: 2, top: 2};
    }

    protected getFacadeHandle(): ISaveHandleData | undefined {
        return {
            id: 0,
            align: {x: ALIGN_CENTER, y: ALIGN_TOP},
            margin: {x: 0, y: 17, z: 0},
            location: HANDLE_TYPE_HORIZONTAL,
        };
    }

    protected initTabletopsData(tabletops?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        if (this.saveData.hasTabletop) {
            return [tabletops && tabletops[0] ? tabletops[0] : {id: 0}];
        } else {
            return undefined;
        }
    }

    protected calculatePlinthsData(plinths?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        // use z position for plinths to avoid z-fighting
        if (this.saveData.hasPlinths) {
         return [
             plinths && plinths[0] ? plinths[0] : {
                 id: 0,
                 positionType: SIDE_TYPE_FRONT,
                 initPosition: {y: '=(-{%50} + 50)',z: '=({%50} + 0.001)'}
             },
         ];
        } else {
            return undefined;
        }
    }

    public setLoadModel(type: string, details: Object3D[]) {
        super.setLoadModel(type, details);
        let child: Object3D;

        if (this.saveData.hasTabletop) {
            for (child of this.threeModel.children) {
                if (child.name === 'tabletop') {
                    child.visible = false;
                }
            }
        }
    }

    protected initApronsData(aprons?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        if (this.saveData.hasAprons) {
            return [
                aprons && aprons[0] ? aprons[0] : {
                    id: 0,
                    positionType: SIDE_TYPE_BACK,
                },
                aprons && aprons[1] ? aprons[1] : {
                    id: 0,
                    positionType: SIDE_TYPE_LEFT,
                },
                aprons && aprons[2] ? aprons[2] : {
                    id: 0,
                    positionType: SIDE_TYPE_RIGHT,
                }
            ];
        } else {
            return undefined;
        }
    }

    protected initCornersData(corners?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        if (this.saveData.hasCorners) {
            return [
                corners && corners[0] ? corners[0] : {
                    id: 0,
                    positionType: SIDE_TYPE_BACK,
                },
                corners && corners[1] ? corners[1] : {
                    id: 0,
                    positionType: SIDE_TYPE_LEFT,
                },
                corners && corners[2] ? corners[2] : {
                    id: 0,
                    positionType: SIDE_TYPE_RIGHT,
                }
            ];
        } else {
            return undefined;
        }
    }
}