import React from "react";
import { IClassNameProps } from "@bem-react/core";
import { useTranslation } from "react-i18next";
import { Button, cnButton } from "../../../components/Button";
import { cn } from "@bem-react/classname";
import { IMaterialData } from "common-code/lib/interfaces/materials/IMaterialData";
import { IEditMode } from "../CurrentStep/WizardEditCurrent";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import FormFacadeMaterial, { INewFacadeMaterialData } from "../FormFacadeMaterial/FormFacadeMaterial";
import { IFacadeMaterialData } from "common-code/lib/interfaces/materials/IFacadeMaterialData";
import { IMaterialMode } from "../WizardCollection/WizardCollection";
import { 
  CHANGE_EDIT_MODE,
   HIDE_LOADING, INIT_EDIT_MATERIAL, SHOW_LOADING } from "../../../../constants";
import { useDispatch } from "react-redux";
import "./EditFacadeMaterials.css";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";

interface IEditFacadeMaterialsProps extends IClassNameProps {
  service: KitchenService;
  saveFacadeMaterial: (facadeListNew: IMaterialData[], facadeMode: IMaterialMode) => Promise<void>;
  saveImagePreview: (imageNew: File, facadeId: string, materialMode: IMaterialMode) => Promise<string | null>; 
}

const cnEditFacadeMaterials = cn("EditFacadeMaterials");

function EditFacadeMaterials({
  saveFacadeMaterial,
  saveImagePreview,
  service,
}: IEditFacadeMaterialsProps) {

  const initialValue = useSelector((state: AppState) => state.wizard.editMaterial.itemFacadeMaterial);
  const [itemFacadeMaterial, setItemFacadeMaterial] = React.useState< INewFacadeMaterialData | null>(initialValue ||null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const facadeMaterials = useSelector(
    (state: AppState) => state.wizard.facadeMaterials
  );

  const handleToggleMode = (str: IEditMode) => {
    dispatch({ type: CHANGE_EDIT_MODE, payload: str});
    dispatch({ type: INIT_EDIT_MATERIAL });
  };

  const createFacadeMaterial = (facadeMaterial: IFacadeMaterialData) => {
    service.addFacadeTextureObject(facadeMaterial);
    setItemFacadeMaterial(facadeMaterial);
  };


  const saveFacadeMaterialsCollection = async (itemFacadeMaterial: INewFacadeMaterialData) => {
    // проверяем корректность заполнения формы
    if (!itemFacadeMaterial) {
      window.alert("Необходимо корректно заполнить форму.");
      return;
    }
    // если такое название уже есть
    if (
      facadeMaterials.length > 0 &&
      facadeMaterials.find((material: IFacadeMaterialData) => material.id === itemFacadeMaterial.id)
    ) {
      window.alert(
        "Материал фасада с таким наменованием уже существует в коллекции. Укажите другое название."
      );
      return;
    }
    dispatch({ type: SHOW_LOADING });

    // сохраняем preview материала
    if (itemFacadeMaterial.imageNew) {
      const imageUrl = await saveImagePreview(
        itemFacadeMaterial.imageNew,
        itemFacadeMaterial.id,
        "facadeMaterials"
      );
      if (!imageUrl) {
        window.alert("Ошибка при сохранении превью. Попробуйте еще раз.");
        dispatch({ type: HIDE_LOADING });
        return;
      }
      itemFacadeMaterial.image = imageUrl;
      // удаляем клуч imageNew
      delete itemFacadeMaterial.imageNew;
    }

    // сохраняем материал фасада
    const facadeMaterialsListNew = [...facadeMaterials, itemFacadeMaterial];
    saveFacadeMaterial(facadeMaterialsListNew, "facadeMaterials").then(() => {
      handleToggleMode("");
      dispatch({ type: HIDE_LOADING });
    });
  };
  


  const editFacadeMaterialsCollection = async (itemFacadeMaterial: INewFacadeMaterialData) => {
  // проверяем корректность заполнения формы
  if (!itemFacadeMaterial) {
    window.alert("Необходимо корректно заполнить форму.");
    return;
  }
  // если такое название уже есть
  if (
    facadeMaterials.length > 0 &&
    !facadeMaterials.find((facadeMaterial: IFacadeMaterialData) => facadeMaterial.id === itemFacadeMaterial.id)
  ) {
    window.alert(
      "Фасад с таким наменованием нет. Укажите другое название."
    );
    return;
  }
  dispatch({ type: SHOW_LOADING });

  // сохраняем preview материала
  if (itemFacadeMaterial.imageNew) {
    const imageUrl = await saveImagePreview(
      itemFacadeMaterial.imageNew,
      itemFacadeMaterial.id,
      "facadeMaterials"
    );
    if (!imageUrl) {
      window.alert("Ошибка при сохранении превью. Попробуйте еще раз.");
      dispatch({ type: HIDE_LOADING });
      return;
    }
    itemFacadeMaterial.image = imageUrl;
    // удаляем ключ imageNew
    delete itemFacadeMaterial.imageNew;
  }

  // сохраняем материал фасада
  const facadeMaterialsListNew = facadeMaterials.map((facadeMaterial: IFacadeMaterialData) => facadeMaterial.id === itemFacadeMaterial.id ? itemFacadeMaterial : facadeMaterial);
  saveFacadeMaterial(facadeMaterialsListNew, "facadeMaterials").then(() => {
    handleToggleMode("");
    dispatch({ type: HIDE_LOADING });
  })
  };

  return (
    <>
      <FormFacadeMaterial
        itemFacadeMaterial={itemFacadeMaterial}
        createFacadeMaterial={createFacadeMaterial}
      />

      <div className={cnEditFacadeMaterials("Buttons-Container")}>
        <Button
          className={cnEditFacadeMaterials(cnButton(), { type: "prev" })}
          leftIcon={{path: "arrow-left",width: 20,height: 20,marginRight: 8,}}
          text={t("Предыдущий шаг") || undefined}
          callBack={() => handleToggleMode("")}
          mods={{mods: {color: "black",bkg: "white",border: "black",fill: "black",},}}
          type={"button"}
        />
      </div>

      {itemFacadeMaterial && (
        <>
          <div className={cnEditFacadeMaterials("Button-Add")}>
            <Button
              className={cnEditFacadeMaterials(cnButton())}
              type={"button"}
              text={"Добавить новый материал фасада в коллекцию"}
              leftIcon={{ path: "plus2", width: 16, height: 16, marginRight: 8,}}
              mods={{mods: {bkg: "white",color: "lightGreen",border: "lightGreen",fill: "lightGreen",},
              hover: {mods: { bkg: "lightGreen", color: "white", fill: "white" },},
              }}
              callBack={() => saveFacadeMaterialsCollection(itemFacadeMaterial)}
            />
            <Button
              className={cnEditFacadeMaterials(cnButton())}
              type={"button"}
              text={"Заменить редактируемый материал фасада в коллекции"}
              leftIcon={undefined}
              mods={{mods: {bkg: "white",color: "lightGreen",border: "lightGreen",fill: "lightGreen",},
              hover: {  mods: { bkg: "lightGreen", color: "white", fill: "white" },},}}
              callBack={() => editFacadeMaterialsCollection(itemFacadeMaterial)}
            />
          </div>
        </>
      )}
    </>
  );
}

export default EditFacadeMaterials;
