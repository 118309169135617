import {
  TABLETOP_DEFAULT_BEVEL_SEGMENTS,
  TABLETOP_DEFAULT_BEVEL_SIZE,
  TABLETOP_DEFAULT_BEVEL_THICKNESS,
} from "../../../../constants";
import {
  EquirectangularReflectionMapping,
  Euler,
  ExtrudeGeometry,
  Mesh,
  MeshStandardMaterial,
  MirroredRepeatWrapping,
  Texture,
  TextureLoader,
  Vector2,
  Vector3,
} from "three";
import { TSideType } from "../../../../../../common-code/types/TSideType";
import { TPoint2D } from "../../../../../../common-code/types/TPoint2D";
import { TLine } from "../../../../../../common-code/types/TLine";
import {
  KDETAIL_PRICE_TYPE_COLLECTIONS,
  SIDE_TYPE_LEFT,
  SIDE_TYPE_RIGHT,
} from "../../../../../../common-code/constants";
import { ThreeKUnitDetail } from "../ThreeKUnitDetail/ThreeKUnitDetail";
import { ICoverMainPoints } from "../../../../interfaces/ICoverMainPoints";
import { IThreeKUnitDetail } from "../../../../interfaces/IThreeKUnitDetail";
import { ThreeUnit } from "../../ThreeUnit/ThreeUnit";
import { TPositionSideType } from "../../../../../../common-code/types/TPositionSideType";
import { ITabletopData } from "../../../../../../common-code/interfaces/materials/ITabletopData";
import { ISaveKUnitDetailData } from "../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData";
import { CommonHelper } from "common-code";
import { MathHelper } from "common-code";
import { ITabletopLinks } from "../../../../interfaces/ITabletopLinks";
import { IDetailSideNeighbors } from "../../../../interfaces/IDetailSideNeighbors";
import { IMaterialTextures } from "../../../../interfaces/IMaterialTextures";
import { ITextureData } from "../../../../../../common-code/interfaces/materials/ITextureData";
import colorMapTexture from "../../../../editors/ThreeEditor/texturex/Wood_023_basecolor.jpg";

export class ThreeTabletop
  extends ThreeKUnitDetail
  implements IThreeKUnitDetail
{
  materialData: ITabletopData;

  constructor(options: ISaveKUnitDetailData, unit: ThreeUnit) {
    super(options, unit);
    this.materialData = this.initMaterialData();
    if (
      this.saveData.sizes &&
      this.saveData.sizes.height &&
      this.materialData.height !== this.saveData.sizes.height
    ) {
      this.saveData.sizes.height = +this.materialData.height;
    }
  }

  public tryRebuildBody() {
    let shapePoints: Vector2[];

    shapePoints = this.calculateShapePoints();
    if (
      this.shapePoints &&
      CommonHelper.md5(shapePoints) !== CommonHelper.md5(this.shapePoints)
    ) {
      this.createShape(shapePoints);
      this.rebuildBody();
    }
  }

  public getGlobalMainPoints(cover: Mesh = this.cover): ICoverMainPoints {
    if (!this.correctLeftPoints || !this.correctRightPoints) {
      throw new Error("error-ThreeTabletop-getGlobalMainPoints");
    }
    let mainPoints: ICoverMainPoints;
    let coverId: string;

    coverId = CommonHelper.md5({
      id: cover.uuid,
      position:
        cover.name === "selectCover"
          ? this.getGlobalPosition().toArray()
          : cover.position.toArray(),
      rotation:
        cover.name === "selectCover"
          ? this.getGlobalRotation().toArray()
          : cover.rotation.toArray(),
      parentPosition: this.unit.getGlobalPosition().toArray(),
      parentRotation: this.unit.getGlobalRotation().toArray(),
    });
    if (this.globalMainPoints[coverId]) {
      return this.globalMainPoints[coverId];
    }
    this.view3d.updateMatrixWorld();
    mainPoints = {
      back: {
        pointA: new Vector3(
          this.correctLeftPoints[0].x,
          0,
          this.correctLeftPoints[0].y
        ).applyMatrix4(this.view3d.matrixWorld),
        pointB: new Vector3(
          this.correctRightPoints[0].x,
          0,
          this.correctRightPoints[0].y
        ).applyMatrix4(this.view3d.matrixWorld),
      },
      front: {
        pointA: new Vector3(
          this.correctLeftPoints[this.correctLeftPoints.length - 1].x,
          0,
          this.correctLeftPoints[this.correctLeftPoints.length - 1].y
        ).applyMatrix4(this.view3d.matrixWorld),
        pointB: new Vector3(
          this.correctRightPoints[this.correctRightPoints.length - 1].x,
          0,
          this.correctRightPoints[this.correctRightPoints.length - 1].y
        ).applyMatrix4(this.view3d.matrixWorld),
      },
      left: {
        pointA: new Vector3(
          this.correctLeftPoints[0].x,
          0,
          this.correctLeftPoints[0].y
        ).applyMatrix4(this.view3d.matrixWorld),
        pointB: new Vector3(
          this.correctLeftPoints[this.correctLeftPoints.length - 1].x,
          0,
          this.correctLeftPoints[this.correctLeftPoints.length - 1].y
        ).applyMatrix4(this.view3d.matrixWorld),
      },
      right: {
        pointA: new Vector3(
          this.correctRightPoints[0].x,
          0,
          this.correctRightPoints[0].y
        ).applyMatrix4(this.view3d.matrixWorld),
        pointB: new Vector3(
          this.correctRightPoints[this.correctRightPoints.length - 1].x,
          0,
          this.correctRightPoints[this.correctRightPoints.length - 1].y
        ).applyMatrix4(this.view3d.matrixWorld),
      },
      top: {
        pointA: this.defaultMainPoint,
        pointB: this.defaultMainPoint,
      },
      bottom: {
        pointA: this.defaultMainPoint,
        pointB: this.defaultMainPoint,
      },
    };
    if (Object.keys(this.globalMainPoints).length > 30) {
      delete this.globalMainPoints[Object.keys(this.globalMainPoints)[0]];
    }
    this.globalMainPoints[coverId] = mainPoints;

    return mainPoints;
  }

  public getDefaultPositionByType(): Vector3 {
    return this.unit.getInitTabletopPosition(this);
  }

  public getDefaultPoints(type: TSideType): Vector2[] {
    let points: Vector2[];

    points = [
      new Vector2(0, -this.getWidth() / 2),
      new Vector2(0, this.getWidth() / 2),
    ];

    return points;
  }

  public initHeight(positionType?: TPositionSideType): number {
    return this.service.getTabletopHeight();
  }

  public initLength(positionType?: TPositionSideType): number {
    return this.unit.getWidth();
  }

  /**
   * Вычисляем ширину столешницы по-умолчанию
   * @protected
   */
  public initWidth(positionType?: TPositionSideType): number {
    return this.service.getTabletopWidth();
  }

  public getUnionYPosition(): number {
    return -this.getHeight() / 2;
  }

  public getMaterialId(): string {
    return this.materialData.id;
  }

  protected getOtherDetails(): ThreeKUnitDetail[] {
    return this.unit.tabletops || [];
  }

  protected loadTextures(): IMaterialTextures {
    let textures: ITextureData[];
    switch (this.materialData.type) {
      case KDETAIL_PRICE_TYPE_COLLECTIONS:
        if (this.materialData.offerTextures) {
          textures = Object.values(this.materialData.offerTextures)[0] || [];
        } else {
          textures = this.materialData.textures || [];
        }
        break;
      default:
        textures = this.materialData.textures || [];
        break;
    }

    return this.service.loadMaterialTextures(this.materialData.id, textures);
  }

  protected initMaterialData(): ITabletopData {
    let material: ITabletopData;
    let height: number | undefined;

    height =
      this.saveData.sizes && this.saveData.sizes.height
        ? this.saveData.sizes.height
        : undefined;
    material = CommonHelper.deepCopy(
      this.service.getTabletopMaterial(this.saveData.material)
    );
    if (height && material.heights && material.heights.includes(height)) {
      material.height = height;
    }

    return material;
  }

  protected initPriceType() {
    if (
      this.saveData.priceType === undefined &&
      this.service.appConfig.catalog.tabletops !== undefined
    ) {
      this.saveData.priceType =
        this.service.appConfig.catalog.tabletops.priceType;
    }
  }

  protected getNeighborLinks(): ITabletopLinks {
    let side: TSideType;
    let index: string;
    let index2: string;
    let tabletopLinks: ITabletopLinks = {};
    let detailSideNeighbors: IDetailSideNeighbors | undefined;

    for (side in this.neighbors) {
      if (this.neighbors[side]) {
        detailSideNeighbors = this.neighbors[side];
        if (!detailSideNeighbors) {
          continue;
        }
        for (index in detailSideNeighbors) {
          if (!tabletopLinks[side + index]) {
            tabletopLinks[side + index] = [];
          }
          for (index2 in detailSideNeighbors[index].neighbors) {
            tabletopLinks[side + index].push(
              detailSideNeighbors[index].neighbors[index2]
            );
          }
        }
      }
    }

    return tabletopLinks;
  }

  protected calculateShapePoints(): Vector2[] {
    let shapePoints: Vector2[] = [];
    let uniquePoints: { [key: string]: boolean } = {};
    let index: number;
    let point: TPoint2D;
    let firstPoint: TPoint2D;
    let prevPoint;
    let nextPoint;
    // let neighborLinks: ITabletopLinks;

    if (!this.correctLeftPoints || !this.correctRightPoints) {
      throw new Error("error-ThreeTabletop-calculateShapePoints");
    }
    // neighborLinks = this.getNeighborLinks();
    prevPoint =
      this.correctRightPoints[0].x.toFixed(3) ===
        this.correctLeftPoints[0].x.toFixed(3) &&
      this.correctRightPoints[0].y.toFixed(3) ===
        this.correctLeftPoints[0].y.toFixed(3)
        ? this.correctRightPoints[1]
        : this.correctRightPoints[0];
    firstPoint = this.getPointWithBevel(
      prevPoint,
      this.correctLeftPoints[0],
      this.correctLeftPoints[1],
      SIDE_TYPE_LEFT
    );
    shapePoints.push(new Vector2(firstPoint.x, firstPoint.y));
    uniquePoints[firstPoint.x + "_" + firstPoint.y] = true;
    for (index = 1; index < this.correctLeftPoints.length - 1; index++) {
      point = this.getPointWithBevel(
        this.correctLeftPoints[index - 1],
        this.correctLeftPoints[index],
        this.correctLeftPoints[index + 1],
        SIDE_TYPE_LEFT
      );
      if (!uniquePoints[point.x + "_" + point.y]) {
        shapePoints.push(new Vector2(point.x, point.y));
        uniquePoints[point.x + "_" + point.y] = true;
      }
    }
    if (this.correctLeftPoints.length > 1) {
      nextPoint =
        this.correctRightPoints[this.correctRightPoints.length - 1].x.toFixed(
          3
        ) === this.correctLeftPoints[0].x.toFixed(3) &&
        this.correctRightPoints[this.correctRightPoints.length - 1].y.toFixed(
          3
        ) === this.correctLeftPoints[0].y.toFixed(3)
          ? this.correctRightPoints[this.correctRightPoints.length - 2]
          : this.correctRightPoints[this.correctRightPoints.length - 1];
      point = this.getPointWithBevel(
        this.correctLeftPoints[this.correctLeftPoints.length - 2],
        this.correctLeftPoints[this.correctLeftPoints.length - 1],
        nextPoint,
        SIDE_TYPE_LEFT
      );
      if (!uniquePoints[point.x + "_" + point.y]) {
        shapePoints.push(new Vector2(point.x, point.y));
        uniquePoints[point.x + "_" + point.y] = true;
      }
    }
    prevPoint =
      this.correctRightPoints[this.correctRightPoints.length - 1].x.toFixed(
        3
      ) ===
        this.correctLeftPoints[this.correctLeftPoints.length - 1].x.toFixed(
          3
        ) &&
      this.correctRightPoints[this.correctRightPoints.length - 1].y.toFixed(
        3
      ) ===
        this.correctLeftPoints[this.correctLeftPoints.length - 1].y.toFixed(3)
        ? this.correctLeftPoints[this.correctLeftPoints.length - 2]
        : this.correctLeftPoints[this.correctLeftPoints.length - 1];
    point = this.getPointWithBevel(
      prevPoint,
      this.correctRightPoints[this.correctRightPoints.length - 1],
      this.correctRightPoints[this.correctRightPoints.length - 2],
      SIDE_TYPE_RIGHT
    );
    if (!uniquePoints[point.x + "_" + point.y]) {
      shapePoints.push(new Vector2(point.x, point.y));
      uniquePoints[point.x + "_" + point.y] = true;
    }
    for (index = this.correctRightPoints.length - 2; index > 0; index--) {
      point = this.getPointWithBevel(
        this.correctRightPoints[index + 1],
        this.correctRightPoints[index],
        this.correctRightPoints[index - 1],
        SIDE_TYPE_RIGHT
      );
      if (!uniquePoints[point.x + "_" + point.y]) {
        shapePoints.push(new Vector2(point.x, point.y));
        uniquePoints[point.x + "_" + point.y] = true;
      }
    }
    if (this.correctRightPoints.length > 1) {
      nextPoint =
        this.correctRightPoints[0].x.toFixed(3) ===
          this.correctLeftPoints[0].x.toFixed(3) &&
        this.correctRightPoints[0].y.toFixed(3) ===
          this.correctLeftPoints[0].y.toFixed(3)
          ? this.correctLeftPoints[1]
          : this.correctLeftPoints[0];
      point = this.getPointWithBevel(
        this.correctRightPoints[1],
        this.correctRightPoints[0],
        nextPoint,
        SIDE_TYPE_RIGHT
      );
      if (!uniquePoints[point.x + "_" + point.y]) {
        shapePoints.push(new Vector2(point.x, point.y));
        uniquePoints[point.x + "_" + point.y] = true;
      }
    }
    shapePoints.push(new Vector2(firstPoint.x, firstPoint.y));

    return shapePoints;
  }

  protected getPointWithBevel(
    prevPoint: Vector2 | undefined,
    point: Vector2,
    nextPoint: Vector2 | undefined,
    sideType: TSideType
  ) {
    let prevLine: TLine;
    let line: TLine;
    let resultPoint: TPoint2D | undefined;

    if (!prevPoint) {
      prevPoint = new Vector2(
        sideType === SIDE_TYPE_LEFT ? 10000 : -10000,
        point.y
      );
    }
    if (!nextPoint) {
      nextPoint = new Vector2(
        sideType === SIDE_TYPE_LEFT ? 10000 : -10000,
        point.y
      );
    }
    prevLine = MathHelper.getParallelLinePoints(
      { x: prevPoint.x, y: prevPoint.y },
      {
        x: point.x,
        y: point.y,
      },
      TABLETOP_DEFAULT_BEVEL_SIZE
    );
    line = MathHelper.getParallelLinePoints(
      { x: point.x, y: point.y },
      {
        x: nextPoint.x,
        y: nextPoint.y,
      },
      TABLETOP_DEFAULT_BEVEL_SIZE
    );
    if (prevLine && line) {
      resultPoint = MathHelper.getIntersectionPoint(prevLine, line);
    }
    if (!resultPoint) {
      resultPoint = {
        x: point.x,
        y: point.y,
      };
    }

    if (resultPoint.x > this.getLength() / 2 - TABLETOP_DEFAULT_BEVEL_SIZE) {
      resultPoint.x = this.getLength() / 2 - TABLETOP_DEFAULT_BEVEL_SIZE;
    }
    if (resultPoint.x < -this.getLength() / 2 + TABLETOP_DEFAULT_BEVEL_SIZE) {
      resultPoint.x = -this.getLength() / 2 + TABLETOP_DEFAULT_BEVEL_SIZE;
    }
    if (resultPoint.y > this.getWidth() / 2 - TABLETOP_DEFAULT_BEVEL_SIZE) {
      resultPoint.y = this.getWidth() / 2 - TABLETOP_DEFAULT_BEVEL_SIZE;
    }
    if (resultPoint.y < -this.getWidth() / 2 + TABLETOP_DEFAULT_BEVEL_SIZE) {
      resultPoint.y = -this.getWidth() / 2 + TABLETOP_DEFAULT_BEVEL_SIZE;
    }

    return resultPoint;
  }

  public createBody() {
    const textureLoader = new TextureLoader();

    // custom texture
    // let map: Texture;
    // map = textureLoader.load(colorMapTexture);

    // custom preferences for the texture
    // map.repeat.x = 0.01;
    // map.repeat.y = 0.01;
    // map.wrapS = MirroredRepeatWrapping;
    // map.wrapT = MirroredRepeatWrapping;
    // map.mapping = EquirectangularReflectionMapping;
    // map.anisotropy = 100;

    let geometry;
    let extrudeSettings;

    extrudeSettings = {
      steps: 4,
      depth: this.getHeightShape(),
      bevelEnabled: true,
      bevelThickness: TABLETOP_DEFAULT_BEVEL_THICKNESS,
      bevelSize: TABLETOP_DEFAULT_BEVEL_SIZE,
      bevelSegments: TABLETOP_DEFAULT_BEVEL_SEGMENTS,
    };
    geometry = new ExtrudeGeometry(this.shape, extrudeSettings);
    geometry.center();

    this.body = new Mesh(geometry,this.getBodyMaterial() );

    // set material directly instead of getBodyMaterial()
    // this.body = new Mesh(
    //   geometry,
    //   new MeshStandardMaterial({
    //     map: map,
    //     roughness: 0.4,
    //     metalness: 0.2,
    //   })
    // );

    if (this.unit.saveData.className === "EquipmentWasher") {
      if (this.body.geometry.boundingBox) {
        // боковой отступ на вибрацию
        const sideGap = 50;
        const maxBoundingBoxX = this.body.geometry.boundingBox?.max.x;
        const minBoundingBoxX = this.body.geometry.boundingBox?.min.x;

        const boundingBoxSum = maxBoundingBoxX + Math.abs(minBoundingBoxX);
        const percent = 100;
        const result = sideGap / (boundingBoxSum / percent) / percent;
        this.body.scale.set(1 + result, 1, 1);
      }
    }

    this.body.rotation.x = 0.5 * Math.PI;
    this.body.updateMatrix();
    this.body.geometry.applyMatrix4(this.body.matrix);
    this.body.rotation.set(0, 0, 0);
    this.body.renderOrder = 2;
    this.body.userData.commonRenderOrder = 2;
    this.body.name = "tabletops";
    this.body.matrixAutoUpdate = false;
    this.body.updateMatrix();
    this.view3d.add(this.body);
    this.addCoverPoints(this.calculateMeshCoverPoints(this.body));
  }

  public getHeightShape(): number {
    return this.getHeight() - TABLETOP_DEFAULT_BEVEL_THICKNESS * 2;
  }

  public getBodyMaterial(): MeshStandardMaterial {
    return new MeshStandardMaterial({
      color: this.materialData.color || "#ffffff",
      map: this.materialTextures.texture || null,
      normalMap: this.materialTextures.normal || null,
      roughnessMap: this.materialTextures.roughness || null,
      envMapIntensity: 5,
    });
  }

  public getDefaultRotationByType(): Euler {
    return new Euler();
  }
}
