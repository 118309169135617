import React, { useState } from "react";
import { cnSettingsMenu } from "../SettingsMenu";
import { ISaveKUnitData } from "../../../../../common-code/interfaces/saveData/ISaveKUnitData";

interface MySliderProps {
  onSetRotation: (value: number) => void;
}

const ModulerRotationSlider: React.FC<MySliderProps> = ({ onSetRotation }) => {
  
  const [rotationValue, setRotationValue] = useState(0);
  // const [positionValue, setPositionValue] = useState(0);

  const handleChangeRotation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRotationValue(Number(e.target.value));
  };



  // const handleChangePosition = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setPositionValue(Number(e.target.value));
  // }




  React.useEffect(() => {
    onSetRotation(Number(rotationValue));
  }, [rotationValue]);

  // React.useEffect(() => {
  //   onSetPosition(Number(positionValue));
  // }, [positionValue]);




  return (
    <div>
      <div className={cnSettingsMenu('TumblerItem')}>
        <input
          type="range"
          min={-Math.PI}
          max={Math.PI + 0.004}
          step={Math.PI / 4}
          value={rotationValue}
          onChange={handleChangeRotation}
        />
        <p className={cnSettingsMenu("TumblerTitle")}>
          {((rotationValue * 180) / Math.PI).toFixed(2)}°
        </p>
      </div>



      {/* {data.equipments && data.equipments.length>0 && data.equipments.map((equipment, index) => (

        
        
        <div className={cnSettingsMenu('TumblerItem')} key={index}> 
          
          <input
          type="range"
          min={-data.sizes.width/2}
          max={data.sizes.width/2}
          value={positionValue}
          onChange={handleChangePosition}
          />
          <p className={cnSettingsMenu("TumblerTitle")}>
            {equipment.name} - {positionValue}мм
          </p>
        </div>
      ))} */}

    </div>
  );
};

export default ModulerRotationSlider;
