import React, { MutableRefObject } from "react";
import { IClassNameProps } from "@bem-react/core";
import { cn } from "@bem-react/classname";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import { ICreateObjectDomElements } from "../../../../interfaces/ICreateObjectDomElements";
import { ICreateGroup } from "../../../../../common-code/interfaces/createData/ICreateGroup";
import { ICreateObjectData } from "../../../../../common-code/interfaces/createData/ICreateObjectData";
import {
  cnModulesCatalog,
  ModulesCatalog,
} from "../../ModulesCatalog/ModulesCatalog";
import { TCreateGroup } from "common-code/lib/types/TCreateGroup";
import { IOptionHiddenText } from "../../../../../common-code/interfaces/option/IOptionHiddenText";
import { CLASSNAME_GROUPS as groupClassNames } from "common-code/lib/constants";
import { cnWizard } from "../../Wizard/Wizard";
import { Button, cnButton } from "../../../components/Button";
import { t } from "i18next";
import {
  cnWizardEditCurrent,
  IEditMode,
} from "../CurrentStep/WizardEditCurrent";
import useCurrentStep from "../../../../hooks/useCurrentStep";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import "./Wizard-Edit-Modules.css";
import { useDispatch } from "react-redux";
import { CHANGE_EDIT_MODE, CHANGE_ITEM_MODULE } from "../../../../constants";


export const cnWizardModules = cn("WizardModules");

export interface IWizardModulesProps extends IClassNameProps {
  service: KitchenService;
  createObjects: MutableRefObject<ICreateObjectDomElements>;
  modules: ICreateGroup[] | undefined;
  saveUnitList: (unitListNew: ICreateGroup[]) => void;
}

export const WizardEditModules: React.FC<IWizardModulesProps> = ({
  service,
  createObjects,
  modules,
  saveUnitList,
  className,
}) => {
  const dispatch = useDispatch();
  const {isEditMode} = useSelector((state: AppState) => state.wizard.editModule);
  const { currentStep } = useCurrentStep(service);

  //  редактирование модуля в коллекции
  const handleEditModule = (module: ICreateObjectData) => {
    dispatch({ type: CHANGE_EDIT_MODE, payload: "module"});
    dispatch({type: CHANGE_ITEM_MODULE , payload: module}); 
  };

  // удаление модуля из коллекции
  const handleDeleteModule = (module: ICreateObjectData) => {
    if (
      window.confirm(`Вы уверены, что хотите удалить модуль "${module.title}"?`)
    ) {
      const classNameValue = (
        module.options.find((el) => el.id === "className") as IOptionHiddenText
      )?.value;
      const group = (groupClassNames as { [key: string]: string })[
        classNameValue
      ] as TCreateGroup;

      const unitListNew = modules?.map((unit) =>
        unit.id === (group as TCreateGroup)
          ? {
              ...unit,
              items: unit.items.filter((item) => item.uid !== module.uid),
            }
          : unit
      );
      saveUnitList(unitListNew as ICreateGroup[]);
    }
  };

  const onCreateObject = (
    objectData: ICreateObjectData,
    action: string | null
  ) => {
    if (action === "delete") {
      handleDeleteModule(objectData);
    } else {
      handleEditModule(objectData);
    }
  };

  const handleToggleModeModule = (str: IEditMode) => {
    dispatch({ type: CHANGE_EDIT_MODE, payload: str});
    dispatch({type: CHANGE_ITEM_MODULE , payload: null}); 
  };
  const type = "editModule";

  const tryExportModules = (saveImages: boolean) => {
    dispatch({ type: CHANGE_EDIT_MODE, payload: "module"});
    service.createModulesFeed(saveImages, 'WizardEdit').then(() => {
      debugger;
      // dispatch({type: HIDE_LOADING});
      // showMessage({type: MESSAGE_TYPE_SUCCESS, message: t('Формирование фида модулей авершено успешно')}, dispatch);
    }).catch(() => {
      debugger;
      // showMessage({type: MESSAGE_TYPE_ERROR, message: t('Ошибка на стороен сервера')}, dispatch);
      // dispatch({type: HIDE_LOADING});
    })
  }
  
  return (
    <>
      <div
        className={cnWizardEditCurrent({ step: currentStep.id }, [className])}
        style={{ display: isEditMode ? "none" : "block" }}
      >
        <div className={cnWizardModules("Container")}>
          <div className={cnWizardModules("Content")}>
            {modules ? (
              <div className={cnWizardModules()}>
                <ModulesCatalog
                  key={"modules"}
                  hasFilter={true}
                  className={cnWizardModules(cnModulesCatalog())}
                  data={modules}
                  onCreateObject={onCreateObject}
                  service={service}
                  createObjectDomElements={createObjects}
                  title={"Модули коллекции для редактирования"}
                  type={type}
                />
              </div>
            ) : (
              <div className={cnWizard("CurrentStepError")}>
                {t("Загрузка каталога")}...
              </div>
            )}
          </div>

          <div className={cnWizardModules("Buttons")}>
            <Button
              className={cnWizardModules(cnButton())}
              type={"button"}
              text={"Добавить модуль"}
              leftIcon={{ path: "plus2", width: 16, height: 16, marginRight: 8, }}
              mods={{
                mods: { bkg: "white", color: "lightGreen", border: "lightGreen", fill: "lightGreen",},
                hover: { mods: { bkg: "lightGreen", color: "white", fill: "white" },},
              }}
              callBack={()=>handleToggleModeModule("module")}
            />
            <Button
                className={cnWizardModules(cnButton())}
                type={"button"}
                text={"Эксп. модулей с карт-ми"}
                leftIcon={{ path: "plus2", width: 16, height: 16, marginRight: 8, }}
                mods={{
                  mods: { bkg: "white", color: "lightGreen", border: "lightGreen", fill: "lightGreen",},
                  hover: { mods: { bkg: "lightGreen", color: "white", fill: "white" },},
                }}
                callBack={()=>tryExportModules(true)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
