import React, { useMemo, useState } from "react";
import { IClassNameProps } from "@bem-react/core";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffectDidMount } from "../../../../hooks/useEffectDidMount";
import { cn } from "@bem-react/classname";
import { ICollectionDto } from "../../../../../common-code/interfaces/ICollectionDto";
import { GalleryCarousel } from "../../../components/GalleryCarousel/GalleryCarousel";
import { TImageData } from "../../../../../common-code/types/TImageData";
import { CollectionDetailDescription } from "../../CollectionDetail/Description/CollectionDetail-Description";
import { IMaterialData } from "common-code/lib/interfaces/materials/IMaterialData";
import { IFacadeData } from "../../../../../common-code/interfaces/materials/IFacadeData";
import { IFacadeMaterialData } from "common-code/lib/interfaces/materials/IFacadeMaterialData";
import MaterialsList from "../MaterialsList/MaterialsList";
import {
  CHANGE_EDIT_MODE,
  CHANGE_ITEM_FACADE,
  CHANGE_ITEM_FACADE_MATERIAL,
  CHANGE_ITEM_MATERIAL,
  CHANGE_ITEM_PLINTH,
} from "../../../../constants";
import { useDispatch } from "react-redux";
import { IPlinthData } from "../../../../../common-code/interfaces/materials/IPlinthData";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import "./WizardCollection.css";

export const cnWizardEditCollection = cn("WizardEditCollection");

export type IMaterialMode =
  | "materials"
  | "corpusMaterials"
  | "facadeMaterials"
  | "facades"
  | "plinths";

export const materialsGroups: Record<IMaterialMode, string> = {
  materials: "Редактирование библиотеки материалов",
  corpusMaterials: "Редактирование материалов корпусов",
  facadeMaterials: "Редактирование материалов фасадов",
  facades: "Редактирование библиотеки фасадов",
  plinths: "Редактирование библиотеки цоколей",
};

export interface IWizardCollectionProps extends IClassNameProps {
  saveMaterial: (materialListNew: IMaterialData[], type: IMaterialMode) => void;
}

export const WizardCollection: React.FC<IWizardCollectionProps> = ({
  saveMaterial
}: IWizardCollectionProps) => {

  const location = useLocation();
  const dispatch = useDispatch();
  const isEditMode = useSelector(
    (state: AppState) => state.wizard.editModule.isEditMode
  );
  const materialsMode = useSelector(
    (state: AppState) => state.wizard.editMaterial.materialsMode
  );
  const [collection, setCollection] = useState<ICollectionDto | undefined>(
    undefined
  );

  useEffectDidMount(() => {
    axios
      .get(
        "/api/collection/" +
          location.search
            .split("&")
            .find((param) => param.includes("xmlId"))
            ?.split("=")[1]
      )
      .then((response) => {
        if (response.data) {
          setCollection(response.data);
        }
      })
      .catch(() => {});
  });
  const collectionImages: TImageData[] = useMemo(() => {
    return collection ? collection.images : [];
  }, [collection]);

  // редактирование материала коллекции
  const handleEditMaterial = (
    item: IMaterialData | IFacadeData | IFacadeMaterialData | IPlinthData,
    type: IMaterialMode
  ) => {
    dispatch({ type: CHANGE_EDIT_MODE, payload: type});  
    const actionType = () => {
      switch (type) {
        case "materials":
        case "corpusMaterials":
          return CHANGE_ITEM_MATERIAL;
        case "facadeMaterials":
          return CHANGE_ITEM_FACADE_MATERIAL;
        case "facades":
          return CHANGE_ITEM_FACADE;
        case "plinths":
          return CHANGE_ITEM_PLINTH;
      }
    };    
    dispatch({ type: actionType(), payload: item });
  };

  return (
    <>
      <div
        className={cnWizardEditCollection(materialsMode ? "Materials" : "")}
        style={{ display: isEditMode ? "none" : "block" }}
      >
        <div className={cnWizardEditCollection("Materials-Groups")}>
          {Object.entries(materialsGroups).map(([key, value]) => (
            <MaterialsList
              key={key}
              saveMaterial={saveMaterial}
              materialGroup={[key as IMaterialMode, value as string]}
              handleEditMaterial={handleEditMaterial}
            />
          ))}
        </div>
        {collection && !materialsMode && (
          <div className={cnWizardEditCollection("Container")}>
            <div className={cnWizardEditCollection("Slider")}>
              <GalleryCarousel images={collectionImages} />
            </div>
            <CollectionDetailDescription collection={collection} />
          </div>
        )}
      </div>
   
    </>
  );
};
