import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {ISaveRoomData} from '../../../../../common-code/interfaces/saveData/ISaveRoomData';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {TSizes} from '../../../../../common-code/types/geometry/TSizes';
import {useTranslation} from 'react-i18next';
import {cnRoomParams} from '../RoomParams';
import {RangeNumber} from '../../../components/RangeNumber/RangeNumber';
import {CommonHelper} from 'common-code';

export interface IRoomParamsSizesProps extends IClassNameProps {
    roomData: ISaveRoomData;
    service: KitchenService;
}

export const RoomParamsSizes: React.FC<IRoomParamsSizesProps> = ({
                                                                     roomData,
                                                                     service,
                                                                     className
                                                                 }) => {
    const {t} = useTranslation();

    const onChangeInput = (value: number, name?: string) => {
        let sizes: TSizes;
        sizes = {...roomData.sizes};
        if (!name) {
            return;
        }
        switch (name) {
            case 'length':
                sizes.length = value;
                break;
            case 'width':
                sizes.width = value;
                break;
            case 'height':
                sizes.height = value;
                break;
        }
        if (CommonHelper.md5(roomData.sizes) !== CommonHelper.md5(sizes)) {
            const newRoomData = service.calculateRoomData(roomData, sizes);
            service.setRoomData(newRoomData, true);
        }
    };

    return (
        <div className={cnRoomParams('Sizes', [className])}>
            <div className={cnRoomParams('SizeItem')}>
                <div className={cnRoomParams('Title')}>{t('Длина')}, {t('мм')}:</div>
                <div className={cnRoomParams('Input', {error: false})}>
                    <RangeNumber min={1000} max={10000} showSlider={false}
                                 value={roomData.sizes.length}
                                 step={5}
                                 callBack={onChangeInput} name="length" viewControl={true} className={'RoomSize'}/>
                </div>
            </div>
            <div className={cnRoomParams('SizeItem')}>
                <div className={cnRoomParams('Title')}>{t('Ширина')}, {t('мм')}:</div>
                <div className={cnRoomParams('Input', {error: false})}>
                    <RangeNumber min={1000} max={10000} showSlider={false}
                                 value={roomData.sizes.width}
                                 step={5}
                                 callBack={onChangeInput} name="width" viewControl={true} className={'RoomSize'}/>
                </div>
            </div>
            <div className={cnRoomParams('SizeItem')}>
                <div className={cnRoomParams('Title')}>{t('Высота')}, {t('мм')}:</div>
                <div className={cnRoomParams('Input', {error: false})}>
                    <RangeNumber min={2000} max={3800} showSlider={false}
                                 value={roomData.sizes.height}
                                 step={5}
                                 callBack={onChangeInput} name="height" viewControl={true} className={'RoomSize'}/>
                </div>
            </div>
        </div>
    );
};