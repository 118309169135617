import React, { useEffect } from 'react'
import { useFormContext } from "react-hook-form";
import Facades from "./elements/Facades/Facades";
import { CLASSNAME_GROUPS as groupClassNames, CLASSNAME_LEVELS as levelClassNames, LEVEL_TOP } from 'common-code/lib/constants';
import TableTops from "./elements/TableTops/TableTops";
import Values from "./components/Values/Values";
import { cn } from '@bem-react/classname';
import { Button } from '../../../components/Button';
import InputForm from './components/InputForm';
import Sizes from './elements/Sizes/Sizes';
import Corpus from './elements/Corpus/Corpus';
import SideTypes from './elements/SydeTypes/SideTypes';
import ClassName from './elements/ClassName/ClassName';
import { ICreateObjectData } from '../../../../../common-code/interfaces/createData/ICreateObjectData';
import { TLevel } from '../../../../../common-code/types/TLevel';
import CatalogCodes from './elements/CatalogCodes/CatalogCodes';
import { IOptionHiddenText } from '../../../../../common-code/interfaces/option/IOptionHiddenText';
import { IFormInput, initialValue } from './initialValue';
import { transliterate } from '../utils/transliterate';
import { getCorpus } from './elements/Corpus/getCorpus';
import { getSizes } from './elements/Sizes/getSize';
import { IClassNameProps } from '@bem-react/core';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/AppStore';
import { useDispatch } from 'react-redux';
import { CHANGE_ITEM_MODULE } from '../../../../constants';
import { IOptionGroup } from 'common-code/lib/interfaces/option/IOptionGroup';
import { IOptionRadioButton } from '../../../../../common-code/interfaces/option/IOptionRadioButton';
import "./FormModule.css";

export const cnCreateForm = cn('CreateForm');

export interface IItemCreateObjectData extends ICreateObjectData {
  group?: string;
  isEdit?: boolean;
  catalogCodeEdited?: boolean;
}

interface IFormModuleProps extends IClassNameProps {
  onOpenModal: () => void, 
  buttonRef: React.RefObject<HTMLButtonElement>
}

function FormModule({ 
      onOpenModal,
      buttonRef
    }: IFormModuleProps) {

      const { handleSubmit, formState: { errors, isSubmitting }} = useFormContext<IFormInput>();
      const dispatch = useDispatch();
      const itemModule: IItemCreateObjectData | null = useSelector((state: AppState) => state.wizard.editModule.itemModule);
      const [level, setLevel] = React.useState<TLevel>(itemModule ? itemModule.level : LEVEL_TOP);
      const [classNameValue, setClassNameValue] = React.useState<string>(itemModule ? 
        (itemModule.options.find((el) => el.id === "className") as IOptionHiddenText)?.value : "");




      // const {handleSubmit, formState: { errors, isSubmitting },  watch} = methods
      
      // React.useEffect(() => {   
      //   const subscription  = watch((value, { name, type }) => {          
      //    if(type === "change" 
      //    ){  handleSubmit(onSubmit)();}
      //   });   
      // return () => subscription.unsubscribe()    
      //   // eslint-disable-next-line
      // }, [watch]);


      
          
      // scroll to error
      const formRef = React.useRef<HTMLFormElement>(null);
      React.useEffect(() => {        
   
        if (Object.keys(errors).length > 0 && !isSubmitting) {          
          formRef?.current?.scrollIntoView({block: 'start', behavior: "smooth"});
        }
        // eslint-disable-next-line
      }, [isSubmitting]);
        
    
      const handleChangeClass = (e: React.ChangeEvent<HTMLSelectElement>)=> {  
        setClassNameValue( e.target.value);   
        setLevel((levelClassNames as  {[key: string] : string})[e.target.value] as TLevel);
      };

    
      const onSubmit =  (data: IFormInput) => {
        const { sideType, className, aprons, corners, plinths, legs, facades, tabletops,
           catalogCode, title, image, depthText, heightText, widthText, depth, height, width, isEdit, integratedHandles} = data;
           if(!catalogCode) return;
           const corpus = getCorpus(data);
           const sizes = getSizes(data);
        
        const dataJson : IItemCreateObjectData = {
          uid: transliterate(catalogCode as string),
          catalogCode: transliterate(catalogCode as string),
          level,
          title,
          depthText: `${ depthText ? depthText : depth} мм`,
          heightText: `${ heightText ? heightText : height} мм`,
          widthText: widthText ? 
          `${widthText} мм` 
          : 
          width && "value" in width ? `${width.value} мм` : `${(width as IOptionRadioButton).items[0]?.id} - ${(width as IOptionRadioButton).items.at(-1)?.id} мм` ,
          image: image,
          options :  [],
          
          // дополнительные поля которые потом удаляются
          notPrice: true,
          group: (groupClassNames as {[key: string] : string})[classNameValue],
          isEdit,
          catalogCodeEdited: isEdit && catalogCode !== initialValue(itemModule).catalogCode 
        };

        const arrBottom = [sizes, sideType, corpus, className, plinths, legs, facades, integratedHandles]  as IOptionGroup[];
        const arrTop = [sideType, corpus, className,  facades,  integratedHandles].filter((el) => el)  as IOptionGroup[];
        if(!classNameValue.includes("Penal")){
          arrBottom.push(...[aprons , corners, tabletops] as IOptionGroup[]);
        }

        dataJson.options = level === "bottom" ? arrBottom.filter((el) => el) : arrTop;

        console.log("dataJson", dataJson);
        dispatch({type: CHANGE_ITEM_MODULE , payload: dataJson}); 
        onOpenModal();
      };

      useEffect(() => {
        if(itemModule) {        
          dispatch({type: CHANGE_ITEM_MODULE , payload: {...itemModule, notPrice: true}}); 
          // onOpenModal(); 
        }
        // eslint-disable-next-line
      },[]);

  return (
      <div className={cnCreateForm("")} >
        <div className={cnCreateForm('Container')}>
      
            <form className={cnCreateForm('Content')} onSubmit={handleSubmit(onSubmit)} ref={formRef} >

              <ClassName handleChangeClass={handleChangeClass} classNameValue={classNameValue} />

                { classNameValue && 
                  <>
                    <InputForm  label="Наименование модуля" name="title" required={false} onFocus={undefined}/>
                    
                    <CatalogCodes level={level}/>

                    <Sizes />

                    <SideTypes />
                    
                    <Corpus/>     

                    { level === "bottom" && 
                      <>
                        {!classNameValue.includes("Penal") && 
                        <>
                          <TableTops />

                          <Values name={"Cтеновые панели"} keyValue={"aprons"}/>
                        
                          <Values name={"Уголок"} keyValue={"corners"}/>
                        </>}

                        <Values name={"Плинтус"} keyValue={"plinths"}/>
                      
                        <Values name={"Ножки"} keyValue={"legs"}/>
                      </>
                    }

                    <Facades />
                
                    <h6 className={cnCreateForm('Header')}> * Поля обязательные для заполнения</h6>
                    <Button
                          ref={buttonRef}
                          className={cnCreateForm('Button')}
                          type={'submit'}
                          text={`Подтвердить изменения`}
                          mods={{
                              mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                              hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                          }}
                          callBack={() => {}}
                      />
                  </>
                }
            </form>
        </div>
    </div>
  )
}

export default FormModule