import React, {ChangeEvent, useState} from 'react';
import {cnSettingsMenu, ISettingsMenuGroupProps} from "../SettingsMenu";
import {useTranslation} from 'react-i18next';
import {ISettingGroupGeneral} from '../../../../interfaces/settingData/ISettingGroupGeneral';
import {IOption} from '../../../../../common-code/interfaces/option/IOption';
import {Tumbler} from '../../../components/Tumbler/Tumbler';
import {ObjectOption} from '../../../components/ObjectOption/ObjectOption';
import {cnObjectOption} from '../../../components/ObjectOption';
import ModulerRotationSlider from './ModuleRotationSlider';
import { ISaveKUnitData } from '../../../../../common-code/interfaces/saveData/ISaveKUnitData';

export interface ISettingsMenuGeneralGroupProps extends ISettingsMenuGroupProps {
    generalData: ISettingGroupGeneral;
    onSetRotation: (value: number) => void;
}

export const SettingsMenuGeneral: React.FC<ISettingsMenuGeneralGroupProps> =
    ({className, data, onRebuildData, generalData, onSetRotation}) => {
        const {t} = useTranslation();

        const onRebuildOptionData = (option: IOption, fieldId: string, value: any) => {
            let changeFieldId: string | undefined;

            switch (fieldId) {
                case 'width':
                    changeFieldId = 'length';
                    break;
                case 'height':
                    changeFieldId = 'height';
                    break;
                case 'depth':
                    changeFieldId = 'width';
                    break;
                default:
                    changeFieldId = fieldId;
                    break;
            }
            onRebuildData('rebuildOtherOption', {option: option, fieldId: changeFieldId, value: value});
        };

        return (
            <div className={cnSettingsMenu('General', [className])}>
                <div className={cnSettingsMenu('Item', {id: 'IsDimensions'})}>
                    {generalData.isDimensions !== undefined && data.isDimensions !== undefined ?
                        <div className={cnSettingsMenu('TumblerItem')}>
                            <p className={cnSettingsMenu('TumblerTitle')}>{t('Учитывать габариты')}</p>
                            <Tumbler
                                className={cnSettingsMenu('TumblerValue')}
                                checked={data.isDimensions}
                                callBack={(event: ChangeEvent<HTMLInputElement>) => {
                                    onRebuildData('rebuildIsDimensions', event.target.checked);
                                }}
                            />
                        </div> : null}
                </div>
                <div className={cnSettingsMenu('Item', {id: 'IsStick'})}>
                    {generalData.isStick !== undefined && data.isStick !== undefined ?
                        <div className={cnSettingsMenu('TumblerItem')}>
                            <p className={cnSettingsMenu('TumblerTitle')}>{t('Прилипание к соседям')}</p>
                            <Tumbler
                                className={cnSettingsMenu('TumblerValue')}
                                checked={data.isStick}
                                callBack={(event: ChangeEvent<HTMLInputElement>) => {
                                    onRebuildData('rebuildIsStick', event.target.checked);
                                }}
                            />
                        </div> : null}
                </div>
                <div className={cnSettingsMenu('Item', {id: 'IsLevelStick'})}>
                    {generalData.isLevelStick !== undefined && data.isLevelStick !== undefined ?
                        <div className={cnSettingsMenu('TumblerItem')}>
                            <p className={cnSettingsMenu('TumblerTitle')}>{t('Прилипание между уровнями')}</p>
                            <Tumbler
                                className={cnSettingsMenu('TumblerValue')}
                                checked={data.isLevelStick}
                                callBack={(event: ChangeEvent<HTMLInputElement>) => {
                                    onRebuildData('rebuildIsLevelStick', event.target.checked);
                                }}
                            />
                        </div> : null}
                </div>
                <div className={cnSettingsMenu('Item', {id: 'IsWallStick'})}>
                    {generalData.isWallStick !== undefined && data.isWallStick !== undefined ?
                        <div className={cnSettingsMenu('TumblerItem')}>
                            <p className={cnSettingsMenu('TumblerTitle')}>{t('Прилипание к стенам')}</p>
                            <Tumbler
                                className={cnSettingsMenu('TumblerValue')}
                                checked={data.isWallStick}
                                callBack={(event: ChangeEvent<HTMLInputElement>) => {
                                    onRebuildData('rebuildIsWallStick', event.target.checked);
                                }}
                            />
                        </div> : null}
                </div>
                
                <ModulerRotationSlider onSetRotation={onSetRotation}/>
               
                {generalData.other.map((option) => {
                    return (<div className={cnSettingsMenu('Item', {id: option.id})} key={option.id}>
                        <ObjectOption
                            type={option.type}
                            option={option}
                            defaultValue={option.defaultValue}
                            value={option.value}
                            className={cnSettingsMenu(cnObjectOption())}
                            onChange={(fieldId: string, value: any) => {
                                onRebuildOptionData(option, fieldId, value);
                            }}
                        />
                    </div>);
                }) }
            </div>
        );
    }
