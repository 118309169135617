import React from "react";
import { Modal, cnModal } from "../../../../../components/Modal";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { cnCreateForm } from "../../FormModule";
import { Button } from "../../../../../components/Button";
import { Select } from "../../../../../components/Select/Select";
import { functionalTypeValues, ITableTop } from "./TableTops";

interface IModalTableTopsProps {
  modal: boolean;
  handleToggleModal: () => void;
  newTabletop: ITableTop;
  setNewTabletop: (newTabletop: ITableTop) => void;
  handleSubmit: () => void;
  keyValue: string;
  onFocus: () => void;
  error?: string;
}

function ModalTableTops({
  modal,
  newTabletop,
  setNewTabletop,
  handleToggleModal,
  handleSubmit,
  keyValue,
  onFocus,
  error,
}: IModalTableTopsProps) {

  const isEdit = keyValue.includes("edit");
  
  return (
    <Modal
      className={cnCreateForm(cnModal())}
      visible={modal}
      callBack={handleToggleModal}
      key={keyValue}
    >
      <h4 className={cnCreateForm("Header")}>
        {isEdit ? "Редактирование " : "Добавление"} столешницы
      </h4>

      <div className={cnCreateForm("Content")}>
        <div>
          <label htmlFor="length">Длина столешницы в мм</label>
          <Textinput
            className={cnCreateForm("Textinput")}
            value={newTabletop.initSizes?.length}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewTabletop(
                newTabletop.initSizes
                  ? {
                      ...newTabletop,
                      initSizes: {
                        ...newTabletop.initSizes,
                        length: Number(e.target.value),
                      },
                    }
                  : {
                      ...newTabletop,
                      initSizes: { length: Number(e.target.value) },
                    }
              )
            }
            name="length"
            onFocus={onFocus}
            type="number"
          />
        </div>

        <div>
          <label htmlFor="width">Глубина столешницы в мм</label>
          <Textinput
            className={cnCreateForm("Textinput")}
            value={newTabletop.initSizes?.width}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewTabletop(
                newTabletop.initSizes
                  ? {
                      ...newTabletop,
                      initSizes: {
                        ...newTabletop.initSizes,
                        width: Number(e.target.value),
                      },
                    }
                  : {
                      ...newTabletop,
                      initSizes: { width: Number(e.target.value) },
                    }
              )
            }
            name="width"
            onFocus={onFocus}
            type="number"
          />
        </div>

        <div>
          <label htmlFor="functionalType">Тип столешницы</label>
          <Select
            className={cnCreateForm("Select")}
            placeholder={"Выберите значение"}
            showPlaceholder={false}
            options={functionalTypeValues}
            value={newTabletop.functionalType ? newTabletop.functionalType : ""}
            callBack={(e) =>
              setNewTabletop(
                newTabletop.functionalType
                  ? { ...newTabletop, functionalType: e.target.value }
                  : { ...newTabletop, functionalType: e.target.value }
              )
            }
          />
        </div>
        {error && <p className="text-danger">{error}</p>}
      </div>

      <Button
        className={cnCreateForm("Button")}
        type={"button"}
        text={`${isEdit ? "Сохранить" : "Добавить"} столешницу`}
        leftIcon={{ path: "plus2", width: 16, height: 16, marginRight: 8 }}
        mods={{
          mods: {
            bkg: "white",
            color: "lightGreen",
            border: "lightGreen",
            fill: "lightGreen",
          },
          hover: { mods: { bkg: "lightGreen", color: "white", fill: "white" } },
        }}
        callBack={handleSubmit}
      />
    </Modal>
  );
}

export default ModalTableTops;
