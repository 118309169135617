import React from 'react';
import FacadeInitSizes from './FacadeComponents/FacadeInitSizes';
import FacadeMargins from './FacadeComponents/FacadeMargins';
import FacadeFor from './FacadeComponents/FacadeFor';
import FacadeGaps from './FacadeComponents/FacadeGaps';
import FacadeAligns from './FacadeComponents/FacadeAligns';
import Handle from './Handle';
import FacadeCalcSizes from './FacadeComponents/FacadeCalcSizes';
import { Button } from '../../../../../components/Button';
import { cnCreateForm } from '../../FormModule';
import { Modal, cnModal } from '../../../../../components/Modal';
import { Textinput } from '@yandex/ui/Textinput/desktop/bundle';
import { Tumbler } from '../../../../../components/Tumbler/Tumbler';
import { Select } from '../../../../../components/Select/Select';
import { editOption, editReverseOption } from '../../../utils/editOption';
import {  facadeModeles, geometryTypes, sideTypes } from '../../constants/constants';
import { FACADE_OPEN_TYPES as openTypes } from 'common-code/lib/constants';
import { IFacade } from './initialFacade';
import Hinges from './Hinges/Hinges';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../redux/AppStore';
import { useEffectDidMount } from '../../../../../../hooks/useEffectDidMount';
import { fetchOptions, saveOptions } from '../../../api/OptionsServices';
import { useDispatch } from 'react-redux';
import { CHANGE_FACADES_FUNCTION_TYPES } from '../../../../../../constants';
import AddOptions from '../../components/AddOptions';

interface ModalFacadesProps {
    handleSubmit: () => void,
    facadeNew: IFacade,
    setFacadeNew: (e:IFacade)=> void,
    handleTogleModal: () => void,
    keyValue: string,
}

function ModalFacades({
        handleSubmit,
        facadeNew,
        setFacadeNew,
        handleTogleModal,
        keyValue,
    } : ModalFacadesProps) {

    const dispatch = useDispatch();
    const {  geometryType, functionalType , sideType, modelType, isGroup, groupId, isParent, isChild, openType, 
        isFlipY, rotation, rotationX, rotationY, rotationZ, isCalcSizes, modal, error  } = facadeNew;

    const [funcTypes, setFuncTypes] = React.useState <Record<string, string>> (
        useSelector((state: AppState) => state.wizard.editModule?.facadesFuncTypes) || {}
    );
    // добавление функциональных типов фасадов
    const addFuncTypes = (funcTypesNew: Record<string, string>) => {
        setFuncTypes(funcTypesNew);
        saveOptions(funcTypesNew, "facade-function-types").then((res: Record<string, string> | undefined) => {
            if(res) dispatch({ type: CHANGE_FACADES_FUNCTION_TYPES, payload: res });
        });
    }

    // если типы фасадов еще не получены загружаем их с сервера
    useEffectDidMount(() => {
        if (Object.keys(funcTypes).length === 0) {
            fetchOptions("facade-function-types").then(
            (res: Record<string, string> | undefined) => {
              if (res) {
                setFuncTypes(res);
                dispatch({ type: CHANGE_FACADES_FUNCTION_TYPES, payload: res });
              }
            }
          );
        }
      });


    const onInputChange = (e:  React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        setFacadeNew({ ...facadeNew, [e.target.name]: e.target.value, error: "" });
    };
    
    const onChecked = (e:React.ChangeEvent<HTMLInputElement>) => {
        setFacadeNew({ ...facadeNew, [e.target.name]: e.target.checked, error: "" });
    }

    const isEdit = keyValue.includes('edit');
    const title = `${isEdit ? 'Редактирование' : 'Добавление'} фасада  ${isParent ? 'родителя ' : ''}${isGroup ? 'группы № ' + groupId  : 'одиночного'}`;


    return (
        <Modal
            className={cnCreateForm(cnModal())}
            visible={modal}
            callBack={handleTogleModal}
            key={keyValue}
        >

                <h4 className={cnCreateForm('Header')}>{title}</h4>

                <div className={cnCreateForm('Content')}>

                    {/* Поля ввода обязательные для заполнения */}

                    <FacadeInitSizes facadeNew={facadeNew} onInputChange={onInputChange} />

                    <div className={cnCreateForm('Box-Input')}>
                            <div>
                                <label htmlFor="sideType">Сторона открывания *</label>
                                    <Select
                                        className={cnCreateForm('Select')}
                                        placeholder={"Выберите значение"}
                                        showPlaceholder={false}
                                        options={editOption(sideTypes)}
                                        value={sideType}
                                        callBack={(e) => onInputChange({...e,target:{...e.target, name: 'sideType'}})}
                                    />
                            </div>
                            <div>
                                <label htmlFor="geometryType">Тип геометрии фасада *</label>
                                    <Select
                                        className={cnCreateForm('Select')}
                                        placeholder={"Выберите значение"}
                                        showPlaceholder={false}
                                        options={editOption(geometryTypes)}
                                        value={geometryType}
                                        callBack={(e) => onInputChange({...e,target:{...e.target, name: 'geometryType'}})}
                                    />
                            </div>
                            <div>
                                <label htmlFor="modelType">Тип модели фасада *</label>
                                    <Select
                                        className={cnCreateForm('Select')}
                                        placeholder={"Выберите значение"}
                                        showPlaceholder={false}
                                        options={editOption(facadeModeles)}
                                        value={modelType}
                                        callBack={(e) => onInputChange({...e,target:{...e.target, name: 'modelType'}})}
                                    />
                            </div>
                        </div>
              

                        {/* Поля ввода необязательные */}

                        {!isChild && 
                        <>
                            <Tumbler
                                className={cnCreateForm('Tumbler')}
                                checked={isCalcSizes}
                                callBack={(e) => onChecked(e)}
                                label="Указать расчетные размеры"
                                name="isCalcSizes"
                            />

                
                            {isCalcSizes && 
                                <FacadeCalcSizes 
                                    facadeNew={facadeNew} 
                                    onInputChange={onInputChange}
                                />
                            }
                        </>}

                        {(!isGroup || isParent) &&
                            <>
                                <h4 className={cnCreateForm('Title')}>Функциональный тип</h4>
                                <label htmlFor="functionalType">Небходимо выбрать функциональный тип фасада из списка</label>
                                <Select
                                    className={cnCreateForm('Select')}
                                    placeholder={"Выберите значение"}
                                    showPlaceholder={false}
                                    options={editReverseOption(funcTypes)}
                                    value={functionalType}
                                    callBack={(e) => onInputChange({...e,target:{...e.target, name: 'functionalType'}})}
                                />                  
                                <AddOptions
                                        options={funcTypes} 
                                        setOptions={addFuncTypes} 
                                    />
                            </>
                        }

                        <Handle facadeNew={facadeNew} onInputChange={onInputChange} onChecked={onChecked}/>
                        <FacadeGaps facadeNew={facadeNew} onInputChange={onInputChange} onChecked={onChecked}/>
                        <FacadeAligns facadeNew={facadeNew} onInputChange={onInputChange} onChecked={onChecked}/>
                        <FacadeMargins facadeNew={facadeNew} onInputChange={onInputChange} onChecked={onChecked} />

                        <div>
                            <label htmlFor="openType">Небходимо выбрать тип открывания фасада</label>
                                <Select
                                    className={cnCreateForm('Select')}
                                    placeholder={"Выберите значение"}
                                    showPlaceholder={false}
                                    options={editOption(openTypes)}
                                    value={openType as string}
                                    callBack={(e) => onInputChange({...e,target:{...e.target, name: 'openType'}})}
                                />
                        </div>   
                        
                        <FacadeFor facadeNew={facadeNew} onInputChange={onInputChange} onChecked={onChecked}/>

                        <Hinges setFacadeNew={setFacadeNew} facadeNew={facadeNew}/>

                        <Tumbler
                            className={cnCreateForm('Tumbler')}
                            checked={isFlipY}
                            callBack={(e) => onChecked(e)}
                            label="Перевернуть фрезировку фасада по вертикали"
                            name="isFlipY"
                        />

                        
            <Tumbler
                className={cnCreateForm('Tumbler')}
                checked={rotation as boolean}
                callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
                label="Изменить положение фасада в пространстве"
                name="rotation"
            />
            
            {rotation && 
                <>
                    <div>
                        <label htmlFor="rotationX">Указать вращение фасада по оси x (градусы от -90 до 90)</label>
                        <Textinput
                            className={cnCreateForm('Textinput')}
                            value={rotationX}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                            name="rotationX"
                            type="number"
                            maxLength={3}
                        />
                    </div>
                    <div>
                        <label htmlFor="rotationY">Указать вращение фасада по оси y (градусы от -90 до 90)</label>
                        <Textinput
                            className={cnCreateForm('Textinput')}
                            value={rotationY}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                            name="rotationY"
                            type="number"
                            maxLength={3}
                        />
                    </div>
                    <div>
                        <label htmlFor="rotationZ">Указать вращение фасада по оси z (градусы от -90 до 90)</label>
                        <Textinput
                            className={cnCreateForm('Textinput')}
                            value={rotationZ}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                            name="rotationZ"
                            type="number"
                            maxLength={3}
                        />
                    </div>
                </> }

             
                        {error && <p className={cnCreateForm('Error')}>{error}</p>}

                        <Button
                            className={cnCreateForm('Button')}
                            type={'button'}
                            text={`${isEdit ? 'Изменить' : 'Добавить'} фасад`}
                            leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                            mods={{
                                mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                                hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                            }}
                            callBack={handleSubmit}
                        />
                </div>

        </Modal>

  )
}

export default ModalFacades