import React, { useEffect, useState } from 'react'
import Facade from './Facade';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../../../components/Button';
import { cnCreateForm } from '../../FormModule';
import { OPTION_TYPE_JSON } from "common-code/lib/constants";
import { ISaveFacadeData } from 'common-code/lib/interfaces/saveData/ISaveFacadeData';
import { IOptionHiddenJson } from '../../../../../../../common-code/interfaces/option/IOptionHiddenJson';
import ModalFacades from './ModalFacades';
import {CLASSNAME_END_UNITS as arrEndUnits } from 'common-code/lib/constants';
import { IFacade, initionalFacade } from './initialFacade';
import { validateFacade } from './validateFacade';
import { createFacade } from './createFacade';
import { IOption } from 'common-code/lib/interfaces/option/IOption';
import { IFormInput } from '../../initialValue';

function Facades() {

    const { setValue, getValues} = useFormContext<IFormInput>();
    const classNameValue = getValues('className')?.value; 
    const IsEndUnit = arrEndUnits.includes(classNameValue);
    const fasadesInitional = getValues("facades") ? (getValues("facades") as IOption).value : [];

    const [objFacades, setObjFacades] = React.useState <Record<string,ISaveFacadeData[]>> ( 
        // массив фасадов в объект с ключами по groupId
        fasadesInitional.reduce((acc: Record<string,ISaveFacadeData[]>, item: ISaveFacadeData) => {
            const key = item.groupId ?`${item.groupId}`: "single";
            if(!acc[key]) {
                acc[key] = [item];
                return acc
            } 
            acc[key] = [...acc[key], item];
            return acc
        }, {})
    );
    // из объекта с фасадами обратно в массив
    const arrFacades = React.useMemo(() => Object.values(objFacades).flat(), [objFacades]);

    // размеры корпуса для валидации фасадов
    const maxDimensions = { 
        maxHeight: getValues('height') ? Number(getValues('height')) : 1000,
        maxWidth: getValues('defaultWidth') ? Number(getValues('defaultWidth')) : 1000,
        maxDepth: getValues('depth') ? Number(getValues('depth')) : 1000,
    };
    const facades: IOption = {
        id: "facades",
        type: OPTION_TYPE_JSON,
        title: "Фасады",
        sort: 0,
        value: arrFacades,
      } as IOptionHiddenJson;

    useEffect(() => {         
        setValue("facades", arrFacades.length > 0 ? facades : null);
        // eslint-disable-next-line
    }, [ facades]);

    const [facadeNew, setFacadeNew] = useState <IFacade> (initionalFacade());

    const handleSubmit =  () => {
        const error = validateFacade(facadeNew, maxDimensions);
        if (error) {
            setFacadeNew({ ...facadeNew, error});
            return;
        }

        const dataFacade = createFacade(facadeNew,IsEndUnit, null);
        const keyFacade = dataFacade.groupId ? dataFacade.groupId.toString() : "single";
        // добавляем фасад в объект с фасадами
        setObjFacades( 
            {...objFacades, [keyFacade]: objFacades.hasOwnProperty(keyFacade) ?  
                [...objFacades[keyFacade], dataFacade] : [dataFacade]}
        );
        setFacadeNew({ ...initionalFacade(), modal: false, error: ""});
    }
    const handleTogleModal = () => {        
        setFacadeNew({ ...facadeNew, modal: !facadeNew.modal, isGroup: false, isParent: false, isCalcSizes: false, isChild: false });
    }

    const openModalGroupParent = () => {
        // определяем существующий максимальный идентификатор
        const groupId = Object.keys(objFacades).length > 0 ? 
        Math.max.apply(null, Object.keys(objFacades).map((el) => Number(el) || 0)) : 0;

        setFacadeNew({ ...facadeNew, modal: !facadeNew.modal, isGroup: true, isCalcSizes: true,
            groupId: (groupId + 1).toString(), isParent: true });
    }

    const openModalGroupChild = (groupId: string, functionalType: string ) => {
        setFacadeNew({ ...facadeNew, modal: !facadeNew.modal, isGroup: true,
            isParent: false, isChild: true, groupId, functionalType });
    }

  return (
        <>
            <h3 className={cnCreateForm('Header')}>Фасады</h3>
            
            { Object.keys(objFacades).map((key, ind) =>
                <div key={ind} className={cnCreateForm('Facades')}>
                    { key === "single" ? 
                        <h4>Одиночные фасады</h4>
                        :
                        <h4>Группа фасадов № {key}</h4>
                    }    
                    { objFacades[key].map((facade, index) =>  
                            <Facade  
                                objFacades={objFacades} 
                                setObjFacades={setObjFacades}  
                                facade={facade} 
                                key={`${key}${index}${facade.initSizes.height}
                                ${facade.initSizes.width}${facade.align?.x}
                                ${facade.align?.y}`} 
                                index={index}
                                maxDimensions={maxDimensions}
                                IsEndUnit={IsEndUnit}
                            />
                        )
                    }
                    {key !== "single" &&  
                        <Button
                            className={cnCreateForm('Button')}
                            type={'button'}
                            text={`Добавить фасад в группу № ${key}`}
                            leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                            mods={{ mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                                    hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}}}
                            callBack={() => openModalGroupChild(key, objFacades[key][0].functionalType as string)}
                        />
                    }
                </div>
            )}
            <div className={cnCreateForm('Container-Btn')}>
                <Button
                    className={cnCreateForm('Button')}
                    type={'button'}
                    text={`Добавить фасад`}
                    leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                    mods={{ mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                            hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}}}
                    callBack={handleTogleModal}
                />

                <Button
                    className={cnCreateForm('Button')}
                    type={'button'}
                    text={`Добавить группу фасадов`}
                    leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                    mods={{ mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                            hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}}}
                    callBack={openModalGroupParent}
                />
            </div>
            <ModalFacades
                handleSubmit={handleSubmit}
                facadeNew={facadeNew}
                setFacadeNew={setFacadeNew}
                handleTogleModal={() =>setFacadeNew({ ...facadeNew, modal: !facadeNew.modal})}
                keyValue={"#addingFacades"}
            />
            <hr/>
        </>
  )
}

export default Facades
