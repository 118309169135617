import { EditorService } from "../EditorService/EditorService";
import { Dispatch } from "redux";
import { KitchenEditor } from "../../editors/KitchenEditor/KitchenEditor";
import { KitchenUserControls } from "../../userControls/KitchenUserControls/KitchenUserControls";
import { ThreeRoom } from "../../objects/threeD/rooms/ThreeRoom/ThreeRoom";
import { ThreeRoomPoint } from "../../points/ThreeRoomPoint/ThreeRoomPoint";
import { ThreeWall } from "../../objects/threeD/rooms/ThreeWall/ThreeWall";
import {
  Box3,
  Euler, Group,
  MeshBasicMaterial,
  Object3D,
  TextureLoader,
  Vector3,
} from "three";
import { DataManager } from "./managers/DataManager";
import { CacheManager } from "./managers/CacheManager";
import { PriceManager } from "./managers/PriceManager";
import { MainManager } from "./managers/MainManager";
import { HistoryManager } from "./managers/HistoryManager";
import { CommonObject } from "../../objects/CommonObject/CommonObject";
import {
  ACTION_COPY,
  ACTION_DELETE,
  ACTION_REPLACE,
  ACTION_SETTINGS,
  ACTION_SHOW_UNIT_SPEC,
  CHANGE_CHECK_STORE,
  CHANGE_KITCHEN_CHECK_ERRORS_OBJECTS,
  CHANGE_KITCHEN_ENABLE_AUTO_SERVICES,
  CHANGE_KITCHEN_ENABLE_SERVICES,
  CHANGE_KITCHEN_EURO_ZAPIL,
  CHANGE_KITCHEN_SHOW_APRONS,
  CHANGE_KITCHEN_SHOW_CORNERS,
  CHANGE_KITCHEN_SHOW_LEGS,
  CHANGE_KITCHEN_SHOW_PLINTHS,
  CHANGE_KITCHEN_SHOW_TABLETOPS,
  CHANGE_KITCHEN_SIZES_TYPE,
  CHANGE_KITCHEN_VIEW,
  CHANGE_PROJECT_DATA,
  CHANGE_REPLACE_DATA,
  CHANGE_UNIT_SPEC,
  CHANGE_WIZARD_INIT_DATA,
  HIDE_LOADING,
  KITCHEN_SIZES_TYPE_NONE,
  KITCHEN_VIEW_SKETCH,
  KITCHEN_VIEW_VISUAL,
  LOADED_PROJECT,
  MESSAGE_TYPE_WARNING,
  SHOW_LOADING,
  SHOW_SETTINGS_MENU,
} from "../../../constants";
import { RebuildManager } from "./managers/RebuildManager";
import { ThreeUnit } from "../../objects/threeD/ThreeUnit/ThreeUnit";
import { ITextureCache } from "../../interfaces/ITextureCache";
import { IMaterialTextures } from "../../interfaces/IMaterialTextures";
import { ThreeFacade } from "../../objects/threeD/details/ThreeFacade/ThreeFacade";
import { ThreePlinthUnion } from "../../objects/threeD/details/ThreePlinth/ThreePlinthUnion";
import { ThreeCornerUnion } from "../../objects/threeD/details/ThreeCorner/ThreeCornerUnion";
import { ThreeApronUnion } from "../../objects/threeD/details/ThreeApron/ThreeApronUnion";
import { ThreeTabletopUnion } from "../../objects/threeD/details/ThreeTabletop/ThreeTabletopUnion";
import { ThreeEquipment } from "../../objects/threeD/equipments/ThreeEquipment/ThreeEquipment";
import { TLevelBoxes } from "../../types/TLevelBoxes";
import { ThreeConstructive } from "../../objects/threeD/constructive/ThreeConstructive/ThreeConstructive";
import { DrawManager } from "./managers/DrawManager";
import { ThreeHandle } from "../../objects/threeD/details/ThreeHandle/ThreeHandle";
import {
  AUTO_SAVE_LOCAL,
  AUTO_SAVE_NONE,
  AUTO_SAVE_SERVER,
  CATALOG_CALCULATE_TYPE_DETAILS,
  CATALOG_CALCULATE_TYPE_MODULE,
  CLASSNAME_EQUIPMENT_BUILTIN_EXTRACT,
  CLASSNAME_EQUIPMENT_BUILTIN_SINK,
  CLASSNAME_EQUIPMENT_MOUNTED_EXTRACT,
  CLASSNAME_EQUIPMENT_SEPARATE_EXTRACT,
  CLASSNAME_EQUIPMENT_SEPARATE_SINK,
  DEFAULT_PROJECT_ID,
  ERROR_MODULES_SELECT,
  FACADE_CALCULATE_NONE,
  FACADE_CALCULATE_SELF_AMOUNT,
  FACADE_FOR_SECOND,
  FACADE_FUNCTIONAL_TYPE_DISHWASHER,
  FURNITURE_FT_HANDLE,
  FURNITURE_FT_HINGE,
  FURNITURE_TYPE_NONE,
  GOOD_TYPE_APRON,
  GOOD_TYPE_CORNER,
  GOOD_TYPE_CORNICE,
  GOOD_TYPE_CORPUS,
  GOOD_TYPE_FACADE,
  GOOD_TYPE_FLOOR,
  GOOD_TYPE_LEG,
  GOOD_TYPE_PLINTH,
  GOOD_TYPE_TABLETOP,
  GOOD_TYPE_WALL,
  GROUP_BIG_EQUIPMENTS,
  GROUP_BOTTOM_EQUIPMENTS,
  GROUP_BUILTIN_EQUIPMENTS,
  GROUP_EQUIPMENTS,
  GROUP_PORTABLE_EQUIPMENTS,
  GROUP_TOP_EQUIPMENTS,
  HANDLE_MODEL_TYPE_DEFAULT,
  LEVEL_BOTTOM,
  LEVEL_TOP,
  NONE_MATERIAL,
  PRICE_CELL_APRON,
  PRICE_CELL_CORNER,
  PRICE_CELL_CORNICE,
  PRICE_CELL_CORPUS,
  PRICE_CELL_EXTRA_OFFERS,
  PRICE_CELL_FACADE,
  PRICE_CELL_FURNITURE,
  PRICE_CELL_HANDLE,
  PRICE_CELL_HINGES,
  PRICE_CELL_MODULE,
  PRICE_CELL_NONE,
  PRICE_CELL_PLINTH,
  PRICE_CELL_TABLETOP,
  SIDE_TYPE_BACK,
  SIDE_TYPE_BOTTOM,
  SIDE_TYPE_DEFAULT,
  SIDE_TYPE_FRONT,
  SIDE_TYPE_LEFT,
  SIDE_TYPE_RIGHT,
  SIDE_TYPE_TOP,
} from "../../../../common-code/constants";
import { ThreeApron } from "../../objects/threeD/details/ThreeApron/ThreeApron";
import { ThreeTabletop } from "../../objects/threeD/details/ThreeTabletop/ThreeTabletop";
import { ThreeCorner } from "../../objects/threeD/details/ThreeCorner/ThreeCorner";
import { ThreePlinth } from "../../objects/threeD/details/ThreePlinth/ThreePlinth";
import { RebuildUnitManager } from "./managers/RebuildUnitManager";
import { TCanUnionDetail } from "../../types/TCanUnionDetail";
import {
  DETAIL_APRONS,
  DETAIL_CORNERS,
  DETAIL_TABLETOPS,
  HISTORY_STATE_TYPE_ACCESSORY,
  HISTORY_STATE_TYPE_CHANGE,
  HISTORY_STATE_TYPE_CREATE,
  HISTORY_STATE_TYPE_EURO_ZAPIL,
  HISTORY_STATE_TYPE_FACADE,
  HISTORY_STATE_TYPE_HANDLE,
  HISTORY_STATE_TYPE_INIT,
  HISTORY_STATE_TYPE_MOVE,
  HISTORY_STATE_TYPE_SERVICE,
  HOB_MIN_WIDTH,
  SCREEN_HEIGHT,
  SCREEN_WIDTH,
} from "../../constants";
import { TDetail } from "../../types/TDetail";
import { ThreeKUnitDetail } from "../../objects/threeD/details/ThreeKUnitDetail/ThreeKUnitDetail";
import { ThreeFloor } from "../../objects/threeD/rooms/ThreeFloor/ThreeFloor";
import { IFacadeMaterialData } from "../../../../common-code/interfaces/materials/IFacadeMaterialData";
import { TWizardUIOptions } from "../../../types/TWizardUIOptions";
import { TWizardUrlOptions } from "../../../types/TWizardUrlOptions";
import { IAppConfig } from "../../../../common-code/interfaces/config/IAppConfig";
import { IProjectData } from "../../../../common-code/interfaces/project/IProjectData";
import { ISaveRoomData } from "../../../../common-code/interfaces/saveData/ISaveRoomData";
import { CommonHelper, KitchenHelper } from "common-code";
import { ICreateObjectData } from "../../../../common-code/interfaces/createData/ICreateObjectData";
import { IOption } from "../../../../common-code/interfaces/option/IOption";
import { IOptionGroup } from "../../../../common-code/interfaces/option/IOptionGroup";
import { TSelectItem } from "../../../../common-code/types/TSelectItem";
import { i18n } from "../../../i18n";
import { TAspectData } from "../../../../common-code/types/TAspectData";
import { ITechnologMap } from "../../../../common-code/interfaces/ITechnologMap";
import { TKitchenView } from "../../../types/TKitchenView";
import { TKitchenSizesType } from "../../../types/TKitchenSizesType";
import { TPoint2D } from "../../../../common-code/types/TPoint2D";
import { IImportOffer } from "../../../../common-code/interfaces/api/IImportOffer";
import { IDetailPriceData } from "../../../../common-code/interfaces/catalog/IDetailPriceData";
import { IDetailKitPriceData } from "../../../../common-code/interfaces/catalog/IDetailKitPriceData";
import { IFacadeData } from "../../../../common-code/interfaces/materials/IFacadeData";
import { IFacadeModelParams } from "../../../../common-code/interfaces/materials/IFacadeModelParams";
import { IFacadeModelData } from "../../../../common-code/interfaces/materials/IFacadeModelData";
import { IHandleData } from "../../../../common-code/interfaces/materials/IHandleData";
import { THandleModelType } from "../../../../common-code/types/THandleModelType";
import { IHandleModelData } from "../../../../common-code/interfaces/materials/IHandleModelData";
import { TLevel } from "../../../../common-code/types/TLevel";
import { IModulePriceData } from "../../../../common-code/interfaces/catalog/IModulePriceData";
import { ISaveUnitData } from "../../../../common-code/interfaces/saveData/ISaveUnitData";
import { IMaterialData } from "../../../../common-code/interfaces/materials/IMaterialData";
import { IUnitKitPrices } from "../../../../common-code/interfaces/catalog/IUnitKitPrices";
import { ITextureData } from "../../../../common-code/interfaces/materials/ITextureData";
import { IEquipmentModelData } from "../../../../common-code/interfaces/IEquipmentModelData";
import { ISaveReplaceData } from "../../../../common-code/interfaces/saveData/ISaveReplaceData";
import { IContextIcon } from "../../../interfaces/IContextIcon";
import { ISettingsMenu } from "../../../interfaces/settingData/ISettingsMenu";
import { IReplaceData } from "../../../interfaces/IReplaceData";
import { ITabletopData } from "../../../../common-code/interfaces/materials/ITabletopData";
import { ICornerData } from "../../../../common-code/interfaces/materials/ICornerData";
import { IApronData } from "../../../../common-code/interfaces/materials/IApronData";
import { IPlinthData } from "../../../../common-code/interfaces/materials/IPlinthData";
import { IModulePriceParams } from "../../../../common-code/interfaces/catalog/IModulePriceParams";
import { IFacadePriceParam } from "../../../../common-code/interfaces/catalog/IFacadePriceParam";
import { ICorpusPriceData } from "../../../../common-code/interfaces/catalog/ICorpusPriceData";
import { IFacadePriceData } from "../../../../common-code/interfaces/catalog/IFacadePriceData";
import { IFurniturePriceParams } from "../../../../common-code/interfaces/catalog/IFurniturePriceParams";
import { ISaveCorpusData } from "../../../../common-code/interfaces/saveData/ISaveCorpusData";
import { ICorpusPriceParams } from "../../../../common-code/interfaces/catalog/ICorpusPriceParams";
import { ISaveFacadeData } from "../../../../common-code/interfaces/saveData/ISaveFacadeData";
import { TSizes3D } from "../../../../common-code/types/TSizes3D";
import { TFacadeSizes } from "../../../../common-code/types/TFacadeSizes";
import { TDirectionSideType } from "../../../../common-code/types/TDirectionSideType";
import { IOfferSpecData } from "../../../../common-code/interfaces/catalog/IOfferSpecData";
import { TStep } from "../../../../common-code/types/TStep";
import { TClassName } from "../../../../common-code/types/TClassName";
import { TKitchenImages } from "../../../../common-code/types/TKitchenImages";
import { ICreateGroup } from "../../../../common-code/interfaces/createData/ICreateGroup";
import { TCreateGroup } from "../../../../common-code/types/TCreateGroup";
import { IOptionCorpusMaterial } from "../../../../common-code/interfaces/option/IOptionCorpusMaterial";
import { IOptionFacadeMaterial } from "../../../../common-code/interfaces/option/IOptionFacadeMaterial";
import { IServiceData } from "../../../../common-code/interfaces/catalog/IServiceData";
import { IProjectServiceData } from "../../../../common-code/interfaces/project/IProjectServiceData";
import { TKitchenCuttingImages } from "../../types/TKitchenCuttingImages";
import { IDetailCuttingData } from "../../interfaces/cutting/IDetailCuttingData";
import { IHistoryState } from "../../interfaces/history/IHistoryState";
import { IHistoryChangeObjectsState } from "../../interfaces/history/IHistoryChangeObjectsState";
import { IHistoryCreateObjectsState } from "../../interfaces/history/IHistoryCreateObjectsState";
import { IHistoryRoomState } from "../../interfaces/history/IHistoryRoomState";
import { IHistoryEuroZapilState } from "../../interfaces/history/IHistoryEuroZapilState";
import { IHistoryAccessoryState } from "../../interfaces/history/IHistoryAccessoryState";
import { IHistoryFacadeState } from "../../interfaces/history/IHistoryFacadeState";
import { IHistoryFacade } from "../../interfaces/history/IHistoryFacade";
import { IHistoryServiceState } from "../../interfaces/history/IHistoryServiceState";
import { ISaveBoxData } from "../../../../common-code/interfaces/saveData/ISaveBoxData";
import { IProjectTabletopData } from "../../../../common-code/interfaces/project/IProjectTabletopData";
import { ITabletopPlanks } from "../../../../common-code/interfaces/plank/ITabletopPlanks";
import { IProjectApronData } from "../../../../common-code/interfaces/project/IProjectApronData";
import { IApronPlanks } from "../../../../common-code/interfaces/plank/IApronPlanks";
import { IProjectCornerData } from "../../../../common-code/interfaces/project/IProjectCornerData";
import { IProjectPlinthData } from "../../../../common-code/interfaces/project/IProjectPlinthData";
import { TFacadeOpenType } from "../../../../common-code/types/TFacadeOpenType";
import { IProjectExtractData } from "../../../../common-code/interfaces/project/IProjectExtractData";
import { ThreeKUnit } from "../../objects/threeD/units/ThreeKUnit/ThreeKUnit";
import { ThreeBuiltInEquipment } from "../../objects/threeD/equipments/ThreeBuiltInEquipment/ThreeBuiltInEquipment";
import { IProjectSinksData } from "../../../../common-code/interfaces/project/IProjectSinksData";
import { IHandlePriceParams } from "../../../../common-code/interfaces/catalog/IHandlePriceParams";
import { IHingePriceParams } from "../../../../common-code/interfaces/catalog/IHingePriceParams";
import { ISaveHandleData } from "../../../../common-code/interfaces/saveData/ISaveHandleData";
import { ISaveHingeData } from "../../../../common-code/interfaces/saveData/ISaveHingeData";
import { TCatalogCalculateType } from "../../../../common-code/types/TCatalogCalculateType";
import { IProjectFormDataItem } from "../../../../common-code/interfaces/project/IProjectFormDataItem";
import { IProjectPriceData } from "../../../../common-code/interfaces/project/IProjectPriceData";
import { IProjectGoods } from "../../../../common-code/interfaces/project/IProjectGoods";
import axios, { AxiosResponse } from "axios";
import { ISpecDataApron } from "../../../../common-code/interfaces/spec/ISpecDataApron";
import { ISpecDataFacade } from "../../../../common-code/interfaces/spec/ISpecDataFacade";
import { ISpecDataTabletop } from "../../../../common-code/interfaces/spec/ISpecDataTabletop";
import { ISpecDataEuroZapil } from "../../../../common-code/interfaces/spec/ISpecDataEuroZapil";
import { IUserData } from "../../../../common-code/interfaces/IUserData";
import { ICheckCatalogUnit } from "../../../../common-code/interfaces/catalog/ICheckCatalogUnit";
import { ICheckCatalogUnits } from "../../../../common-code/interfaces/catalog/ICheckCatalogUnits";
import { IAPIProduct } from "../../../../common-code/interfaces/api/IAPIProduct";
import { ICheckCatalogUnitKitItem } from "../../../../common-code/interfaces/catalog/ICheckCatalogUnitKitItem";
import { IProjectResetData } from "../../../interfaces/IProjectResetData";
import { TErrorModulesFunction } from "../../../../common-code/types/appConfig/TErrorModulesFunction";
import { ITechnologMaps } from "../../../../common-code/interfaces/ITechnologMaps";
import { IHistoryHandleState } from "../../interfaces/history/IHistoryHandleState";
import { IOfferSpecDataItems } from "../../../../common-code/interfaces/catalog/IOfferSpecDataItems";
import { IFacadeHandleData } from "../../../../common-code/interfaces/materials/IFacadeHandleData";
import { TOptionalPoint3D } from "../../../../common-code/types/TOptionalPoint3D";
import { ICreateObjectSelectOptions } from "../../../../common-code/interfaces/createData/ICreateObjectSelectOptions";
import { ThreeConstructiveModel } from "../../objects/threeD/constructive/ThreeConstructiveModel/ThreeConstructiveModel";
import { IConstructiveModelData } from "../../../../common-code/interfaces/room/IConstructiveModelData";
import { IUnitSpecData } from "../../../interfaces/IUnitSpecData";
import { ISpecItem } from "../../../../common-code/interfaces/catalog/ISpecItem";
import { TSideType } from "../../../../common-code/types/TSideType";
import { IPositionInfo } from "../../interfaces/IPositionInfo";
import { IHistoryMoveState } from "../../interfaces/history/IHistoryMoveState";
import { IOfferPriceParam } from "../../../../common-code/interfaces/catalog/IOfferPriceParam";
import { IProjectOffer } from "../../../../common-code/interfaces/project/IProjectOffer";
import { TProjectLocalSaveData } from "../../../../common-code/types/project/TProjectLocalSaveData";
import {
  checkLocalProjectSaveTime,
  deleteFromLocalStorage,
  getInLocalStorage,
  setToLocalStorage,
} from "../../../helpers";
import { IWizardResetData } from "../../../interfaces/IWizardResetData";
import { IProjectUnits } from "../../../../common-code/interfaces/project/IProjectUnits";
import { IWizardInitData } from "../../../../common-code/interfaces/api/IWizardInitData";
import { IClientSessionData } from "../../../../common-code/interfaces/session/IClientSessionData";
import { IProjectOffers } from "../../../../common-code/interfaces/project/IProjectOffers";
import { ISpecFormFilter } from "../../../../common-code/interfaces/catalog/ISpecFormFilter";
import { IOffer } from "../../../../common-code/interfaces/catalog/IOffer";
import { IDetailData } from "../../../../common-code/interfaces/materials/IDetailData";
import { TGoodType } from "../../../../common-code/types/TGoodType";
import { ThreeLeg } from "../../objects/threeD/details/ThreeLeg/ThreeLeg";
import { IProjectOrderParts } from "../../../../common-code/interfaces/project/IProjectOrderParts";
import { IProjectSpecList } from "../../../../common-code/interfaces/project/IProjectSpecList";
import { TCheckCatalogType } from "../../../../common-code/types/appConfig/TCheckCatalogType";
import { IProjectLegsData } from "../../../../common-code/interfaces/project/IProjectLegsData";
import { IHistoryObjectData } from "../../interfaces/history/IHistoryObjectData";
import { IProjectOrder } from "../../../../common-code/interfaces/project/IProjectOrder";
import { TPriceCell } from "../../../../common-code/types/price/TPriceCell";
import { IOptionRadioButton } from "../../../../common-code/interfaces/option/IOptionRadioButton";

import colorMap from "../../editors/ThreeEditor/texturex/Plastic013A_2K-PNG_Color.png";
import normalMap from "../../editors/ThreeEditor/texturex/Plastic013A_2K-PNG_NormalGL.png";
import roughnessMap from "../../editors/ThreeEditor/texturex/Plastic013A_2K-PNG_Roughness.png";
import displacementMap from "../../editors/ThreeEditor/texturex/Plastic013A_2K-PNG_Displacement.png";
import { INewMaterialData } from "../../../ui/elements/WizardEdit/FormMaterial/FormMaterial";
import {IIntegratedHandleData} from '../../../../common-code/interfaces/materials/IIntegratedHandleData';
import { INewPlinthData } from "../../../ui/elements/WizardEdit/FormPlinth/FormPlinth";
import { ThreeIntegratedHandleUnion } from "../../objects/threeD/details/ThreeIntegratedHandle/ThreeIntegratedHandleUnion";
import { leftCameraAspect } from "../../../ui/elements/WizardEdit/utils/CameraLeftAspect";
import { frontCameraAspect } from "../../../ui/elements/WizardEdit/utils/CameraFrontAspect";

export class KitchenService extends EditorService {
  protected editor: KitchenEditor;
  protected room: ThreeRoom | undefined;
  private mainManager: MainManager;
  private rebuildManager: RebuildManager;
  private dataManager: DataManager;
  private cacheManager: CacheManager;
  private priceManager: PriceManager;
  private historyManager: HistoryManager;
  private drawManager: DrawManager;
  private rebuildUnitManager: RebuildUnitManager;

  private screenFacadeMaterials?: IFacadeMaterialData[];
  private kitchenHelper: KitchenHelper;
  private initData?: IWizardInitData;

  constructor(
    editor: KitchenEditor,
    userControls: KitchenUserControls,
    reduxDispatch: Dispatch,
    urlOptions: TWizardUrlOptions,
    kitchenOptions: TWizardUIOptions,
    appConfig: IAppConfig,
    projectData: IProjectData,
    sessionData: IClientSessionData
  ) {
    super(
      editor,
      userControls,
      kitchenOptions,
      urlOptions,
      reduxDispatch,
      appConfig,
      projectData,
      sessionData
    );
    this.editor = editor;
    this.urlOptions = urlOptions;
    this.kitchenOptions = kitchenOptions;
    this.mainManager = new MainManager(this);
    this.rebuildManager = new RebuildManager(this);
    this.dataManager = new DataManager(this);
    this.cacheManager = new CacheManager(this);
    this.priceManager = new PriceManager(this);
    this.historyManager = new HistoryManager(this);
    this.drawManager = new DrawManager(this);
    this.rebuildUnitManager = new RebuildUnitManager(this);
    this.ready = false;
    this.kitchenHelper = new KitchenHelper(
      {
        preloadPrices: this.kitchenOptions.preloadPrices,
        checkStore: this.kitchenOptions.checkStore,
      },
      this.appConfig,
      this.dataManager.units,
      this.dataManager.unitKits,
      this.dataManager.prices,
      this.dataManager.getCorpusMaterialsObject(),
      this.dataManager.getFacadeMaterialsObject()
    );
    window.addEventListener(
      "message",
      this.onIntegrationMessageListener.bind(this)
    );
  }

  public async initState(container: HTMLDivElement, roomData: ISaveRoomData) {
    if (this.ready) {
      return;
    }
    this.reduxDispatch({
      type: SHOW_LOADING,
    });
    this.editor.initState(container);
    window.addEventListener("resize", this.threeEditorOnResize, false);
    window.addEventListener("pointermove", this.threeEditorPointerMove, false);
    this.userControls.run(container);
    await this.onInit(CommonHelper.deepCopy(roomData));
    this.reduxDispatch({
      type: HIDE_LOADING,
    });
  }

  public getCameraPosition(): Vector3 {
    return this.editor.getCameraPosition();
  }

  // For WizardEdit
  public async initStateEDIT(
    container: HTMLDivElement,
    roomData: ISaveRoomData
  ) {
    if (this.ready) {
      return;
    }
    this.reduxDispatch({
      type: SHOW_LOADING,
    });
    this.editor.initStateEDIT(container);
    window.addEventListener("resize", this.threeEditorOnResize, false);
    window.addEventListener("pointermove", this.threeEditorPointerMove, false);
    await this.onInitEDIT(CommonHelper.deepCopy(roomData));
    this.reduxDispatch({
      type: HIDE_LOADING,
    });
  }

  public async resetProject(newData: IProjectResetData): Promise<boolean> {
    if (this.resetProjectData(newData)) {
      this.resetHistoryState();
      await this.onInit(
        CommonHelper.deepCopy(EditorService.getDefaultRoomData())
      );
    }

    return true;
  }

  public remove() {
    window.removeEventListener("resize", this.threeEditorOnResize, false);
    window.removeEventListener(
      "pointermove",
      this.threeEditorPointerMove,
      false
    );
    window.removeEventListener(
      "message",
      this.onIntegrationMessageListener,
      false
    );
    this.userControls.stop();
    this.rebuildManager.remove();
    this.cacheManager.remove();
    this.dataManager.remove();
    this.historyManager.remove();
    this.mainManager.remove();
    this.removeRoom();
    super.remove();
  }

  public startRender() {
    this.editor.startRender();
  }

  public resizeCanvas() {
    this.editor.resizeCanvas();
  }

  public stopRender() {
    this.editor.stopRender();
  }

  public showAll() {
    this.editor.showAll();
  }

  public hideRoom() {
    this.room?.hide();
  }

  public showRoom() {
    this.room?.show();
  }

  public setAppConfig(appConfig: IAppConfig) {
    super.setAppConfig(appConfig);
    this.kitchenHelper.setAppConfig(appConfig);
  }

  public setUnitsToKitchenHelper() {
    this.kitchenHelper.setUnits(this.dataManager.units);
  }

  public setUnitKitsToKitchenHelper() {
    this.kitchenHelper.setUnitKits(this.dataManager.unitKits);
  }

  public setUnitPricesToKitchenHelper() {
    this.kitchenHelper.setUnitPrices(this.dataManager.prices);
  }

  public setCorpusMaterialsToKitchenHelper() {
    this.kitchenHelper.setCorpusMaterials(
      this.dataManager.getCorpusMaterialsObject()
    );
  }

  public setFacadeMaterialsToKitchenHelper() {
    this.kitchenHelper.setFacadeMaterials(
      this.dataManager.getFacadeMaterialsObject()
    );
  }

  public createPdf(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.sendProjectToSave(false, false, true)
        .then((projectId) => {
          axios
            .get("/api/project/pdf", { params: { project: projectId } })
            .then((response) => {
              resolve(response.data);
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  }

  public createWord(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.sendProjectToSave(false, false, true)
        .then((projectId) => {
          axios
            .get("/api/project/word", { params: { project: projectId } })
            .then((response) => {
              resolve(response.data);
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  }

  public disableCatalog() {
    this.dataManager.disableCatalog();
  }

  public clearSelectCovers() {
    this.editor.clearTrySelectingCover();
    this.editor.clearSelectingCover();
    this.editor.clearSelectedCovers();
    this.editor.clearCurrentCover();
  }

  public hideMenus() {
    this.hideSettingsMenu();
    this.hideContextMenu();
  }

  public deleteAllObjects(setState?: boolean) {
    let deleteData: {
      isOk: boolean;
      historyState?: IHistoryCreateObjectsState;
    };
    deleteData = this.mainManager.deleteAllObjects();
    if (deleteData.isOk) {
      this.clearSelectCovers();
      this.hideMenus();
      this.rebuildScene();
      if (setState && deleteData.historyState) {
        this.setHistoryState(deleteData.historyState);
      }
      return true;
    }
    return false;
  }

  public removeWalls() {
    this.room?.removeWalls();
  }

  public createWalls() {
    this.room?.createWalls();
  }

  public canChangeHandles(): boolean {
    return false;
  }

  public setTopHandle(handle: IHandleData, setState?: boolean) {
    let newHandle: IHandleData;
    let oldHandle: IHandleData | undefined;

    oldHandle = this.dataManager.topHandle;
    newHandle = this.dataManager.setTopHandle(handle);
    this.mainManager.setHandle(newHandle, LEVEL_TOP);
    this.rebuildScene();
    if (setState) {
      this.setHistoryState({
        type: HISTORY_STATE_TYPE_HANDLE,
        data: {
          oldData: { level: LEVEL_TOP, handle: oldHandle },
          newData: { level: LEVEL_TOP, handle: newHandle },
        },
      } as IHistoryHandleState);
    }
  }

  public setBottomHandle(handle: IHandleData, setState?: boolean) {
    let newHandle: IHandleData;
    let oldHandle: IHandleData | undefined;

    oldHandle = this.dataManager.bottomHandle;
    newHandle = this.dataManager.setBottomHandle(handle);
    this.mainManager.setHandle(newHandle, LEVEL_BOTTOM);
    this.rebuildScene();
    if (setState) {
      this.setHistoryState({
        type: HISTORY_STATE_TYPE_HANDLE,
        data: {
          oldData: { level: LEVEL_BOTTOM, handle: oldHandle },
          newData: { level: LEVEL_BOTTOM, handle: newHandle },
        },
      } as IHistoryHandleState);
    }
  }

  public getTopHandle(): IHandleData | undefined {
    return this.dataManager.topHandle;
  }

  public getBottomHandle(): IHandleData | undefined {
    return this.dataManager.bottomHandle;
  }

  public getDefaultOptions(
    objectData: ICreateObjectData,
    width?: number,
    corpusWidth?: number
  ): any {
    return KitchenHelper.getDefaultOptions(objectData, width, corpusWidth);
  }

  public findCreateObjectFacadeMaterialByCorpusMaterialId(
    objectData: ICreateObjectData,
    createOptions: any,
    materialId: string
  ): IFacadeMaterialData | undefined {
    let priceParams: IModulePriceParams | undefined;
    let modulePrices: IModulePriceData[];
    let modulePrice: IModulePriceData;

    if (
      this.getCreateObjectCalculateType(objectData) !==
      CATALOG_CALCULATE_TYPE_MODULE
    ) {
      return undefined;
    }
    if (createOptions.corpus) {
      createOptions.corpus.material = materialId;
    } else {
      createOptions.corpusMaterial = materialId;
    }
    priceParams = this.calculateCreateObjectPriceParams(
      objectData,
      createOptions
    );
    if (priceParams) {
      if (
        priceParams.facadeMaterial === NONE_MATERIAL ||
        priceParams.corpusMaterial === NONE_MATERIAL
      ) {
        return undefined;
      }
      modulePrices = this.calculatePricesByModule(priceParams).filter(
        (itemPriceData) => itemPriceData.errors.length <= 0
      );
      for (modulePrice of modulePrices) {
        if (modulePrice.module && modulePrice.module.offer) {
          return this.dataManager.getFacadeMaterialById(
            modulePrice.module.offer.facadeMaterial
          );
        }
      }
    }

    return undefined;
  }

  public findCreateObjectCorpusMaterialByFacadeMaterialId(
    objectData: ICreateObjectData,
    createOptions: any,
    facadeMaterialId: string
  ): IMaterialData | undefined {
    if (
      this.getCreateObjectCalculateType(objectData) !==
      CATALOG_CALCULATE_TYPE_MODULE
    ) {
      return undefined;
    }
    let priceParams: IModulePriceParams | undefined;
    let modulePrices: IModulePriceData[];
    let modulePrice: IModulePriceData;

    createOptions.facadeMaterial = facadeMaterialId;
    priceParams = this.calculateCreateObjectPriceParams(
      objectData,
      createOptions
    );
    if (!priceParams) {
      return undefined;
    }
    if (
      priceParams.facadeMaterial === NONE_MATERIAL ||
      priceParams.corpusMaterial === NONE_MATERIAL
    ) {
      return undefined;
    }
    modulePrice = this.calculatePrice(priceParams);
    if (!modulePrice.errors.length) {
      return undefined;
    }
    delete priceParams.corpusMaterial;
    modulePrices = this.calculatePricesByModule(priceParams).filter(
      (itemPriceData) => itemPriceData.errors.length <= 0
    );
    for (modulePrice of modulePrices) {
      if (modulePrice.module && modulePrice.module.offer) {
        return this.dataManager.getCorpusMaterialById(
          modulePrice.module.offer.corpusMaterial
        );
      }
    }

    return undefined;
  }

  public getDefaultAspect(): TAspectData {
    return this.editor.getDefaultAspect();
  }

  public setAspect(aspect: TAspectData) {
    this.editor.setAspect(aspect);
  }

  public initEditorCuttingViewer() {
    this.editor.initCuttingViewer();
  }

  public removeEditorCuttingViewer() {
    this.editor.removeCuttingViewer();
  }

  public clearEditorCuttingScene() {
    this.editor.clearCuttingScene();
  }

  public addTextureObject(material: INewMaterialData) {
    this.editor.addTextureObject(material);
  }

  public addPlinthObject(material: INewPlinthData) {
    this.editor.addPlinthObject(material);
  }

  public addFacadeTextureObject(facadeMaterial: IFacadeMaterialData) {
    const material = this.dataManager.getMaterialById(facadeMaterial['material'])
    const material2 = facadeMaterial['material2'] ? this.dataManager.getMaterialById(facadeMaterial['material2']) : undefined;
    if(material)this.editor.addFacadeTextureObject(material, material2);
  }

  // Method for WizardEdit
  public clearEditorScene() {
    this.editor.clearSceneFromModel();
  }

  public renderStep() {
    this.editor.renderStep(true);
  }

  public initPostProcessing() {
    this.editor.initPostProcessing();
  }

  public renderCuttingStep(cuttingData: IDetailCuttingData[]) {
    this.editor.renderCuttingStep(cuttingData);
  }

  public getFixAspect(): TAspectData | undefined {
    return undefined;
  }

  public getTechnologMap(facadeId: string): ITechnologMap | undefined {
    let technologMap: ITechnologMap | undefined;

    try {
      technologMap = this.dataManager.getTechnologMap(facadeId);
    } catch (e) {
      technologMap = undefined;
    }

    return technologMap;
  }

  public tryUpdateEquipments(technologMaps: ITechnologMaps) {
    let equipments: ThreeUnit[];
    let equipment: ThreeUnit;
    let bottomTechnologMap: ITechnologMap | undefined;
    let topTechnologMap: ITechnologMap | undefined;

    equipments = this.mainManager.getEquipments();
    bottomTechnologMap = this.getTechnologMap(
      this.getDefaultFacadeData(LEVEL_BOTTOM).id
    );
    topTechnologMap = this.getTechnologMap(
      this.getDefaultFacadeData(LEVEL_TOP).id
    );
    for (equipment of equipments) {
      switch (equipment.getLevel()) {
        case LEVEL_TOP:
          equipment.updateFromTechnologMap(topTechnologMap);
          break;
        case LEVEL_BOTTOM:
        default:
          equipment.updateFromTechnologMap(bottomTechnologMap);
          break;
      }
    }
  }

  public getWallById(id: number): ThreeWall | undefined {
    return this.room?.tryGetWallById(id);
  }

  public isOffline(): boolean {
    return false;
  }

  public getDefaultSketchViewMaterial(): MeshBasicMaterial {
    return this.cacheManager.getDefaultSketchViewMaterial();
  }

  public getRoomSketchViewMaterial(): MeshBasicMaterial {
    return this.cacheManager.getRoomSketchViewMaterial();
  }

  public isRoomVisible(): boolean {
    return this.room ? this.room.isVisible() : true;
  }

  public toggleRoomVisible() {
    if (!this.room) {
      return;
    }
    this.room.toggleVisible();
  }

  public setViewType(viewType: TKitchenView) {
    if (!this.room) {
      throw new Error("error-KitchenService-setViewType");
    }
    this.kitchenOptions.viewType = viewType;
    this.mainManager.updateViewType();
    this.rebuildManager.updateViewType();
    this.room.updateViewType();
    this.reduxDispatch({
      type: CHANGE_KITCHEN_VIEW,
      payload: "" + this.kitchenOptions.viewType,
    });
  }

  public setSizeType(sizeType: TKitchenSizesType) {
    this.kitchenOptions.sizesType = sizeType;
    this.rebuildScene();
    this.reduxDispatch({
      type: CHANGE_KITCHEN_SIZES_TYPE,
      payload: "" + this.kitchenOptions.sizesType,
    });
  }

  public getImageData(
    rendererType: string,
    screenAll?: boolean
  ): Promise<string> {
    return this.editor.getImageData(rendererType, screenAll);
  }

  public getDivImageData(
    rendererType: string,
    screenAll?: boolean
  ): Promise<string> {
    return this.editor.getDivImageData(rendererType, screenAll);
  }

  public getUnionUnits(): Array<
    ThreePlinthUnion | ThreeCornerUnion | ThreeApronUnion | ThreeTabletopUnion | ThreeIntegratedHandleUnion
  > {
    return this.rebuildManager.getUnions();
  }

  public getEndUnits(): ThreeUnit[] {
    return this.mainManager.getEndUnits();
  }

  public getAngleUnits(): ThreeUnit[] {
    return this.mainManager.getAngleUnits();
  }

  public getSceneBox(): Box3 {
    return this.mainManager.getSceneBox();
  }

  public getUnitsPolygon(): TPoint2D[] | undefined {
    return this.mainManager.getUnitsPolygon();
  }

  public setRoomData(roomData: ISaveRoomData, setState?: boolean) {
    if (!this.room) {
      throw new Error("error-KitchenService-setRoomData");
    }
    let historyState: IHistoryRoomState | undefined;

    historyState = this.room.rebuild(roomData);
    this.rebuildScene();
    if (historyState && setState) {
      this.setHistoryState(historyState);
    }
  }

  public getRoomData(isTemplate?: boolean): ISaveRoomData {
    if (!this.room) {
      throw new Error("error-KitchenService-getRoomData");
    }

    return this.room.getSaveData(isTemplate);
  }

  public getRoom(): ThreeRoom {
    if (!this.room) {
      throw new Error("error-KitchenService-getRoom");
    }
    return this.room;
  }

  public getFloor(): ThreeFloor | null {
    if (!this.room) {
      throw new Error("error-KitchenService-getFloor");
    }
    return this.room.getFloor();
  }

  public getWalls(): ThreeWall[] {
    if (!this.room) {
      throw new Error("error-KitchenService-getWalls");
    }
    return this.room.getWalls();
  }

  public getUnits(types?: string[]): ThreeUnit[] {
    return this.mainManager.getUnits(types);
  }

  public getObjects(): ThreeUnit[] {
    return this.mainManager.getObjects();
  }

  public getEquipments(): ThreeUnit[] {
    return this.mainManager.getEquipments();
  }

  public getObjectById(id: number): ThreeUnit | undefined {
    let object: ThreeUnit | undefined;

    object = this.mainManager.getObjectById(id);
    if (!object && this.room) {
      object = this.room.getConstructive(id);
    }
    return object;
  }

  public getAprons(): ThreeApron[] {
    let aprons: ThreeApron[] = this.mainManager.getAprons();
    let apron: ThreeApron;

    for (apron of this.rebuildManager.unions.aprons) {
      if (
        apron.isView3dVisible() &&
        apron.isCalculate() &&
        this.isCalculateAprons()
      ) {
        aprons.push(apron);
      }
    }

    return aprons;
  }

  public getFacadeGoods(): IImportOffer[] | undefined {
    return this.dataManager.getFacadeGoods();
  }

  public getSpecEdges(): ThreeKUnitDetail[] {
    return [];
  }

  public getSpecFacadeMaterials(): ThreeKUnitDetail[] {
    return [];
  }

  public getSpecAccessories(): ThreeKUnitDetail[] {
    return [];
  }

  public getSpecServices(): IProjectServiceData[] {
    let specServices: IProjectServiceData[] = [];
    let service: IProjectServiceData;

    if (!this.isEnableServices()) {
      return specServices;
    }
    if (this.projectData.services) {
      for (service of this.projectData.services) {
        if (service.sum > 0) {
          specServices.push(service);
        }
      }
    }

    return specServices;
  }

  public getSpecEquipments(): ThreeEquipment[] {
    return [];
  }

  public getProjectHandles(): ThreeHandle[] {
    let handles: ThreeHandle[] = [];
    let units: ThreeUnit[];
    let unit: ThreeUnit;
    let facade: ThreeFacade;

    units = this.mainManager.getObjects();

    for (unit of units) {
      if (unit.facades) {
        for (facade of unit.facades) {
          if (facade.handle) {
            handles.push(facade.handle);
          }
        }
      }
    }

    return handles;
  }

  public getSpecHandles(): ThreeHandle[] {
    let handles: ThreeHandle[];
    let handle: ThreeHandle;
    let specHandles: ThreeHandle[] = [];
    let priceData: IDetailKitPriceData | undefined;

    handles = this.getProjectHandles();
    for (handle of handles) {
      priceData = handle.getPriceData();
      if (priceData) {
        specHandles.push(handle);
      }
    }

    return specHandles;
  }

  public getProjectLegs(): ThreeLeg[] {
    return this.mainManager.getProjectLegs();
  }

  public getSpecFacades(): ThreeFacade[] {
    let facade: ThreeFacade;
    let facades: ThreeFacade[];
    let specFacade: IDetailKitPriceData | undefined;
    let specFacades: ThreeFacade[] = [];

    facades = this.getProjectFacades();

    for (facade of facades) {
      if (facade.getCalculateType() !== FACADE_CALCULATE_SELF_AMOUNT) {
        continue;
      }
      specFacade = facade.getPriceData();
      if (specFacade) {
        specFacades.push(facade);
      }
    }

    return specFacades;
  }

  public getSpecGlasses() {
    return [];
  }

  public getSpecTabletops(): ThreeTabletop[] {
    let tabletop: ThreeTabletop;
    let tabletops: ThreeTabletop[];
    let specTabletop: IDetailKitPriceData | undefined;
    let specTabletops: ThreeTabletop[] = [];

    tabletops = this.getTabletops();

    for (tabletop of tabletops) {
      specTabletop = tabletop.getPriceData();
      if (specTabletop) {
        specTabletops.push(tabletop);
      }
    }

    return specTabletops;
  }

  public getSpecAprons(): ThreeApron[] {
    let apron: ThreeApron;
    let aprons: ThreeApron[];
    let specApron: IDetailKitPriceData | undefined;
    let specAprons: ThreeApron[] = [];

    aprons = this.getAprons();

    for (apron of aprons) {
      specApron = apron.getPriceData();
      if (specApron) {
        specAprons.push(apron);
      }
    }

    return specAprons;
  }

  public getCanOrderPart(): boolean {
    return true;
  }

  public getProjectOrderParts(): IProjectOrderParts | undefined {
    if (!this.getCanOrderPart()) {
      return undefined;
    }
    let orderPrats: IProjectOrderParts;

    orderPrats = this.projectData.orderParts || {};

    return orderPrats;
  }

  public getProjectLegsHeight(): number | undefined {
    let technologMap: ITechnologMap | undefined;

    if (this.projectData.legsHeight) {
      return this.projectData.legsHeight;
    }
    technologMap = this.getTechnologMap(
      this.getBottomFacadeMaterial()?.facade || "default"
    );
    if (technologMap) {
      return technologMap.bottomUnits.legsHeight;
    }

    return undefined;
  }

  public getCollectionId(): string {
    return this.dataManager.collectionId;
  }

  public getProjectSpecList(): IProjectSpecList {
    return {
      units: this.getUnitsSpecItems(this.getObjects()),
      facades: this.getDetailsSpecItems(this.getSpecFacades()),
      tabletops: this.getDetailsSpecItems(this.getSpecTabletops()),
      aprons: this.getDetailsSpecItems(this.getSpecAprons()),
      corners: this.getDetailsSpecItems(this.getSpecCorners()),
      plinths: this.getDetailsSpecItems(this.getSpecPlinths()),
      planks: this.getPlanksSpecItems(this.getSpecPlanks()),
      handles: this.getDetailsSpecItems(this.getSpecHandles()),
      extraOffers: this.getProjectOffersSpecItems(this.getProjectOffers()),
    };
  }

  public calculateProjectExtraOffers(): IProjectOffers | undefined {
    return this.mainManager.calculateProjectExtraOffers();
  }

  public isUniqueSpecModules(): boolean {
    return false;
  }

  public getUnitsSpecItems(units: ThreeUnit[]): ISpecItem[] {
    let unit: ThreeUnit;
    let sortUnits: { [s: string]: { count: number; unit: ThreeUnit } } = {};
    let uid: string;
    let specUnits: { [key: string]: ISpecItem } = {};
    let unitPrice: IModulePriceData | undefined;
    let unitSpecData: ISpecItem;
    let unitOffersSpecData: IOfferSpecData[];
    let unitOfferSpecData: IOfferSpecData;
    let childrenUnitSpecData: ISpecItem[];
    let index: string;

    for (unit of units) {
      uid = unit.getSpecUid();
      if (!sortUnits[uid]) {
        sortUnits[uid] = {
          count: 1,
          unit: unit,
        };
      } else {
        sortUnits[uid].count++;
      }
    }
    for (index in sortUnits) {
      unitSpecData = sortUnits[index].unit.getSpecModuleData(
        sortUnits[index].count
      );
      unitPrice = sortUnits[index].unit.getPriceData();
      if (unitPrice) {
        childrenUnitSpecData = [];
        unitOffersSpecData = this.getUnitOffersSpecData(
          unitPrice,
          sortUnits[index].count
        );
        for (unitOfferSpecData of unitOffersSpecData) {
          childrenUnitSpecData.push(
            this.getOfferDataSpecLayout(
              unitOfferSpecData,
              sortUnits[index].count
            )
          );
        }
        if (childrenUnitSpecData.length > 0) {
          unitSpecData.children = childrenUnitSpecData;
        }
      }
      specUnits[index] = unitSpecData;
    }

    return Object.values(specUnits);
  }

  public getOfferDataSpecLayout(
    offerData: IOfferSpecData,
    count: number
  ): ISpecItem {
    let stock: number;

    stock = offerData.stock || 0;
    return {
      externalGuid: "" + offerData.id,
      vendorCode: offerData.vendorCode,
      name: offerData.name,
      count: offerData.count * count,
      formatCount: "" + offerData.count * count + " " + i18n.t("шт"),
      price: offerData.price,
      stock: stock,
      formatStock: stock ? "" + stock + " " + i18n.t("шт") : "-",
      active: offerData.active,
      article: offerData.article,
      part: offerData.part,
      unitId: offerData.unitId,
      cell: offerData.cell,
      cellIndex: offerData.cellIndex,
    };
  }

  public getProjectOffersSpecItems(
    projectOffers: IProjectOffers | undefined
  ): ISpecItem[] {
    let specOffers: ISpecItem[] = [];
    let index: string;
    let intIndex: number;
    let itemOffer: IImportOffer | undefined;
    let itemPrice: number;
    let itemStock: number;

    if (!projectOffers) {
      return specOffers;
    }
    intIndex = 0;
    for (index in projectOffers) {
      itemOffer = projectOffers[index].offer;
      if (itemOffer) {
        itemPrice = projectOffers[index].price || 0;
        itemStock = projectOffers[index].stock || 0;
        specOffers.push({
          externalGuid: "" + itemOffer.id,
          vendorCode: itemOffer.vendorCode,
          name: itemOffer.name,
          count: projectOffers[index].count,
          formatCount: "" + projectOffers[index].count + " " + i18n.t("шт"),
          price: itemPrice,
          stock: itemStock,
          formatStock: itemStock ? "" + itemStock + " " + i18n.t("шт") : "-",
          active: projectOffers[index].active,
          article: itemOffer.article,
          part: projectOffers[index].part,
          unitId: projectOffers[index].unitId,
          cell: projectOffers[index].cell,
          cellIndex: intIndex,
        });
        intIndex++;
      }
    }

    return specOffers;
  }

  public getPlanksSpecItems(planks: []): ISpecItem[] {
    return planks;
  }

  public getDetailsSpecItems(
    details: ThreeKUnitDetail[] | ThreeFacade[] | ThreeHandle[]
  ): ISpecItem[] {
    let detail: ThreeKUnitDetail | ThreeFacade | ThreeHandle;
    let detailKitPriceData: IDetailKitPriceData | undefined;
    let detailPriceData: IDetailPriceData;
    let detailsPriceData: { [key: string]: IDetailPriceData } = {};
    let specItems: ISpecItem[] = [];
    let offer: IImportOffer | undefined;
    let index: string;
    let intIndex: number;
    let stock: number;

    for (detail of details) {
      detailKitPriceData = detail.getPriceData();
      if (detailKitPriceData !== undefined) {
        for (detailPriceData of detailKitPriceData.kit) {
          if (detailPriceData.offer !== undefined) {
            if (
              !detailsPriceData[
                detailPriceData.offer[this.getOfferExternalId()]
              ]
            ) {
              detailsPriceData[
                detailPriceData.offer[this.getOfferExternalId()]
              ] = { ...detailPriceData };
            } else {
              detailsPriceData[
                detailPriceData.offer[this.getOfferExternalId()]
              ].count += detailPriceData.count;
            }
          }
        }
      }
    }
    intIndex = 0;
    for (index in detailsPriceData) {
      offer = detailsPriceData[index].offer;
      if (offer !== undefined) {
        stock = detailsPriceData[index].stock || 0;
        specItems.push({
          externalGuid: "" + offer.id,
          vendorCode: offer.vendorCode,
          name: offer.name,
          count: detailsPriceData[index].count,
          formatCount: "" + detailsPriceData[index].count + " " + i18n.t("шт"),
          price: detailsPriceData[index].price,
          stock: stock,
          formatStock: stock ? "" + stock + " " + i18n.t("шт") : "-",
          active: detailsPriceData[index].active,
          article: offer.article,
          part: this.getSpecItemOrderPart(
            detailsPriceData[index].unitId,
            detailsPriceData[index].cell,
            intIndex
          ),
          unitId: detailsPriceData[index].unitId,
          cell: detailsPriceData[index].cell,
          cellIndex: intIndex,
        });
        intIndex++;
      }
    }

    return specItems;
  }

  public getSpecCorners(): ThreeCorner[] {
    let corner: ThreeCorner;
    let corners: ThreeCorner[];
    let specCorner: IDetailKitPriceData | undefined;
    let specCorners: ThreeCorner[] = [];

    corners = this.getCorners();

    for (corner of corners) {
      specCorner = corner.getPriceData();
      if (specCorner) {
        specCorners.push(corner);
      }
    }

    return specCorners;
  }

  public getSpecPlanks() {
    return this.rebuildManager.getSpecPlanks();
  }

  public getSpecPlinths(): ThreePlinth[] {
    let plinth: ThreePlinth;
    let plinths: ThreePlinth[];
    let specPlinth: IDetailKitPriceData | undefined;
    let specPlinths: ThreePlinth[] = [];

    plinths = this.getPlinths();

    for (plinth of plinths) {
      specPlinth = plinth.getPriceData();
      if (specPlinth) {
        specPlinths.push(plinth);
      }
    }

    return specPlinths;
  }

  public isCalculateTabletops(): boolean {
    if (!this.isCatalogCalculatePrice()) {
      return true;
    }
    if (this.appConfig.catalog.tabletops) {
      return this.appConfig.catalog.tabletops.isCalculate;
    }

    return false;
  }

  public isCalculateAprons(): boolean {
    if (!this.isCatalogCalculatePrice()) {
      return true;
    }
    if (this.appConfig.catalog.aprons) {
      return this.appConfig.catalog.aprons.isCalculate;
    }

    return false;
  }

  public isCalculatePlinths(): boolean {
    if (!this.isCatalogCalculatePrice()) {
      return true;
    }
    if (this.appConfig.catalog.plinths) {
      return this.appConfig.catalog.plinths.isCalculate;
    }

    return false;
  }

  public isCalculateCorners(): boolean {
    if (!this.isCatalogCalculatePrice()) {
      return true;
    }
    if (this.appConfig.catalog.corners) {
      return this.appConfig.catalog.corners.isCalculate;
    }

    return false;
  }

  public getTabletops(): ThreeTabletop[] {
    let tabletops: ThreeTabletop[] = this.mainManager.getTabletops();
    let tabletop: ThreeTabletop;

    for (tabletop of this.rebuildManager.unions.tabletops) {
      if (
        tabletop.isView3dVisible() &&
        tabletop.isCalculate() &&
        this.isCalculateTabletops()
      ) {
        tabletops.push(tabletop);
      }
    }

    return tabletops;
  }

  public getProjectFacades(): ThreeFacade[] {
    let facades: ThreeFacade[];

    facades = this.mainManager.getFacades();

    return facades;
  }

  public getMaxDetailLength(
    detailName: TCanUnionDetail,
    detail: TDetail
  ): number | undefined {
    switch (detailName) {
      case DETAIL_TABLETOPS:
        return this.dataManager.getMaxDetailLength(
          detail.getMaterialId(),
          GOOD_TYPE_TABLETOP
        );
      case DETAIL_APRONS:
        return this.dataManager.getMaxDetailLength(
          detail.getMaterialId(),
          GOOD_TYPE_APRON
        );
      case DETAIL_CORNERS:
        return this.dataManager.getMaxDetailLength(
          detail.getMaterialId(),
          GOOD_TYPE_CORNER
        );
      default:
        return undefined;
    }
  }

  public getCorners(): ThreeCorner[] {
    let corners: ThreeCorner[] = this.mainManager.getCorners();
    let corner: ThreeCorner;

    for (corner of this.rebuildManager.unions.corners) {
      if (
        corner.isView3dVisible() &&
        corner.isCalculate() &&
        this.isCalculateCorners()
      ) {
        corners.push(corner);
      }
    }

    return corners;
  }

  public getPlinths(): ThreePlinth[] {
    let plinths: ThreePlinth[] = this.mainManager.getPlinths();
    let plinth: ThreePlinth;

    for (plinth of this.rebuildManager.unions.plinths) {
      if (
        plinth.isView3dVisible() &&
        plinth.isCalculate() &&
        this.isCalculatePlinths()
      ) {
        plinths.push(plinth);
      }
    }

    return plinths;
  }

  public getBottomObjects(): ThreeUnit[] {
    return this.mainManager.getBottomObjects();
  }

  public getTopObjects(): ThreeUnit[] {
    return this.mainManager.getTopObjects();
  }

  public getFacadeData(id: string): IFacadeData | undefined {
    return this.dataManager.getFacadeData(id);
  }

  public calculateHandleData(
    saveHandleData?: ISaveHandleData,
    facadeHandleData?: IFacadeHandleData | IHandleData,
    facadeOpenType?: TFacadeOpenType,
    level?: TLevel,
    facadeSizes?: TFacadeSizes,
    facadeSideType?: TDirectionSideType
  ): ISaveHandleData | undefined {
    return this.dataManager.calculateHandleData(
      saveHandleData,
      facadeHandleData,
      facadeOpenType,
      level,
      facadeSizes,
      facadeSideType
    );
  }

  public getHandleModelId(
    saveModelId?: string,
    facadeModelId?: string,
    level?: TLevel
  ): string | undefined {
    let projectHandle: IHandleData | undefined;

    projectHandle =
      level === LEVEL_TOP ? this.getTopHandle() : this.getBottomHandle();
    if (saveModelId) {
      return saveModelId;
    }
    if (projectHandle) {
      return projectHandle.id;
    }
    if (facadeModelId) {
      return facadeModelId;
    }

    return undefined;
  }

  public getInitHandlePosition(
    facadeHandleData: IFacadeHandleData,
    saveHandleData: ISaveHandleData,
    sizes: TFacadeSizes
  ): TOptionalPoint3D | undefined {
    let position: TOptionalPoint3D | undefined;

    if (facadeHandleData.position) {
      position = {
        x: KitchenHelper.calculateSizeByParent(
          facadeHandleData.position.x || 0,
          sizes.width,
          this.getDataForSizeByParent()
        ),
        y: KitchenHelper.calculateSizeByParent(
          facadeHandleData.position.y || 0,
          sizes.height,
          this.getDataForSizeByParent()
        ),
        z: KitchenHelper.calculateSizeByParent(
          facadeHandleData.position.z || 0,
          sizes.width,
          this.getDataForSizeByParent()
        ),
      };
    } else if (saveHandleData.position) {
      position = saveHandleData.position;
    }

    return position;
  }

  public getInitHandleRotation(
    facadeHandleData: IFacadeHandleData,
    saveHandleData: ISaveHandleData,
    sizes: TFacadeSizes
  ): TOptionalPoint3D | undefined {
    let rotation: TOptionalPoint3D | undefined;

    if (facadeHandleData.rotation) {
      rotation = {
        x: KitchenHelper.calculateSizeByParent(
          facadeHandleData.rotation.x || 0,
          sizes.width,
          this.getDataForSizeByParent()
        ),
        y: KitchenHelper.calculateSizeByParent(
          facadeHandleData.rotation.y || 0,
          sizes.height,
          this.getDataForSizeByParent()
        ),
        z: KitchenHelper.calculateSizeByParent(
          facadeHandleData.rotation.x || 0,
          sizes.width,
          this.getDataForSizeByParent()
        ),
      };
    } else if (saveHandleData.rotation) {
      rotation = saveHandleData.rotation;
    }

    return rotation;
  }

  public getCreateObjectDynamicSettings(
    objectData: ICreateObjectData
  ): ICreateObjectSelectOptions {
    return KitchenHelper.getCreateObjectDynamicSettings(objectData);
  }

  public getCreateObjectByUid(id: string): ICreateObjectData | undefined {
    let createObjectData: ICreateObjectData | undefined;

    createObjectData = this.dataManager.getCreateUnitByUid(id);
    if (createObjectData) {
      return createObjectData;
    }
    createObjectData = this.dataManager.getCreateConstructiveByUid(id);
    if (createObjectData) {
      return createObjectData;
    }
  }

  public getCreateUnitByUid(id: string): ICreateObjectData | undefined {
    return this.dataManager.getCreateUnitByUid(id);
  }

  public getCreateConstructiveByUid(id: string): ICreateObjectData | undefined {
    return this.dataManager.getCreateConstructiveByUid(id);
  }

  public getCreateUnitByCatalogCode(id: string): ICreateObjectData | undefined {
    return this.dataManager.getCreateUnitByCatalogCode(id);
  }

  public getFacadeThreeModel(
    facadeData: IFacadeData,
    findParams: IFacadeModelParams,
    gaps: { height: number; width: number }
  ): IFacadeModelData | undefined {
    let modelData: IFacadeModelData;
    let selectModelData: IFacadeModelData | undefined;
    let similarFacade: IFacadeModelData | undefined;
    let similarGap = { width: Infinity, height: Infinity };

    for (modelData of facadeData.models) {
      if (
        findParams.height >= modelData.height - gaps.height &&
        findParams.height <= modelData.height + gaps.height
      ) {
        if (
          findParams.width >= modelData.width - gaps.width &&
          findParams.width <= modelData.width + gaps.width
        ) {
          if (findParams.type === modelData.type) {
            if (!selectModelData) {
              selectModelData = modelData;
            } else if (
              Math.abs(findParams.height - selectModelData.height) >
                Math.abs(findParams.height - modelData.height) ||
              Math.abs(findParams.width - selectModelData.width) >
                Math.abs(findParams.width - modelData.width)
            ) {
              selectModelData = modelData;
            }
          }
        }
      }
      if (
        findParams.type === modelData.type &&
        similarGap.height >= Math.abs(modelData.height - findParams.height) &&
        similarGap.width >= Math.abs(modelData.width - findParams.width)
      ) {
        similarGap.height = Math.abs(modelData.height - findParams.height);
        similarGap.width = Math.abs(modelData.width - findParams.width);
        similarFacade = modelData;
      }
    }
    // Берем самый подходящий по размеру если такой есть
    if (selectModelData === undefined && similarFacade) {
      selectModelData = similarFacade;
    }
    if (selectModelData === undefined) {
      return undefined;
    }

    return selectModelData;
  }

  public getHandleThreeModel(
    handleData: IHandleData,
    modelType: THandleModelType
  ): IHandleModelData | undefined {
    let selectModelData: IHandleModelData | undefined;
    let defaultModelData: IHandleModelData | undefined;
    let modelData: IHandleModelData;

    for (modelData of handleData.models) {
      if (!modelData.type || modelData.type === HANDLE_MODEL_TYPE_DEFAULT) {
        defaultModelData = modelData;
      }
      if (
        (!modelData.type && modelType === HANDLE_MODEL_TYPE_DEFAULT) ||
        (modelData.type && modelData.type === modelType)
      ) {
        selectModelData = modelData;
        break;
      }
    }
    if (!selectModelData && defaultModelData) {
      return defaultModelData;
    }

    return selectModelData;
  }

  public getHandleData(id: string): IHandleData | undefined {
    return this.dataManager.getHandleData(id);
  }

  public getDefaultFacadeData(level: TLevel): IFacadeData {
    return this.dataManager.getDefaultFacadeData(level);
  }

  public getUnitsWithPlinths(): ThreeUnit[] {
    return this.mainManager.getUnitsWithPlinths();
  }

  public findPointById(pointId: number) {
    let point: ThreeRoomPoint;
    if (this.room) {
      for (point of this.room.getPoints()) {
        if (point.id === pointId) {
          return point;
        }
      }
      for (point of this.room.getWallIslandPoints()) {
        if (point.id === pointId) {
          return point;
        }
      }
    }
    throw new Error("error-KitchenService-findPointById");
  }

  public getRoomPolygon(): TPoint2D[] {
    if (!this.room) {
      throw new Error("error-KitchenService-getRoomPolygon");
    }
    return this.room.getPolygon();
  }

  public getRoomBox(): Box3 {
    if (!this.room) {
      throw new Error("error-KitchenService-getRoomBox");
    }
    return this.room.getBox();
  }

  public isHidePrice(): boolean {
    return false;
  }

  public createDynamicCommonObject(
    uid: string,
    createOptions: any,
    modulePrice: IModulePriceData,
    catalogCode?: string
  ): ThreeUnit | undefined {
    const saveObjectData: ISaveUnitData = this.generateSaveObjectData(
      uid,
      createOptions,
      modulePrice,
      catalogCode
    );
    let newUnit: ThreeUnit | undefined;

    this.clearSelectCovers();

    newUnit = this.createCommonObject(saveObjectData);
    if (newUnit) {
      newUnit.trySetDefaultPosition();
      this.setHistoryState({
        type: HISTORY_STATE_TYPE_CREATE,
        data: {
          objects: [newUnit.getData()],
        },
      } as IHistoryCreateObjectsState);
    }
    this.rebuildScene();

    return newUnit;
  }

  public createDynamicCommonObjectEDIT(
    uid: string,
    createOptions: any,
    modulePrice: IModulePriceData,
    catalogCode?: string
  ): ThreeUnit | undefined {
    const saveObjectData: ISaveUnitData = this.generateSaveObjectData(
      uid,
      createOptions,
      modulePrice,
      catalogCode
    );
    let newUnit: ThreeUnit | undefined;

    this.clearSelectCovers();

    newUnit = this.createCommonObjectEDIT(saveObjectData);
    if (newUnit) {
      newUnit.trySetDefaultPosition();
      this.setHistoryState({
        type: HISTORY_STATE_TYPE_CREATE,
        data: {
          objects: [newUnit.getData()],
        },
      } as IHistoryCreateObjectsState);
    }
    this.rebuildScene();

    return newUnit;
  }

  public createCommonObject(
    objectData: ISaveUnitData
  ): ThreeUnit | ThreeConstructive | undefined {
    let unit: ThreeUnit | ThreeConstructive | undefined;

    unit = this.mainManager.createCommonObject(objectData);

    return unit;
  }

  public createCommonObjectEDIT(
    objectData: ISaveUnitData
  ): ThreeUnit | ThreeConstructive | undefined {
    let unit: ThreeUnit | ThreeConstructive | undefined;

    // to delete parts correctly
    this.clearEditorScene();

    unit = this.mainManager.createCommonObjectEDIT(objectData);

    return unit;
  }

  public generateSaveObjectData(
    uid: string,
    createOptions: any,
    modulePrice: IModulePriceData,
    catalogCode?: string
  ): ISaveUnitData {
    return this.mainManager.generateSaveObjectData(
      uid,
      createOptions,
      modulePrice,
      catalogCode
    );
  }

  public getTextures(): ITextureCache {
    return this.cacheManager.textures;
  }

  public getFloorMaterial(materialId?: string): IMaterialData {
    return this.dataManager.getFloorMaterial(materialId);
  }

  public getWallMaterial(materialId?: string): IMaterialData {
    return this.dataManager.getWallMaterial(materialId);
  }

  public getFacadeMaterial(
    level: TLevel,
    facadeMaterialId?: string
  ): IFacadeMaterialData {
    return this.dataManager.getFacadeMaterial(level, facadeMaterialId);
  }

  public getMaterial(level: TLevel, materialId?: string): IMaterialData {
    return this.dataManager.getMaterial(level, materialId);
  }

  public getPanelMaterial(materialId: string): IMaterialData | undefined {
    return this.dataManager.getPanelMaterial(materialId);
  }
  public getGlassMaterial(materialId?: string): IMaterialData {
    return this.dataManager.getGlassMaterial(materialId);
  }

  public loadCreateObjectPrices(
    createObject: ICreateObjectData
  ): Promise<IUnitKitPrices> {
    return this.dataManager.loadCreateObjectPrices(createObject);
  }

  public getCheckCatalogType(
    unitData: ICreateObjectData | ISaveUnitData | IModulePriceParams
  ): TCheckCatalogType | undefined {
    return this.kitchenHelper.getCheckCatalogType(unitData);
  }

  public checkLoadOfferPrice(offerId: string): boolean {
    return this.dataManager.checkLoadOfferPrice(offerId);
  }

  public loadPrices(offerIds: string[]): Promise<IUnitKitPrices> {
    return this.dataManager.loadPrices(offerIds);
  }

  public loadMaterialTextures(
    materialId: string,
    textures?: ITextureData[]
  ): IMaterialTextures {
    return this.cacheManager.loadMaterialTextures(materialId, textures);
  }

  public loadMaterialTexturesEDIT(): IMaterialTextures {
    const textureLoader = new TextureLoader();
    // Custom Textures
    const colorTexture = textureLoader.load(colorMap);
    const normalTexture = textureLoader.load(normalMap);
    const roughnessTexture = textureLoader.load(roughnessMap);
    const displacementTexture = textureLoader.load(displacementMap);

    const textures: IMaterialTextures = {
      normal: normalTexture,
      texture: colorTexture,
      roughness: roughnessTexture,
      diffuse: displacementTexture,
    };

    return textures;
  }

  public loadFacadeThreeModel(facade: ThreeFacade) {
    return this.cacheManager.loadFacadeThreeModel(facade);
  }

  public loadEquipmentThreeModel(equipment: ThreeEquipment) {
    return this.cacheManager.loadEquipmentThreeModel(equipment);
  }

  public loadConstructiveThreeModel(constructive: ThreeConstructiveModel) {
    return this.cacheManager.loadConstructiveThreeModel(constructive);
  }

  public loadHandleThreeModel(handle: ThreeHandle) {
    return this.cacheManager.loadHandleThreeModel(handle);
  }

  public getEquipmentModel(equipment: ThreeEquipment): IEquipmentModelData {
    return this.dataManager.getEquipmentModel(equipment);
  }

  public getConstructiveModel(
    constructive: ThreeConstructiveModel
  ): IConstructiveModelData {
    return this.dataManager.getConstructiveModel(constructive);
  }

  public getTopFirstYPosition(): number {
    return this.dataManager.getTopFirstYPosition();
  }

  public getInstallTopUnitHeight(): number {
    return this.dataManager.getInstallTopUnitHeight();
  }

  public getTopUnitHeight(facadeId?: string): number {
    let topFacadeId: string;

    topFacadeId =
      facadeId ||
      (this.dataManager.topFacadeMaterial
        ? this.dataManager.topFacadeMaterial.facade
        : "default");

    return this.dataManager.getTopUnitHeight(topFacadeId);
  }

  public getBottomUnitHeight(facadeId?: string): number {
    let bottomFacadeId: string;

    bottomFacadeId =
      facadeId ||
      (this.dataManager.bottomFacadeMaterial
        ? this.dataManager.bottomFacadeMaterial.facade
        : "default");

    return this.dataManager.getBottomUnitHeight(bottomFacadeId);
  }

  public getBottomUnitLegsHeight(facadeId?: string): number {
    let bottomFacadeId: string;

    bottomFacadeId =
      facadeId ||
      (this.dataManager.bottomFacadeMaterial
        ? this.dataManager.bottomFacadeMaterial.facade
        : "default");

    return this.dataManager.getBottomUnitLegsHeight(bottomFacadeId);
  }

  public deleteBuiltInEquipment(
    equipmentId: number,
    setState?: boolean
  ): boolean {
    let historyState: IHistoryChangeObjectsState | undefined;

    historyState = this.mainManager.tryDeleteBuiltInEquipment(equipmentId);
    if (setState && historyState) {
      this.setHistoryState(historyState);
    }

    return true;
  }

  public deleteCommonObject(objectId: number, setState?: boolean): boolean {
    let historyState: IHistoryCreateObjectsState | undefined;

    historyState = this.mainManager.tryDeleteUnit(objectId);
    if (!historyState) {
      historyState = this.mainManager.tryDeleteEquipment(objectId);
    }
    if (!historyState && this.room) {
      historyState = this.room.deleteConstructive(objectId);
    }

    this.clearSelectCovers();
    this.rebuildScene();
    if (historyState && setState) {
      this.setHistoryState(historyState);
      this.hideMenus();
    }

    return !!historyState;
  }

  public copyCommonObject(objectId: number, setState?: boolean): boolean {
    let unit: ThreeUnit | undefined;
    let newUnit: ThreeUnit | undefined;
    let constructive: ThreeConstructive | undefined;
    let newConstructive: ThreeConstructive | undefined;
    let historyState: IHistoryCreateObjectsState;

    unit = this.mainManager.getUnit(objectId);
    if (!unit) {
      unit = this.mainManager.getEquipment(objectId);
    }
    if (unit) {
      newUnit = this.createCommonObject(unit.getData());
      if (newUnit) {
        newUnit.trySetDefaultPosition();
        historyState = {
          type: HISTORY_STATE_TYPE_CREATE,
          data: {
            objects: [newUnit.getData()],
          },
        };
        if (setState) {
          this.setHistoryState(historyState);
        }
      }
      this.rebuildScene();

      return !!newUnit;
    }
    if (this.room) {
      constructive = this.room.getConstructive(objectId);
      if (constructive) {
        newConstructive = this.room.createConstructiveUnit(
          constructive.getData()
        );
        if (newConstructive) {
          historyState = {
            type: HISTORY_STATE_TYPE_CREATE,
            data: {
              objects: [newConstructive.getData()],
            },
          };
          if (setState) {
            this.setHistoryState(historyState);
          }
        }
        this.rebuildScene();

        return !!newConstructive;
      }
    }

    return false;
  }

  public getUnitSpecCommonObject(objectId: number): {
    data: ISpecItem[] | undefined;
    moduleSpecData: ISpecItem | undefined;
  } {
    let unit: ThreeUnit | undefined;

    unit = this.mainManager.getObjectById(objectId);
    if (!unit) {
      unit = this.mainManager.getEquipment(objectId);
    }
    if (!unit) {
      return {
        data: undefined,
        moduleSpecData: undefined,
      };
    }
    const unitSpec: ISpecItem[] | undefined = unit.getUnitSpec();
    const unitModuleSpecData: ISpecItem | undefined =
      unit.getUnitModuleSpecData(1);

    return {
      data: unitSpec,
      moduleSpecData: unitModuleSpecData,
    };
  }

  public getReplaceCommonObject(
    objectId: number
  ): ISaveReplaceData[] | undefined {
    let unit: ThreeUnit | undefined;

    unit = this.mainManager.getUnit(objectId);
    if (!unit) {
      unit = this.mainManager.getEquipment(objectId);
    }
    if (!unit) {
      return undefined;
    }
    const createObjectData: ISaveReplaceData[] | undefined =
      unit.getSimilarBySizes();
    if (createObjectData) {
      return createObjectData;
    }

    return undefined;
  }

  public tryCreateBuiltInEquipment(objectData: ICreateObjectData): ThreeUnit[] {
    return this.mainManager.tryCreateBuiltInEquipment(objectData);
  }

  public tryReplaceCommonObject(
    objectId: number,
    replaceData: ISaveReplaceData,
    setState?: boolean
  ): boolean {
    const copyReplaceData = CommonHelper.deepCopy(replaceData);
    let unit: ThreeUnit | undefined = this.mainManager.getUnit(objectId);
    let historyState: IHistoryChangeObjectsState;
    let oldSaveData: ISaveUnitData;
    let saveObjectData: ISaveUnitData;

    if (!unit) {
      unit = this.mainManager.getEquipment(objectId);
    }
    if (!unit) {
      return false;
    }
    const replaceUnit: ICreateObjectData | undefined = this.getCreateUnitByUid(
      copyReplaceData.objectData.uid
    );
    if (!replaceUnit) {
      return false;
    }
    oldSaveData = unit.getData();
    const modulePrice: IModulePriceData = this.calculateCreateObjectPrice(
      replaceUnit,
      copyReplaceData.createOptions
    );
    saveObjectData = this.generateSaveObjectData(
      copyReplaceData.objectData.uid,
      copyReplaceData.createOptions,
      modulePrice,
      copyReplaceData.objectData.catalogCode
    );
    saveObjectData.position = { ...unit.positionInfo.position };
    saveObjectData.rotation = { ...unit.positionInfo.rotation };
    saveObjectData.wall = unit.getWallId();
    this.deleteCommonObject(objectId);
    const newUnit: ThreeUnit | undefined =
      this.createCommonObject(saveObjectData);
    if (!newUnit) {
      return false;
    }
    newUnit.setPosition(
      new Vector3(
        saveObjectData.position.x,
        newUnit.defaultYPosition(),
        saveObjectData.position.z
      )
    );
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_CHANGE,
        data: {
          objects: [
            {
              oldData: oldSaveData,
              newData: newUnit.getData(),
            },
          ],
        },
      };
      this.setHistoryState(historyState);
    }
    this.loadPrices(this.getModuleLoadingOfferIds(modulePrice)).then(() => {
      this.rebuildScene();
    });

    return true;
  }

  public tryDeleteSelectObject(): boolean {
    let currentCover: Object3D | undefined;

    currentCover = this.editor.getCurrentCover();
    if (
      currentCover &&
      currentCover.userData.commonObject instanceof CommonObject
    ) {
      return this.deleteCommonObject(
        currentCover.userData.commonObject.getId(),
        true
      );
    }

    return false;
  }

  public clearCreateBuiltInEquipment() {
    return this.mainManager.clearCreateBuiltInEquipment();
  }

  public applyContextAction(data: IContextIcon): boolean {
    let currentCover: Object3D | undefined;
    let currentCommonObject: ThreeUnit;
    let result: boolean = false;
    let settingsMenu: ISettingsMenu;
    let replaceData: IReplaceData;

    currentCover = this.editor.getCurrentCover();
    if (
      currentCover &&
      currentCover.userData.commonObject instanceof ThreeUnit
    ) {
      currentCommonObject = currentCover.userData.commonObject;
      if (data.actionData.id === currentCommonObject.getId()) {
        switch (data.action) {
          case ACTION_DELETE:
            if (currentCommonObject instanceof ThreeBuiltInEquipment) {
              result = this.deleteBuiltInEquipment(data.actionData.id, true);
            } else {
              result = this.deleteCommonObject(data.actionData.id, true);
            }
            break;
          case ACTION_SETTINGS:
            settingsMenu = {
              visible: true,
              unitId: currentCommonObject.getId(),
              data: currentCommonObject.getData(),
              createObjectData: this.getCreateObjectByUid(
                currentCommonObject.getUid()
              ),
              priceData: currentCommonObject.getPriceData(),
              groups: currentCommonObject.getSettingsGroups(),
            };
            this.reduxDispatch({
              type: SHOW_SETTINGS_MENU,
              payload: settingsMenu,
            });
            break;
          case ACTION_COPY:
            result = this.copyCommonObject(data.actionData.id, true);
            break;
          case ACTION_REPLACE:
            replaceData = {
              visible: true,
              unitId: currentCommonObject.getId(),
              data: this.getReplaceCommonObject(data.actionData.id),
            };
            this.reduxDispatch({
              type: CHANGE_REPLACE_DATA,
              payload: replaceData,
            });
            break;
          case ACTION_SHOW_UNIT_SPEC:
            const unitSpecData: {
              data: ISpecItem[] | undefined;
              moduleSpecData: ISpecItem | undefined;
            } = this.getUnitSpecCommonObject(data.actionData.id);
            this.reduxDispatch({
              type: CHANGE_UNIT_SPEC,
              payload: {
                visible: true,
                unitId: currentCommonObject.getId(),
                data: unitSpecData.data,
                moduleSpecData: unitSpecData.moduleSpecData,
              } as IUnitSpecData,
            });
            break;
          default:
            debugger;
            break;
        }
      }
    }
    if (data.hide) {
      this.hideContextMenu();
      this.hideSettingsMenu();
    }

    return result;
  }

  public rebuildScene(): boolean {
    return this.rebuildManager.rebuildScene();
  }

  public historyUndo() {
    this.historyManager.undoState();
    this.rebuildScene();
    this.afterChangeHistory();
  }

  public historyRedo() {
    this.historyManager.redoState();
    this.rebuildScene();
    this.afterChangeHistory();
  }

  public setHistoryState(state: IHistoryState) {
    this.updateProjectData();
    this.historyManager.addState(state);
    this.afterChangeHistory();
  }

  public resetHistoryState() {
    this.historyManager.resetHistoryState();
    this.afterChangeHistory();
  }

  public isShowTabletops(): boolean {
    return this.kitchenOptions.showTabletops;
  }

  public isShowAprons(): boolean {
    return this.kitchenOptions.showAprons;
  }

  public isShowCorners(): boolean {
    return this.kitchenOptions.showCorners;
  }

  public isShowPlinths(): boolean {
    return this.kitchenOptions.showPlinths;
  }

  public isShowIntegratedHandles(): boolean {
    return this.kitchenOptions.showIntegratedHandles;
  }

  public isShowLegs(): boolean {
    return this.kitchenOptions.showLegs;
  }

  public isEuroZapil(): boolean {
    return this.kitchenOptions.euroZapil !== undefined
      ? this.kitchenOptions.euroZapil
      : false;
  }

  public setCheckSore(value: boolean) {
    this.kitchenOptions.checkStore = value;
    this.reduxDispatch({
      type: CHANGE_CHECK_STORE,
      payload: value,
    });
    this.disableCatalog();
    this.hideMenus();
  }

  public checkRebuildPlinths(facadeId: string): boolean {
    let technologMap: ITechnologMap | undefined;

    technologMap = this.getTechnologMap(facadeId);
    if (technologMap && technologMap.plinths?.checkRebuild !== undefined) {
      return technologMap.plinths.checkRebuild;
    }

    return true;
  }

  public setShowTabletops(value: boolean, setState?: boolean) {
    let historyState: IHistoryAccessoryState;
    let oldData: boolean;

    oldData = this.kitchenOptions.showTabletops;
    this.kitchenOptions.showTabletops = value;
    this.reduxDispatch({
      type: CHANGE_KITCHEN_SHOW_TABLETOPS,
      payload: value,
    });
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_TABLETOP,
          booleanData: {
            oldData: oldData,
            newData: this.kitchenOptions.showTabletops,
          },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public getNeedSave(): boolean {
    return this.kitchenOptions.needSave;
  }

  //TODO
  public getEuroZapil(): number {
    return 0;
  }

  public setEuroZapil(value: boolean, setState?: boolean) {
    let historyState: IHistoryEuroZapilState;
    let oldData: boolean;

    oldData = this.kitchenOptions.euroZapil;
    this.kitchenOptions.euroZapil = value;
    this.reduxDispatch({
      type: CHANGE_KITCHEN_EURO_ZAPIL,
      payload: value,
    });
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_EURO_ZAPIL,
        data: {
          oldData: oldData,
          newData: this.kitchenOptions.euroZapil,
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public isEnableServices(): boolean {
    return this.kitchenOptions.enableServices !== undefined
      ? this.kitchenOptions.enableServices
      : false;
  }

  public setEnableServices(value: boolean, setState?: boolean) {
    if (this.kitchenOptions.enableServices === undefined) {
      return;
    }
    let historyState: IHistoryServiceState;
    let oldData: boolean;

    oldData = this.kitchenOptions.enableServices;
    this.kitchenOptions.enableServices = value;
    this.reduxDispatch({
      type: CHANGE_KITCHEN_ENABLE_SERVICES,
      payload: value,
    });
    this.setServiceCount(0);
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_SERVICE,
        data: {
          type: "all",
          oldData: oldData,
          newData: this.kitchenOptions.enableServices,
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public isEnableAutoServices() {
    return this.kitchenOptions.enableAutoServices;
  }

  public setServiceCount(value: number, service?: IProjectServiceData) {
    let services: IProjectServiceData[] | undefined;
    let item: IProjectServiceData;

    services = this.getProjectServices();
    if (!services) {
      return;
    }
    for (item of services) {
      if (!service || item.id === service.id) {
        item.count = value;
        item.sum = item.count * item.price;
      }
    }

    this.setProjectServices(services);
    this.rebuildScene();
  }

  public setProjectServices(services: IProjectServiceData[]) {
    this.projectData.services = services;
    this.setProjectDataRedux();
  }

  public getProjectServices(): IProjectServiceData[] | undefined {
    return this.projectData.services
      ? this.projectData.services
      : this.calculateProjectServices();
  }

  public calculateProjectServices(): IProjectServiceData[] | undefined {
    if (!this.dataManager.services) {
      return undefined;
    }
    let serviceItem: IServiceData;
    let projectServices: IProjectServiceData[] = [];
    let projectService: IProjectServiceData;

    for (serviceItem of this.dataManager.services) {
      projectService = {
        id: serviceItem.id,
        name: serviceItem.name,
        importName: serviceItem.importName,
        vendorCode: serviceItem.vendorCode,
        price: serviceItem.price,
        oldPrice: serviceItem.oldPrice,
        editable: serviceItem.editable,
        editableAmount: serviceItem.editableAmount,
        extraParams: serviceItem.extraParams,
        isDefault: serviceItem.isDefault,
        count: 0,
        sum: 0,
        sort: serviceItem.sort || 500,
        autoCount: 0,
      };
      projectServices.push(projectService);
    }

    return projectServices;
  }

  public setServiceIsAuto(value: boolean, service?: IProjectServiceData) {
    let services: IProjectServiceData[] | undefined;
    let item: IProjectServiceData;

    services = this.getProjectServices();
    if (!services) {
      return;
    }
    for (item of services) {
      if (!service || item.id === service.id) {
        item.isAuto = value;
        if (item.isAuto && item.autoCount !== undefined) {
          item.count = item.autoCount;
          item.sum = item.count * item.price;
        }
      }
    }

    this.setProjectServices(services);
    this.rebuildScene();
  }

  public setEnableAutoServices(value: boolean, setState?: boolean) {
    if (this.kitchenOptions.enableAutoServices === undefined) {
      return;
    }
    let historyState: IHistoryServiceState;
    let oldData: boolean;

    oldData = this.kitchenOptions.enableAutoServices;
    this.kitchenOptions.enableAutoServices = value;
    this.reduxDispatch({
      type: CHANGE_KITCHEN_ENABLE_AUTO_SERVICES,
      payload: value,
    });
    this.setServiceIsAuto(value);
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_SERVICE,
        data: {
          type: "auto",
          oldData: oldData,
          newData: this.kitchenOptions.enableAutoServices,
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public setShowAprons(value: boolean, setState?: boolean) {
    let historyState: IHistoryAccessoryState;
    let oldData: boolean;

    oldData = this.kitchenOptions.showAprons;
    this.kitchenOptions.showAprons = value;
    this.mainManager.clearSelfVisibleAprons();
    this.reduxDispatch({
      type: CHANGE_KITCHEN_SHOW_APRONS,
      payload: value,
    });
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_APRON,
          booleanData: {
            oldData: oldData,
            newData: this.kitchenOptions.showAprons,
          },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public facadeOpacity(): void {
    const sceneChildren: ThreeUnit[] = this.mainManager.getUnits();

    if (sceneChildren[sceneChildren.length - 1].facades.length) {
      sceneChildren[sceneChildren.length - 1].facades.forEach(
        (facade: ThreeFacade) => {
          if (facade.bodyMaterial) {
            facade.bodyMaterial.transparent = true;
            facade.bodyMaterial.needsUpdate = true;
            facade.bodyMaterial.opacity >= 1
              ? (facade.bodyMaterial.opacity = 0.5)
              : (facade.bodyMaterial.opacity += 0.5);
          }
        }
      );
    } else {
      window.alert("Добавьте фасады!");
      return;
    }
  }

  // Feature for aprons/corners displaying in Form-3d
  public setShowPart(parts: ThreeApron[] | ThreeCorner[] | undefined) {
    parts?.forEach((part) => {
      if (!part.view3d.visible) {
        part.view3d.visible = true;
      } else {
        part.view3d.visible = false;
      }
    });
  }

  public sceneCheck() {
    return this.mainManager.getUnits();
  }

  public setShowCorners(value: boolean, setState?: boolean) {
    let historyState: IHistoryAccessoryState;
    let oldData: boolean;

    oldData = this.kitchenOptions.showCorners;
    this.kitchenOptions.showCorners = value;
    this.reduxDispatch({
      type: CHANGE_KITCHEN_SHOW_CORNERS,
      payload: value,
    });
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_CORNER,
          booleanData: {
            oldData: oldData,
            newData: this.kitchenOptions.showCorners,
          },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public getLegsData(facadeId: string): IProjectLegsData | undefined {
    let technologMap: ITechnologMap | undefined;
    let legsData: IProjectLegsData;
    let legsHeight: number | undefined;

    technologMap = this.getTechnologMap(facadeId);
    if (!technologMap) {
      return undefined;
    }
    legsHeight =
      this.getProjectLegsHeight() || technologMap.bottomUnits.legsHeight;
    if (!legsHeight) {
      return undefined;
    }
    legsData = {
      height: legsHeight,
    };
    if (technologMap.legs) {
      if (technologMap.legs.defaultHeight) {
        legsData.defaultHeight = technologMap.legs.defaultHeight;
      }
      if (technologMap.legs.heights) {
        legsData.heights = technologMap.legs.heights;
      }
    }

    return legsData;
  }

  public setLegsHeight(height: number, setState?: boolean) {
    let historyState: IHistoryChangeObjectsState;
    let changeObjects: IHistoryObjectData[];
    let legsData: IProjectLegsData | undefined = this.getLegsData(
      this.getBottomFacadeMaterial()?.facade || "default"
    );

    if (!legsData || !legsData.heights || !legsData.heights.includes(height)) {
      return;
    }
    this.projectData.legsHeight = height;
    changeObjects = this.mainManager.setLegsHeight(height);
    this.rebuildScene();
    if (setState && changeObjects.length) {
      historyState = {
        type: HISTORY_STATE_TYPE_CHANGE,
        data: {
          objects: changeObjects,
        },
      };
      this.setHistoryState(historyState);
    } else {
      this.updateProjectData();
      this.autoSaveProject();
    }
  }

  public setShowLegs(value: boolean, setState?: boolean) {
    let historyState: IHistoryAccessoryState;
    let oldData: boolean;

    oldData = this.kitchenOptions.showLegs;
    this.kitchenOptions.showLegs = value;
    this.reduxDispatch({
      type: CHANGE_KITCHEN_SHOW_LEGS,
      payload: value,
    });
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_LEG,
          booleanData: {
            oldData: oldData,
            newData: this.kitchenOptions.showLegs,
          },
        },
      };
      this.setHistoryState(historyState);
    } else {
      this.autoSaveProject();
    }
  }

  public setShowPlinths(value: boolean, setState?: boolean) {
    let historyState: IHistoryAccessoryState;
    let oldData: boolean;

    oldData = this.kitchenOptions.showPlinths;
    this.kitchenOptions.showPlinths = value;
    this.reduxDispatch({
      type: CHANGE_KITCHEN_SHOW_PLINTHS,
      payload: value,
    });
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_PLINTH,
          booleanData: {
            oldData: oldData,
            newData: this.kitchenOptions.showPlinths,
          },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public setTopFacadeMaterial(data: IFacadeMaterialData, setState?: boolean) {
    let historyState: IHistoryAccessoryState;
    let oldData: IFacadeMaterialData | undefined;

    if (data.level && data.level !== LEVEL_TOP) {
      return;
    }

    oldData = this.dataManager.topFacadeMaterial;
    this.dataManager.setTopFacadeMaterial(data);
    this.mainManager.setTopFacadeMaterial(data);
    this.tryReplaceCorpusMaterial(data);
    this.preloadPrices();
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_FACADE,
          level: LEVEL_TOP,
          material: {
            oldData: oldData,
            newData: data,
          },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public setTabletop(data: ITabletopData, setState?: boolean) {
    let cornerMaterial: ICornerData | undefined;
    let historyState: IHistoryAccessoryState;
    let oldData: ITabletopData | undefined;

    if (data.corner) {
      cornerMaterial = this.getCornerMaterial(data.corner);
      if (cornerMaterial.id !== data.corner) {
        cornerMaterial = undefined;
      }
    }
    oldData = this.dataManager.selectTabletop;
    this.dataManager.setTabletop(data, cornerMaterial);
    this.mainManager.setTabletop(data, cornerMaterial);
    this.rebuildManager.clearUnitDetails();
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_TABLETOP,
          material: { oldData: oldData, newData: data },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public setApron(data: IApronData, setState?: boolean) {
    let historyState: IHistoryAccessoryState;
    let oldData: IApronData | undefined;

    oldData = this.dataManager.selectApron;
    this.dataManager.setApron(data);
    this.mainManager.setApron(data);
    this.rebuildManager.clearUnitDetails();
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_APRON,
          material: {
            oldData: oldData,
            newData: CommonHelper.deepCopy(data),
          },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public setApronHeight(
    apronData: IApronData,
    height: number,
    setState?: boolean
  ) {
    this.dataManager.setApronHeight(apronData, height, setState);
  }

  public setTabletopHeight(
    tabletopData: ITabletopData,
    height: number,
    setState?: boolean
  ) {
    this.dataManager.setTabletopHeight(tabletopData, height, setState);
  }

  public setCorner(data: ICornerData, setState?: boolean) {
    let historyState: IHistoryAccessoryState;
    let oldData: ICornerData | undefined;

    oldData = this.dataManager.selectCorner;
    this.dataManager.setCorner(data);
    this.mainManager.setCorner(data);
    this.rebuildManager.clearUnitDetails();
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_CORNER,
          material: { oldData: oldData, newData: data },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public setPlinth(data: IPlinthData, units?: ThreeUnit[], setState?: boolean) {
    let historyState: IHistoryAccessoryState;
    let oldData: IPlinthData | undefined;

    oldData = this.dataManager.selectPlinth;
    this.dataManager.setPlinth(data);
    this.mainManager.setPlinth(data, units);
    this.rebuildManager.clearUnitDetails();
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_PLINTH,
          material: { oldData: oldData, newData: data },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public setPlinthHeight(
    plinthData: IPlinthData,
    height: number,
    setState?: boolean
  ) {
    this.dataManager.setPlinthHeight(plinthData, height, setState);
  }

  public setFloor(data: IMaterialData, setState?: boolean) {
    if (!this.room) {
      throw new Error("error-KitchenService-setFloor");
    }
    let historyState: IHistoryAccessoryState;
    let oldData: IMaterialData | undefined;

    oldData = this.dataManager.selectFloor;
    this.dataManager.setFloor(data);
    this.room.setFloorMaterial(data);
    this.rebuildManager.clearUnitDetails();
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_FLOOR,
          material: { oldData: oldData, newData: data },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public setWall(data: IMaterialData, wallId?: number, setState?: boolean) {
    if (!this.room) {
      throw new Error("error-KitchenService-setWall");
    }
    let historyState: IHistoryAccessoryState;
    let oldData: IMaterialData | undefined;

    oldData = this.dataManager.selectWall;
    this.dataManager.setWall(data);
    this.room.setWallMaterial(data, wallId);
    this.rebuildManager.clearUnitDetails();
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_WALL,
          material: { oldData: oldData, newData: data },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public setSelectFacade(facadeId: string, level: TLevel, setState?: boolean) {
    let facadeMaterial: IFacadeMaterialData | undefined;
    let newCorpusMaterial: IMaterialData;
    let historyState: IHistoryFacadeState;
    let oldData: IHistoryFacade | undefined;

    facadeMaterial = this.dataManager.getFacadeMaterialByFacadeId(
      facadeId,
      level
    );
    if (!facadeMaterial) {
      return undefined;
    }
    switch (level) {
      case LEVEL_BOTTOM:
        if (this.dataManager.bottomFacadeMaterial) {
          oldData = {
            facadeId: this.dataManager.bottomFacadeMaterial.facade,
            level: LEVEL_BOTTOM,
          };
        }
        this.dataManager.setBottomFacadeMaterial(facadeMaterial);
        break;
      case LEVEL_TOP:
        if (this.dataManager.topFacadeMaterial) {
          oldData = {
            facadeId: this.dataManager.topFacadeMaterial.facade,
            level: LEVEL_TOP,
          };
        }
        this.dataManager.setTopFacadeMaterial(facadeMaterial);
        break;
    }
    newCorpusMaterial = this.dataManager.getCorpusMaterial(
      facadeMaterial.corpusMaterial,
      facadeMaterial
    );
    this.dataManager.setCorpusMaterial(newCorpusMaterial);
    if (newCorpusMaterial.plinthMaterial) {
      const plinthData: IPlinthData = this.getPlinthMaterial(
        newCorpusMaterial.plinthMaterial
      );
      this.dataManager.setPlinth(plinthData);
    }
    this.preloadPrices();
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_FACADE,
        data: {
          oldData: oldData,
          newData: { facadeId: facadeId, level: level },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public setBottomFacadeMaterial(
    data: IFacadeMaterialData,
    setState?: boolean
  ) {
    let historyState: IHistoryAccessoryState;
    let oldData: IFacadeMaterialData | undefined;

    oldData = this.dataManager.bottomFacadeMaterial;
    this.dataManager.setBottomFacadeMaterial(data);
    const units: ThreeUnit[] = this.mainManager.setBottomFacadeMaterial(data);
    this.tryReplaceCorpusMaterial(data);
    this.tryReplacePlinthMaterial(data, units);
    this.tryReplaceTabletopMaterial(data);
    this.preloadPrices();
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_FACADE,
          level: LEVEL_BOTTOM,
          material: { oldData: oldData, newData: data },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public setSelectKitCode(kitCode: TSelectItem, setState?: boolean) {
    let oldData: TSelectItem | undefined;
    let historyState: IHistoryAccessoryState;

    oldData = this.getSelectKitCode();
    this.dataManager.setSelectKitCode(kitCode);
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: "kitCode",
          selectData: { oldData: oldData, newData: kitCode },
        },
      };
      this.setHistoryState(historyState);
    }
    this.rebuildScene();
  }

  public getSelectKitCode(): TSelectItem | undefined {
    return this.dataManager.selectKitCode;
  }

  public tryReplaceCorpusMaterial(data: IFacadeMaterialData) {
    if (data.corpusMaterial) {
      const corpusMaterial: IMaterialData = this.getCorpusMaterial(
        data.corpusMaterial
      );
      this.setCorpusMaterial(corpusMaterial);
    }
  }

  public setCorpusMaterial(data: IMaterialData, setState?: boolean) {
    let historyState: IHistoryAccessoryState;
    let oldData: IMaterialData | undefined;

    oldData = this.dataManager.selectCorpusMaterial;
    this.dataManager.setCorpusMaterial(data);
    const units: ThreeUnit[] = this.mainManager.setCorpusMaterial(data);
    this.tryReplacePlinthMaterial(data, units);
    this.preloadPrices();
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_ACCESSORY,
        data: {
          type: GOOD_TYPE_CORPUS,
          material: { oldData: oldData, newData: data },
        },
      };
      this.setHistoryState(historyState);
    }
  }

  public tryReplacePlinthMaterial(data: IMaterialData, units?: ThreeUnit[]) {
    if (data.plinthMaterial) {
      let plinthData: IPlinthData;
      let plinthHeight: number;

      plinthData = CommonHelper.deepCopy(
        this.getPlinthMaterial(data.plinthMaterial)
      );
      plinthHeight = this.getPlinthHeight();
      if (plinthData.heights && plinthData.heights.includes(plinthHeight)) {
        plinthData.height = plinthHeight;
      }
      this.setPlinth(plinthData, units);
    }
  }

  public getProjectModulesPrice(): number {
    return (
      this.projectData.priceData.units + this.projectData.priceData.facades
    );
  }

  public tryReplaceTabletopMaterial(data: IMaterialData) {
    let tabletop: ITabletopData | undefined;

    if (data.tabletopMaterial) {
      tabletop = this.getTabletopMaterial(data.tabletopMaterial.id);
      if (
        tabletop &&
        tabletop.heights &&
        tabletop.heights.includes(data.tabletopMaterial.height)
      ) {
        tabletop.height = data.tabletopMaterial.height;
        this.setTabletop(tabletop);
      }
    }
  }

  public calculateProjectPrice(): boolean {
    const price = this.priceManager.calculate();
    if (
      CommonHelper.md5(price) !== CommonHelper.md5(this.projectData.priceData)
    ) {
      this.projectData.priceData = price;
      this.setProjectDataRedux();
      return true;
    }

    return false;
  }

  public calculateProjectGoods(): IProjectGoods {
    let goods: IProjectGoods;

    goods = {
      modules: this.calculateApiProjectDetailItems(
        this.mainManager.getObjects()
      ),
      facades: this.calculateApiProjectDetailItems(this.getSpecFacades()),
      glasses: this.calculateApiProjectDetailItems(this.getSpecGlasses()),
      materials: this.calculateApiProjectDetailItems(
        this.getSpecFacadeMaterials()
      ),
      tabletops: this.calculateApiProjectDetailItems(this.getSpecTabletops()),
      edges: this.calculateApiProjectDetailItems(this.getSpecEdges()),
      aprons: this.calculateApiProjectDetailItems(this.getSpecAprons()),
      corners: this.calculateApiProjectDetailItems(this.getSpecCorners()),
      planks: this.calculateApiProjectDetailItems(this.getSpecPlanks()),
      plinths: this.calculateApiProjectDetailItems(this.getSpecPlinths()),
      accessories: this.calculateApiProjectDetailItems(
        this.getSpecAccessories()
      ),
      services: this.calculateApiProjectServiceItems(),
      equipments: this.calculateApiProjectDetailItems(this.getSpecEquipments()),
      handles: this.calculateApiProjectDetailItems(this.getSpecHandles()),
    };

    return goods;
  }

  public getProjectId(): string {
    return this.projectData.id;
  }

  public getProjectPrice(): IProjectPriceData {
    return this.projectData.priceData;
  }

  public getPlinthDepth(): number {
    return this.dataManager.getPlinthDepth();
  }

  public getPlinthHeight(): number {
    return this.getProjectLegsHeight() || this.dataManager.getPlinthHeight();
  }

  public getApronHeight(): number {
    return this.dataManager.getApronHeight();
  }

  public getTabletopHeight(): number {
    return this.dataManager.getTabletopHeight();
  }

  public getTabletopFrontGap(facadeId?: string): number {
    return this.dataManager.getTabletopFrontGap(facadeId || "default");
  }

  public getTabletopWidth(facadeId?: string): number {
    return this.dataManager.getTabletopWidth(facadeId || "default");
  }

  public getCornerHeight(): number {
    return this.dataManager.getCornerHeight();
  }

  public getIntegrationHandleHeight(): number {
    return this.dataManager.getIntegrationHandleHeight();
  }

  public getCornerWidth(): number {
    return this.dataManager.getCornerWidth();
  }

  public getIntegrationHandleWidth(): number {
    return this.dataManager.getIntegrationHandleWidth();
  }

  public getCornerContourPath(): TPoint2D[] | undefined {
    return this.dataManager.getCornerContourPath();
  }

  public getTabletopMaterial(materialId?: string): ITabletopData {
    return this.dataManager.getTabletopMaterial(materialId);
  }

  public getApronMaterial(materialId?: string): IApronData {
    return this.dataManager.getApronMaterial(materialId);
  }

  public getCorpusMaterial(
    materialId?: string,
    facadeMaterial?: IFacadeMaterialData
  ): IMaterialData {
    return this.dataManager.getCorpusMaterial(materialId, facadeMaterial);
  }

  public getFrameMaterial(materialId?: string): IMaterialData {
    // TODO заменить на данне из АПИ
    return {
      id: "black",
      title: "Черный",
      color: "#575656",
    };
  }

  public changeUnitPosition(
    unitId: number,
    type: TSideType,
    delta: number,
    setState?: boolean
  ): boolean {
    let unit: ThreeUnit | undefined;
    let oldPosition: Vector3;
    let newPosition: Vector3;
    let oldPositionInfo: IPositionInfo | undefined;

    unit = this.getObjectById(unitId);
    if (unit) {
      oldPositionInfo = unit.getPositionInfo();
      oldPosition = unit.getPosition().clone();
      newPosition = unit.getPosition().clone();
      switch (type) {
        case SIDE_TYPE_BOTTOM:
        case SIDE_TYPE_TOP:
          newPosition.y += delta;
          break;
        case SIDE_TYPE_BACK:
          newPosition.z += delta;
          break;
        case SIDE_TYPE_FRONT:
          newPosition.z += delta;
          break;
        case SIDE_TYPE_RIGHT:
          newPosition.x += delta;
          break;
        case SIDE_TYPE_LEFT:
          newPosition.x += delta;
          break;
      }

      if (
        newPosition.x !== oldPosition.x ||
        newPosition.y !== oldPosition.y ||
        newPosition.z !== oldPosition.z
      ) {
        if (unit.trySetPosition(newPosition, true)) {
          unit.afterTryMove(true, true);
        }
        this.reduxDispatch({
          type: SHOW_SETTINGS_MENU,
          payload: {
            visible: true,
            unitId: unit.getId(),
            data: unit.getData(),
            createObjectData: this.getCreateObjectByUid(unit.getUid()),
            price: unit.getPrice(),
            groups: unit.getSettingsGroups(),
          },
        });
        this.rebuildScene();
        if (setState) {
          this.setHistoryState({
            type: HISTORY_STATE_TYPE_MOVE,
            data: {
              objects: [
                {
                  objectId: unit.getId(),
                  oldPosition: oldPositionInfo,
                  newPosition: unit.getPositionInfo(true),
                },
              ],
            },
          } as IHistoryMoveState);
        }

        return true;
      }
    }

    return false;
  }

  public getCornerMaterial(materialId?: string): ICornerData {
    return this.dataManager.getCornerMaterial(materialId);
  }

  public getIntegratedHandleMaterial(materialId?: string): IIntegratedHandleData {
    return this.dataManager.getIntegratedHandleMaterial(materialId);
  }

  public getPlinthMaterial(
    materialId?: string,
    corpusMaterialId?: string
  ): IPlinthData {
    return this.dataManager.getPlinthMaterial(materialId, corpusMaterialId);
  }

  public rebuildRoomSizes(levelBoxes: TLevelBoxes) {
    if (!this.room) {
      throw new Error("error-KitchenService-rebuildRoomSizes");
    }
    this.room.rebuildSizes(levelBoxes);
  }

  public setProjectToOrder(order: IProjectOrder) {
    this.projectData.orderId = order.id;
    this.projectData.orderTime = order.createTime;
    this.setProjectDataRedux(true);
  }

  public setProjectOffer(
    offer: IImportOffer,
    count: number,
    part?: number,
    isUpdate?: boolean
  ) {
    this.mainManager.setProjectOffer(offer, count, part);
    if (isUpdate !== false) {
      this.updateProjectOffers();
    }
  }

  public setAutoProjectOffer(
    offer: IImportOffer,
    count: number,
    part?: number,
    isUpdate?: boolean
  ) {
    this.mainManager.setAutoProjectOffer(offer, count, part);
    if (isUpdate !== false) {
      this.updateProjectOffers();
    }
  }

  public setUnitOffer(unitId: number, offer: IImportOffer, count: number) {
    this.mainManager.setUnitOffer(unitId, offer, count);
  }

  public updateProjectOffers(loadPrices?: boolean) {
    let extraOffers: IProjectOffers | undefined;

    extraOffers = this.calculateProjectExtraOffers();
    if (
      extraOffers &&
      (!this.projectData.extraOffers ||
        CommonHelper.md5(extraOffers) !==
          CommonHelper.md5(this.projectData.extraOffers))
    ) {
      this.projectData.extraOffers = extraOffers;
      this.setProjectDataRedux();
      this.autoSaveProject();
    }
    if (loadPrices !== false) {
      this.loadExtraOffersPrices(this.projectData.extraOffers).then(
        (prices: IUnitKitPrices) => {
          this.mainManager.setProjectOffersPrices(prices);
          if (!this.calculateProjectPrice()) {
            this.setProjectDataRedux();
          }
        }
      );
    }
  }

  public loadExtraOffersPrices(extraOffers?: {
    [key: string]: IProjectOffer;
  }): Promise<IUnitKitPrices> {
    return new Promise<IUnitKitPrices>((resolve, reject) => {
      if (!extraOffers) {
        resolve({});
        return;
      }
      let id: string;
      let loafOffers: { [key: string]: string } = {};
      let loafOfferIds: string[];

      for (id in extraOffers) {
        if (!this.checkLoadOfferPrice(extraOffers[id].id)) {
          loafOffers[extraOffers[id].id] = extraOffers[id].id;
        }
      }
      loafOfferIds = Object.keys(loafOffers);
      if (!loafOfferIds.length) {
        resolve({});
        return;
      }
      this.loadPrices(loafOfferIds)
        .then((prices: IUnitKitPrices) => {
          resolve(prices);
        })
        .catch(() => {
          reject();
        });
    });
  }

  public getProjectOffers(): IProjectOffers | undefined {
    return this.mainManager.getProjectOffers();
  }

  public getProjectOffer(offer: IImportOffer): IProjectOffer | undefined {
    return this.mainManager.getProjectOffer(offer);
  }

  public getCatalogOffers(
    offers: IImportOffer[],
    isActive?: boolean
  ): IProjectOffer[] {
    let catalogOffers: IProjectOffer[] = [];
    let offer: IImportOffer;

    for (offer of offers) {
      catalogOffers.push(
        this.getCatalogOffer(offer, 0, PRICE_CELL_EXTRA_OFFERS)
      );
    }
    if (isActive) {
      catalogOffers = catalogOffers.filter((value) => value.active !== false);
    }

    catalogOffers.sort((a, b) => {
      if (b.active && !a.active) {
        return 1;
      }
      if (a.active && !b.active) {
        return -1;
      }
      if (a.sort && b.sort) {
        return b.sort - a.sort;
      }
      if (a.sort) {
        return -1;
      }
      if (b.sort) {
        return 1;
      }
      return 0;
    });

    return catalogOffers;
  }
  public getCatalogOffer(
    offer: IImportOffer,
    unitId: number,
    cell: TPriceCell
  ): IProjectOffer {
    let externalId: "externalGuid" | "vendorCode";
    let catalogOffer: IProjectOffer;

    externalId = this.getOfferExternalId();
    catalogOffer = {
      id: offer[externalId],
      offer: offer,
      count: 0,
      unitId: unitId,
      cell: cell,
      sort: offer.sort,
    };
    if (this.dataManager.prices[offer[externalId]]) {
      catalogOffer.price = this.dataManager.prices[offer[externalId]].price;
      catalogOffer.oldPrice =
        this.dataManager.prices[offer[externalId]].oldPrice;
      catalogOffer.active = this.dataManager.prices[offer[externalId]].active;
      catalogOffer.stock = this.dataManager.prices[offer[externalId]].stock;
    }

    return catalogOffer;
  }

  public searchOffers(
    searchText: string,
    filter?: ISpecFormFilter
  ): Promise<IImportOffer[]> {
    return this.dataManager.searchOffers(searchText, filter);
  }

  public getOffersByCatalogCode(code: string): IImportOffer[] {
    return this.dataManager.unitKits && this.dataManager.unitKits[code]
      ? this.dataManager.unitKits[code]
      : [];
  }

  public getOfferById(offerId: string): IImportOffer | undefined {
    return this.kitchenHelper.getOfferById(offerId);
  }

  public getPriceCellByGoodType(type: TGoodType): TPriceCell {
    switch (type) {
      case GOOD_TYPE_TABLETOP:
        return PRICE_CELL_TABLETOP;
      case GOOD_TYPE_APRON:
        return PRICE_CELL_APRON;
      case GOOD_TYPE_CORNER:
        return PRICE_CELL_CORNER;
      case GOOD_TYPE_CORNICE:
        return PRICE_CELL_CORNICE;
      case GOOD_TYPE_PLINTH:
        return PRICE_CELL_PLINTH;
      case GOOD_TYPE_FACADE:
        return PRICE_CELL_FACADE;
      default:
        return PRICE_CELL_NONE;
    }
  }

  public getDetailPriceDataById(
    offerId: string,
    unitId: number,
    cell: TPriceCell,
    cellIndex: number
  ): IDetailPriceData {
    if (!this.isCatalogCalculatePrice()) {
      return {
        id: "",
        price: 0,
        errors: [],
        note: i18n.t("расчет не предусмотрен"),
        unitId: unitId,
        cell: cell,
        count: 1,
        cellIndex: cellIndex,
        sizes: { width: 0, height: 0, depth: 0 },
      };
    }
    if (!this.dataManager.prices[offerId]) {
      return {
        id: "",
        price: 0,
        errors: [
          { id: "notFount", message: i18n.t("Цена не получена с сервера") },
        ],
        unitId: unitId,
        cell: cell,
        count: 1,
        cellIndex: cellIndex,
        sizes: { width: 0, height: 0, depth: 0 },
      };
    } else {
      return {
        id: offerId,
        price: this.dataManager.prices[offerId].price,
        oldPrice: this.dataManager.prices[offerId].oldPrice,
        active: this.dataManager.prices[offerId].active,
        stock: this.dataManager.prices[offerId].stock,
        errors: [],
        unitId: unitId,
        cell: cell,
        count: 1,
        cellIndex: cellIndex,
        sizes: { width: 0, height: 0, depth: 0 },
      };
    }
  }

  public calculateUnitPriceParams(unit: ThreeUnit): IModulePriceParams {
    return {
      catalogCode: unit.getCatalogCode(),
      level: unit.getLevel(),
      width: unit.getWidth(),
      height: unit.getHeight(),
      depth: unit.getDepth(),
      sideType: unit.getSideType(),
      corpusMaterial: unit.getCorpusMaterialId(),
      facadeMaterial: unit.getPriceFacadeMaterialId(),
      facadeMaterial2: unit.getPriceFacadeMaterial2Id(),
      frameMaterial: unit.getFrameMaterialId(),
      panelMaterial: unit.getPanelMaterialId(),
      corpus: unit.getCorpusPriceParams(),
      facades: unit.getFacadesPriceParams(),
      calculateType: unit.getCalculateType(),
      notPrice: unit.getNotPrice(),
      extraOffers: unit.getExtraOffersPriceParams(),
      checkCatalogType: unit.getCheckCatalogType(),
      part: unit.getOrderPart(),
      unitId: unit.getId(),
      cell: PRICE_CELL_MODULE,
    };
  }

  public getImportGood(
    offerId: string,
    catalogCode?: string
  ): IImportOffer | undefined {
    return this.dataManager.getImportGood(offerId, catalogCode);
  }

  public getDetailOffer(
    detail: ThreeKUnitDetail,
    type: string
  ): IImportOffer | undefined {
    return this.dataManager.getDetailOffer(detail, type);
  }

  public getFacadeOffer(facade: ThreeFacade): IImportOffer | undefined {
    return this.dataManager.getFacadeOffer(facade);
  }

  public getHandleOffer(handle: ThreeHandle): IImportOffer | undefined {
    return this.dataManager.getHandleOffer(handle);
  }

  public getLegOffer(leg: ThreeLeg): IImportOffer | undefined {
    return this.dataManager.getLegOffer(leg);
  }

  public getFurnitureOffer(
    functionalType: string,
    sizes?: TSizes3D
  ): IImportOffer | undefined {
    return this.dataManager.getFurnitureOffer(functionalType, sizes);
  }

  public getDetailKitPriceData(
    detail: ThreeKUnitDetail | ThreeFacade | ThreeHandle,
    offers: IImportOffer[],
    count: number = 1,
    offersCount: { [key: string]: number },
    type: TGoodType
  ): IDetailKitPriceData {
    let offer: IImportOffer;
    let detailKitPriceData: IDetailKitPriceData;
    let offerPriceData: IDetailPriceData;
    let oldPrice: number | undefined;
    let active: boolean | undefined;
    let stock: number | undefined;
    let isLoadingPrices: boolean;
    let cellIndex: number;

    isLoadingPrices = false;
    detailKitPriceData = {
      id: "" + detail.getId(),
      price: 0,
      errors: [],
      kit: [],
      count: count,
    };

    cellIndex = 0;
    for (offer of offers) {
      if (!this.dataManager.prices[offer[this.getOfferExternalId()]]) {
        detailKitPriceData.kit.push({
          id: offer[this.getOfferExternalId()],
          price: 0,
          errors: [],
          note: i18n.t("Идет расчет..."),
          offer: offer,
          count: offersCount[offer[this.getOfferExternalId()]] || 1,
          unitId: 0,
          cell: this.getPriceCellByGoodType(type),
          cellIndex: cellIndex,
          sizes: {
            width: offer.width,
            height: offer.height,
            depth: offer.depth,
          },
        });
        isLoadingPrices = true;
      } else {
        detailKitPriceData.kit.push({
          id: offer[this.getOfferExternalId()],
          price:
            this.dataManager.prices[offer[this.getOfferExternalId()]].price,
          oldPrice:
            this.dataManager.prices[offer[this.getOfferExternalId()]].oldPrice,
          active:
            this.dataManager.prices[offer[this.getOfferExternalId()]].active,
          stock:
            this.dataManager.prices[offer[this.getOfferExternalId()]].stock,
          errors: [],
          offer: offer,
          count: offersCount[offer[this.getOfferExternalId()]] || 1,
          unitId: 0,
          cell: this.getPriceCellByGoodType(type),
          cellIndex: cellIndex,
          sizes: {
            width: offer.width,
            height: offer.height,
            depth: offer.depth,
          },
        });
      }
      cellIndex++;
    }
    oldPrice = 0;
    active = true;
    stock = 0;
    for (offerPriceData of detailKitPriceData.kit) {
      detailKitPriceData.price += offerPriceData.price * offerPriceData.count;
      if (offerPriceData.oldPrice !== undefined && oldPrice !== undefined) {
        oldPrice += offerPriceData.oldPrice * offerPriceData.count;
      } else {
        oldPrice = undefined;
      }
      if (offerPriceData.active !== undefined && active !== undefined) {
        active = offerPriceData.active;
      } else {
        active = undefined;
      }
      if (offerPriceData.stock !== undefined && stock !== undefined) {
        stock = offerPriceData.stock;
      } else {
        stock = undefined;
      }
    }
    detailKitPriceData.oldPrice = oldPrice;
    detailKitPriceData.active = active;
    detailKitPriceData.stock = stock;
    if (isLoadingPrices) {
      detailKitPriceData.note = i18n.t("Идет расчет...");
    }

    return detailKitPriceData;
  }

  // TODO доделать метод, чтобы можно было устанавливать из ЛК значение
  public getDetailMinPiece(
    detail: ThreeKUnitDetail,
    offer: IImportOffer
  ): number {
    if (detail instanceof ThreeApron) {
      if (offer.corpusMaterial) {
        return 0;
      }
    }

    return 0;
  }

  public getDetailPriceData(
    offer: IImportOffer,
    count: number,
    unitId: number,
    cell: TPriceCell,
    cellIndex: number
  ): IDetailPriceData {
    if (!this.dataManager.prices[offer[this.getOfferExternalId()]]) {
      return {
        id: offer[this.getOfferExternalId()],
        price: 0,
        errors: [],
        note: i18n.t("Идет расчет..."),
        offer: offer,
        count: count,
        unitId: unitId,
        cell: cell,
        cellIndex: cellIndex,
        sizes: { width: offer.width, height: offer.height, depth: offer.depth },
      };
    } else {
      return {
        id: offer[this.getOfferExternalId()],
        price: this.dataManager.prices[offer[this.getOfferExternalId()]].price,
        oldPrice:
          this.dataManager.prices[offer[this.getOfferExternalId()]].oldPrice,
        active:
          this.dataManager.prices[offer[this.getOfferExternalId()]].active,
        stock: this.dataManager.prices[offer[this.getOfferExternalId()]].stock,
        errors: [],
        offer: offer,
        count: count,
        unitId: unitId,
        cell: cell,
        cellIndex: cellIndex,
        sizes: { width: offer.width, height: offer.height, depth: offer.depth },
      };
    }
  }

  public calculatePricesByModule(
    params: IModulePriceParams
  ): IModulePriceData[] {
    let offers: IImportOffer[];
    let offer: IImportOffer;
    let modulePrices: IModulePriceData[] = [];

    if (
      !this.dataManager.unitKits ||
      !this.dataManager.unitKits[params.catalogCode]
    ) {
      return [];
    }
    offers = KitchenHelper.calculateModuleFacadesOffers(
      params,
      this.dataManager.unitKits[params.catalogCode]
    );
    for (offer of offers) {
      if (!this.dataManager.prices[offer[this.getOfferExternalId()]]) {
        modulePrices.push({
          id: offer[this.getOfferExternalId()],
          price: 0,
          errors: [],
          note: i18n.t("Идет расчет..."),
          unitId: params.unitId,
          cell: params.cell,
          module: {
            id: offer[this.getOfferExternalId()],
            price: 0,
            errors: [],
            offer: offer,
            count: 1,
            unitId: params.unitId,
            cell: params.cell,
            cellIndex: 0,
            sizes: {
              width: params.width,
              height: params.height,
              depth: params.depth,
            },
          },
          sizes: {
            width: params.width,
            height: params.height,
            depth: params.depth,
          },
        });
      } else {
        modulePrices.push({
          id: offer[this.getOfferExternalId()],
          price:
            this.dataManager.prices[offer[this.getOfferExternalId()]].price,
          oldPrice:
            this.dataManager.prices[offer[this.getOfferExternalId()]].oldPrice,
          active:
            this.dataManager.prices[offer[this.getOfferExternalId()]].active,
          stock:
            this.dataManager.prices[offer[this.getOfferExternalId()]].stock,
          errors: [],
          unitId: params.unitId,
          cell: params.cell,
          module: {
            id: offer[this.getOfferExternalId()],
            price:
              this.dataManager.prices[offer[this.getOfferExternalId()]].price,
            oldPrice:
              this.dataManager.prices[offer[this.getOfferExternalId()]]
                .oldPrice,
            active:
              this.dataManager.prices[offer[this.getOfferExternalId()]].active,
            stock:
              this.dataManager.prices[offer[this.getOfferExternalId()]].stock,
            errors: [],
            count: 1,
            offer: offer,
            unitId: params.unitId,
            cell: params.cell,
            cellIndex: 0,
            sizes: {
              width: params.width,
              height: params.height,
              depth: params.depth,
            },
          },
          sizes: {
            width: params.width,
            height: params.height,
            depth: params.depth,
          },
        });
      }
    }

    return modulePrices;
  }

  public calculatePriceByModule(
    params: IModulePriceParams
  ): IModulePriceData | undefined {
    return this.kitchenHelper.calculatePriceByModule(params);
  }

  public async createModulesFeed(createImages: boolean, type: string): Promise<boolean> {
    let group: ICreateGroup;
    let createUnit: ICreateObjectData;
    let checkUnit: ICheckCatalogUnit;
    let checkUnitsArray: ICheckCatalogUnit[] = [];
    let checkUnits: ICheckCatalogUnits = {};
    let modules: IAPIProduct[];
    let isSave: boolean;

    for (group of this.dataManager.units) {
      for (createUnit of group.items) {
        if (createUnit.notPrice) {
          continue;
        }
        if (createUnit.disable) {
          continue;
        }
        checkUnit = await this.calculateCheckUnit(
          createUnit,
          group,
          type,
          createImages
        );
        checkUnitsArray.push(checkUnit);
      }
    }
    for (checkUnit of checkUnitsArray) {
      checkUnits[checkUnit.unit.uid] = checkUnit;
    }
    modules = await this.kitchenHelper.createModulesFeed(checkUnits);
    isSave = await this.saveModulesFeed(modules);

    return isSave;
  }

  public async createCheckUnitImagesEDIT(
      checkUnit: ICheckCatalogUnit
  ): Promise<boolean> {
    let sizeId: string;
    let corpusId: string;
    let facadeId: string;
    let kitId: string;
    let kitItem: ICheckCatalogUnitKitItem;
    let unit: ThreeUnit | undefined;
    let images: TKitchenImages | undefined;
    let saveImages: TKitchenImages | undefined;

    for (sizeId in checkUnit.sizes) {
      for (corpusId in checkUnit.sizes[sizeId].corpusMaterials) {
        for (facadeId in checkUnit.sizes[sizeId].corpusMaterials[corpusId]
            .facadeMaterials) {
          for (kitId in checkUnit.sizes[sizeId].corpusMaterials[corpusId]
              .facadeMaterials[facadeId].kits) {
            kitItem =
                checkUnit.sizes[sizeId].corpusMaterials[corpusId].facadeMaterials[
                    facadeId
                    ].kits[kitId];
            if (kitItem.price.errors.length) {
              continue;
            }
            unit = this.createDynamicCommonObjectEDIT(
                checkUnit.unit.uid,
                kitItem.createOptions,
                kitItem.price,
                checkUnit.unit.catalogCode
            );
            if (unit) {
              this.rebuildScene();
         
              const leftAspect = leftCameraAspect(this, checkUnit.unit);
              const frontAspect = frontCameraAspect(this, checkUnit.unit);

              await this.cacheManager.isLoaded();
              images = await this.createImagesEDIT(leftAspect, frontAspect);
              saveImages = await this.saveUnitImages(unit.getSpecUid(), images);
              if (saveImages) {
                checkUnit.sizes[sizeId].corpusMaterials[
                    corpusId
                    ].facadeMaterials[facadeId].kits[kitId].visual =
                    saveImages.visual;
                checkUnit.sizes[sizeId].corpusMaterials[
                    corpusId
                    ].facadeMaterials[facadeId].kits[kitId].sketch =
                    saveImages.sketch;
                checkUnit.sizes[sizeId].corpusMaterials[
                    corpusId
                    ].facadeMaterials[facadeId].kits[kitId].otherImages =
                    saveImages.otherImages;
              }
              this.deleteCommonObject(unit.getId());
            }
          }
        }
      }
    }
    return true;
  }

  public onSetRotation(rotationY: number, unitId: number ) {
    let unit: ThreeUnit | undefined;
    unit = this.mainManager.getUnit(unitId);
    unit?.setRotation(new Euler(0, rotationY, 0));

  }

  public onSetPosition(positionX: number, unitId: number, name: string) { 
    let unit: ThreeUnit | undefined;
    unit = this.mainManager.getUnit(unitId);

    if (!unit) {
      return;
    }

    (unit as ThreeKUnit).equipments.forEach((el) => {
      if (el.saveData.name === name) {
        unit?.setBuildInEquipmentPositionLocal(positionX, el);
      }
    })
  }

  public async createCheckUnitImages(
    checkUnit: ICheckCatalogUnit
  ): Promise<boolean> {
    let sizeId: string;
    let corpusId: string;
    let facadeId: string;
    let kitId: string;
    let kitItem: ICheckCatalogUnitKitItem;
    let unit: ThreeUnit | undefined;
    let unitPosition: Vector3;
    let images: TKitchenImages | undefined;
    let saveImages: TKitchenImages | undefined;

    for (sizeId in checkUnit.sizes) {
      for (corpusId in checkUnit.sizes[sizeId].corpusMaterials) {
        for (facadeId in checkUnit.sizes[sizeId].corpusMaterials[corpusId]
          .facadeMaterials) {
          for (kitId in checkUnit.sizes[sizeId].corpusMaterials[corpusId]
            .facadeMaterials[facadeId].kits) {
            kitItem =
              checkUnit.sizes[sizeId].corpusMaterials[corpusId].facadeMaterials[
                facadeId
              ].kits[kitId];
            if (kitItem.price.errors.length) {
              continue;
            }
            unit = this.createDynamicCommonObject(
              checkUnit.unit.uid,
              kitItem.createOptions,
              kitItem.price,
              checkUnit.unit.catalogCode
            );
            if (unit) {
              unitPosition = unit.getPosition().clone();
              unitPosition.x = 0;
              unitPosition.z = 0;
              unit.setPosition(unitPosition);
              unit.setWall(undefined);
              unit.setRotation(new Euler());
              this.rebuildScene();
              await this.cacheManager.isLoaded();
              images = await this.createUnitImages();
              saveImages = await this.saveUnitImages(unit.getSpecUid(), images);
              if (saveImages) {
                checkUnit.sizes[sizeId].corpusMaterials[
                  corpusId
                ].facadeMaterials[facadeId].kits[kitId].visual =
                  saveImages.visual;
                checkUnit.sizes[sizeId].corpusMaterials[
                  corpusId
                ].facadeMaterials[facadeId].kits[kitId].sketch =
                  saveImages.sketch;
              }
              this.deleteCommonObject(unit.getId());
            }
          }
        }
      }
    }
    return true;
  }

  public createUnitImages(): Promise<TKitchenImages | undefined> {
    return this.drawManager.createImages();
  }

  public saveUnitImages(
    unitId: string,
    images?: TKitchenImages
  ): Promise<TKitchenImages | undefined> {
    return new Promise<TKitchenImages | undefined>((resolve) => {
      if (!images) {
        resolve(undefined);
        return;
      }
      axios
        .post("/api/catalog/unit-image-save", {
          collection: this.getCollectionId(),
          unit: unitId,
          visual: images.visual,
          sketch: images.sketch,
          otherImages: images.otherImages,
        })
        .then((response: AxiosResponse) => {
          if (
            response &&
            response.data &&
            response.data.visual &&
            response.data.sketch
          ) {
            resolve({
              visual: response.data.visual,
              sketch: response.data.sketch,
            });
          } else {
            resolve(undefined);
          }
        })
        .catch(() => {
          resolve(undefined);
        });
    });
  }

  public saveModulesFeed(modules: IAPIProduct[]): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      axios
        .post("/api/catalog/unit-feed-save", {
          modules: modules,
          collection: this.getCollectionId(),
        })
        .then((response: AxiosResponse) => {
          resolve(response.data === true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  public calculatePriceByDetails(
    params: IModulePriceParams
  ): IModulePriceData | undefined {
    return this.kitchenHelper.calculatePriceByDetails(params);
  }

  public getCorpusPriceParamsByData(
    unitId: number,
    data?: ISaveCorpusData
  ): ICorpusPriceParams | undefined {
    if (!data) {
      return undefined;
    }
    if (!data.material) {
      return undefined;
    }

    let corpusPriceParams: ICorpusPriceParams;
    let goodData: IFurniturePriceParams;

    corpusPriceParams = {
      material: data.material,
      catalogCode: data.catalogCode || "",
      width: data.sizes.length,
      height: data.sizes.height,
      depth: data.sizes.width,
      frameMaterial: data.frameMaterial,
      panelMaterial: data.panelMaterial,
      furniture: data.furniture,
      unitId: unitId,
      cell: PRICE_CELL_CORPUS,
    };
    if (data.furniture) {
      corpusPriceParams.furniture = [];
      for (goodData of data.furniture) {
        corpusPriceParams.furniture.push({
          height: KitchenHelper.calculateSizeByParent(
            goodData.height,
            corpusPriceParams.height,
            this.getDataForSizeByParent()
          ),
          depth: KitchenHelper.calculateSizeByParent(
            goodData.depth,
            corpusPriceParams.depth,
            this.getDataForSizeByParent()
          ),
          width: KitchenHelper.calculateSizeByParent(
            goodData.width,
            corpusPriceParams.width,
            this.getDataForSizeByParent()
          ),
          catalogCode: goodData.catalogCode,
          functionalType: goodData.functionalType,
          kitCode: goodData.kitCode,
          kitAmount: goodData.kitAmount,
          furnitureType: goodData.furnitureType,
          count: goodData.count,
          unitId: unitId,
          cell: PRICE_CELL_FURNITURE,
        });
      }
    }

    return corpusPriceParams;
  }

  public getFacadesPriceParamsByData(
    unitId: number,
    facadesData?: ISaveFacadeData[],
    parentSizes?: TSizes3D
  ): IFacadePriceParam[] | undefined {
    let sizes: TFacadeSizes;
    let facade: ISaveFacadeData;
    let facadePriceParams: IFacadePriceParam[] = [];

    if (!facadesData || facadesData.length <= 0 || !parentSizes) {
      return facadePriceParams;
    }

    for (facade of facadesData) {
      if (facade.calculateType === FACADE_CALCULATE_NONE) {
        continue;
      }
      if (!facade.facadeMaterial) {
        return undefined;
      }
      sizes = KitchenHelper.calculateFacadeSizes(
        facade.initSizes,
        {
          width: parentSizes.depth,
          length: parentSizes.width,
          height: parentSizes.height,
        },
        this,
        facade.gap,
        facade.calculateSizes
      );
      facadePriceParams.push({
        sideType: facade.sideType,
        height: sizes.height,
        width: sizes.width,
        functionalType: facade.functionalType,
        modelType: facade.modelType,
        facadeMaterial: facade.facadeMaterial,
        calculateType: facade.calculateType,
        for: facade.for,
        handle: this.calculateHandlePriceParams(unitId, facade.handle),
        hinges: this.calculateHingesPriceParams(unitId, facade.hinges),
        unitId: unitId,
        cell: PRICE_CELL_FACADE,
      });
    }

    return facadePriceParams;
  }

  public calculateHandlePriceParams(
    unitId: number,
    handle?: ISaveHandleData
  ): IHandlePriceParams | undefined {
    if (!handle || !handle.calculate) {
      return undefined;
    }

    return {
      count: 1,
      depth: handle.sizes ? handle.sizes.depth : 0,
      width: handle.sizes ? handle.sizes.width : 0,
      height: handle.sizes ? handle.sizes.height : 0,
      catalogCode: "handle",
      functionalType: FURNITURE_FT_HANDLE,
      furnitureType: handle.furnitureType || FURNITURE_TYPE_NONE,
      unitId: unitId,
      cell: PRICE_CELL_HANDLE,
    };
  }

  public calculateHingesPriceParams(
    unitId: number,
    hinges?: ISaveHingeData[]
  ): IHingePriceParams[] | undefined {
    if (!hinges) {
      return undefined;
    }
    let hingeData: ISaveHingeData;
    let priceParams: IHingePriceParams[] = [];
    let kitCode: TSelectItem | undefined;

    kitCode = this.getSelectKitCode();
    for (hingeData of hinges) {
      if (hingeData.availableKitCodes) {
        if (kitCode && hingeData.availableKitCodes.includes(kitCode.id)) {
          priceParams.push({
            kitCode: kitCode.id,
            functionalType: FURNITURE_FT_HINGE,
            furnitureType: hingeData.furnitureType,
            catalogCode: "furniture",
            height: 0,
            width: 0,
            depth: 0,
            count: 1,
            part: hingeData.part,
            unitId: unitId,
            cell: PRICE_CELL_HINGES,
          });
        }
      } else {
        priceParams.push({
          functionalType: FURNITURE_FT_HINGE,
          catalogCode: "furniture",
          furnitureType: hingeData.furnitureType,
          height: 0,
          width: 0,
          depth: 0,
          count: 1,
          unitId: unitId,
          cell: PRICE_CELL_HINGES,
        });
      }
    }

    return priceParams;
  }

  public setOrderPartsToModulePrice(
    modulePrice: IModulePriceData,
    orderParts?: IProjectOrderParts
  ): IModulePriceData {
    if (!this.getCanOrderPart()) {
      return modulePrice;
    }
    let parts: IProjectOrderParts;
    let index: string;

    parts = orderParts || {};
    modulePrice.part =
      parts[PRICE_CELL_MODULE] &&
      parts[PRICE_CELL_MODULE][0] &&
      parts[PRICE_CELL_MODULE][0].part > 0
        ? parts[PRICE_CELL_MODULE][0].part
        : 1;
    if (modulePrice.module) {
      modulePrice.module.part =
        parts[PRICE_CELL_MODULE] &&
        parts[PRICE_CELL_MODULE][0] &&
        parts[PRICE_CELL_MODULE][0].part > 0
          ? parts[PRICE_CELL_MODULE][0].part
          : 1;
    }
    if (modulePrice.corpus) {
      modulePrice.corpus.part =
        parts[PRICE_CELL_CORPUS] &&
        parts[PRICE_CELL_CORPUS][0] &&
        parts[PRICE_CELL_CORPUS][0].part > 0
          ? parts[PRICE_CELL_CORPUS][0].part
          : 1;
      if (modulePrice.corpus.furniture) {
        for (index in modulePrice.corpus.furniture) {
          modulePrice.corpus.furniture[index].part =
            parts[PRICE_CELL_FURNITURE] &&
            parts[PRICE_CELL_FURNITURE][+index] &&
            parts[PRICE_CELL_FURNITURE][+index].part > 0
              ? parts[PRICE_CELL_FURNITURE][+index].part
              : 1;
        }
      }
      if (modulePrice.extraOffers) {
        for (index in modulePrice.extraOffers) {
          modulePrice.extraOffers[index].part =
            parts[PRICE_CELL_EXTRA_OFFERS] &&
            parts[PRICE_CELL_EXTRA_OFFERS][+index] &&
            parts[PRICE_CELL_EXTRA_OFFERS][+index].part > 0
              ? parts[PRICE_CELL_EXTRA_OFFERS][+index].part
              : 1;
        }
      }
    }
    if (modulePrice.facades) {
      for (index in modulePrice.facades) {
        modulePrice.facades[index].part =
          parts[PRICE_CELL_FACADE] &&
          parts[PRICE_CELL_FACADE][+index] &&
          parts[PRICE_CELL_FACADE][+index].part > 0
            ? parts[PRICE_CELL_FACADE][+index].part
            : 1;
      }
    }

    return modulePrice;
  }

  public getOrderParts(): IProjectOrderParts | undefined {
    if (!this.getCanOrderPart()) {
      return undefined;
    }

    return this.projectData.orderParts;
  }

  public setOrderPartsToDetailPrice(
    priceData: IDetailKitPriceData,
    orderParts?: IProjectOrderParts
  ): IDetailKitPriceData {
    if (!this.getCanOrderPart() || !orderParts) {
      return priceData;
    }
    let index: string;

    for (index in priceData.kit) {
      if (
        orderParts[priceData.kit[index].cell] &&
        orderParts[priceData.kit[index].cell][priceData.kit[index].cellIndex] &&
        orderParts[priceData.kit[index].cell][priceData.kit[index].cellIndex]
          .part > 0
      ) {
        priceData.kit[index].part =
          orderParts[priceData.kit[index].cell][
            priceData.kit[index].cellIndex
          ].part;
      } else {
        priceData.kit[index].part = 1;
      }
    }
    return priceData;
  }

  public calculatePrice(params: IModulePriceParams): IModulePriceData {
    let modulePriceData: IModulePriceData | undefined;

    if (
      !this.isCatalogCalculatePrice() ||
      params.catalogCode.length <= 0 ||
      (params.notPrice && !params.extraOffers)
    ) {
      return {
        id: "",
        price: 0,
        errors: [],
        note: i18n.t("расчет не предусмотрен"),
        unitId: params.unitId,
        cell: params.cell,
        sizes: {
          width: params.width,
          height: params.height,
          depth: params.depth,
        },
      };
    }

    switch (params.calculateType) {
      case CATALOG_CALCULATE_TYPE_MODULE:
        modulePriceData = this.calculatePriceByModule(params);
        break;
      case CATALOG_CALCULATE_TYPE_DETAILS:
        modulePriceData = this.calculatePriceByDetails(params);
        break;
    }

    return (
      modulePriceData || {
        id: "",
        price: 0,
        errors: [
          {
            id: "calculatePrice",
            message: i18n.t("Ошибка расчета цены модуля!"),
          },
        ],
        unitId: params.unitId,
        cell: params.cell,
        sizes: {
          width: params.width,
          height: params.height,
          depth: params.depth,
        },
      }
    );
  }

  public calculateCreateObjectPriceParams(
    objectData: ICreateObjectData,
    createOptions: any,
    width?: number
  ): IModulePriceParams | undefined {
    let defaultCorpusMaterial: IMaterialData;
    let defaultFacadeMaterial: IFacadeMaterialData;
    let facadeMaterialId: string | undefined;
    let availableFacadeMaterials: string[];
    let defaultFacadeMaterialId: string;
    let defaultFacadeMaterial2Id: string | undefined;
    let widthKey: string;

    if (
      !width &&
      objectData.availableFacadeMaterials &&
      !objectData.availableFacadeMaterials["default"]
    ) {
      width =
        createOptions.sizes &&
        createOptions.sizes.width &&
        !isNaN(+createOptions.sizes.width)
          ? +createOptions.sizes.width
          : createOptions.corpus &&
            createOptions.corpus.width &&
            !isNaN(+createOptions.corpus.width)
          ? +createOptions.corpus.width
          : undefined;
      if (!width) {
        debugger;
      }
    }
    widthKey = width ? "" + width : "default";
    defaultCorpusMaterial = this.getCorpusMaterial();
    facadeMaterialId =
      createOptions.facades && createOptions.facades[0]
        ? createOptions.facades[0].facadeMaterial
        : createOptions.facadeMaterial;
    defaultFacadeMaterial = this.getFacadeMaterial(
      objectData.level,
      facadeMaterialId
    );
    if (
      objectData.availableFacadeMaterials &&
      objectData.availableFacadeMaterials[widthKey] &&
      (!objectData.availableFacadeMaterials[widthKey][
        defaultFacadeMaterial.facade
      ] ||
        !(
          objectData.availableFacadeMaterials[widthKey][
            defaultFacadeMaterial.facade
          ] as any
        )[defaultFacadeMaterial.id])
    ) {
      if (
        objectData.availableFacadeMaterials[widthKey][
          defaultFacadeMaterial.facade
        ]
      ) {
        availableFacadeMaterials = objectData.availableFacadeMaterials[
          widthKey
        ][defaultFacadeMaterial.facade] as string[];
        defaultFacadeMaterial = this.getFacadeMaterial(
          objectData.level,
          availableFacadeMaterials[0]
        );
      }
    }
    defaultFacadeMaterialId = defaultFacadeMaterial.id;
    //TODO нужно протестировать с модулями, где это используется
    defaultFacadeMaterial2Id = this.getFacadeMaterial(
      objectData.level,
      facadeMaterialId
    ).id;

    return this.kitchenHelper.calculateCreateObjectPriceParams(
      objectData,
      createOptions,
      defaultCorpusMaterial,
      defaultFacadeMaterialId,
      defaultFacadeMaterial2Id
    );
  }

  public getUnitSaveDataPriceParams(data: ISaveUnitData): IModulePriceParams {
    let priceParams: IModulePriceParams;
    let corpusParams: ICorpusPriceParams | undefined;
    let facadesParams: IFacadePriceParam[] | undefined;
    let facadesParam: IFacadePriceParam;
    let corpusMaterial: string;
    let facadeMaterial: string;
    let facadeMaterial2: string | undefined;
    let parentSizes: TSizes3D | undefined;

    corpusParams = this.getCorpusPriceParamsByData(data.id, data.corpus);
    if (corpusParams) {
      corpusMaterial = corpusParams.material;
      parentSizes = {
        depth: corpusParams.depth,
        height: corpusParams.height,
        width: corpusParams.width,
      };
    } else if (data.corpus && data.corpus.material) {
      corpusMaterial = data.corpus.material;
    } else {
      corpusMaterial = NONE_MATERIAL;
    }
    if (!parentSizes && data.sizes) {
      parentSizes = {
        depth: data.sizes.width,
        height: data.sizes.height,
        width: data.sizes.length,
      };
    }
    facadesParams = this.getFacadesPriceParamsByData(
      data.id,
      data.facades,
      parentSizes
    );
    facadeMaterial = NONE_MATERIAL;
    if (facadesParams) {
      for (facadesParam of facadesParams) {
        if (
          facadesParam.calculateType &&
          [FACADE_CALCULATE_SELF_AMOUNT, FACADE_CALCULATE_NONE].includes(
            facadesParam.calculateType
          )
        ) {
          continue;
        }
        if (facadesParam.for === FACADE_FOR_SECOND) {
          facadeMaterial2 = facadesParam.facadeMaterial;
          continue;
        }
        facadeMaterial = facadesParam.facadeMaterial;
        if (facadeMaterial && facadeMaterial2) {
          break;
        }
      }
    }
    priceParams = {
      catalogCode: data.catalogCode || "",
      level: data.level || LEVEL_BOTTOM,
      width: data.sizes.length,
      height: data.sizes.height,
      depth: data.sizes.width,
      sideType: data.sideType || SIDE_TYPE_DEFAULT,
      frameMaterial: data.corpus?.frameMaterial,
      panelMaterial: data.corpus?.panelMaterial,
      corpusMaterial: corpusMaterial,
      facadeMaterial: facadeMaterial,
      facadeMaterial2: facadeMaterial2,
      corpus: corpusParams,
      facades: facadesParams,
      notPrice: data.notPrice,
      calculateType: data.calculateType || this.getCatalogCalculateType(),
      extraOffers: this.getExtraOffersPriceParamsBySaveData(data),
      checkCatalogType: data.checkCatalogType,
      unitId: data.id,
      cell: PRICE_CELL_MODULE,
    };

    return priceParams;
  }

  public calculateCreateObjectPrice(
    objectData: ICreateObjectData,
    createOptions: any
  ): IModulePriceData {
    let priceParams: IModulePriceParams | undefined;
    priceParams = this.calculateCreateObjectPriceParams(
      objectData,
      createOptions
    );
    if (priceParams) {
      return this.calculatePrice(priceParams);
    } else {
      return {
        id: "",
        price: 0,
        errors: [
          {
            id: "calculatePrice",
            message: "Ошибка расчета параметров для цены модуля!",
          },
        ],
        unitId: 0,
        cell: PRICE_CELL_MODULE,
        sizes: {
          width: 0,
          height: 0,
          depth: 0,
        },
      };
    }
  }

  public isCatalogCalculatePrice(): boolean {
    return this.appConfig.catalog.calculatePrice;
  }

  public clearProjectSaveData() {}

  public getUnitOffersSpecData(
    unitPrice: IModulePriceData,
    count: number
  ): IOfferSpecData[] {
    let specItems: IOfferSpecDataItems = {};
    let furnitureData: ICorpusPriceData;
    let facadePrice: IFacadePriceData;
    let detailPrice: IDetailPriceData;
    let index: number;
    let index2: number;

    if (unitPrice.module && unitPrice.module.offer) {
      this.setUniqueSpecData(
        {
          id: unitPrice.module.offer.id,
          price: unitPrice.module.price,
          oldPrice: unitPrice.module.oldPrice,
          count: count,
          name: unitPrice.module.offer.name,
          sum: count * unitPrice.module.price,
          vendorCode: unitPrice.module.offer.vendorCode,
          stock: unitPrice.module.stock,
          active: unitPrice.module.active,
          article: unitPrice.module.offer.article,
          part: unitPrice.part,
          unitId: unitPrice.unitId,
          cell: unitPrice.cell,
          cellIndex: 0,
        },
        specItems
      );
    }
    if (unitPrice.corpus && unitPrice.corpus.offer) {
      this.setUniqueSpecData(
        {
          id: unitPrice.corpus.offer.id,
          price: unitPrice.corpus.price,
          oldPrice: unitPrice.corpus.oldPrice,
          count: unitPrice.corpus.count,
          name: unitPrice.corpus.offer.name,
          sum: unitPrice.corpus.price * unitPrice.corpus.count,
          vendorCode: unitPrice.corpus.offer.vendorCode,
          stock: unitPrice.corpus.stock,
          active: unitPrice.corpus.active,
          article: unitPrice.corpus.offer.article,
          part: unitPrice.corpus.part,
          unitId: unitPrice.corpus.unitId,
          cell: unitPrice.corpus.cell,
          cellIndex: 0,
        },
        specItems
      );
    }
    if (
      unitPrice.corpus &&
      unitPrice.corpus.furniture &&
      unitPrice.corpus.furniture.length > 0
    ) {
      index = 0;
      for (furnitureData of unitPrice.corpus.furniture) {
        if (furnitureData.offer) {
          this.setUniqueSpecData(
            {
              id: furnitureData.offer.id,
              price: furnitureData.price,
              oldPrice: furnitureData.oldPrice,
              count: furnitureData.count,
              name: furnitureData.offer.name,
              sum: furnitureData.price * furnitureData.count,
              vendorCode: furnitureData.offer.vendorCode,
              stock: furnitureData.stock,
              active: furnitureData.active,
              article: furnitureData.offer.article,
              part: furnitureData.part,
              unitId: furnitureData.unitId,
              cell: furnitureData.cell,
              cellIndex: index,
            },
            specItems
          );
          index++;
        }
      }
    }
    if (unitPrice.facades) {
      index = 0;
      for (facadePrice of unitPrice.facades) {
        if (facadePrice.offer) {
          this.setUniqueSpecData(
            {
              id: facadePrice.offer.id,
              price: facadePrice.price,
              oldPrice: facadePrice.oldPrice,
              count: facadePrice.count,
              name: facadePrice.offer.name,
              sum: facadePrice.price * facadePrice.count,
              vendorCode: facadePrice.offer.vendorCode,
              stock: facadePrice.stock,
              active: facadePrice.active,
              article: facadePrice.offer.article,
              part: facadePrice.part,
              unitId: facadePrice.unitId,
              cell: facadePrice.cell,
              cellIndex: index,
            },
            specItems
          );
          index++;
        }
        if (facadePrice.handle) {
          if (facadePrice.handle.offer) {
            this.setUniqueSpecData(
              {
                id: facadePrice.handle.offer.id,
                price: facadePrice.handle.price,
                oldPrice: facadePrice.handle.oldPrice,
                count: facadePrice.handle.count,
                name: facadePrice.handle.offer.name,
                sum: facadePrice.handle.price * facadePrice.handle.count,
                vendorCode: facadePrice.handle.offer.vendorCode,
                stock: facadePrice.handle.stock,
                active: facadePrice.handle.active,
                article: facadePrice.handle.offer.article,
                part: facadePrice.handle.part,
                unitId: facadePrice.handle.unitId,
                cell: facadePrice.handle.cell,
                cellIndex: 0,
              },
              specItems
            );
          }
        }
        if (facadePrice.hinges) {
          index2 = 0;
          for (detailPrice of facadePrice.hinges) {
            if (detailPrice.offer) {
              this.setUniqueSpecData(
                {
                  id: detailPrice.offer.id,
                  price: detailPrice.price,
                  oldPrice: detailPrice.oldPrice,
                  count: detailPrice.count,
                  name: detailPrice.offer.name,
                  sum: detailPrice.price * detailPrice.count,
                  vendorCode: detailPrice.offer.vendorCode,
                  stock: detailPrice.stock,
                  active: detailPrice.active,
                  article: detailPrice.offer.article,
                  part: detailPrice.part,
                  unitId: detailPrice.unitId,
                  cell: detailPrice.cell,
                  cellIndex: index2,
                },
                specItems
              );
              index2++;
            }
          }
        }
      }
    }
    if (unitPrice.extraOffers) {
      index = 0;
      for (detailPrice of unitPrice.extraOffers) {
        if (detailPrice.offer) {
          this.setUniqueSpecData(
            {
              id: detailPrice.offer.id,
              price: detailPrice.price,
              oldPrice: detailPrice.oldPrice,
              count: detailPrice.count,
              name: detailPrice.offer.name,
              sum: detailPrice.price * detailPrice.count,
              vendorCode: detailPrice.offer.vendorCode,
              stock: detailPrice.stock,
              active: detailPrice.active,
              article: detailPrice.offer.article,
              part: detailPrice.part,
              unitId: detailPrice.unitId,
              cell: detailPrice.cell,
              cellIndex: index,
            },
            specItems
          );
          index++;
        }
      }
    }

    return Object.values(specItems);
  }

  public changeStep(step: TStep) {
    this.hideMenus();
    this.clearSelectCovers();
  }

  public getFacadesByFunctionalType(functionalType: string): IImportOffer[] {
    return this.dataManager.getFacadesByFunctionalType(functionalType);
  }

  public isCanHasDishwasherFacade(
    width: number,
    facadeMaterial: string
  ): boolean {
    let facadeGoods: IImportOffer[];
    let facadeGood: IImportOffer;

    facadeGoods = this.getFacadesByFunctionalType(
      FACADE_FUNCTIONAL_TYPE_DISHWASHER
    );
    for (facadeGood of facadeGoods) {
      if (
        facadeGood.width === width &&
        facadeGood.facadeMaterial === facadeMaterial
      ) {
        return true;
      }
    }

    return false;
  }

  public getCreateEquipmentsFromClassName(
    className: TClassName
  ): ICreateObjectData[] {
    return this.dataManager.getCreateEquipmentsFromClassName(className);
  }

  public setCreateUnitDynamicOptions(
    createUnit: ICreateObjectData,
    width?: number,
    createOptions?: any
  ): any | undefined {
    if (createUnit.notPrice) {
      return undefined;
    }
    let selectOptions: ICreateObjectSelectOptions;

    selectOptions = this.getCreateObjectDynamicSettings(createUnit);
    if (!Object.keys(selectOptions).length) {
      width = undefined;
    }
    const objectFacadeMaterials: IOptionFacadeMaterial | undefined =
      this.calculateCreateObjectFacadeMaterials(createUnit, width);
    const objectCorpusColors: IOptionCorpusMaterial | undefined =
      this.calculateCreateObjectCorpusColors(
        createUnit,
        objectFacadeMaterials,
        undefined,
        width
      );
    const objectKitCodes: IOptionRadioButton | undefined =
      this.calculateCreateObjectKitCodes(createUnit, undefined, width);

    let index: string;
    let index2: string;
    let isSetCorpusMaterial: boolean;
    let isSetFacadeMaterial: boolean;
    let newCreateOptions: any | undefined;
    let newCorpusMaterial: string | undefined;
    let newFacadeMaterial: string | undefined;
    let isSetKitCodes: boolean;

    newCreateOptions = createOptions
      ? CommonHelper.deepCopy(createOptions)
      : undefined;
    if (newCreateOptions) {
      if (
        newCreateOptions.corpusMaterial &&
        newCreateOptions.corpusMaterial !== NONE_MATERIAL
      ) {
        if (
          objectCorpusColors &&
          !objectCorpusColors.items.filter(
            (item) => item.id === newCreateOptions.corpusMaterial
          ).length
        ) {
          newCorpusMaterial =
            objectCorpusColors.value || objectCorpusColors.defaultValue;
        }
      }
      if (
        newCreateOptions.corpus &&
        newCreateOptions.corpus.material &&
        newCreateOptions.corpus.material !== NONE_MATERIAL
      ) {
        if (
          objectCorpusColors &&
          !objectCorpusColors.items.filter(
            (item) => item.id === newCreateOptions.corpus.material
          ).length
        ) {
          newCorpusMaterial =
            objectCorpusColors.value || objectCorpusColors.defaultValue;
        }
      }
      if (
        newCreateOptions.facadeMaterial &&
        newCreateOptions.facadeMaterial !== NONE_MATERIAL
      ) {
        if (
          objectFacadeMaterials &&
          !objectFacadeMaterials.items.filter(
            (item) => item.facadeMaterial.id === newCreateOptions.facadeMaterial
          ).length
        ) {
          newFacadeMaterial =
            objectFacadeMaterials.value || objectFacadeMaterials.defaultValue;
        }
      }
    }
    if (newCreateOptions && (newCorpusMaterial || newFacadeMaterial)) {
      if (newCorpusMaterial && newCreateOptions.corpusMaterial) {
        newCreateOptions.corpusMaterial = newCorpusMaterial;
      }
      if (
        newCorpusMaterial &&
        newCreateOptions.corpus &&
        newCreateOptions.corpus.material
      ) {
        newCreateOptions.corpus.material = newCorpusMaterial;
      }
      if (newFacadeMaterial && newCreateOptions.facadeMaterial) {
        newCreateOptions.facadeMaterial = newFacadeMaterial;
      }
    } else {
      newCreateOptions = undefined;
    }

    isSetCorpusMaterial = false;
    isSetFacadeMaterial = false;
    isSetKitCodes = false;
    for (index in createUnit.options) {
      if (
        createUnit.options[index].id === "corpusMaterial" &&
        (createUnit.options[index] as IOption).value === NONE_MATERIAL
      ) {
        isSetCorpusMaterial = true;
        break;
      }
      if (createUnit.options[index].id === "corpus") {
        isSetCorpusMaterial = false;
        for (index2 in (createUnit.options[index] as IOptionGroup).options) {
          if (
            (createUnit.options[index] as IOptionGroup).options[index2].id ===
            "material"
          ) {
            if (
              (createUnit.options[index] as IOptionGroup).options[index2]
                .value === NONE_MATERIAL
            ) {
              isSetCorpusMaterial = true;
            } else if (objectCorpusColors) {
              (createUnit.options[index] as IOptionGroup).options[index2] =
                objectCorpusColors;
              isSetCorpusMaterial = true;
            } else {
              (createUnit.options[index] as IOptionGroup).options.splice(
                +index,
                1
              );
            }
            break;
          }
        }
        if (!isSetCorpusMaterial && objectCorpusColors) {
          (createUnit.options[index] as IOptionGroup).options.push(
            objectCorpusColors
          );
        }
        break;
      }
    }
    for (index in createUnit.options) {
      if (createUnit.options[index].id === "facadeMaterial") {
        if (objectFacadeMaterials) {
          createUnit.options[index] = objectFacadeMaterials;
          isSetFacadeMaterial = true;
        } else {
          createUnit.options.splice(+index, 1);
        }
        break;
      }
    }
    for (index in createUnit.options) {
      if (createUnit.options[index].id === "kitCode") {
        if (objectKitCodes) {
          createUnit.options[index] = objectKitCodes;
          isSetKitCodes = true;
        } else {
          createUnit.options.splice(+index, 1);
        }
        break;
      }
    }
    if (!isSetFacadeMaterial && objectFacadeMaterials) {
      createUnit.options.push(objectFacadeMaterials);
    }
    if (!isSetKitCodes && objectKitCodes) {
      createUnit.options.push(objectKitCodes);
    }

    return newCreateOptions;
  }

  public calculateCreateObjectFacadeMaterials(
    objectData: ICreateObjectData,
    width?: number
  ): IOptionFacadeMaterial | undefined {
    return this.dataManager.calculateCreateObjectFacadeMaterials(
      objectData,
      width
    );
  }

  public calculateCreateObjectCorpusColors(
    objectData: ICreateObjectData,
    objectFacadeMaterials: IOptionFacadeMaterial | undefined,
    materialId?: string,
    width?: number
  ): IOptionCorpusMaterial | undefined {
    return this.dataManager.calculateCreateObjectCorpusColors(
      objectData,
      objectFacadeMaterials,
      materialId,
      width
    );
  }

  public calculateCreateObjectKitCodes(
    objectData: ICreateObjectData,
    materialId?: string,
    width?: number
  ): IOptionRadioButton | undefined {
    return this.dataManager.calculateCreateObjectKitCodes(
      objectData,
      materialId,
      width
    );
  }

  public getOfferExternalId(): "externalGuid" | "vendorCode" {
    return this.kitchenHelper.getOfferExternalId();
  }

  public getModuleOffers(modulePrice: IModulePriceData): IOffer[] {
    return this.kitchenHelper.getModuleOffers(modulePrice);
  }

  public getModuleOfferIds(modulePrice: IModulePriceData): string[] {
    return this.kitchenHelper.getModuleOfferIds(modulePrice);
  }

  public getModuleLoadingOfferIds(modulePrice: IModulePriceData): string[] {
    let moduleOfferIds: string[];
    let offerId: string;
    let loadingOfferIds: string[] = [];

    moduleOfferIds = this.getModuleOfferIds(modulePrice);
    for (offerId of moduleOfferIds) {
      if (!this.checkLoadOfferPrice(offerId)) {
        loadingOfferIds.push(offerId);
      }
    }

    return loadingOfferIds;
  }

  public getCatalogPriceId(): string {
    if (this.appConfig.location && this.appConfig.location.priceId) {
      return this.appConfig.location.priceId;
    }
    return this.appConfig.catalog.defaultPriceId || "default";
  }

  public getCreateObjectCatalogCodes(
    createObject: ICreateObjectData
  ): string[] {
    let catalogCodes: string[] = [];
    let createOptions: any;
    let priceParams: IModulePriceParams | undefined;
    let furnitureParam: IFurniturePriceParams;
    let facadeParam: IFacadePriceParam;
    let isFacades: boolean = false;

    createOptions = this.getDefaultOptions(createObject);
    priceParams = this.calculateCreateObjectPriceParams(
      createObject,
      createOptions
    );

    if (priceParams) {
      catalogCodes.push(priceParams.catalogCode);
      if (priceParams.corpus) {
        if (priceParams.corpus.catalogCode) {
          catalogCodes.push(priceParams.corpus.catalogCode);
        }
        if (priceParams.corpus.furniture) {
          for (furnitureParam of priceParams.corpus.furniture) {
            catalogCodes.push(furnitureParam.catalogCode);
          }
        }
      }
      if (priceParams.facades) {
        for (facadeParam of priceParams.facades) {
          if (facadeParam.calculateType !== FACADE_CALCULATE_NONE) {
            isFacades = true;
          }
        }
      }
      if (isFacades) {
        catalogCodes.push("facade");
      }
      if (priceParams.extraOffers) {
        catalogCodes.push("furniture");
      }
    }

    return catalogCodes;
  }

  public calculateTablesCount(): number {
    return this.mainManager.calculateTablesCount();
  }

  public calculateStretchTablesCount(): number {
    return this.mainManager.calculateStretchTablesCount();
  }

  public calculateStretchCupboardsCount(): number {
    return this.mainManager.calculateStretchCupboardsCount();
  }

  public calculateCupboardsCount(): number {
    return this.mainManager.calculateCupboardsCount();
  }

  public calculatePenBoxCount(): number {
    return this.mainManager.calculatePenBoxCount();
  }

  public getBarCountersCount(): number {
    return this.mainManager.getBarCountersCount();
  }

  public getEquipmentsCount(classNames: TClassName[]): number {
    let objects: ThreeUnit[];
    let object: ThreeUnit;
    let equipment: ThreeBuiltInEquipment;
    let count: number;

    count = 0;
    objects = this.getObjects();
    for (object of objects) {
      if (object instanceof ThreeKUnit && object.equipments) {
        if (
          object.isEquipment() &&
          classNames.includes(object.getClassName() as TClassName)
        ) {
          count++;
        }
        for (equipment of object.equipments) {
          if (classNames.includes(equipment.getClassName() as TClassName)) {
            count++;
          }
        }
      }
    }

    return count;
  }

  public getSinks(): IProjectSinksData {
    return {
      separate: this.getEquipmentsCount([CLASSNAME_EQUIPMENT_SEPARATE_SINK]),
      builtIn: this.getEquipmentsCount([CLASSNAME_EQUIPMENT_BUILTIN_SINK]),
    };
  }

  public getGasliftsCount(): number {
    return 0;
  }

  public getExtractsCount(): IProjectExtractData {
    let objects: ThreeUnit[];
    let object: ThreeUnit;
    let equipment: ThreeBuiltInEquipment;
    let projectExtracts: IProjectExtractData;

    projectExtracts = {
      separate: 0,
      builtIn: 0,
      mounted: 0,
    };
    objects = this.getObjects();
    for (object of objects) {
      if (
        object.isEquipment() &&
        object.getClassName() === CLASSNAME_EQUIPMENT_SEPARATE_EXTRACT
      ) {
        projectExtracts.separate++;
      }
      if (object instanceof ThreeKUnit && object.equipments) {
        for (equipment of object.equipments) {
          if (
            equipment.getClassName() === CLASSNAME_EQUIPMENT_BUILTIN_EXTRACT
          ) {
            projectExtracts.builtIn++;
          }
          if (
            equipment.getClassName() === CLASSNAME_EQUIPMENT_MOUNTED_EXTRACT
          ) {
            projectExtracts.mounted++;
          }
        }
      }
    }

    return projectExtracts;
  }

  public calculateStretchPenBoxCount(): number {
    return this.mainManager.calculateStretchPenBoxCount();
  }

  public calculateBoxes(): ISaveBoxData[] {
    return this.mainManager.calculateBoxes();
  }

  public calculateTabletopsData(): IProjectTabletopData {
    return this.mainManager.calculateTabletopsData(this.getTabletops());
  }

  public calculateApronsData(): IProjectApronData {
    return this.mainManager.calculateApronsData(this.getAprons());
  }

  public calculateCornersData(): IProjectCornerData {
    return this.mainManager.calculateCornersData(this.getCorners());
  }

  public calculatePlinthsData(): IProjectPlinthData {
    return this.mainManager.calculatePlinthsData(this.getPlinths());
  }

  public getAllFacades(
    openTypes?: TFacadeOpenType[],
    functionalTypes?: string[]
  ): ThreeFacade[] {
    return this.mainManager.getAllFacades(openTypes, functionalTypes);
  }

  public getAllHandles(): ThreeHandle[] {
    return this.mainManager.getAllHandles();
  }

  public calculateSockets(): number {
    return this.getAprons().length > 0 ? 2 : 0;
  }

  public calculateRailings(): number {
    return this.getAprons().length > 0 ? 1 : 0;
  }

  public getTabletopPlanks(): ITabletopPlanks {
    return this.rebuildManager.planksData.tabletop;
  }

  public getApronPlanks(): IApronPlanks {
    return this.rebuildManager.planksData.apron;
  }

  public setProjectOwner(userId: number, projectId: string): Promise<boolean> {
    return axios
      .post("/api/project/set-owner", { userId: userId, projectId: projectId })
      .then((response) => {
        if (!!(response.data && response.data.success)) {
          if (response.data.user && response.data.user.id) {
            this.setProjectCustomer(response.data.user);
            return true;
          }
        }
        return !!(response.data && response.data.success);
      })
      .catch(() => {
        debugger;
        return false;
      });
  }

  public setProjectCustomer(userData: IUserData) {
    this.projectData.customer = userData;
    this.sendToRedux({
      type: CHANGE_PROJECT_DATA,
      payload: CommonHelper.deepCopy(this.projectData),
    });
  }

  public sendProjectToSave(
    isTemplate?: boolean,
    isCopy?: boolean,
    saveImages?: boolean
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.saveRoom(isTemplate, isCopy)
        .then((roomId) => {
          this.saveProject(roomId, isTemplate, isCopy, saveImages)
            .then((projectId) => {
              resolve(projectId);
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  }

  public getCreateObjectCalculateType(
    createObject: ICreateObjectData
  ): TCatalogCalculateType {
    return this.kitchenHelper.getCreateObjectCalculateType(createObject);
  }

  public getCatalogCalculateType(): TCatalogCalculateType {
    return this.appConfig.catalog.calculateType;
  }

  public isSelectErrorObjects(): boolean {
    if (this.urlOptions.screen) {
      return false;
    }
    return this.appConfig.catalog.selectErrorObjects !== undefined
      ? this.appConfig.catalog.selectErrorObjects
      : false;
  }

  public isSelectPriceErrorObjects(): boolean {
    if (this.urlOptions.screen) {
      return false;
    }
    return this.getErrorModulesFunction() === ERROR_MODULES_SELECT;
  }

  public getCommonObjectExtraOffers(
    objectId: number
  ): IProjectOffers | undefined {
    let object: ThreeUnit | undefined;
    let extraOffers: IProjectOffers | undefined;

    object = this.getObjectById(objectId);
    if (object) {
      extraOffers = object.getExtraOffers();
      return extraOffers ? CommonHelper.deepCopy(extraOffers) : undefined;
    }

    return undefined;
  }

  public getProjectFormDataItems(): IProjectFormDataItem[] {
    let priceData: IProjectPriceData;

    priceData = this.getProjectPrice();
    return [
      {
        amount: 1,
        article: "",
        price: priceData.full,
        category: "kitchen",
        comment: "",
        name: "",
        id: this.getProjectId(),
        type: "project",
        modifiers: [],
        sum: priceData.full,
        validate: true,
      },
    ];
  }

  public getHobMinWidth(facadeId: string): number {
    let technologMap: ITechnologMap | undefined;

    technologMap = this.getTechnologMap(facadeId);

    return technologMap && technologMap.hob && technologMap.hob.minWidth
      ? technologMap.hob.minWidth
      : HOB_MIN_WIDTH;
  }

  public setRoomPlanes(planes: Object3D[]) {
    let walls: ThreeWall[];
    let wall: ThreeWall;
    let wallFront: Object3D | undefined;

    walls = this.getRoom().getWalls();
    for (wall of walls) {
      wallFront = wall.getFront();
      if (wallFront) {
        planes.push(wallFront);
      }
    }
  }

  public getAvailableFacadeIds(): string[] | undefined {
    return this.dataManager.getAvailableFacadeIds();
  }

  public getAvailableFacadeMaterialIds(facadeId: string): string[] | undefined {
    return this.dataManager.getAvailableFacadeMaterialIds(facadeId);
  }

  public setSpecItemOrderPart(
    specItem: ISpecItem,
    orderPart: number,
    index: number
  ): boolean {
    let unit: ThreeUnit | undefined;
    let projectOrderParts: IProjectOrderParts;
    let externalId: "externalGuid" | "vendorCode";

    externalId = this.getOfferExternalId();
    projectOrderParts = this.getProjectOrderParts() || {};
    if (!specItem[externalId]) {
      return this.afterSetSpecItemOrderPart(false);
    }
    if (specItem.unitId > 0) {
      unit = this.mainManager.getUnit(specItem.unitId);
      if (!unit) {
        return this.afterSetSpecItemOrderPart(false);
      } else {
        return this.afterSetSpecItemOrderPart(
          unit.setSpecItemOrderPart(specItem, orderPart, index)
        );
      }
    } else {
      if (!projectOrderParts[specItem.cell]) {
        projectOrderParts[specItem.cell] = [];
      }
      if (!projectOrderParts[specItem.cell][index]) {
        projectOrderParts[specItem.cell][index] = {
          id: specItem[externalId],
          part: orderPart,
        };
      } else {
        projectOrderParts[specItem.cell][index] = {
          id: specItem[externalId],
          part: orderPart,
        };
      }
    }
    this.projectData.orderParts = projectOrderParts;

    return this.afterSetSpecItemOrderPart(true);
  }

  public emptyLoadEntities(): string[] {
    let emptyEntities: string[] = [];

    if (!this.dataManager.materials?.length) {
      emptyEntities.push('materials');
    }
    if (!this.dataManager.facades?.length) {
      emptyEntities.push('facades');
    }
    if (!this.dataManager.facadeMaterials?.length) {
      emptyEntities.push('facadeMaterials');
    }
    if (!this.dataManager.corpusMaterials?.length) {
      emptyEntities.push('corpusMaterials');
    }
    if (!this.dataManager.tabletops?.length) {
      emptyEntities.push('tabletops');
    }
    if (!this.dataManager.aprons?.length) {
      emptyEntities.push('aprons');
    }
    if (!this.dataManager.corners?.length) {
      emptyEntities.push('corners');
    }
    return emptyEntities;
  }

  protected afterSetSpecItemOrderPart(isSet: boolean): boolean {
    this.updateProjectData();
    this.autoSaveProject();
    this.rebuildScene();

    return isSet;
  }

  protected getSpecItemOrderPart(
    unitId: number,
    cell: TPriceCell,
    cellIndex: number
  ): number | undefined {
    if (!this.getCanOrderPart()) {
      return undefined;
    }
    let orderPart: number;

    orderPart = 1;
    if (!unitId) {
      if (
        this.projectData.orderParts &&
        this.projectData.orderParts[cell] &&
        this.projectData.orderParts[cell][cellIndex] &&
        this.projectData.orderParts[cell][cellIndex].part
      ) {
        orderPart = this.projectData.orderParts[cell][cellIndex].part;
      }
    }

    return orderPart;
  }

  protected getExtraOffersPriceParamsBySaveData(
    data: ISaveUnitData
  ): IOfferPriceParam[] | undefined {
    if (!data.extraOffers) {
      return undefined;
    }
    let index: string;
    let projectOffer: IProjectOffer;
    let extraOffers: { [key: string]: IOfferPriceParam } = {};

    for (index in data.extraOffers) {
      projectOffer = data.extraOffers[index];
      if (!extraOffers[projectOffer.id]) {
        extraOffers[projectOffer.id] = {
          id: projectOffer.id,
          count: projectOffer.count,
        };
      } else {
        extraOffers[projectOffer.id].count += projectOffer.count;
      }
    }

    return Object.values(extraOffers);
  }

  protected afterChangeHistory() {
    this.autoSaveProject();
  }

  protected autoSaveProject() {
    if (
      !this.appConfig.autoSaveType ||
      this.appConfig.autoSaveType === AUTO_SAVE_NONE
    ) {
      return;
    }
    switch (this.appConfig.autoSaveType) {
      case AUTO_SAVE_LOCAL:
        this.autoSaveToLocal();
        break;
      case AUTO_SAVE_SERVER:
        this.autoSaveToServer();
        break;
    }
  }

  protected setUniqueSpecData(
    item: IOfferSpecData,
    items: IOfferSpecDataItems
  ) {
    if (!items[item.id]) {
      items[item.id] = item;
    } else {
      items[item.id].count += item.count;
      items[item.id].sum = items[item.id].count * items[item.id].price;
    }
  }

  protected autoSaveToLocal() {
    if (this.projectData.id !== DEFAULT_PROJECT_ID) {
      return;
    }
    const projectName: string = CommonHelper.getLocalProjectName(
      "" + this.sessionData.user.id,
      this.dataManager.selectCorpusMaterial?.id,
      this.dataManager.topFacadeMaterial?.id,
      this.dataManager.bottomFacadeMaterial?.id
    );

    let stringOldSaveData: string | undefined;
    let parseOldSaveData: any;
    let currentTime: number;
    let oldSaveData: TProjectLocalSaveData | undefined;
    let newSaveData: TProjectLocalSaveData;

    currentTime = new Date().getTime();
    stringOldSaveData = getInLocalStorage(projectName);
    if (stringOldSaveData) {
      parseOldSaveData = JSON.parse(stringOldSaveData);
      if (
        parseOldSaveData &&
        "time" in parseOldSaveData &&
        "project" in parseOldSaveData
      ) {
        oldSaveData = parseOldSaveData;
      }
    }
    if (oldSaveData) {
      if (
        !checkLocalProjectSaveTime(
          oldSaveData,
          currentTime,
          this.appConfig.localProjectSaveTime
        )
      ) {
        deleteFromLocalStorage(projectName);
        return;
      }
    }
    newSaveData = {
      time: currentTime,
      project: this.projectData,
      room: this.getRoomData(),
    };
    setToLocalStorage(projectName, JSON.stringify(newSaveData));
  }

  protected autoSaveToServer() {
    console.log("autoSaveToServer");
  }

  protected resetProjectData(newData: IWizardResetData): boolean {
    let projectData: IProjectData;

    this.deleteAllObjects();
    projectData = EditorService.getDefaultProjectData(
      this.appConfig,
      this.sessionData.user,
      this.getCollectionId()
    );
    projectData.corpusMaterial = newData.corpus;
    projectData.bottomFacade = newData.bottomFacadeMaterial;
    projectData.topFacade = newData.topFacadeMaterial;
    this.projectData = projectData;
    this.setProjectDataRedux();

    return true;
  }

  protected async calculateCheckUnit(
    createUnit: ICreateObjectData,
    group: ICreateGroup,
    type: string,
    createImages?: boolean,
  ): Promise<ICheckCatalogUnit> {
    let checkUnit: ICheckCatalogUnit;
    checkUnit = {
      sizes: {},
      unit: createUnit,
      group: {
        id: group.id,
        title: group.title,
      },
    };
    switch (this.getCreateObjectCalculateType(createUnit)) {
      case CATALOG_CALCULATE_TYPE_MODULE:
        this.kitchenHelper.calculateCheckUnitByModule(checkUnit);
        break;
      case CATALOG_CALCULATE_TYPE_DETAILS:
        this.kitchenHelper.calculateCheckUnitByDetails(checkUnit);
        break;
    }
    if (createImages) {
      switch (type) {
        case 'WizardEdit':
          await this.createCheckUnitImagesEDIT(checkUnit);
          break;
        default:
          this.deleteAllObjects();
          this.hideRoom();
          this.hideMenus();
          await this.createCheckUnitImages(checkUnit);
          break;
      }
    }

    return checkUnit;
  }

  protected getSpecDataAprons(): ISpecDataApron[] {
    let aprons: ThreeApron[];
    let apron: ThreeApron;
    let specDataAprons: ISpecDataApron[] = [];

    aprons = this.getSpecAprons();
    for (apron of aprons) {
      specDataAprons.push({
        data: {
          width: apron.getLength(),
          depth: apron.getHeight(),
          height: apron.getWidth(),
          name: apron.getMaterialTitle(),
        },
        id: apron.getId(),
      });
    }
    return specDataAprons;
  }

  protected getSpecDataFacades(): ISpecDataFacade[] {
    return [];
  }

  protected getSpecDataTabletops(): ISpecDataTabletop[] {
    let tabletops: ThreeTabletop[];
    let tabletop: ThreeTabletop;
    let specDataTabletops: ISpecDataTabletop[] = [];

    tabletops = this.getSpecTabletops();

    for (tabletop of tabletops) {
      specDataTabletops.push({
        id: tabletop.getId(),
        data: {
          height: tabletop.getHeight(),
          width: tabletop.getLength(),
          depth: tabletop.getWidth(),
          name: tabletop.getMaterialTitle(),
        },
      });
    }
    return specDataTabletops;
  }

  protected getSpecDataEuroZapil(): ISpecDataEuroZapil[] {
    return [];
  }

  protected afterSetLocations() {
    this.clearPricesCache();
    this.preloadPrices();
    this.disableCatalog();
    this.initProjectPrices();
  }

  protected clearPricesCache() {
    this.dataManager.clearPricesCache();
  }

  protected initProjectPrices() {
    this.mainManager.initProjectPrices();
  }

  protected calculateApiProjectServiceItems(): {
    [key: string]: number;
  } {
    let items: { [key: string]: number } = {};
    let services: IProjectServiceData[];
    let service: IProjectServiceData;

    services = this.getSpecServices();
    for (service of services) {
      if (!items[service.id]) {
        items[service.id] = service.count;
      } else {
        items[service.id] += service.count;
      }
    }

    return items;
  }

  protected calculateApiProjectDetailItems(
    details: Array<
      ThreeUnit | ThreeKUnitDetail | ThreeFacade | ThreeHandle | ThreeEquipment
    >
  ): {
    [key: string]: number;
  } {
    let detail:
      | ThreeUnit
      | ThreeKUnitDetail
      | ThreeFacade
      | ThreeHandle
      | ThreeEquipment;
    let offerId: string | undefined;
    let offerIds: string[];
    let items: { [key: string]: number } = {};

    for (detail of details) {
      offerIds = detail.getOfferIds();
      for (offerId of offerIds) {
        if (!items[offerId]) {
          items[offerId] = 1;
        } else {
          items[offerId] += 1;
        }
      }
    }

    return items;
  }

  public onIntegrationMessageListener(event: MessageEvent) {}

  public createImages(
    sizeType?: TKitchenSizesType,
    aspect?: TAspectData
  ): Promise<TKitchenImages | undefined> {
    return this.drawManager.createImages(sizeType, aspect);
  }

  public createImagesEDIT(
    leftAspect: TAspectData,
    frontAspect: TAspectData
  ): Promise<TKitchenImages | undefined> {
    return this.drawManager.createImagesEDIT(leftAspect, frontAspect);
  }
 
  public createDivImages(
    sizeType?: TKitchenSizesType,
    aspect?: TAspectData
  ): Promise<TKitchenImages | undefined> {
    return this.drawManager.createDivImages(sizeType, aspect);
    
  } 

  public createCuttingImages(): Promise<TKitchenCuttingImages | undefined> {
    return this.drawManager.createCuttingImages();
  }

  protected calculateProjectData(): IProjectData {
    let projectData: IProjectData;

    projectData = { ...this.projectData };
    if (!projectData.createTime) {
      projectData.createTime = Math.floor(Date.now() / 1000);
    }
    projectData.updateTime = Math.floor(Date.now() / 1000);
    projectData.sessionId = this.getProjectSessionId();
    projectData.units = this.mainManager.calculateUnitsData();
    projectData.equipments = this.mainManager.calculateEquipmentsData();
    projectData.showAprons = this.kitchenOptions.showAprons;
    projectData.showPlinths = this.kitchenOptions.showPlinths;
    projectData.showLegs = this.kitchenOptions.showLegs;
    projectData.legsHeight = this.getProjectLegsHeight();
    projectData.showTabletops = this.kitchenOptions.showTabletops;
    projectData.showCorners = this.kitchenOptions.showCorners;
    projectData.collection = this.getCollectionId();
    projectData.specList = this.getProjectSpecList();
    projectData.extraOffers = this.calculateProjectExtraOffers();
    projectData.orderParts = this.getProjectOrderParts();
    projectData.enableServices = this.kitchenOptions.enableServices;
    projectData.enableAutoServices = this.kitchenOptions.enableAutoServices;
    projectData.kitCode = this.dataManager.selectKitCode
      ? this.dataManager.selectKitCode.id
      : undefined;
    projectData.goods = this.calculateProjectGoods();
    if (this.dataManager.topFacadeMaterial) {
      projectData.topFacade = this.dataManager.topFacadeMaterial.id;
    }
    if (this.dataManager.bottomFacadeMaterial) {
      projectData.bottomFacade = this.dataManager.bottomFacadeMaterial.id;
    }
    if (this.dataManager.selectPlinth) {
      projectData.plinth = this.dataManager.selectPlinth.id;
      projectData.plinthHeight = this.dataManager.selectPlinth.height;
    }
    if (this.dataManager.selectCorner) {
      projectData.corner = this.dataManager.selectCorner.id;
    }
    if (this.dataManager.selectApron) {
      projectData.apron = this.dataManager.selectApron.id;
      projectData.apronHeight = this.dataManager.selectApron.height;
    }
    if (this.dataManager.selectTabletop) {
      projectData.tabletop = this.dataManager.selectTabletop.id;
      projectData.tabletopHeight = this.dataManager.selectTabletop.height;
    }
    if (this.dataManager.topHandle) {
      projectData.topHandle = this.dataManager.topHandle.id;
    }
    if (this.dataManager.bottomHandle) {
      projectData.bottomHandle = this.dataManager.bottomHandle.id;
    }
    if (this.dataManager.selectCorpusMaterial) {
      projectData.corpusMaterial = this.dataManager.selectCorpusMaterial.id;
    }

    return projectData;
  }

  private async onInit(roomData: ISaveRoomData) {
    await this.loadInitData();
    await this.dataManager.initState();
    await this.initRoom(roomData);
    this.dataManager.checkSelectData();
    this.initProjectDecorData();
    this.initProject();
    this.preloadPrices();
    this.ready = true;
    this.setHistoryState({
      type: HISTORY_STATE_TYPE_INIT,
      data: {},
    });
    this.sendToRedux({ type: LOADED_PROJECT });
    this.setNeedSave(this.getInitNeedSave());
    this.rebuildScene();
    this.tryStartScreen();
  }

  // For WizardEdit
  private async onInitEDIT(roomData: ISaveRoomData) {
    await this.loadInitData();
    await this.dataManager.initState();
    await this.initRoomEDIT(roomData);
    this.initProjectDecorData();
    this.initProject();
    this.ready = true;
    this.setHistoryState({
      type: HISTORY_STATE_TYPE_INIT,
      data: {},
    });
    this.sendToRedux({ type: LOADED_PROJECT });
    this.setNeedSave(this.getInitNeedSave());
  }

  protected getInitNeedSave(): boolean {
    return (
      !this.isEmptyProjectData() && this.projectData.id === DEFAULT_PROJECT_ID
    );
  }

  protected isEmptyProjectData(): boolean {
    let group: keyof IProjectUnits;

    if (this.projectData.units) {
      for (group in this.projectData.units) {
        if (
          this.projectData.units[group] &&
          this.projectData.units[group].length
        ) {
          return false;
        }
      }
    }

    return true;
  }

  protected initProjectDecorData() {
    let handle: IHandleData | undefined;
    let corpusMaterial: IMaterialData | undefined;
    let facadeMaterial: IFacadeMaterialData | undefined;

    if (this.projectData.showAprons !== undefined) {
      this.kitchenOptions.showAprons = this.projectData.showAprons;
      this.sendToRedux({
        type: CHANGE_KITCHEN_SHOW_APRONS,
        payload: this.projectData.showAprons,
      });
    }
    if (this.projectData.showCorners !== undefined) {
      this.kitchenOptions.showCorners = this.projectData.showCorners;
      this.sendToRedux({
        type: CHANGE_KITCHEN_SHOW_CORNERS,
        payload: this.projectData.showCorners,
      });
    }
    if (this.projectData.showTabletops !== undefined) {
      this.kitchenOptions.showTabletops = this.projectData.showTabletops;
      this.sendToRedux({
        type: CHANGE_KITCHEN_SHOW_TABLETOPS,
        payload: this.projectData.showTabletops,
      });
    }
    if (this.projectData.showPlinths !== undefined) {
      this.kitchenOptions.showPlinths = this.projectData.showPlinths;
      this.sendToRedux({
        type: CHANGE_KITCHEN_SHOW_PLINTHS,
        payload: this.projectData.showPlinths,
      });
    }
    if (this.projectData.showLegs !== undefined) {
      this.kitchenOptions.showLegs = this.projectData.showLegs;
      this.sendToRedux({
        type: CHANGE_KITCHEN_SHOW_LEGS,
        payload: this.projectData.showLegs,
      });
    }
    if (this.projectData.enableServices !== undefined) {
      this.kitchenOptions.enableServices = this.projectData.enableServices;
      this.sendToRedux({
        type: CHANGE_KITCHEN_ENABLE_SERVICES,
        payload: this.projectData.enableServices,
      });
    }
    if (this.projectData.enableAutoServices !== undefined) {
      this.kitchenOptions.enableAutoServices =
        this.projectData.enableAutoServices;
      this.sendToRedux({
        type: CHANGE_KITCHEN_ENABLE_AUTO_SERVICES,
        payload: this.projectData.enableAutoServices,
      });
    }
    if (this.projectData.kitCode !== undefined) {
      let kitCode: TSelectItem | undefined;

      kitCode = this.dataManager.getKitCodeById(this.projectData.kitCode);
      if (kitCode) {
        this.dataManager.setSelectKitCode(kitCode);
      }
    }
    if (
      this.dataManager.selectTabletop &&
      this.projectData.tabletop !== this.dataManager.selectTabletop.id
    ) {
      this.dataManager.setTabletop(
        this.getTabletopMaterial(this.projectData.tabletop)
      );
    }
    if (
      this.dataManager.selectTabletop &&
      this.projectData.tabletopHeight !== undefined &&
      this.projectData.tabletopHeight !==
        this.dataManager.selectTabletop.height &&
      this.dataManager.selectTabletop.heights &&
      this.dataManager.selectTabletop.heights.includes(
        this.projectData.tabletopHeight
      )
    ) {
      this.dataManager.setTabletopHeight(
        this.dataManager.selectTabletop,
        this.projectData.tabletopHeight
      );
    }
    if (
      this.dataManager.selectApron &&
      this.projectData.apron !== this.dataManager.selectApron.id
    ) {
      this.dataManager.setApron(this.getApronMaterial(this.projectData.apron));
    }
    if (
      this.dataManager.selectApron &&
      this.projectData.apronHeight !== undefined &&
      this.projectData.apronHeight !== this.dataManager.selectApron.height &&
      this.dataManager.selectApron.heights &&
      this.dataManager.selectApron.heights.includes(
        this.projectData.apronHeight
      )
    ) {
      this.dataManager.setApronHeight(
        this.dataManager.selectApron,
        this.projectData.apronHeight
      );
    }
    if (
      this.dataManager.selectCorner &&
      this.projectData.corner !== this.dataManager.selectCorner.id
    ) {
      this.dataManager.setCorner(
        this.getCornerMaterial(this.projectData.apron)
      );
    }
    if (
      this.dataManager.selectPlinth &&
      this.projectData.plinth !== this.dataManager.selectPlinth.id
    ) {
      this.dataManager.setPlinth(
        this.getPlinthMaterial(this.projectData.apron)
      );
    }
    if (
      this.dataManager.selectPlinth &&
      this.projectData.plinthHeight !== undefined &&
      this.projectData.plinthHeight !== this.dataManager.selectPlinth.height &&
      this.dataManager.selectPlinth.heights &&
      this.dataManager.selectPlinth.heights.includes(
        this.projectData.plinthHeight
      )
    ) {
      this.dataManager.setPlinthHeight(
        this.dataManager.selectPlinth,
        this.projectData.plinthHeight
      );
    }
    if (
      this.dataManager.topHandle &&
      this.projectData.topHandle !== this.dataManager.topHandle.id
    ) {
      handle = this.getHandleData(this.projectData.topHandle as string);
      if (handle) {
        this.dataManager.setTopHandle(handle);
      }
    }
    if (
      this.dataManager.bottomHandle &&
      this.projectData.bottomHandle !== this.dataManager.bottomHandle.id
    ) {
      handle = this.getHandleData(this.projectData.bottomHandle as string);
      if (handle) {
        this.dataManager.setBottomHandle(handle);
      }
    }
    if (
      this.dataManager.selectCorpusMaterial &&
      this.projectData.corpusMaterial !==
        this.dataManager.selectCorpusMaterial.id
    ) {
      corpusMaterial = this.getCorpusMaterial(
        this.projectData.corpusMaterial as string
      );
      if (corpusMaterial) {
        this.dataManager.setCorpusMaterial(corpusMaterial);
      }
    }
    if (
      this.dataManager.topFacadeMaterial &&
      this.projectData.topFacade !== this.dataManager.topFacadeMaterial.id
    ) {
      facadeMaterial = this.getFacadeMaterial(
        LEVEL_TOP,
        this.projectData.topFacade as string
      );
      if (facadeMaterial) {
        this.dataManager.setTopFacadeMaterial(facadeMaterial);
      }
    }
    if (
      this.dataManager.bottomFacadeMaterial &&
      this.projectData.bottomFacade !== this.dataManager.bottomFacadeMaterial.id
    ) {
      facadeMaterial = this.getFacadeMaterial(
        LEVEL_BOTTOM,
        this.projectData.bottomFacade as string
      );
      if (facadeMaterial) {
        this.dataManager.setBottomFacadeMaterial(facadeMaterial);
      }
    }
  }

  public tryStartScreen() {
    switch (this.urlOptions.screen) {
      case "facade":
      case "facades":
        debugger;
        this.startFacadeScreen();
        break;
      case "module":
      case "modules":
        debugger;
        this.startModulesScreen();
        break;
    }
  }

  public getCheckErrorObjects(): boolean {
    return this.kitchenOptions.checkPriceErrors;
  }

  public setCheckErrorObjects(value: boolean): boolean {
    this.kitchenOptions.checkPriceErrors = value;
    this.reduxDispatch({
      type: CHANGE_KITCHEN_CHECK_ERRORS_OBJECTS,
      payload: value,
    });
    return this.kitchenOptions.checkPriceErrors;
  }

  public onStartVisualModulesScreen() {
    this.startModulesScreen([KITCHEN_VIEW_VISUAL]);
  }

  public startFacadeScreen() {
    if (!this.dataManager.units) {
      return;
    }
    if (!this.dataManager.facadeMaterials) {
      return;
    }

    let createUnit: ICreateObjectData | undefined;
    const uid: string = this.urlOptions.unit || "shkaf-1dver";
    let defaultOptions: any;
    let newUnit: ThreeUnit | undefined;
    let isLoad: boolean;
    let index;

    createUnit = this.getCreateUnitByUid(uid);
    if (!createUnit) {
      return;
    }
    this.editor.setScreenWidth(SCREEN_WIDTH);
    this.editor.setScreenHeight(SCREEN_HEIGHT);
    this.editor.setClearColor(0xffffff, 0);
    this.deleteAllObjects();
    this.hideRoom();
    defaultOptions = this.getDefaultOptions(createUnit);
    const modulePrice: IModulePriceData = {
      id: "",
      price: 0,
      errors: [],
      unitId: 0,
      cell: PRICE_CELL_MODULE,
      sizes: {
        width: defaultOptions.width || 0,
        height: defaultOptions.height || 0,
        depth: defaultOptions.depth || 0,
      },
    };
    const saveObjectData: ISaveUnitData = this.generateSaveObjectData(
      createUnit.uid,
      defaultOptions,
      modulePrice,
      createUnit.catalogCode
    );
    newUnit = this.createCommonObject(saveObjectData);
    if (newUnit) {
      newUnit.showOnlyFacades();
      newUnit.hideHandles();
      this.showAll();
      this.screenFacadeMaterials = [...this.dataManager.facadeMaterials].filter(
        (item) => {
          return (
            !createUnit ||
            !createUnit.enableFacades ||
            createUnit.enableFacades.includes(item.facade)
          );
        }
      );
      const checkInterval = setInterval(() => {
        isLoad = true;
        for (index in this.cacheManager.cacheModels) {
          if (!this.cacheManager.cacheModels[index].isLoad) {
            isLoad = false;
            break;
          }
        }
        for (index in this.cacheManager.cacheTextures) {
          if (!this.cacheManager.cacheTextures[index].isLoad) {
            isLoad = false;
            break;
          }
        }
        if (isLoad && newUnit) {
          clearInterval(checkInterval);
          this.recursiveFacadeScreen(newUnit);
        }
      }, 100);
    }
  }

  private recursiveFacadeScreen(unit: ThreeUnit) {
    if (this.screenFacadeMaterials && this.screenFacadeMaterials.length > 0) {
      const facadeMaterial = this.screenFacadeMaterials.shift();
      let index;
      let isLoad: boolean;
      const urlParams = new URLSearchParams(window.location.search);
      const urlFacadeMaterials: string | null = urlParams.get("materials");

      if (facadeMaterial) {
        if (
          urlFacadeMaterials &&
          !urlFacadeMaterials.includes(facadeMaterial.id)
        ) {
          this.recursiveFacadeScreen(unit);
          return;
        }
        unit.trySetFacadeMaterial(facadeMaterial, true);
        const checkInterval = setInterval(() => {
          isLoad = true;
          for (index in this.cacheManager.cacheModels) {
            if (!this.cacheManager.cacheModels[index].isLoad) {
              isLoad = false;
              break;
            }
          }
          for (index in this.cacheManager.cacheTextures) {
            if (!this.cacheManager.cacheTextures[index].isLoad) {
              isLoad = false;
              break;
            }
          }
          if (isLoad) {
            clearInterval(checkInterval);
            this.showAll();
            this.editor.renderStep(true);
            setTimeout(() => {
              this.createImages(KITCHEN_SIZES_TYPE_NONE).then((images) => {
                if (images) {
                  this.savePng(images.visual, facadeMaterial.id + ".png");
                }
                this.recursiveFacadeScreen(unit);
              });
            }, 100);
          }
        }, 100);
      }
    }
  }

  public async startModulesScreen(
    saveTypes: TKitchenView[] = [KITCHEN_VIEW_SKETCH]
  ) {
    if (!this.dataManager.units) {
      return;
    }
    let group: ICreateGroup;
    let createUnit: ICreateObjectData;
    let defaultOptions: any;
    let newUnit: ThreeUnit | undefined;
    let images: TKitchenImages | undefined;

    this.setCheckErrorObjects(false);
    this.editor.setScreenWidth(SCREEN_WIDTH);
    this.editor.setScreenHeight(SCREEN_HEIGHT);
    this.editor.setClearColor(0xffffff, 0);
    this.deleteAllObjects();
    this.hideRoom();
    const uid: string | undefined = this.urlOptions.unit;

    for (group of this.dataManager.units) {
      if (
        [
          GROUP_PORTABLE_EQUIPMENTS,
          GROUP_BIG_EQUIPMENTS,
          GROUP_BOTTOM_EQUIPMENTS,
          GROUP_TOP_EQUIPMENTS,
          GROUP_BUILTIN_EQUIPMENTS,
          GROUP_EQUIPMENTS,
        ].includes(group.id)
      ) {
        continue;
      }
      for (createUnit of group.items) {
        if (
          this.dataManager.isCreateUnitDisableByFacadeThreeModel(
            createUnit,
            this.getDefaultFacadeData(createUnit.level)
          )
        ) {
          continue;
        }
        if (uid && uid !== createUnit.uid) {
          continue;
        }
        defaultOptions = this.getDefaultOptions(createUnit);
        const modulePrice: IModulePriceData = {
          id: "",
          price: 0,
          errors: [],
          unitId: 0,
          cell: PRICE_CELL_MODULE,
          sizes: {
            width: defaultOptions.width || 0,
            height: defaultOptions.height || 0,
            depth: defaultOptions.depth || 0,
          },
        };
        defaultOptions.notPrice = true;
        const saveObjectData: ISaveUnitData = this.generateSaveObjectData(
          createUnit.uid,
          defaultOptions,
          modulePrice,
          createUnit.catalogCode
        );

        newUnit = this.createCommonObject(saveObjectData);
        if (newUnit) {
          newUnit.setPosition(new Vector3(0, newUnit.defaultYPosition(), 0));
          this.rebuildScene();
          if (!newUnit.isEquipment()) {
            await this.cacheManager.isLoaded();
            this.showAll();
            images = await this.createImages(KITCHEN_SIZES_TYPE_NONE);
            if (images) {
              for (let imageType of saveTypes) {
                this.savePng(
                  images[imageType],
                  createUnit.title + "_" + imageType + ".png"
                );
              }
            }
          }
          this.deleteCommonObject(newUnit.getId());
        }
      }
    }
  }

  public getSelectCorpusMaterial(): IMaterialData | undefined {
    return this.dataManager.selectCorpusMaterial;
  }

  public getBottomFacadeMaterial(): IFacadeMaterialData | undefined {
    return this.dataManager.bottomFacadeMaterial;
  }

  public getTopFacadeMaterial(): IFacadeMaterialData | undefined {
    return this.dataManager.topFacadeMaterial;
  }

  public checkProjectModules() {
    this.mainManager.checkProjectModules();
  }

  public getErrorModulesFunction(): TErrorModulesFunction {
    return this.appConfig.catalog.errorModulesFunction || ERROR_MODULES_SELECT;
  }

  public getDetailCollectionOffers(
    detailData: IDetailData,
    type: TGoodType
  ): IImportOffer[] {
    return this.dataManager.getDetailCollectionOffers(detailData, type);
  }

  private initProject() {
    this.mainManager.initProject();
    this.rebuildScene();
    this.checkProjectModules();
    this.setProjectDataRedux();
  }

  private preloadPrices() {
    let offerIds: string[];

    if (!this.kitchenOptions.preloadPrices) {
      return;
    }
    offerIds = this.dataManager
      .getAllCurrentOffers()
      .map((offer) => offer[this.getOfferExternalId()]);
    this.reduxDispatch({
      type: SHOW_LOADING,
    });
    this.loadPrices(offerIds)
      .then(() => {
        this.disableCatalog();
        this.reduxDispatch({
          type: HIDE_LOADING,
        });
      })
      .catch(() => {
        this.disableCatalog();
        this.reduxDispatch({
          type: HIDE_LOADING,
        });
      });
  }

  private async initRoom(roomData: ISaveRoomData) {
    if (!this.room) {
      this.room = new ThreeRoom(roomData, this);
      this.room.initState();
      this.room.createView();
    } else if (this.room.isChangeData(roomData)) {
      this.room.rebuild(roomData);
    }
    const wallMaterialId: string | undefined = roomData.walls[0]
      ? roomData.walls[0].materialId
      : undefined;
    this.dataManager.setWall(this.dataManager.getWallMaterial(wallMaterialId));
    this.dataManager.setFloor(
      this.dataManager.getFloorMaterial(roomData.floor.materialId)
    );
  }

  private async initRoomEDIT(roomData: ISaveRoomData) {
    if (!this.room) this.room = new ThreeRoom(roomData, this);
  }

  private removeRoom() {
    this.room?.remove();
  }

  public getCanvas(): HTMLCanvasElement {
    return this.editor.getCanvas();
  }

  public rebuildData(unitId: number, method: string, value: any): boolean {
    let unit: ThreeUnit | undefined;
    const rebuildUnitMethods: any = this.rebuildUnitManager.getAllMethods();

    unit = this.mainManager.getUnit(unitId);
    if (!unit) {
      unit = this.mainManager.getEquipment(unitId);
    }
    if (!unit && this.room) {
      unit = this.room.getConstructive(unitId);
    }
    if (!unit || !(method in rebuildUnitMethods)) {
      return false;
    }

    const oldSaveData: ISaveUnitData = CommonHelper.deepCopy(unit.getData());
    // @ts-ignore
    const newSaveData = this.rebuildUnitManager[method](oldSaveData, value);
    if (CommonHelper.md5(oldSaveData) === CommonHelper.md5(newSaveData)) {
      return true;
    }
    if (this.rebuildObject(unit, newSaveData, true)) {
      this.reduxDispatch({
        type: SHOW_SETTINGS_MENU,
        payload: {
          visible: true,
          unitId: unit.getId(),
          data: unit.getData(),
          createObjectData: this.getCreateObjectByUid(unit.getUid()),
          price: unit.getPrice(),
          groups: unit.getSettingsGroups(),
        },
      });

      return true;
    } else {
      this.showMessage({
        type: MESSAGE_TYPE_WARNING,
        message: i18n.t("Не удалось изменить свойства модуля"),
      });

      return false;
    }
  }

  public rebuildObject(
    object: ThreeUnit,
    newSaveData: ISaveUnitData,
    setState?: boolean
  ): boolean {
    let modulePrice: IModulePriceData;
    let historyState: IHistoryChangeObjectsState;
    const oldSaveData: ISaveUnitData = CommonHelper.deepCopy(object.getData());

    if (CommonHelper.md5(oldSaveData) === CommonHelper.md5(newSaveData)) {
      return true;
    }
    modulePrice = this.calculatePrice(
      this.getUnitSaveDataPriceParams(newSaveData)
    );

    if (modulePrice.errors.length > 0) {
      return false;
    }

    object.rebuild(newSaveData);
    if (
      oldSaveData.sizes.length !== newSaveData.sizes.length ||
      (oldSaveData.corpus !== undefined &&
        newSaveData.corpus !== undefined &&
        oldSaveData.corpus.sizes.length !== newSaveData.corpus.sizes.length)
    ) {
      if (!object.checkIntersects() || object.checkMoveInRoom()) {
        console.log("need set new position");
      }
    }
    this.rebuildManager.clearUnitDetails();
    this.rebuildScene();
    if (setState) {
      historyState = {
        type: HISTORY_STATE_TYPE_CHANGE,
        data: {
          objects: [{ oldData: oldSaveData, newData: newSaveData }],
        },
      };
      this.setHistoryState(historyState);
    }

    return true;
  }

  public getCreateGroupUnits(
    types?: TCreateGroup[],
    level?: TLevel
  ): ICreateGroup[] | undefined {
    return this.dataManager.getCreateGroupUnits(types, level);
  }

  public loadInitData(): Promise<IWizardInitData> {
    return new Promise((resolve) => {
      let initData: IWizardInitData = {};
      resolve(initData);
    });
  }

  public setInitData(initData: IWizardInitData) {
    this.initData = initData;
    this.sendToRedux({
      type: CHANGE_WIZARD_INIT_DATA,
      payload: CommonHelper.deepCopy(this.initData),
    });
    if (this.initData.title) {
      this.setAppConfig({
        ...this.appConfig,
        wizardTitle: this.initData.title,
      });
    }
  }

  public sceneChildren() {
    return this.editor.sceneChildren();
  }
}