import {Room} from '../../../Room/Room';
import {RoomPoint} from '../../../../points/RoomPoint/RoomPoint';
import {KitchenService} from '../../../../services/KitchenService/KitchenService';
import {ThreeWall} from '../ThreeWall/ThreeWall';
import {ThreeFloor} from '../ThreeFloor/ThreeFloor';
import {ThreeRoof} from '../ThreeRoof/ThreeRoof';
import {ThreeDoor} from '../../constructive/ThreeDoor/ThreeDoor';
import {ThreeWindow} from '../../constructive/ThreeWindow/ThreeWindow';
import {ThreeRoomPoint} from '../../../../points/ThreeRoomPoint/ThreeRoomPoint';
import {Box3, Mesh, Vector3} from 'three';
import {TPoint2D} from '../../../../../../common-code/types/TPoint2D';
import {ThreeConstructive} from '../../constructive/ThreeConstructive/ThreeConstructive';
import {TLevelBoxes} from '../../../../types/TLevelBoxes';
import {
    CLASSNAME_CONSTRUCTIVE_COOLER,
    CLASSNAME_CONSTRUCTIVE_DOOR,
    CLASSNAME_CONSTRUCTIVE_DOORWAY,
    CLASSNAME_CONSTRUCTIVE_GAS_BOILER,
    CLASSNAME_CONSTRUCTIVE_GAS_METER,
    CLASSNAME_CONSTRUCTIVE_PILLAR,
    CLASSNAME_CONSTRUCTIVE_RADIATOR_SECTION,
    CLASSNAME_CONSTRUCTIVE_SOCKET,
    CLASSNAME_CONSTRUCTIVE_SWITCH,
    CLASSNAME_CONSTRUCTIVE_VENTILATION,
    CLASSNAME_CONSTRUCTIVE_WALL_ISLAND,
    CLASSNAME_CONSTRUCTIVE_WATER_HEATER_HORIZONTAL,
    CLASSNAME_CONSTRUCTIVE_WATER_HEATER_VERTICAL,
    CLASSNAME_CONSTRUCTIVE_WINDOW, LEVEL_BOTTOM, LEVEL_TOP,
    PILLAR_TYPE_DEFAULT,
    PILLAR_TYPE_HORIZONTAL,
    PILLAR_TYPE_VERTICAL,
} from '../../../../../../common-code/constants';
import {ThreeDoorway} from '../../constructive/ThreeDoorway/ThreeDoorway';
import {ThreePillar} from '../../constructive/ThreePillar/ThreePillar';
import {ThreeWallIsland} from '../../constructive/ThreeWallIsland/ThreeWallIsland';
import {ThreePillarHorizontal} from '../../constructive/ThreePillar/ThreePillarHorizontal';
import {ThreePillarVertical} from '../../constructive/ThreePillar/ThreePillarVertical';
import {ISaveRoomData} from '../../../../../../common-code/interfaces/saveData/ISaveRoomData';
import {IMaterialData} from '../../../../../../common-code/interfaces/materials/IMaterialData';
import {TWizardUIOptions} from '../../../../../types/TWizardUIOptions';
import {ISaveConstructiveData} from '../../../../../../common-code/interfaces/saveData/ISaveConstructiveData';
import {ISaveDoorData} from '../../../../../../common-code/interfaces/saveData/ISaveDoorData';
import {ISaveDoorwayData} from '../../../../../../common-code/interfaces/saveData/ISaveDoorwayData';
import {ISaveWindowData} from '../../../../../../common-code/interfaces/saveData/ISaveWindowData';
import {ISavePillarData} from '../../../../../../common-code/interfaces/saveData/ISavePillarData';
import {ISaveWallIslandData} from '../../../../../../common-code/interfaces/saveData/ISaveWallIslandData';
import {ISavePoint2DData} from '../../../../../../common-code/interfaces/saveData/ISavePoint2DData';
import {ISaveWallData} from '../../../../../../common-code/interfaces/saveData/ISaveWallData';
import {ISaveFloorData} from '../../../../../../common-code/interfaces/saveData/ISaveFloorData';
import {ISaveRoofData} from '../../../../../../common-code/interfaces/saveData/ISaveRoofData';
import {CHANGE_ROOM_VISIBLE} from '../../../../../constants';
import {HISTORY_STATE_TYPE_DELETE} from '../../../../constants';
import {IHistoryCreateObjectsState} from '../../../../interfaces/history/IHistoryCreateObjectsState';
import {TLevel} from '../../../../../../common-code/types/TLevel';
import {ThreeConstructiveModel} from '../../constructive/ThreeConstructiveModel/ThreeConstructiveModel';
import {
    ThreeBottomConstructiveModel
} from '../../constructive/ThreeConstructiveModel/types/ThreeBottomConstructiveModel';
import {ThreeTopConstructiveModel} from '../../constructive/ThreeConstructiveModel/types/ThreeTopConstructiveModel';

export class ThreeRoom extends Room {
    protected roomData: ISaveRoomData;
    protected points: ThreeRoomPoint[];
    protected wallIslandPoints: ThreeRoomPoint[];
    protected walls: ThreeWall[];
    protected wallIslandWalls: ThreeWall[];
    private floor: ThreeFloor | null;
    private roof: ThreeRoof | null;
    protected service: KitchenService;
    protected constructiveUnits: ThreeConstructive[];

    constructor(roomData: ISaveRoomData, kitchenService: KitchenService) {
        super(roomData, kitchenService);
        this.roomData = roomData;
        this.points = [];
        this.wallIslandPoints = [];
        this.walls = [];
        this.wallIslandWalls = [];
        this.service = kitchenService;
        this.floor = null;
        this.roof = null;
        this.constructiveUnits = this.initConstructiveUnits();
    }

    public getService(): KitchenService {
        return this.service;
    }

    public initState(isRebuild?: boolean) {
        this.createPoints();
        this.createWalls();
        this.setWallNeighbors();
        this.createFloor();
        // this.createRoof();
    }

    public createView() {
        this.createWallViews();
        this.createFloorView();
        this.createRoofView();
        this.createConstructiveUnits();
        this.rebuildSizes();
        super.createView();
    }

    public toggleVisible() {
        if (this.isVisible()) {
            this.hide();
        } else {
            this.show();
        }
    }

    public show() {
        this.visible = true;
        let wall: ThreeWall;
        for (wall of this.walls) {
            wall.view3d.visible = true;
        }
        for (wall of this.wallIslandWalls) {
            wall.view3d.visible = true;
        }
        if (this.floor) {
            this.floor.view3d.visible = true;
        }
        if (this.roof) {
            this.roof.view3d.visible = true;
        }
    }

    public hide() {
        this.visible = false;
        let wall: ThreeWall;
        for (wall of this.walls) {
            wall.view3d.visible = false;
        }
        for (wall of this.wallIslandWalls) {
            wall.view3d.visible = false;
        }
        if (this.floor) {
            this.floor.view3d.visible = false;
        }
        if (this.roof) {
            this.roof.view3d.visible = false;
        }
            }

    public remove() {
        this.removeChildren();
    }

    public setFloorMaterial(material: IMaterialData) {
        if (!this.floor) {
            return;
        }
        this.floor.trySetMaterial(material);
    }

    public setWallMaterial(material: IMaterialData, wallId?: number) {
        let wall: ThreeWall;

        for (wall of this.walls) {
            if (wallId && wallId !== wall.getId()) {
                continue;
            }
            wall.trySetMaterial(material);
        }
        for (wall of this.wallIslandWalls) {
            if (wallId && wallId !== wall.getId()) {
                continue;
            }
            wall.trySetMaterial(material);
        }
    }

    public addWallIslandPoint(point: ThreeRoomPoint) {
        this.wallIslandPoints.push(point);
    }

    public removeWallIslandPoint(pointId: number) {
        let index: string;
        for (index in this.wallIslandPoints) {
            if (this.wallIslandPoints[index].getId() === pointId) {
                this.wallIslandPoints.splice(+index, 1);
            }
        }
    }

    public addWallIslandWall(wall: ThreeWall) {
        this.wallIslandWalls.push(wall);
    }

    public removeWallIslandWall(wallId: number) {
        let index: string;
        for (index in this.wallIslandWalls) {
            if (this.wallIslandWalls[index].getId() === wallId) {
                this.wallIslandWalls.splice(+index, 1);
            }
        }
    }

    public getIntersectWalls(): Mesh[] {
        let meshObjects: Mesh[] = [];
        let wall: ThreeWall;

        for (wall of this.walls) {
            if (wall.front) {
                meshObjects.push(wall.front);
            }
        }

        return meshObjects;
    }

    public getDefaultWall(): ThreeWall {
        let wall: ThreeWall = this.walls[0];
        if (wall) {
            return wall;
        }
        throw new Error('error-ThreeRoom-getDefaultWall');
    }

    public getWallById(id: number): ThreeWall {
        let wall: ThreeWall;
        for (wall of this.walls) {
            if (wall.getId() === id) {
                return wall;
            }
        }
        for (wall of this.wallIslandWalls) {
            if (wall.getId() === id) {
                return wall;
            }
        }
        throw new Error('error-ThreeRoom-getWallById');
    }

    public tryGetWallById(id: number): ThreeWall | undefined {
        let wall: ThreeWall | undefined;
        try {
            wall = this.getWallById(id);
        } catch (e) {

        }

        return wall;
    }

    public getPoints(): ThreeRoomPoint[] {
        return this.points;
    }

    public getWallIslandPoints(): ThreeRoomPoint[] {
        return this.wallIslandPoints;
    }

    public getFloor(): ThreeFloor | null {
        return this.floor;
    }

    public getWalls(): ThreeWall[] {
        return this.walls.concat(this.wallIslandWalls);
    }

    public getPolygon(): TPoint2D[] {
        let point: ThreeRoomPoint;
        let polygon: TPoint2D[] = [];

        for (point of this.points) {
            polygon.push({x: point.value.x, y: point.value.z});
        }

        return polygon;
    }

    public getBox(): Box3 {
        let point: ThreeRoomPoint;
        let points: Vector3[] = [];
        let box: Box3;

        for (point of this.points) {
            points.push(new Vector3(point.value.x, 0, point.value.z));
            points.push(new Vector3(point.value.x, this.getHeight(), point.value.z));
        }

        box = new Box3().setFromPoints(points);

        return box;
    }

    public updateViewType() {
        let wall: ThreeWall;
        let options: TWizardUIOptions;

        options = this.service.getOptions();
        for (wall of this.walls) {
            wall.setViewType(options.viewType);
        }
        this.roof?.setViewType(options.viewType);
        this.floor?.setViewType(options.viewType);
        this.constructiveUpdateViewType();
    }

    public rebuildSizes(levelBoxes?: TLevelBoxes) {
        let wall: ThreeWall;
        let unitsPolygon: TPoint2D[] | undefined;
        let constructive: ThreeConstructive;

        unitsPolygon = this.service.getUnitsPolygon();
        for (wall of this.walls) {
            wall.rebuildSizes(unitsPolygon);
        }
        for (constructive of this.constructiveUnits) {
            constructive.rebuildSizes(levelBoxes);
        }
    }

    public createConstructiveUnit(data: ISaveConstructiveData): ThreeConstructive | undefined {
        let constructive: ThreeConstructive | undefined;

        switch (data.className) {
            case CLASSNAME_CONSTRUCTIVE_DOOR:
                constructive = this.createDoor(data as ISaveDoorData);
                break;
            case CLASSNAME_CONSTRUCTIVE_DOORWAY:
                constructive = this.createDoorway(data as ISaveDoorwayData);
                break;
            case CLASSNAME_CONSTRUCTIVE_WINDOW:
                constructive = this.createWindow(data as ISaveWindowData);
                break;
            case CLASSNAME_CONSTRUCTIVE_PILLAR:
                constructive = this.createPillar(data as ISavePillarData);
                break;
            case CLASSNAME_CONSTRUCTIVE_WALL_ISLAND:
                constructive = this.createWallIsland(data as ISaveWallIslandData);
                break;
            case CLASSNAME_CONSTRUCTIVE_GAS_METER:
            case CLASSNAME_CONSTRUCTIVE_GAS_BOILER:
            case CLASSNAME_CONSTRUCTIVE_VENTILATION:
            case CLASSNAME_CONSTRUCTIVE_WATER_HEATER_HORIZONTAL:
            case CLASSNAME_CONSTRUCTIVE_WATER_HEATER_VERTICAL:
            case CLASSNAME_CONSTRUCTIVE_SOCKET:
            case CLASSNAME_CONSTRUCTIVE_SWITCH:
            case CLASSNAME_CONSTRUCTIVE_RADIATOR_SECTION:
                constructive = this.createConstructiveModel(data as ISaveConstructiveData, LEVEL_TOP);
                break;
            case CLASSNAME_CONSTRUCTIVE_COOLER:
                constructive = this.createConstructiveModel(data as ISaveConstructiveData, LEVEL_BOTTOM);
                break;
        }

        if (constructive) {
            this.constructiveUnits.push(constructive);
        }

        return constructive;
    }

    public getConstructive(id: number): ThreeConstructive | undefined {
        let index;
        let constructive: ThreeConstructive;

        for (index in this.constructiveUnits) {
            constructive = this.constructiveUnits[index];
            if (constructive.getId() === id) {
                return constructive;
            }
        }

        return undefined;
    }


    public deleteConstructive(id: number): IHistoryCreateObjectsState | undefined {
        let index;
        let constructive: ThreeConstructive;
        let historyState: IHistoryCreateObjectsState;

        for (index in this.constructiveUnits) {
            constructive = this.constructiveUnits[index];
            if (constructive.getId() === id) {
                historyState = {
                    type: HISTORY_STATE_TYPE_DELETE,
                    data: {
                        objects: [constructive.getData()]
                    }
                };
                constructive.remove();
                this.constructiveUnits.splice(+index, 1);

                return historyState;
            }
        }

        return undefined;
    }

    protected setVisible(value: boolean) {
        this.visible = value;
        this.service.sendToRedux({
            type: CHANGE_ROOM_VISIBLE,
            payload: this.visible
        });
    }

    protected getPointsSaveData(): ISavePoint2DData[] {
        let point: ThreeRoomPoint;
        let pointsData: ISavePoint2DData[] = [];

        for (point of this.points) {
            pointsData.push(point.getData());
        }

        return pointsData;
    }

    protected getWallsSaveData(): ISaveWallData[] {
        let wall: ThreeWall;
        let wallsData: ISaveWallData[] = [];

        for (wall of this.walls) {
            wallsData.push(wall.getData());
        }

        return wallsData;
    }

    protected getConstructiveSaveData(): ISaveConstructiveData[] {
        let constructiveUnit: ThreeConstructive;
        let constructiveList: ISaveConstructiveData[] = [];

        for (constructiveUnit of this.constructiveUnits) {
            constructiveList.push(constructiveUnit.getData());
        }

        return constructiveList;
    }

    protected getFloorSaveData(): ISaveFloorData {
        if (!this.floor) {
            throw new Error('error-ThreeRoom-getFloorSaveData');
        }
        return this.floor.getData();
    }

    protected getRoofSaveData(): ISaveRoofData {
        if (!this.roof) {
            return {id: 0};
            // throw new Error('error-ThreeRoom-getRoofSaveData');
        }
        return this.roof.getData();
    }

    protected initConstructiveUnits(): ThreeConstructive[] {
        return [];
    }

    protected constructiveUpdateViewType() {
        let options: TWizardUIOptions;
        let constructive: ThreeConstructive;

        options = this.service.getOptions();
        for (constructive of this.constructiveUnits) {
            constructive.setViewType(options.viewType);
        }
    }

    protected createPoints() {
        let pointData: ISavePoint2DData;
        let roomPoint;

        for (pointData of this.roomData.points) {
            roomPoint = new ThreeRoomPoint(pointData, this.service);
            roomPoint.initState();
            roomPoint.createView();
            this.points.push(roomPoint);
        }
    }

    public createWalls() {
        let wallData: ISaveWallData;
        let wall: ThreeWall;

        for (wallData of this.roomData.walls) {
            wall = new ThreeWall(wallData, this);
            this.walls.push(wall);
        }
    }

    protected setWallNeighbors() {
        let index;
        let index2;

        for (index in this.walls) {
            for (index2 in this.walls) {
                if (index === index2) {
                    continue;
                }
                if (this.walls[index].pointA.getId() === this.walls[index2].pointB.getId()) {
                    this.walls[index].setLeftNeighbor(this.walls[index2]);
                    this.walls[index2].setRightNeighbor(this.walls[index]);
                }
            }
        }
    }

    protected createFloor() {
        this.floor = new ThreeFloor(this.roomData.floor, this);
    }

    // protected createRoof() {
    //     this.roof = new ThreeRoof(this.roomData.roof, this);
    // }

    protected removeChildren() {
        this.removeConstructiveUnits();
        this.removeFloor();
        this.removeRoof();
        this.removeWalls();
        this.removePoints();
    }

    protected removeConstructiveUnits() {
        let constructive: ThreeConstructive | undefined;
        for (constructive of this.constructiveUnits) {
            constructive.remove();
        }
        this.constructiveUnits = [];
    }

    private removeFloor() {
        if (this.floor) {
            this.floor.remove();
        }
        this.floor = null;
    }

    private removeRoof() {
        if (this.roof) {
            this.roof.remove();
        }
        this.roof = null;
    }

    public removeWalls() {
        let wall: ThreeWall;

        for (wall of this.walls) {
            wall.remove();
        }

        this.walls = [];
    }

    private removePoints() {
        let point: RoomPoint;

        for (point of this.points) {
            point.remove();
        }
        this.points = [];
    }

    private createWallViews() {
        let wall: ThreeWall;

        for (wall of this.walls) {
            wall.initState();
            wall.createView();
        }
    }

    private createFloorView() {
        this.floor?.createView();
    }

    private createRoofView() {
        this.roof?.createView();
    }

    protected createConstructiveUnits() {
        let constructive: ThreeConstructive | undefined;
        let constructiveData: ISaveConstructiveData;

        for (constructiveData of this.roomData.constructive) {
            constructive = this.createConstructiveUnit(constructiveData);
            if (!constructive) {
                console.error('can not create constructive', constructiveData);
            }
        }
    }

    protected createDoors(doorsData: ISaveDoorData[]): ThreeDoor[] {
        let doors: ThreeDoor[] = [];
        for (let door of doorsData) {
            doors.push(this.createDoor(door));
        }
        return doors;
    }

    protected createDoor(doorData: ISaveDoorData): ThreeDoor {
        let door: ThreeDoor;

        door = new ThreeDoor(doorData, this.getWallById(doorData.wall));
        door.initState();
        door.createView();

        return door;
    }

    protected createDoorway(doorwayData: ISaveDoorwayData): ThreeDoorway {
        let doorway: ThreeDoorway;

        doorway = new ThreeDoorway(doorwayData, this.getWallById(doorwayData.wall));
        doorway.initState();
        doorway.createView();

        return doorway;
    }

    protected createWindows(windowsData: ISaveWindowData[]): ThreeWindow[] {
        let windows: ThreeWindow[] = [];
        for (let window of windowsData) {
            windows.push(this.createWindow(window));
        }
        return windows;
    }

    protected createWindow(data: ISaveWindowData): ThreeWindow {
        let oneWindow: ThreeWindow;

        oneWindow = new ThreeWindow(data, this);
        oneWindow.initState();
        oneWindow.createView();

        return oneWindow;
    }

    protected createPillar(data: ISavePillarData): ThreePillar {
        let pillar: ThreePillar;

        switch (data.pillarType) {
            case PILLAR_TYPE_HORIZONTAL:
                if (!data.wall) {
                    data.wall = this.service.getRoom().getDefaultWall().getId();
                }
                pillar = new ThreePillarHorizontal(data, this.getWallById(data.wall));
                pillar.initState();
                pillar.createView();
                break;
            case PILLAR_TYPE_VERTICAL:
                pillar = new ThreePillarVertical(data, this);
                pillar.initState();
                pillar.createView();
                break;
            case PILLAR_TYPE_DEFAULT:
                pillar = new ThreePillar(data, this);
                pillar.initState();
                pillar.createView();
                break;
        }


        return pillar;
    }

    protected createWallIsland(data: ISaveWallIslandData): ThreeWallIsland {
        let wallIsland: ThreeWallIsland;

        wallIsland = new ThreeWallIsland(data, this);
        wallIsland.initState();
        wallIsland.createView();

        return wallIsland;
    }

    protected createConstructiveModel(data: ISaveConstructiveData, level: TLevel): ThreeConstructiveModel | undefined {
        let constructiveModel: ThreeConstructiveModel | undefined;

        switch (level) {
            case LEVEL_BOTTOM:
                constructiveModel = new ThreeBottomConstructiveModel(data, this);
                break;
            case LEVEL_TOP:
                constructiveModel = new ThreeTopConstructiveModel(data, this);
                break;

        }
        if (constructiveModel) {
            constructiveModel.initState();
            constructiveModel.createView();
        }

        return constructiveModel;
    }
}
