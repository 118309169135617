import React, {useMemo} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {KitchenService} from '../../../../../3d/services/KitchenService/KitchenService';
import {AppState} from '../../../../../redux/AppStore';
import {IProjectData} from '../../../../../../common-code/interfaces/project/IProjectData';
import {IProjectPriceData} from '../../../../../../common-code/interfaces/project/IProjectPriceData';
import {CurrencyHelper} from '../../../../../../domain/CurrencyHelper/CurrencyHelper';

export const cnWizardProjectPrice = cn('WizardProjectPrice');
export interface IWizardProjectPriceTableProps extends IClassNameProps {
    service: KitchenService;
}

export const WizardProjectPriceTable: React.FC<IWizardProjectPriceTableProps> = () => {
    const loadingPrice: boolean = useSelector((state: AppState) => state.ui.loadingPrice);
    const projectData: IProjectData = useSelector((state: AppState) => state.wizard.project);
    const {t} = useTranslation();

    const priceItems: {type: keyof IProjectPriceData, title: string}[] = useMemo(() => ([
        {type: 'units', title: t('Модули')},
        {type: 'facades', title: t('Фасады')},
        {type: 'handles', title: t('Ручки')},
        {type: 'tabletops', title: t('Столешница')},
        {type: 'edges', title: t('Кромка')},
        {type: 'aprons', title: t('Стеновая панель')},
        {type: 'corners', title: t('Уплотнительный уголок')},
        {type: 'plinths', title: t('Плинтус')},
        {type: 'planks', title: t('Планки/накладки')},
        {type: 'services', title: t('Услуги')},
        {type: 'full', title: t('Итого')},
    ]), [t]);

    const renderPrice = (data: {type: keyof IProjectPriceData, title: string, modTypeVal?: string}, index: number) => {
        if (projectData.priceData[data.type] === undefined) {
            return null;
        }

        const value: number | undefined = projectData.priceData[data.type];
        if (value === undefined || (!value && data.type !== 'full')) {
            return null;
        }
        switch (data.type) {
            case 'full':
                return (
                    <div
                        key={index}
                        className={cnWizardProjectPrice('PriceItem', {type: data.modTypeVal, full: true})}
                    >
                        <p className={cnWizardProjectPrice('PriceTitle', {type: data.modTypeVal, full: true})}>{data.title}:</p>
                        <p className={cnWizardProjectPrice('PriceValue', {type: data.modTypeVal, full: true})}>{CurrencyHelper.formatValue(value)}</p>
                    </div>
                );
            default:
                return (
                    <div
                        key={index}
                        className={cnWizardProjectPrice('PriceItem')}
                    >
                        <p className={cnWizardProjectPrice('PriceTitle')}>{data.title}:</p>
                        <p className={cnWizardProjectPrice('PriceValue')}>{CurrencyHelper.formatValue(value)}</p>
                    </div>
                );
        }
    }

    if (loadingPrice || !projectData) {
        return null;
    }
    return (
        <>
            {priceItems.map((item, index) => {
                return (
                    renderPrice(item, +index)
                );
            })
            }
        </>
    );
};