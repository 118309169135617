import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeBottomEndNormalCorpus} from '../../../details/ThreeCorpus/types/ThreeBottomEndNormalCorpus';
import {
    FACADE_POSITION_TYPE_ANGLE,
    GEOMETRY_TYPE_SQUARE,
    SIDE_TYPE_FRONT,
    SIDE_TYPE_LEFT, SIDE_TYPE_NONE,
    SIDE_TYPE_RIGHT,
    UNIT_PLINTHS_TYPE_BACK_NEIGHBOR, UNIT_PLINTHS_TYPE_DEFAULT
} from '../../../../../../../common-code/constants';
import {TOptionalSizes} from '../../../../../../../common-code/types/TOptionalSizes';
import {Box3, Euler, Vector3} from 'three';
import {TPoint2D} from '../../../../../../../common-code/types/TPoint2D';
import {ICorpusPoints} from '../../../../../interfaces/ICorpusPoints';
import {ThreeFacade} from '../../../details/ThreeFacade/ThreeFacade';
import {ThreeSquareFacade} from '../../../details/ThreeFacade/geometry/ThreeSquareFacade';
import {TDirectionSideType} from '../../../../../../../common-code/types/TDirectionSideType';
import {
    ISaveBottomUnitEndNormalData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitEndNormalData';
import {
    ISaveBottomEndNormalCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomEndNormalCorpusData';
import {CommonHelper} from 'common-code';
import {ISaveLegData} from '../../../../../../../common-code/interfaces/saveData/ISaveLegData';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {MathHelper} from 'common-code';
import {ISaveFacadeData} from '../../../../../../../common-code/interfaces/saveData/ISaveFacadeData';

export class ThreeBottomUnitEndNormal extends ThreeBottomUnit {
    saveData: ISaveBottomUnitEndNormalData;
    corpus: ThreeBottomEndNormalCorpus;

    constructor(options: ISaveBottomUnitEndNormalData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public isEndUnit(): boolean {
        return true;
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    protected initCorpus(corpusData: ISaveBottomEndNormalCorpusData): ThreeBottomEndNormalCorpus {
        return new ThreeBottomEndNormalCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    protected calculateInitLegsData(legs?: ISaveLegData[]): ISaveLegData[] | undefined {
        console.log('calculateInitLegsData');
        if (legs) {
            let index;

            for (index in legs) {
                switch (+index) {
                    case 0:
                        legs[index].initPosition = {x: '50', z: '50'};
                        break;
                    case 1:
                        legs[index].initPosition = {x: '=({%100}-50)', z: '50'};
                        break;
                    case 2:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                                legs[index].initPosition = {x: '=({%100}-50)', z:  '=({%100}-50)'};
                                break;
                            case SIDE_TYPE_RIGHT:
                                legs[index].initPosition = {x: '=({%100}-50)', z:  this.corpus.getSmallDepth() - 50};
                                break;
                        }
                        break;
                    case 3:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                                legs[index].initPosition = {x: '50', z:  this.corpus.getSmallDepth() - 50};
                                break;
                            case SIDE_TYPE_RIGHT:
                                legs[index].initPosition = {x: '50', z:  '=({%100}-50)'};
                                break;
                        }
                        break;
                }
            }
        }

        return legs;
    }

    protected calculateSidePlinthSizes(depth: number): TOptionalSizes {
        let sizes: TOptionalSizes;
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);
        switch (this.getPlinthsType()) {
            case UNIT_PLINTHS_TYPE_BACK_NEIGHBOR:
                sizes = {length: depth + (Math.abs(coverBox.min.z) - this.corpus.getDepth()/2)};
                break;
            case UNIT_PLINTHS_TYPE_DEFAULT:
                sizes = {length: depth};
                break;
        }

        return sizes;
    }

    protected initTabletopsData(tabletops?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        let index: string;

        if (!tabletops) {
            tabletops = [{
                id: 0,
                leftPoints: this.getSideTabletopPoints(SIDE_TYPE_LEFT),
                rightPoints: this.getSideTabletopPoints(SIDE_TYPE_RIGHT),
            }];
        }
        for (index in tabletops) {
            tabletops[index].leftPoints = this.getSideTabletopPoints(SIDE_TYPE_LEFT);
            tabletops[index].rightPoints = this.getSideTabletopPoints(SIDE_TYPE_RIGHT);
        }

        return tabletops;
    }

    protected getSideTabletopPoints(sideType: TDirectionSideType) {
        let points: TPoint2D[];
        let tabletopWidth: number;

        tabletopWidth = this.service.getTabletopWidth();
        switch (this.getSideType()) {
            case SIDE_TYPE_RIGHT:
                if (sideType === SIDE_TYPE_LEFT) {
                    points = this.getDefaultSideTabletopPoints(tabletopWidth);
                } else {
                    points = this.getSmallSideTabletopPoints(tabletopWidth);
                }
                break;
            case SIDE_TYPE_LEFT:
            default:
                if (sideType === SIDE_TYPE_RIGHT) {
                    points = this.getDefaultSideTabletopPoints(tabletopWidth);
                } else {
                    points = this.getSmallSideTabletopPoints(tabletopWidth);
                }
                break;
        }

        return points;
    }

    protected getSmallSideTabletopPoints(tabletopWidth: number): TPoint2D[] {
        let points: TPoint2D[] = [];
        let sideWidth: number;
        sideWidth = this.getWidth() - this.corpus.getSmallWidth() - this.corpus.getThickness();

        points.push({x: sideWidth, y: -tabletopWidth / 2});
        points.push({x: sideWidth, y: -this.getDepth() / 2 + this.corpus.getSmallDepth() + this.service.getTabletopFrontGap()});
        points.push({x: 0, y: tabletopWidth / 2});

        return points;
    }

    protected getDefaultSideTabletopPoints(tabletopWidth: number): TPoint2D[] {
        let points: TPoint2D[] = [];

        points.push({x: 0, y: -tabletopWidth / 2});
        points.push({x: 0, y: tabletopWidth / 2});

        return points;
    }

    protected calculatePlinthsData(plinths?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        let index;
        let angleData;

        if (plinths) {
            for (index in plinths) {
                switch (plinths[index].positionType) {
                    case SIDE_TYPE_LEFT:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                            default:
                                plinths[index].sizes = this.calculateSidePlinthSizes(this.corpus.getSmallDepth());
                                break;
                            case SIDE_TYPE_RIGHT:
                                plinths[index].sizes = this.calculateSidePlinthSizes(this.corpus.getDepth());
                                break;
                        }
                        break;
                    case SIDE_TYPE_RIGHT:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                            default:
                                plinths[index].sizes = this.calculateSidePlinthSizes(this.corpus.getDepth());
                                break;
                            case SIDE_TYPE_RIGHT:
                                plinths[index].sizes = this.calculateSidePlinthSizes(this.corpus.getSmallDepth());
                                break;
                        }
                        break;
                    case SIDE_TYPE_FRONT:
                        plinths[index].sizes = {length: this.corpus.getSmallWidth()};
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                            default:
                                plinths[index].position = {x: this.corpus.getWidth()/2 - this.corpus.getSmallWidth()/2};
                                break;
                            case SIDE_TYPE_RIGHT:
                                plinths[index].position = {x: -this.corpus.getWidth()/2 + this.corpus.getSmallWidth()/2};
                                break;
                        }
                        break;
                    case SIDE_TYPE_NONE:
                        angleData = this.getAngleData();
                        plinths[index].sizes = {length: angleData.length};
                        plinths[index].position = {x: angleData.centerPoint.x, z: angleData.centerPoint.y};
                        plinths[index].rotation = {y: angleData.normalAngle};
                        break;
                }
            }
        }

        return plinths;
    }

    protected getAngleData() {
        let pointA, pointB;
        let length: number, normalAngle, centerPoint, parallelLine, centerFacadePoint;

        switch (this.getSideType()) {
            case SIDE_TYPE_RIGHT:
                pointA = {
                    x: -this.corpus.getWidth() / 2 + this.corpus.getSmallWidth(),
                    y: this.corpus.getDepth() / 2
                };
                pointB = {x: this.corpus.getWidth() / 2 - this.corpus.getThickness(), y: -this.corpus.getDepth() / 2 + this.corpus.getSmallDepth()};
                break;
            case SIDE_TYPE_LEFT:
            default:
                pointA = {x: -this.corpus.getWidth() / 2 + this.corpus.getThickness(), y: -this.corpus.getDepth() / 2 + this.corpus.getSmallDepth()};
                pointB = {
                    x: this.corpus.getWidth() / 2 - this.corpus.getSmallWidth(),
                    y: this.corpus.getDepth() / 2
                };
                break;
        }

        length = MathHelper.getLength2D(pointA, pointB);
        normalAngle = 2 * Math.PI - MathHelper.getNormalAngle({x: pointB.x - pointA.x, y: pointB.y - pointA.y});
        parallelLine = MathHelper.getParallelLinePoints(pointA, pointB, this.corpus.getThickness() / 2);
        centerPoint = MathHelper.getCenterPoint(parallelLine.pointA, parallelLine.pointB);
        parallelLine = MathHelper.getParallelLinePoints(pointA, pointB, -this.corpus.getThickness() / 2);
        centerFacadePoint = MathHelper.getCenterPoint(parallelLine.pointA, parallelLine.pointB);

        return {
            length: length,
            normalAngle: normalAngle,
            centerPoint: centerPoint,
            centerFacadePoint: centerFacadePoint
        };
    }

    protected initApronsData(aprons?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        return this.initApronsCornersData(aprons);
    }

    protected initCornersData(corners?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        return this.initApronsCornersData(corners);
    }

    protected initApronsCornersData(details?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        let index: string;
        let coverBox: Box3;

        if (details) {
            coverBox = this.getCoverBox(0);
            for (index in details) {
                if (this.getSideType() === details[index].positionType) {
                    details[index].sizes = {length:  this.corpus.getSmallDepth() + this.service.getTabletopFrontGap()
                            + (Math.abs(coverBox.min.z) - this.corpus.getDepth()/2)}
                }
            }
        }
        return details;
    }

    protected createFacades() {
        let facadeData: ISaveFacadeData;
        let facade: ThreeFacade | undefined;
        let facadePoint: TPoint2D;
        let angle: number;

        this.saveData.facades = this.calculateFacadesData(this.saveData.facades);
        if (!this.saveData.facades) {
            return;
        }

        const corpusPoints: ICorpusPoints = this.getCorpusPoints();
        this.facades = [];
        if (!this.isSingleFacadeCorpus() && this.saveData.facades.length > 1) {
            for (facadeData of this.saveData.facades) {
                switch (facadeData.geometryType) {
                    case GEOMETRY_TYPE_SQUARE:
                        facade = new ThreeSquareFacade(CommonHelper.deepCopy(facadeData), this);
                }
                if (!facade) {
                    continue;
                }
                facade.initState();
                facade.createView();
                switch (facadeData.positionType) {
                    case FACADE_POSITION_TYPE_ANGLE:
                        facadePoint = MathHelper.getPointByRatio2D(corpusPoints.pointA, corpusPoints.pointC, 0.5, true);
                        facade.setPosition(new Vector3(facadePoint.x, 0, facadePoint.y));
                        angle = this.getSideType() === SIDE_TYPE_RIGHT ?
                            2 * Math.PI - MathHelper.getNormalAngle({x: corpusPoints.pointA.x - corpusPoints.pointC.x, y: corpusPoints.pointA.y - corpusPoints.pointC.y}) :
                            2 * Math.PI - MathHelper.getNormalAngle({x: corpusPoints.pointC.x - corpusPoints.pointA.x, y: corpusPoints.pointC.y - corpusPoints.pointA.y});
                        facade.setRotation(new Euler(0, angle, 0));
                        break;
                    default:
                        facadePoint = MathHelper.getPointByRatio2D(corpusPoints.pointE, corpusPoints.pointC, 0.5, true);
                        facade.setPosition(new Vector3(facadePoint.x, 0, facadePoint.y));
                        facade.setRotation(new Euler(0, 0, 0));
                }
                this.view3d.add(facade.view3d);
                this.facades.push(facade);
            }

            return;
        }

        const isIntersectFacadeCorpus: TPoint2D | undefined = this.isIntersectFacadeCorpus();
        const pointB: TPoint2D = isIntersectFacadeCorpus ? isIntersectFacadeCorpus : corpusPoints.pointB;
        for (facadeData of this.saveData.facades) {
            switch (facadeData.geometryType) {
                case GEOMETRY_TYPE_SQUARE:
                    facade = new ThreeSquareFacade(CommonHelper.deepCopy(facadeData), this);
            }
            if (!facade) {
                continue;
            }
            facade.initState();
            facade.createView();
            facadePoint = MathHelper.getPointByRatio2D(corpusPoints.pointA, pointB, 0.5, true);
            facade.setPosition(new Vector3(facadePoint.x, 0, facadePoint.y));
            angle = this.getSideType() === SIDE_TYPE_RIGHT ?
                2 * Math.PI - MathHelper.getNormalAngle({x: corpusPoints.pointA.x - corpusPoints.pointC.x, y: corpusPoints.pointA.y - corpusPoints.pointC.y}) :
                2 * Math.PI - MathHelper.getNormalAngle({x: corpusPoints.pointC.x - corpusPoints.pointA.x, y: corpusPoints.pointC.y - corpusPoints.pointA.y});
            facade.setRotation(new Euler(0, angle, 0));
            this.view3d.add(facade.view3d);
            this.facades.push(facade);
        }
    }

    protected calculateFacadesData(facades?: ISaveFacadeData[]): ISaveFacadeData[] | undefined {
        if (!facades) {
            return undefined;
        }

        const corpusPoints: ICorpusPoints = this.getCorpusPoints();
        let mainFacadeWidth: string;
        let anotherFacadeWidth: string;
        let facadeData: ISaveFacadeData;

        if (!this.isSingleFacadeCorpus() && facades.length > 1) {
            mainFacadeWidth = '' + Math.floor(MathHelper.getLength2D(corpusPoints.pointA, corpusPoints.pointC));
            anotherFacadeWidth = '' + Math.floor(MathHelper.getLength2D(corpusPoints.pointC, corpusPoints.pointE));
            for (facadeData of facades) {
                switch (facadeData.positionType) {
                    case FACADE_POSITION_TYPE_ANGLE:
                        facadeData.initSizes.width = mainFacadeWidth;
                        break;
                    default:
                        facadeData.initSizes.width = anotherFacadeWidth;
                }
            }

            return facades;
        }

        const isIntersectFacadeCorpus: TPoint2D | undefined = this.isIntersectFacadeCorpus();
        if (isIntersectFacadeCorpus) {
            mainFacadeWidth = '' + Math.floor(MathHelper.getLength2D(corpusPoints.pointA, isIntersectFacadeCorpus));
        } else {
            mainFacadeWidth = '' + Math.floor(MathHelper.getLength2D(corpusPoints.pointA, corpusPoints.pointB));
        }
        for (facadeData of facades) {
            facadeData.initSizes.width = mainFacadeWidth;
        }

        return facades;
    }

    private isIntersectFacadeCorpus(): TPoint2D | undefined {
        const corpusPoints: ICorpusPoints = this.getCorpusPoints();

        return MathHelper.getIntersectionPoint({pointA: corpusPoints.pointA, pointB: corpusPoints.pointB}, {pointA: corpusPoints.pointD, pointB: corpusPoints.pointE});
    }

    private isSingleFacadeCorpus(): boolean {
        return MathHelper.isEqualPoints2D(this.getCorpusPoints().pointB, this.getCorpusPoints().pointC);
    }

    private getCorpusPoints(): ICorpusPoints {
        let pointA: TPoint2D;
        let pointB: TPoint2D;
        let pointC: TPoint2D;
        let pointD: TPoint2D;
        let pointE: TPoint2D;
        let points: ICorpusPoints;
        let smallWidth: number;
        let smallDepth: number;

        smallWidth = this.corpus.getSmallWidth() ? +this.corpus.getSmallWidth() : +this.corpus.getThickness();
        smallDepth = this.corpus.getSmallDepth() ? +this.corpus.getSmallDepth() : +this.corpus.getBackThickness();
        switch (this.getSideType()) {
            case SIDE_TYPE_RIGHT:
                pointA = {x: this.getWidth()/2 - this.corpus.getThickness(), y: -this.getDepth()/2 + smallDepth};
                pointB = {x: -this.getWidth()/2 + this.corpus.getThickness(), y: this.getDepth()/2};
                pointC = {x: -this.getWidth()/2 + smallWidth, y: this.getDepth()/2};
                pointD = {x: -this.getWidth()/2, y: -this.getDepth()/2};
                pointE = {x: -this.getWidth()/2, y: this.getDepth()/2};
                points = {pointA, pointB, pointC, pointE, pointD};
                break;
            case SIDE_TYPE_LEFT:
            default:
                pointA = {x: -this.getWidth()/2 + this.corpus.getThickness(), y: -this.getDepth()/2 + smallDepth};
                pointB = {x: this.getWidth()/2 - this.corpus.getThickness(), y: this.getDepth()/2};
                pointC = {x: this.getWidth()/2 - smallWidth, y: this.getDepth()/2};
                pointD = {x: this.getWidth()/2, y: -this.getDepth()/2};
                pointE = {x: this.getWidth()/2, y: this.getDepth()/2};
                points = {pointA, pointB, pointC, pointE, pointD};
        }

        return points;
    }
    
}