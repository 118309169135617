import { useFormContext } from "react-hook-form";
import { OPTION_TYPE_JSON } from "common-code/lib/constants";
import React, { useEffect, useState } from "react";
import { ISaveIntegratedHandleData } from "common-code/lib/interfaces/saveData/ISaveIntegratedHandleData";
import { IOption } from "common-code/lib/interfaces/option/IOption";
import { IOptionHiddenJson } from "../../../../../../../common-code/interfaces/option/IOptionHiddenJson";
import IntegratedHandle from "./IntegratedHandle";
import { Button } from "../../../../../components/Button";
import ModalIntegratedHandles from "./ModalIntegratedHandles";
import { TIntegratedHandleFunctionalType } from "common-code/lib/types/materials/TIntegratedHandleFunctionalType";
import { IDetailPosition } from "common-code/lib/interfaces/geometry/IDetailPosition";
import "./IntegratedHandles.css";
import { cn } from "@bem-react/classname";
import { initionalIntegratedHandle } from "./initionalIntegratedHandle";
import { IFormInput } from "../../initialValue";

export const cnIntegratedHandles = cn("IntegratedHandles");

export interface IIntegratedHandle {
  id: number;
  height: number;
  width: number;
  functionalType: TIntegratedHandleFunctionalType;

  interval?: {
    pointA: IDetailPosition;
    pointB: IDetailPosition;
  };
}

export const integratedHandlesFunctionalTypes = [
  { value: "default", content: "default" },
  { value: "betweenBoxes", content: "betweenBoxes" },
];

export default function IntegratedHandles() {



  const { setValue, getValues } = useFormContext<IFormInput>();
  const integratedHandlesInitional = getValues("integratedHandles")
    ? (getValues("integratedHandles") as IOption).value
    : [];
  const [arrIntegratedHandles, setArrIntegratedHandles] = React.useState<
    ISaveIntegratedHandleData[]
  >(integratedHandlesInitional);
  const [modal, setModal] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const integratedHandles: IOption = {
    id: "integratedHandles",
    type: OPTION_TYPE_JSON,
    title: "Интегрированные ручки",
    sort: 0,
    value: arrIntegratedHandles,
  } as IOptionHiddenJson;

  useEffect(() => {
    if (arrIntegratedHandles.length > 0) {
      setValue("integratedHandles", integratedHandles);
    } else {
      setValue("integratedHandles", null);
    }
    // eslint-disable-next-line
  }, [integratedHandles]);

  const [newIntegratedHandle, setNewIntegratedHandle] =
    useState<IIntegratedHandle>(initionalIntegratedHandle());

  const validateIntegratedHandle = (newIntegratedHandle: IIntegratedHandle) => {
    if (
      isNaN(newIntegratedHandle.height) ||
      newIntegratedHandle.height < 0 ||
      newIntegratedHandle.height > 1200
    ) {
      return "Высота должна быть в диапазоне от 0 до 1200";
    }

    if (
      isNaN(newIntegratedHandle.width) ||
      newIntegratedHandle.width < 0 ||
      newIntegratedHandle.width > 1200
    ) {
      return "Ширины должна быть в диапазоне от 0 до 1200";
    }

    return "";
  };

  const onFocus = () => {
    setError("");
  };

  const handleSubmit = () => {
    const error = validateIntegratedHandle(newIntegratedHandle);
    if (error) {
      setError(error);
      return;
    }
    setArrIntegratedHandles([...arrIntegratedHandles, newIntegratedHandle]);
    setModal(!modal);
  };

  const handleToggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      {arrIntegratedHandles.map((integratedHandle, index) => (
        <IntegratedHandle
          arrIntegratedHandles={arrIntegratedHandles}
          setArrIntegratedHandles={setArrIntegratedHandles}
          integratedHandle={integratedHandle}
          key={index}
          index={index}
          validateIntegratedHandle={validateIntegratedHandle}
        />
      ))}

      <Button
        className={cnIntegratedHandles("Button")}
        type={"button"}
        text={`Добавить интегрированные ручки`}
        leftIcon={{ path: "plus2", width: 16, height: 16, marginRight: 8 }}
        mods={{
          mods: {
            bkg: "white",
            color: "lightGreen",
            border: "lightGreen",
            fill: "lightGreen",
          },
          hover: { mods: { bkg: "lightGreen", color: "white", fill: "white" } },
        }}
        callBack={handleToggleModal}
      />

      <ModalIntegratedHandles
        modal={modal}
        onFocus={onFocus}
        handleToggleModal={handleToggleModal}
        error={error}
        newIntegratedHandle={newIntegratedHandle}
        setNewIntegratedHandle={setNewIntegratedHandle}
        handleSubmit={handleSubmit}
        keyValue={`#IntegratedHandle`}
      />
    </>
  );
}
