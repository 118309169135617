import { ThreeCorpus } from "../ThreeCorpus/ThreeCorpus";
import { CommonDetail } from "../../../CommonDetail/CommonDetail";
import {
  BoxGeometry,
  BufferGeometry,
  DoubleSide,
  ExtrudeGeometry,
  Mesh,
  MeshStandardMaterial,
  Shape,
  Vector3,
} from "three";
import {
  SHELF_TYPE_HORIZONTAL,
  SHELF_TYPE_VERTICAL,
} from "../../../../../../common-code/constants";
import { ExtrudeGeometryOptions } from "three/src/geometries/ExtrudeGeometry";
import { IMaterialTextures } from "../../../../interfaces/IMaterialTextures";
import { KitchenService } from "../../../../services/KitchenService/KitchenService";
import { ISaveShelfData } from "../../../../../../common-code/interfaces/saveData/ISaveShelfData";
import { TSizes } from "../../../../../../common-code/types/geometry/TSizes";
import { IMaterialData } from "../../../../../../common-code/interfaces/materials/IMaterialData";
import { KitchenHelper } from "common-code";

export class ThreeShelf extends CommonDetail {
  saveData: ISaveShelfData;
  corpus: ThreeCorpus;
  service: KitchenService;
  sizes: TSizes;
  shape?: Shape;
  bodyMaterial?: MeshStandardMaterial;
  materialData?: IMaterialData;
  materialTextures?: IMaterialTextures;

  constructor(options: ISaveShelfData, corpus: ThreeCorpus, shape?: Shape) {
    super(options, corpus.unit);
    this.service = corpus.unit.service;
    this.corpus = corpus;
    this.saveData = options;
    this.sizes = this.initSizes();
    this.materialData = this.initMaterialData(this.saveData.materialId);
    this.materialTextures = this.loadTextures();
    this.shape = shape;
  }

  public initState(isRebuild?: boolean) {
    this.createBody();
    super.initState(isRebuild);
  }

  public createView(isRebuild?: boolean) {
    this.corpus.view3d.add(this.view3d);
    super.createView(isRebuild);
  }

  public remove() {
    this.removeChildren();
    this.corpus.view3d.remove(this.view3d);
  }

  public getThickness() {
    return +this.saveData.thickness;
  }

  public getWidth() {
    return +this.sizes.length;
  }

  public getDepth() {
    return +this.sizes.width;
  }

  public getHeight() {
    return +this.sizes.height;
  }

  public calculateGlobalFrontVector() {
    this.globalFrontVector = this.unit.globalFrontVector;
    this.setCenterPosition();
  }

  public setCenterPosition() {
    if (
      !this.view3d.userData.centerPosition ||
      !(this.view3d.userData.centerPosition instanceof Vector3)
    ) {
      this.view3d.userData.centerPosition = new Vector3();
    }
    this.view3d.userData.centerPosition = this.view3d.userData.centerPosition
      .copy(this.view3d.position)
      .applyMatrix4(this.unit.view3d.matrixWorld);
  }

  protected loadTextures(): IMaterialTextures | undefined {
    if (this.materialData) {
      return this.service.loadMaterialTextures(
        this.materialData.id,
        this.materialData.textures
      );
    }

    return undefined;
  }

  protected initMaterialData(materialId?: string): IMaterialData | undefined {
    if (materialId) {
      return this.service.getPanelMaterial(materialId);
    }
    return undefined;
  }

  protected initPosition(): Vector3 {
    let position: Vector3;

    position = new Vector3();
    if (this.saveData.initPosition) {
      if (this.saveData.initPosition.x !== undefined) {
        position.x =
          -this.corpus.getInnerWidth() / 2 +
          KitchenHelper.calculateSizeByParent(
            this.saveData.initPosition.x,
            this.corpus.getInnerWidth(),
            this.service.getDataForSizeByParent()
          );
      }
      if (this.saveData.initPosition.y !== undefined) {
        // TODO: fix this
        if(+(this.saveData.initPosition.y) === +(this.saveData.initPosition.y)){
          position.y =
          -this.corpus.getInnerHeight() / 2 +
          KitchenHelper.calculateSizeByParent(
            this.saveData.initPosition.y as number - this.corpus.getThickness() , 
            this.corpus.getInnerHeight(),
            this.service.getDataForSizeByParent()
          );
        } else {
          position.y =
            -this.corpus.getInnerHeight() / 2 +
            KitchenHelper.calculateSizeByParent(
              this.saveData.initPosition.y,
              this.corpus.getInnerHeight(),
              this.service.getDataForSizeByParent()
            );
        }
      }
      if (this.saveData.initPosition.z !== undefined) {
        position.z =
          -this.corpus.getDepth() / 2 +
          this.corpus.getBackThickness() +
          KitchenHelper.calculateSizeByParent(
            this.saveData.initPosition.z,
            this.corpus.getInnerDepth(),
            this.service.getDataForSizeByParent()
          );
      }
    }
    if (this.saveData.position) {
      if (
        this.saveData.position.x !== undefined &&
        !isNaN(+this.saveData.position.x)
      ) {
        position.x = +this.saveData.position.x;
      }
      if (
        this.saveData.position.y !== undefined &&
        !isNaN(+this.saveData.position.y)
      ) {
        position.y = +this.saveData.position.y;
      }
      if (
        this.saveData.position.z !== undefined &&
        !isNaN(+this.saveData.position.z)
      ) {
        position.z = +this.saveData.position.z;
      }
    }

    return position;
  }

  protected initSizes(): TSizes {
    let sizes: TSizes;

    switch (this.saveData.type) {
      case SHELF_TYPE_HORIZONTAL:
      default:
        sizes = {
          length: KitchenHelper.calculateSizeByParent(
            this.saveData.length,
            this.corpus.getInnerWidth(),
            this.service.getDataForSizeByParent()
          ),
          width: KitchenHelper.calculateSizeByParent(
            this.saveData.depth,
            this.corpus.getInnerDepth(),
            this.service.getDataForSizeByParent()
          ),
          height: this.getThickness(),
        };
        break;
      case SHELF_TYPE_VERTICAL:
        sizes = {
          length: this.getThickness(),
          width: KitchenHelper.calculateSizeByParent(
            this.saveData.depth,
            this.corpus.getInnerDepth(),
            this.service.getDataForSizeByParent()
          ),
          height: KitchenHelper.calculateSizeByParent(
            this.saveData.length,
            this.corpus.getInnerHeight(),
            this.service.getDataForSizeByParent()
          ),
        };
        break;
    }

    return sizes;
  }

  protected createBody() {
    let bodyGeometry: BufferGeometry;
    let body: Mesh;

    bodyGeometry = this.getBodyGeometry();
    body = new Mesh(bodyGeometry, this.createBodyMaterial());
    body.name = "shelf";
    body.matrixAutoUpdate = false;
    body.userData.transparentRenderOrder = 5;

    if (this.corpus.unit.saveData.className === "TopUnitWinery") {
      body.position.z = this.shape ? -this.getThickness() / 4 : 0;
    }

    // const classNames = [
    //   "TopUnitAngleCube",
    //   "BottomUnitEndNormal",
    //   "BottomUnitEndFullLengthSidewall",
    //   "BottomUnitEndOpened",
    //   "BottomUnitEndOpenedCircle",
    //   "BottomUnitEndFullLengthSidewallOpened",
    //   "TopUnitEndNormal",
    //   "TopUnitEndOpened",
    //   "TopUnitEndOpenedCircle",
    //   "TopUnitAngleDouble",
    // ];

    // if (classNames.includes(this.corpus.unit.saveData.className)) {
    //   body.rotation.x = 0.5 * Math.PI;
    // } else {
    //   body.rotation.x = 0;
    // }
    body.updateMatrix();
    body.geometry.applyMatrix4(body.matrix);
    body.rotation.x = 0;
    body.position.y = 0;
    this.createMeshCarcass(body);
    this.view3d.add(body);
  }

  protected getBodyGeometry(): BufferGeometry {
    let bodyGeometry: BufferGeometry;
    let extrudeSettings: ExtrudeGeometryOptions;

    if (this.shape) {
      extrudeSettings = {
        steps: 1,
        depth: this.getThickness(),
        bevelEnabled: false,
      };
      bodyGeometry = new ExtrudeGeometry(this.shape, extrudeSettings);
    } else {
      bodyGeometry = new BoxGeometry(
        this.getWidth(),
        this.getHeight(),
        this.getDepth()
      );
    }

    return bodyGeometry;
  }

  protected createBodyMaterial(): MeshStandardMaterial {
    if (!this.bodyMaterial) {
      if (this.materialData && this.materialTextures) {
        this.bodyMaterial = new MeshStandardMaterial({
          color: this.materialData.color || "#cccccc",
          emissive: this.materialData.emissiveColor || undefined,
          map: this.materialTextures.texture || null,
          normalMap: this.materialTextures.normal || null,
          roughnessMap: this.materialTextures.roughness || null,
          side: DoubleSide,
          envMapIntensity: 5,
        });
      } else if (this.shape) {
        this.bodyMaterial = this.corpus.getShapeBodyMaterial();
      } else {
        this.bodyMaterial = this.corpus.getBodyMaterial();
      }
    }

    return this.bodyMaterial;
  }
}
