import {ThreeTopUnit} from '../ThreeTopUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeTopAngleCubeCorpus} from '../../../details/ThreeCorpus/types/ThreeTopAngleCubeCorpus';
import {ThreeFacade} from '../../../details/ThreeFacade/ThreeFacade';
import {Euler, Vector3} from 'three';
import {FACADE_CELL_DEFAULT} from '../../../../../../../common-code/constants';
import {TPoint2D} from "../../../../../../../common-code/types/TPoint2D";
import {
    ISaveTopUnitAngleCubeData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitAngleCubeData';
import {
    ISaveTopAngleCubeCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopAngleCubeCorpusData';
import {CommonHelper} from 'common-code';
import {ISaveFacadeData} from '../../../../../../../common-code/interfaces/saveData/ISaveFacadeData';
import {MathHelper} from 'common-code';

export class ThreeTopUnitAngleCube extends ThreeTopUnit {
    corpus: ThreeTopAngleCubeCorpus;
    saveData: ISaveTopUnitAngleCubeData;

    constructor(options: ISaveTopUnitAngleCubeData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public isAngleUnit(): boolean {
        return true;
    }

    protected initCorpus(corpusData: ISaveTopAngleCubeCorpusData): ThreeTopAngleCubeCorpus {
        return new ThreeTopAngleCubeCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    protected createFacades() {
        super.createFacades();
        let facade: ThreeFacade;
        let catheter, hypotenuse;

        for (facade of this.facades) {
            if (facade.getCellName() !== FACADE_CELL_DEFAULT) {
                continue;
            }
            hypotenuse = this.getWidth() - this.corpus.getBackThickness() - this.corpus.getSideDepth() - 2;
            catheter = Math.sqrt(Math.pow(hypotenuse, 2) / 2);
            hypotenuse = catheter - this.corpus.getThickness() / 2;
            catheter = Math.sqrt(Math.pow(hypotenuse, 2) / 2) + this.corpus.getThickness();
            facade.setPosition(new Vector3(this.getWidth() / 2 - catheter, 0, this.getDepth() / 2 - catheter));
            facade.setRotation(new Euler(0, 0.25 * Math.PI, facade.isFlipY() ? Math.PI : 0));
        }
    }

    protected calculateFacadesData(facades?: ISaveFacadeData[]): ISaveFacadeData[] | undefined {
        let pointA: TPoint2D;
        let pointB: TPoint2D;
        let facadeWidth: string;

        pointA = {x: this.getWidth()/2 - this.corpus.getThickness(), y: -this.getDepth()/2 + this.corpus.getSideDepth()};
        pointB = {x: -this.getWidth()/2 + this.corpus.getSideDepth(), y: this.getDepth()/2 - this.corpus.getThickness()};
        facadeWidth = '' + Math.ceil(MathHelper.getLength2D(pointA, pointB));
        if (facades) {
            let facade: ISaveFacadeData;
            for (facade of facades) {
                facade.initSizes.width = facadeWidth;
            }
        }

        return facades;
    }

    public getDepth() {
        return +this.saveData.sizes.length;
    }

}