import React from 'react';
import { Modal, cnModal } from '../../../../../components/Modal';
import { Textinput } from '@yandex/ui/Textinput/desktop/bundle';
import { Select } from '../../../../../components/Select/Select';
import { editOption } from '../../../utils/editOption';
import { Tumbler } from '../../../../../components/Tumbler/Tumbler';
import { cnCreateForm } from '../../FormModule';
import { Button } from '../../../../../components/Button';
import { legsValues } from '../../constants/constants';
import { SIDE_TYPES as sideTypes  } from 'common-code/lib/constants';
import { IParamsValue } from './initialValue';

interface IModalValuesProps {
    handleToggleModal: () => void,
    name: string,
    keyValue: string,
    handleSubmit: () => void,
    paramsValue: IParamsValue,
    setParamsValue: (value: IParamsValue) => void
}


function ModalValues({
    handleToggleModal,
    name,
    keyValue,
    handleSubmit,
    paramsValue,
    setParamsValue
}:IModalValuesProps) {

    const { positionType,marginX, marginY, marginZ, initPositionX, initPositionY, initPositionZ, initSizesLength,
     rotationX, rotationY, rotationZ, margin, initPosition, initSizes, rotation, modal, error } = paramsValue;
     
    
    const onFocus = () => {
        setParamsValue({ ...paramsValue, error: ''});
    }
    const onInputChange = (e:  React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {    
        setParamsValue({ ...paramsValue, [e.target.name]: e.target.value, error: '' });
    };

    const onChecked = (e:  React.ChangeEvent<HTMLInputElement>) => {
        setParamsValue({ ...paramsValue, [e.target.name]: e.target.checked, error: '' });
    };

    const isEdit = keyValue.includes('edit');

  return (
    <Modal
        className={cnCreateForm(cnModal())}
        visible={modal as boolean}
        callBack={handleToggleModal}
        key={keyValue}
    >

            <h4 className={cnCreateForm('Header')}>{isEdit ? 'Редактирование ' : 'Добавление '} значения для {name}</h4>

            <div className={cnCreateForm('Content')}>

                    <label htmlFor="positionType">Выберите позицию для элемента {name} *</label>
                    <Select
                        className={cnCreateForm('Select')}
                        placeholder={"Выберите значение"}
                        showPlaceholder={false}
                        options={keyValue.includes("legs") ? editOption(legsValues) : editOption(sideTypes)}
                        value={positionType}
                        callBack={e => onInputChange({...e, target: {...e.target, name: 'positionType'}})}
                    />
                    {!keyValue.includes("legs") && <>
                    <Tumbler
                        className={cnCreateForm('Tumbler')}
                        checked={initSizes}
                        callBack={onChecked}
                        label="Добавить начальные размеры"
                        name="initSizes"
                    />

                    {initSizes && <>
                        <label htmlFor="initSizesLength">Указать длину в мм</label>
                        <Textinput
                            className={cnCreateForm('Textinput')}
                            value={initSizesLength}
                            onChange={onInputChange}
                            name="initSizesLength"
                            onFocus={onFocus}
                            type="number"
                        />
                    </>}
                    </>}

                    {!keyValue.includes("legs") &&
                    <>
                    <Tumbler
                        className={cnCreateForm('Tumbler')}
                        checked={rotation}
                        callBack={e => onChecked(e)}
                        label="Добавить вращение"
                        name="rotation"
                    />

                    {rotation && 

                    <div className={cnCreateForm('Box-Input')}>
                        <div>
                            <label htmlFor="rotationX">Вращение по оси X (от -180° до 180°)</label>
                            <Textinput
                                className={cnCreateForm('Textinput')}
                                value={rotationX}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="rotationX"
                                onFocus={onFocus}
                                type="number"
                            />
                        </div>
                        <div>
                            <label htmlFor="rotationY">Вращение по оси Y (от -180° до 180°)</label>
                            <Textinput
                                className={cnCreateForm('Textinput')}
                                value={rotationY}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="rotationY"
                                onFocus={onFocus}
                                type="number"
                            />
                        </div>
                        <div>
                            <label htmlFor="rotationZ">Вращение по оси Z (от -180° до 180°)</label>
                            <Textinput
                                className={cnCreateForm('Textinput')}
                                value={rotationZ}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="rotationZ"
                                onFocus={onFocus}
                                type="number"
                            />
                        </div>

                    </div>}

                
                        <Tumbler
                            className={cnCreateForm('Tumbler')}
                            checked={initPosition}
                            callBack={onChecked}
                            label="Добавить начальную позицию для элемента"
                            name="initPosition"
                        />

                        {initPosition && <div className={cnCreateForm('Box-Input')}>
                            <div>
                                <label htmlFor="initPositionX">Позиция по гор-ли в мм</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={initPositionX}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="initPositionX"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>
                            <div>
                                <label htmlFor="initPositionY">Позиция по вертикали в мм</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={initPositionY}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="initPositionY"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>
                            <div>
                                <label htmlFor="initPositionZ">Позиция в глубину в мм</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={initPositionZ}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="initPositionZ"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>
                        </div>}
                    </>}


                    <Tumbler
                        className={cnCreateForm('Tumbler')}
                        checked={margin}
                        callBack={onChecked}
                        label="Добавить отступы для элемента"
                        name="margin"
                    />

                    {margin && <div className={cnCreateForm('Box-Input')}>
                        <div>
                            <label htmlFor="marginX">Отступ по горизонтали в мм</label>
                            <Textinput
                                className={cnCreateForm('Textinput')}
                                value={marginX}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="marginX"
                                onFocus={onFocus}
                                type="number"
                            />
                        </div>
                        {!keyValue.includes("legs") &&
                        <div>
                            <label htmlFor="marginY">Отступ по вертикали в мм</label>
                            <Textinput
                                className={cnCreateForm('Textinput')}
                                value={marginY}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="marginY"
                                onFocus={onFocus}
                                type="number"
                            />
                        </div>}
                        <div>
                            <label htmlFor="marginZ">Отступ в глубину в мм</label>
                            <Textinput
                                className={cnCreateForm('Textinput')}
                                value={marginZ}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="marginZ"
                                onFocus={onFocus}
                                type="number"
                            />
                        </div>
                    </div>}

                    <p className="text-danger">{error}</p>
                </div>

                <Button
                    className={cnCreateForm('Button')}
                    type={'button'}
                    text={`${isEdit ? 'Редактировать' : 'Добавить'} элемент ${name}`}
                    leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                    mods={{
                        mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                        hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                    }}
                    callBack={handleSubmit}
                />

    </Modal>

  )
}

export default ModalValues