import {ThreeKUnit} from '../ThreeKUnit/ThreeKUnit';
import {ThreeLeg} from '../../details/ThreeLeg/ThreeLeg';
import {KitchenService} from '../../../../services/KitchenService/KitchenService';
import {Box3, Vector3} from 'three';
import {
    LEG_NORMAL,
    LEG_SMALL,
    SIDE_TYPE_BOTTOM,
} from '../../../../../../common-code/constants';
import {ThreePlinth} from '../../details/ThreePlinth/ThreePlinth';
import {ThreeLegSmall} from "../../details/ThreeLeg/types/ThreeLegSmall";
import {ThreeLegNormal} from "../../details/ThreeLeg/types/ThreeLegNormal";
import {ISavePenalUnitData} from '../../../../../../common-code/interfaces/saveData/ISavePenalUnitData';
import {ISaveLegData} from '../../../../../../common-code/interfaces/saveData/ISaveLegData';
import {CommonHelper} from 'common-code';

export class ThreePenalUnit extends ThreeKUnit {
    saveData: ISavePenalUnitData;
    legs: ThreeLeg[];
    plinths: ThreePlinth[];

    constructor(options: ISavePenalUnitData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.legs = [];
        this.plinths = [];
        this.transparentForBack = true;
    }
    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
        this.createLegs();
    }

    protected calculateChildrenGlobalFrontVector() {
        super.calculateChildrenGlobalFrontVector();
        let leg: ThreeLeg;

        for (leg of this.legs) {
            leg.calculateGlobalFrontVector();
        }
    }

    protected createLegs() {
        this.saveData.legs = this.calculateInitLegsData(this.saveData.legs);
        if (!this.saveData.legs) {
            return;
        }
        let legData: ISaveLegData;
        let leg: ThreeLeg;
        let box: Box3 = new Box3();

        for (legData of this.saveData.legs) {
            switch (this.saveData.legType) {
                case LEG_SMALL:
                    leg = new ThreeLegSmall(CommonHelper.deepCopy(legData), this);
                    break;
                case LEG_NORMAL:
                default:
                    leg = new ThreeLegNormal(CommonHelper.deepCopy(legData), this);
            }
            leg.initState();
            leg.createView();
            // leg.view3d.userData.transparentAngle = -0.4;
            this.view3d.add(leg.view3d);
            box.setFromObject(leg.view3d);
            this.legs.push(leg);
            this.addCoverPoints([this.view3d.worldToLocal(box.min), this.view3d.worldToLocal(box.max)]);
        }
    }

    protected calculateInitLegsData(legs?: ISaveLegData[]): ISaveLegData[] | undefined {
        return legs;
    }

    public setPosition(position?: Vector3): void {
        if (position) {
            this.view3d.position.copy(position);
        } else {
            if (this.view3d.position.y !== this.defaultYPosition()) {
                this.view3d.position.y = this.defaultYPosition();
            }
        }
        this.cover.position.copy(this.view3d.position);
        this.cover.userData.oldPosition =
            this.cover.userData.correctPosition = this.cover.position.clone();
        this.updateAllMatrices();
    }

    public defaultYPosition(): number {
        return this.getSideDistance(SIDE_TYPE_BOTTOM);
    }
}